@if(showForm()) {
    <form [formGroup]="form" class="file-upload__native-selection">
        <input type="file" #fileSelector
               formControlName="file_selection"
               [accept]="acceptedMimeTypes">
    </form>
    <ion-row>
        <ion-col>
            <ion-item>
                <ion-label>{{inputLabel}}</ion-label>
                <ion-button
                    title="{{inputLabel}}"
                    size="small"
                    fill="outline"
                    (click)="openFileSelector()"
                ><mat-icon
                    [icon]="TitleIcon.DOCUMENT"
                    [theme]="IconTheme.INHERIT"
                ></mat-icon>
                </ion-button>
            </ion-item>
        </ion-col>
    </ion-row>
}
