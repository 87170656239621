import { Component, Inject, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent, cleanInjectedValue, AlertsService } from 'nb-form';
import { IonicModule, ModalController } from '@ionic/angular';
import { PERSON_SCHEMA } from '../../../schema/person.schema';
import { MyAccountService } from '../../../services/my-account.service';
import { AuthService, LoginResponseInterface, ToastService, LoadingService, formatPhoneNumber, ErrorService, IconDirective, IconEnum, IconThemeEnum } from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'lib-my-account-person-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        NbFormComponent,
        MatIcon
    ],
    templateUrl: './profil-person-modal.component.html',
    styleUrl: './profil-person-modal.component.scss',
    providers: [
        MyAccountService
    ]
})
export class ProfilPersonModalComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    personForm: Form;

    @ViewChild('personInstance')
    personInstance!: NbFormComponent

    disablePersonButton: WritableSignal<boolean> = signal(true);


    title = "Ihre Person";

    constructor(
        @Inject(PERSON_SCHEMA) private personSchema: FormPage,
        private myAccountService: MyAccountService,
        private modalController: ModalController,
        private authService: AuthService,
        private toastService: ToastService,
        private alertService: AlertsService,
        private loading: LoadingService,
        private errorService: ErrorService
    ) {
        const session = this.authService.getSession() as LoginResponseInterface;
        this.personForm =  new Form();
        this.personForm.id = 2;
        const tmpSchema = cleanInjectedValue(personSchema);
        tmpSchema.header = null;
        this.personForm.schema = [tmpSchema];
        this.personForm.data = {
            sex: session.sex,
            academic: session.academic,
            firstName: session.firstName,
            lastName: session.lastName,
            phone: session.phone
        };
    }

    onChangeForm() {
        if (this.personInstance) {
            const validator = this.personInstance.getValidator();
            this.disablePersonButton.set(!validator.validate());
        }
    }

    async clickUpdatePerson() {
        if (this.disablePersonButton()) {
            return;
        }

        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie jetzt die Stammdaten ändern?",
            textOk: "Ja",
            textCancel: "Nein"
        });

        if (confirmation) {
            await this.loading.start();

            const data = this.personForm.data;
            const normalizedPhone = formatPhoneNumber(data['phone'] as string);

            const result = await this.myAccountService.updatePerson({
                sex: data['sex'] as string,
                academic: data['academic'] as string,
                firstName: data['firstName'] as string,
                lastName: data['lastName'] as string,
                phone: normalizedPhone,
                language: null
            }).catch(async (e) => {
                await this.loading.stop();
                return null;
            });

            if (result) {
                this.authService.updatePersonInSession(
                    data['sex'] as string,
                    data['academic'] as string,
                    data['firstName'] as string,
                    data['lastName'] as string,
                    data['phone'] as string
                );
                await this.loading.stop();
                await this.toastService.presentToast("Ihre Stammdaten wurden erfolgreich aktualisiert.");
                await this.modalController.dismiss({
                    saved: true
                });
            } else {
                this.errorService.highlightFormErrors(this.personInstance, this.personSchema);
            }
        }
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }


}
