import { SkillInterface } from "@nf-workforce/shared";

export function skillsToOptions(options: SkillInterface[]): { key: string, value: string }[] {
    return options.map((val) => {
        return {
            key: val.id.toString(),
            value: val.label
        };
    })
}
