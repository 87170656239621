import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    AgencyMemberInterface,
    BooleanToStringPipe,
    DriverInterface,
    FullnamePipe,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    TitleIconEnum
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';
import { DriverStatusCardComponent } from '../driver-status-card/driver-status-card.component';

@Component({
    selector: 'agency-driver-profile-image-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        FullnamePipe,
        IconDirective,
        MatIcon,
        BooleanToStringPipe,
        PrintNbDateTimePipe,
        DriverStatusCardComponent
    ],
    templateUrl: './driver-profile-image-card.component.html',
    styleUrl: './driver-profile-image-card.component.scss',
    providers: [
        PrintNbDatePipe,
        FullnamePipe,
    ]
})
export class DriverProfileImageCardComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    account!: AgencyMemberInterface;
    @Input()
    driver!: DriverInterface;
    @Input()
    profileImage!: string | null;
    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    showPlace: WritableSignal<boolean> = signal(false);

    constructor(
        private printNbDatePipe: PrintNbDatePipe,
    )
    {}
    ngOnInit(): void {
        if (this.account !== null && this.driver !== null && this.profileImage !== null ) {
            this.showPlace.set(true);
        }
    }

    reloadDriver() {
        this.reload.emit(true);
    }

}
