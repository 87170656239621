@if (agencyMemberForm) {
<ion-content>
    <ion-row>
        <ion-col>
            <ion-card>
                <ion-card-content>
                    <nb-form
                        [form]="agencyMemberForm"
                        appearance="outline"
                        [delay]="0"
                        [debug]="false"
                    ></nb-form>
                </ion-card-content>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-button
                            fill="outline"
                            [color]="IconTheme.SECONDARY"
                            (click)="clickGoBack()">
                            <mat-icon [icon]="IconEnum.BACK" [theme]="IconTheme.INHERIT"></mat-icon>
                            Zurück
                        </ion-button>
                    </ion-buttons>
                    <ion-buttons slot="end">
                        <ion-button
                            fill="outline"
                            expand="block" (click)="clickSave()"
                            [color]="IconTheme.SUCCESS"
                        ><mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT"></mat-icon>
                            Account anlegen
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-card>

        </ion-col>
    </ion-row>

</ion-content>
}
