<ion-content>
    <ion-col class ="ion-padding">
        <ion-row class ="ion-text-center">
            @if (messages().length < 1) {
                <ion-col>
                    <ion-text class="ion-text-center">
                        Sie haben bisher noch keine Nachrichten erhalten.
                    </ion-text>
                </ion-col>
               
            } @else {
                <ion-col>
                    <div uiTable class="expand-xl fixed-header w-100" [changeEmitter]="changeEmitter">
                        <div uiTableHead>
                            <div uiTableCell align="right">
                                #
                            </div>

                            <div uiTableCell>
                                Betreff
                            </div>

                            <div uiTableCell>
                                Nachricht
                            </div>

                            <div uiTableCell>
                                Sender
                            </div>

                            <div uiTableCell></div>

                        </div>
            
                        @for (message of messages(); track message.id) {
                            <div uiTableRow>
                                <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                    <div uiTableKicker># {{ message.id }}</div>
                                    <div class="headline"><h3>{{ message.betreff }}</h3></div>
                                </div>
            
                                <div uiTableCell align="right" [hideOnCollapse]="true">
                                    {{ message.id }}
                                </div>
            
                                <div uiTableCell>
                                    <span uiTableCellLabel>
                                        Betreff:
                                    </span>
                                    {{ message.betreff }}
                                </div>
            
                                <div uiTableCell>
                                    <span uiTableCellLabel>
                                        Nachricht:
                                    </span>
                                    <span class ="whitespace-pre-wrap">
                                        {{ message.nachricht }}
                                    </span>
                                </div>
            
                                <div uiTableCell>
                                    <span uiTableCellLabel>
                                        Sender:
                                    </span>
                                    {{ message.sender.userName}}
                                </div>

                                <div uiTableCell>
                                    <span uiTableCellLabel>
                                        Jobdetails:
                                    </span>

                                   <ion-button size="small" fill ="outline" (click)="clickViewJob(message)">
                                        Job ansehen
                                   </ion-button>
                                </div>
                            </div>
                        }
            
                        @if (enableMoreResults()) {
                            <div uiTableFooter>
                                <ion-button size="small" fill="outline" (click)="clickLoadNextPage()">
                                    <ion-icon name="cloud-download-sharp" class="@todo-68"></ion-icon>
                                    mehr Ergebnisse laden
                                </ion-button>
                            </div>
                        }
                    </div>
                </ion-col>
            }
        </ion-row>
       
    </ion-col>
</ion-content>


