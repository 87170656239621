import { Component, Input, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import {
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    TitleIconEnum
} from '@nf-workforce/shared';

@Component({
    selector: 'joblist-workforce-job',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        PrintNbDateTimePipe,
        PrintNbDatePipe
    ],
    templateUrl: './workforce-job.component.html',
    styleUrl: './workforce-job.component.scss',
})
export class WorkforceJobComponent {

    @Input()
    job!: JobListInterface | null;

    showContent: WritableSignal<boolean> = signal(false);

    get isActive(): boolean {
        if (this.job) {
            return this.job.isActive;
        }
        return false;
    }

    onClickShowContent() {
        this.showContent.set(!this.showContent());
    }

    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
    protected readonly IconEnum = IconEnum;
}
