import { InjectionToken } from '@angular/core';
import { getTimeZoneOptions } from '@nf-workforce/shared';
import { FormPage } from 'nb-form';


export const JOB_LIST_SCHEMA = new InjectionToken<FormPage>('"Jobliste Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'joblist',
            navigation_label: 'joblist',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'joblist-name',
                    type: 'textarea',
                    model: 'name',
                    label: 'Name',
                    placeholder: 'Name des Jobs',
                    required: true,
                    columnSize: 12,
                    maxRows: 1
                },
                {
                    id: 'joblist-description',
                    type: 'textarea',
                    model: 'description',
                    label: 'Beschreibung',
                    placeholder: 'Beschreibung des Jobs',
                    required: true,
                    columnSize: 12,
                    rows: 2
                },
                {
                    id: 'joblist-moderated',
                    type: 'checkbox',
                    model: 'moderated',
                    label: 'Moderierte Bewerbungen',
                    columnSize: 12,
                    default: false
                },
                {
                    id: 'joblist-free-places',
                    type: 'number',
                    model: 'freePlaces',
                    label: 'Anzahl Plätze',
                    required: true,
                    columnSize: 12
                },
                {
                    id: 'joblist-duration-text',
                    type: 'sub-header',
                    label: 'Laufzeit des Jobs',
                    columnSize: 12,
                },
                {
                    id: 'joblist-start-date',
                    type: 'date',
                    model: 'startDate',
                    label: 'Start',
                    required: true,
                    columnSize: 6,
                    showTime: true
                },
                {
                    id: 'joblist-end-date',
                    type: 'date',
                    model: 'endDate',
                    label: 'Ende',
                    required: true,
                    columnSize: 6,
                    showTime: true
                },
                {
                    id: 'joblist-timezone',
                    type: 'nb-dropdown',
                    model: 'timezone',
                    label: 'Zeitzone auswählen',
                    options: getTimeZoneOptions(),
                    columnSize: 12
                },
                {
                    id: 'joblist-bewerbung-text',
                    type: 'sub-header',
                    label: 'Bewerbungsfristen',
                    columnSize: 12,
                },
                {
                    id: 'joblist-show-date',
                    type: 'date',
                    model: 'showDate',
                    label: 'Start',
                    required: true,
                    columnSize: 4
                },
                {
                    id: 'joblist-close-date',
                    type: 'date',
                    model: 'closeDate',
                    label: 'Ende',
                    required: true,
                    columnSize: 4
                },
                {
                    id: 'joblist-deadline',
                    type: 'date',
                    model: 'deadline',
                    label: 'Fahrer darf sich austragen bis',
                    required: false,
                    columnSize: 4
                }
            ]
        };
    }
});

