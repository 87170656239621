
<mat-form-field>
    <mat-label>
        {{ label }}
    </mat-label>
    <input type="text" matInput [formControl]="formControl" [matAutocomplete]="auto">
    @if (formControl.value !== null && formControl.value.length > 0) {
        <ion-icon matSuffix name="close-outline" size="large" (click)="resetAutocomplete()"></ion-icon>
    }

    <mat-autocomplete #auto="matAutocomplete">
        @for (option of options; track option.key) {
            <mat-option [value]="option.value" (click)="optionSelected()">{{option.value}}</mat-option>
        }
    </mat-autocomplete>
    @if (options.length < 1 && selectedOption === null) {
        <mat-hint color="warning">
            Es wurden keine Ergebnisse gefunden, bitte ändern Sie Ihre Eingabe.
        </mat-hint>
    }
  
</mat-form-field>
