import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
    const authService = inject(AuthService);
    const router = inject(Router);
    const userSession = authService.getSession();

    // Durchlassen, wenn der Benutzer eine aktive Session hat
    if (userSession !== undefined) {
        return true;
    }

    // Bei keiner aktiven Session nach /login routen
    router.navigate(['/login']);
    return false;
  }