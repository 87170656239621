import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { IconDirective, IconEnum, IconThemeEnum, JobListInterface, LoadingService, ToastService } from '@nf-workforce/shared';
import { JobListService } from '../../../../services/job-list.service';
import { JobKnowledgeHelperService } from '../../../../services/job-knowledge-helper.service';
import { EavRequestInterface } from '../../../../interface/joblist/eav-request.interface';

@Component({
    selector: 'joblist-edit-knowledge-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon
    ],
    templateUrl: './edit-job-knowledge-modal.component.html',
    styleUrl: './edit-job-knowledge-modal.component.scss',
    providers: [
        JobKnowledgeHelperService,
        JobListService
    ]
})
export class EditJobKnowledgeModalComponent implements OnInit{
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    job!: JobListInterface;

    @Input()
    formType!: string;


    constructor(
        private knowledgeHelper: JobKnowledgeHelperService,
        private modalController: ModalController,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private jobListService: JobListService,
    ) {

    }

    ngOnInit() {
        if (this.job !== null && this.formType !== null) {
            this.knowledgeHelper.setJob(this.job);
        }
    }

    get title():string {
        switch (this.formType) {
            case 'fahrererlaubnis':
                return 'Fahrerlaubnis bearbeiten';
            case 'skills':
                return 'Skills bearbeiten';
            default:
                return 'Sprachen bearbeiten';
        }
    }

    get formSkills() {
        switch (this.formType) {
            case 'fahrererlaubnis':
                return this.knowledgeHelper.eavFahrerlaubnisse;
            case 'skills':
                return this.knowledgeHelper.eavSkills;
            default:
                return this.knowledgeHelper.eavLanguages;
        }
    }

    isSkillActive(eavId: number) {
        return this.knowledgeHelper.isEavActivated(eavId, this.formType);
    }

    getSkillName(eavId: number) {
        return this.knowledgeHelper.getSkillName(eavId, this.formType);
    }

    async onSkillChange(event: CustomEvent) {
        const payload = {
            eavId: parseInt(event.detail.value, 10),
            isActive: event.detail.checked
        } as EavRequestInterface;
        await this.loadingService.start();
        const result = await this.patch(payload);
        await this.loadingService.stop();
        if (result === null) {
            await this.toastService.presentToast('Es ist ein Fehler aufgetreten');
        }
    }

    async patch(payload: EavRequestInterface) {
        if (this.formType === 'fahrererlaubnis') {
            return await this.jobListService.patchFahrerlaubnis(this.job.id, payload)
                .catch((e) => {
                    console.debug("Fehler beim Speichern des Requests", e);
                    return null;
                });
        } else if (this.formType === 'skills') {
            return await this.jobListService.patchSkills(this.job.id, payload)
                .catch((e) => {
                    console.debug("Fehler beim Speichern des Requests", e);
                    return null;
                });
        } else if (this.formType === 'languages') {
            return await this.jobListService.patchLanguages(this.job.id, payload)
                .catch((e) => {
                    console.debug("Fehler beim Speichern des Requests", e);
                    return null;
                });
        }
        return null;
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: true
        });
    }
}
