import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import {
    AgencyMemberInterface,
    BooleanToStringPipe,
    FullnamePipe,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    PageAction,
    PageActionService,
    PageFilterAction,
    PrintNbDateTimePipe,
    SearchMenuInterface,
    SearchQueryInterface,
    TitleIconEnum
} from '@nf-workforce/shared';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableKickerDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { agencyDriverSearch } from '../../search/agency-driver-list.config';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { DriverAgencyProfileStatePipe } from '../../pipes/driver-agency-profile-state.pipe';

@Component({
    selector: 'agency-my-agency-drivers',
    standalone: true,
    imports: [
        BooleanToStringPipe,
        CommonModule,
        FullnamePipe,
        IonicModule,
        PrintNbDateTimePipe,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableKickerDirective,
        TableRowDirective,
        IconDirective,
        MatIcon,
        DriverAgencyProfileStatePipe
    ],
    templateUrl: './my-agency-drivers.component.html',
    providers: []
})
export class MyAgencyDriversComponent implements OnInit {

    changeEmitter: Observable<AgencyMemberInterface[]>;
    drivers: WritableSignal<AgencyMemberInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);
    hasDrivers: WritableSignal<boolean> = signal(false);

    private limit = 10;
    private nextPage = 1;
    private searchQuery: SearchQueryInterface = { search: {}, sort: {} };

    constructor(
        @Inject(agencyDriverSearch) private searchConfig: SearchMenuInterface,
        private agencyMemberService: MyAgencyMemberService,
        private pageActionService: PageActionService,
        private router: Router,
    ) {
        this.changeEmitter = toObservable(this.drivers);
    }

    async ngOnInit(): Promise<void> {
        const drivers = await this.loadDriverList()
        this.drivers.set(drivers);
        this.pageActionService.addAction(PageAction.route(
            ' Fahrer:in anlegen',
            '/my-agency/add-driver',
            'agency_driver_new'
        ));
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({ search: {}, sort: {} });
            }
        ));
    }

    private async loadDriverList(): Promise<AgencyMemberInterface[]> {
        const driverResponse = await this.agencyMemberService.getDriverList(this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.error('Fehler bei der Anfrage', e);
                return null;
        });
        if (driverResponse) {
            this.nextPage = driverResponse.page + 1;
            if (driverResponse.maxResults > 0) {
                this.hasDrivers.set(true);
            } else {
                this.hasDrivers.set(false);
            }
            if (this.nextPage <= driverResponse.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }
            return driverResponse.data;
        }
        return [];
    }

    /**
     * Laden weiterer Ergebnisse
     */
    async clickLoadNextPage() {
        const currentDrivers = this.drivers();
        const newDrivers = await this.loadDriverList();
        this.drivers.set(currentDrivers.concat(newDrivers));
    }

    /**
     * Navigation zum anlegen neuer Fahrer
     */
    clickAddDriver() {
        this.router.navigate(['/my-agency/add-driver']);
    }

    clickGoToDetails(driver: AgencyMemberInterface) {
        this.router.navigate([`/my-agency/driver/${driver.id}`]);
    }

    async searchChanged($event: SearchQueryInterface) {
        this.nextPage = 1;
        this.searchQuery = $event;
        this.drivers.set(await this.loadDriverList());
    }

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
}
