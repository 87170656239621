import { AgencyMemberComponent } from '../agency-member/agency-member.component';
import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { EditAgencyMemberComponent } from '../edit-agency-member/edit-agency-member.component';
import { AgencyInterface, ENV, FullnamePipe, IconDirective, IconEnum, IconThemeEnum, LoadingService } from '@nf-workforce/shared';
import { IonicModule, ModalController } from '@ionic/angular';
import { MyAgencyService } from '../../services/my-agency.service';
import { AgencyLogoComponent } from './agency-logo/agency-logo.component';
import { EditAgencyComponent } from './edit-agency/edit-agency.component';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
    selector: 'agency-my-agency-edit',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        AgencyMemberComponent,
        EditAgencyMemberComponent,
        AgencyLogoComponent,
        EditAgencyComponent,
        FullnamePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './my-agency-edit.component.html',
    styleUrl: './my-agency-edit.component.scss',
})
export class MyAgencyEditComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    agency: WritableSignal<AgencyInterface | null> = signal(null);

    constructor(
        private loadingService: LoadingService,
        private myAgencyService: MyAgencyService,
        private modalController: ModalController,
        private router: Router,
    ) { }

    async ngOnInit(): Promise<void> {
        await this.loadAgency();
    }

    get logoUrl(): string {
        return this.agency
            ? `${ENV.apiUrl}/public/images/274e2d31-a2e7-461f-bda1-36c4d766c519/agency/${this.agency()?.id}/logo`
            : '';
    }

    get phone(): string {
        const phone = this.agency()?.phone;
        if (typeof phone === 'string' && phone !== '') {
            return phone;
        }
        return '-';
    }

    get web(): string {
        const web = this.agency()?.web;
        if (typeof web === 'string' && web !== '') {
            return web;
        }
        return '-';
    }

    get description(): string {
        const description = this.agency()?.description;
        if (typeof description === 'string' && description !== '') {
            return description;
        }
        return '-';
    }

    /**
     * Laden der Agentur, zu welcher der Benutzer gehört
     */
    async loadAgency() {
        this.agency.set(null);
        await this.loadingService.start();
        const agencyResult = await this.myAgencyService.getAgency()
            .catch((e) => {
                console.warn("Fehler beim LAden der Agentur", e);
                return null;
            });
        await this.loadingService.stop();
        if (agencyResult) {
            this.agency.set(agencyResult);
        }
    }

    async clickGoToAgencyLogo() {
        await this.router.navigate(['/my-agency/agency-logo']);
    }

    async clickEditAgency() {
        const modal = await this.modalController.create({
            component: EditAgencyComponent,
            componentProps: {
                agency: this.agency()
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    await this.loadAgency();
                }
            }
        }
    }
}
