import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    AuthService,
    DriverProfileInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoginResponseInterface,
    PrintNbDatePipe,
    TitleIconEnum,
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-stammdaten-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './profile-stammdaten.component.html',
    styleUrl: './profile-stammdaten.component.scss',
})
export class ProfileStammdatenComponent {
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
    protected readonly IconEnum = IconEnum;

    user: LoginResponseInterface;

    @Input()
    profile: DriverProfileInterface | null = null;

    @Output()
    edit: EventEmitter<string> = new EventEmitter();

    clickEditSubProfile(profileType: string) {
        this.edit.emit(profileType);
    }

    constructor(
        private authService: AuthService,
    ) {
        this.user = this.authService.getSession() as LoginResponseInterface;
    }

}
