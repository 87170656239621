import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import {
    DriverDataInterface,
    DriverFahrerlaubnisEnum,
    EavService,
    FahrerlaubnisEavInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    ToastService
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import {DriverFahrerlaubnisInterface} from '@nf-workforce/shared';
import { NbFormComponent } from 'nb-form';
import { DriverEavRequestInterface } from '../../../../interface/requests/driver-eav-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteDriverEavRequestInterface } from '../../../../interface/requests/delete-driver-eav-request.interface';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-fahrerlaubnis',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
        IconDirective,
        MatIcon
    ],
    templateUrl: './profile-fahrerlaubnis.component.html',
    styleUrl: './profile-fahrerlaubnis.component.scss',
})
export class ProfileFahrerlaubnisComponent implements OnInit{

    eavKlassen: FahrerlaubnisEavInterface[] = [];
    activeKlassenIds: number[] = [];
    driverFahrerlaubnis: DriverFahrerlaubnisInterface[] | null = null;
    showPlace: WritableSignal<boolean> = signal(false);

    @Input()
    driver: DriverDataInterface | null = null;

    constructor(
        private driverProfileService: DriverProfileService,
        private eavService: EavService,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private modalController: ModalController,
    ) {
        const eavFahrerlaubnis = this.eavService.getFahrerlaubnis();
        if (eavFahrerlaubnis) {
            this.eavKlassen = eavFahrerlaubnis;
        } else {
            throw new Error('EavFahrerlaubnis nicht gesetzt');
        }
    }

    setActiveKlassenIds() {
        this.showPlace.set(false);
        this.activeKlassenIds = [];
        if (this.driver) {
            this.driverFahrerlaubnis = this.driver.driverUserDto.fahrerlaubnis;
        }
        if (this.eavKlassen
            && this.eavKlassen.length > 0
            && this.driverFahrerlaubnis
            && this.driverFahrerlaubnis.length > 0
        ) {
            this.driverFahrerlaubnis.forEach((skill) => {
                this.activeKlassenIds.push(skill.eavId);
            });
        }
        this.showPlace.set(true);
    }

    async ngOnInit() {
        this.setActiveKlassenIds();
    }

    isSkillActive(eavSkill: FahrerlaubnisEavInterface) {
        const result = this.activeKlassenIds.find(activeSkill => {
            return activeSkill === eavSkill.id;
        });
        return (typeof result !== 'undefined' && result > 0);
    }

    getDriverKlassenId(eavSkill: FahrerlaubnisEavInterface) {
        const result = this.activeKlassenIds.find(activeSkill => {
            return activeSkill === eavSkill.id;
        });
        if (typeof result !== 'undefined' && result > 0) {
            return result;
        }
        return null;
    }

    private async addKlasse(idEav: number) {
        await this.loadingService.start();
        const payload = {
            eavId: idEav
        } as DriverEavRequestInterface;
        const result = await this.driverProfileService.patchDriverFahrerlaubnis(payload)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler beim Speichern, bitte versuchen Sie es später erneut.");
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert.")
        }
    }

    private async removeKlasse(skillId: number) {
        await this.loadingService.start();
        const payload = {
            id: skillId
        } as DeleteDriverEavRequestInterface;
        const result = await this.driverProfileService.deleteDriverFahrerlaubnis(payload)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler beim Speichern, bitte versuchen Sie es später erneut.");
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert.")
        }

    }

    async onSkillChange(event: CustomEvent) {
        const eavId = parseInt(event.detail.value, 10);
        if (event.detail.checked) {
            await this.addKlasse(eavId);
        } else {
            if (this.driverFahrerlaubnis === null || this.driverFahrerlaubnis.length < 1) {
                console.warn("Kann keine Fahrerlaubnis löschen: Keine Fahrerlaubnis vorhanden");
                return;
            }
            const driverFahrerlaubnis = this.driverFahrerlaubnis.find((fahrerlaubnis) => {
                return (fahrerlaubnis.eavId === eavId);
            });
            if (!driverFahrerlaubnis) {
                return;
            }
            await this.removeKlasse(driverFahrerlaubnis.id);
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly DriverFahrerlaubnisEnum = DriverFahrerlaubnisEnum;
}
