import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { DriverListService } from '../../services/driver-list.service';
import { hasProperty, JobListInterface, LoadingService, TitleService } from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { JobListService } from '../../services/job-list.service';
import { WorkforceJobComponent } from './workforce-job/workforce-job.component';
import { WorkforceListComponent } from './workforce-list/workforce-list.component';
import { OneJobNavigationComponent } from '../one-job-navigation/one-job-navigation.component';

@Component({
    selector: 'joblist-workforce',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        WorkforceJobComponent,
        WorkforceListComponent,
        OneJobNavigationComponent
    ],
    providers: [
        DriverListService,
        JobListService,
    ],
    templateUrl: './workforce.component.html',
    styleUrl: './workforce.component.scss',
})
export class WorkforceComponent implements OnInit {

    private jobId = 0;

    job: WritableSignal<JobListInterface | null> = signal(null);


    constructor(
        private activatedRoute: ActivatedRoute,
        private jobService: JobListService,
        private loadingService: LoadingService,
        private titleService: TitleService,
    ) {}

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'id')) {
            this.jobId = parseInt(params['id']);
        }
        this.job.set(await this.loadJob());
        this.titleService.titleEmitter.emit(`Job #${this.job()?.id} Personal`);
    }

    /**
     * Laden des anzuzeigenden Jobs anhand der übergebenen Id
     *
     * @returns
     */
    private async loadJob(): Promise<JobListInterface> {
        await this.loadingService.start();
        const jobResult = await this.jobService.getOne(this.jobId)
            .catch((e: HttpErrorResponse) => {
                console.debug('Fehler bei der Anfrage: ', e);
                return null;
            });
        await this.loadingService.stop();

        if (jobResult && jobResult.data.length === 1) {
            return jobResult.data[0];
        }

        throw new Error(`Fehler beim Laden der Jobliste # ${this.jobId}`);
    }

    async reloadJobList() {
        this.job.set(null);
        this.job.set(await this.loadJob());
    }
}
