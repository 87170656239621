import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DocumentEavInterface } from '../interfaces/dto/document-eav.interface';
import { EavDocumentId } from '../enum/eav-document.enum';

@Injectable({
    providedIn: 'root'
})
export class EavService {

    driverDocuments: DocumentEavInterface[] | null = null;
    companyDocuments: DocumentEavInterface[] | null = null;
    documentsWithoutLicence: DocumentEavInterface[] | null = null;

    constructor(
        private authService: AuthService
    ) {}

    getSkills() {
        const session = this.authService.getSession();
        return session ? session.eavSkills : undefined;
    }

    getDocuments() {
        const session = this.authService.getSession();
        return session ? session.eavDocuments : undefined;
    }

    getDocsWithoutLicences() {
        if (this.documentsWithoutLicence === null) {
            const eavDocs = this.getDocuments();
            if (eavDocs) {
                const documentsWithoutLicence = eavDocs.filter((eav) => {
                    return (eav.id !== EavDocumentId.DRIVER_LICENCE_ID && eav.id !== EavDocumentId.DRIVER_PSHEIN_ID);
                });
                if (documentsWithoutLicence.length > 0) {
                    this.documentsWithoutLicence = documentsWithoutLicence;
                } else {
                    this.documentsWithoutLicence = null;
                }
            } else {
                this.documentsWithoutLicence = null;
            }
        }
        return this.documentsWithoutLicence;
    }

    getDriverDocument() {
        if (this.driverDocuments === null) {
            const documentsWithoutLicence = this.getDocsWithoutLicences();
            if (documentsWithoutLicence !== null) {
                if (documentsWithoutLicence.length > 0) {
                    const docs = documentsWithoutLicence.filter((eav) => {
                        return eav.onlyDriver;
                    });
                    if (docs.length > 0) {
                        this.driverDocuments = docs;
                    }
                }
            }
        }
        return this.driverDocuments;
    }

    getCompanyDocuments() {
        if (this.companyDocuments === null) {
            const documentsWithoutLicence = this.getDocsWithoutLicences();
            if (documentsWithoutLicence !== null) {
                if (documentsWithoutLicence.length > 0) {
                    const docs = documentsWithoutLicence.filter((eav) => {
                        return eav.onlyCompany;
                    });
                    if (docs.length > 0) {
                        this.companyDocuments = docs;
                    }
                }
            }
        }
        return this.companyDocuments;
    }

    getDriverLicenceDocument() {
        const eavDocs = this.getDocuments();
        if (eavDocs) {
            return eavDocs.find(eav => {
                return (eav.id === EavDocumentId.DRIVER_LICENCE_ID)
            });
        }
        return undefined;
    }

    getPScheinDocument() {
        const eavDocs = this.getDocuments();
        if (eavDocs) {
            return eavDocs.find(eav => {
                return (eav.id === EavDocumentId.DRIVER_PSHEIN_ID)
            });
        }
        return undefined;
    }

    getCertDocument() {
        const eavDocs = this.getDocuments();
        if (eavDocs) {
            return eavDocs.find(eav => {
                return (eav.id === EavDocumentId.DRIVER_CERTS_ID)
            });
        }
        return undefined;
    }

    getOtherDocument() {
        const eavDocs = this.getDocuments();
        if (eavDocs) {
            return eavDocs.find(eav => {
                return (eav.id === EavDocumentId.DRIVER_OTHERS_ID)
            });
        }
        return undefined;
    }

    getCountries() {
        const session = this.authService.getSession();
        return session ? session.eavCountries : undefined;
    }

    getLanguages() {
        const session = this.authService.getSession();
        return session ? session.eavLanguages : undefined;
    }

    getFahrerlaubnis() {
        const session = this.authService.getSession();
        return session ? session.eavFahrerlaubnis : undefined;
    }
}
