@if (profile) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Stammdaten</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.ID_CARD"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Ihre Person</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"
                              (click)="clickEditSubProfile('profil-person')">
                    </mat-icon>
                </span>
                </label>
                <span class="driver-skill__skill">
                   {{ user.firstName }} {{ user.lastName }}
                    @if (user.phone) {
                        <br>{{ user.phone }}
                    }
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Adresse</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditSubProfile('wohnort')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    @if (profile.street) {
                        {{ profile.street }}<br>
                    }
                    @if (profile.zipCode || profile.city) {
                        {{ profile.zipCode }} {{ profile.city }} <br>
                    }
                    @if (profile.area) {
                        {{ profile.area }}&nbsp;,
                    }
                    @if(profile.country) {
                        {{ profile.country }}
                    }
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Geboren</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditSubProfile('birthday')">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                </span>
                </label>
                <span class="driver-skill__skill">
                    Am: {{ profile.birthday | printNbDate }}<br>
                    In: {{ profile.birthplace}}
                </span>
                <br>
                <span class="driver-skill__skill">
                    Staatsangehörigkeit: {{ profile.nationality }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Abrechnung</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditSubProfile('abrechnung')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    Steuer-ID: {{ profile.taxId }}<br>
                    KV-Nummer: {{ profile.kkNr }} <br>
                    RV-Nummer:  {{ profile.rvNr }} <br>
                    IBAN: {{ profile.iban }}
                </span>
            </div>
        </ion-card-content>
    </ion-card>
}
