import { FormElementOption } from "nb-form";
import { TIMEZONE_LIST } from "../timezones/timezones";

/**
 * Gibt die Zeitzonen als Liste für die übergabe als Nb-FOrm Options zurück
 * 
 * @returns 
 */
export function getTimeZoneOptions(): FormElementOption[] {
    return TIMEZONE_LIST.map((tzIdentifier) => {
        return {
            key: tzIdentifier,
            value: tzIdentifier
        };
    });
}