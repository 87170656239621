
export function addClass(
    nativeElement: Element | null | undefined,
    className: string
): void {
    if (!nativeElement) {
        console.warn('Warning bb8fe90e-1ad0-454c-84c0-203d357fbe0c');
        return;
    }
    if (nativeElement.classList.contains(className)) {
        return;
    }
    nativeElement.classList.add(className);
}

export function removeClass(
    nativeElement: Element | null | undefined,
    className: string
): void {
    if (!nativeElement) {
        console.warn('Warning 98476840-26cc-4119-a48f-2b3af832c8d9');
        return;
    }
    if (nativeElement.classList.contains(className)) {
        nativeElement.classList.remove(className);
    }
}
