import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'app-not-found',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
    ],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
    providers: []
})
export class NotFoundComponent {

}
