import { ChangeDetectorRef, Component, Inject, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent, cleanInjectedValue, AlertsService } from 'nb-form';
import { IonicModule, ModalController } from '@ionic/angular';
import { ACCOUNT_RECOVERY_SCHEMA } from '../../../schema/account-recovery.schema';
import { MyAccountService } from '../../../services/my-account.service';
import {
    ToastService,
    LoadingService,
    isString,
    IconDirective,
    IconEnum,
    IconThemeEnum
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'lib-my-account-password-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        NbFormComponent,
        MatIcon
    ],
    templateUrl: './profil-password-modal.component.html',
    styleUrl: './profil-password-modal.component.scss',
    providers: [
        MyAccountService
    ]
})
export class ProfilPasswordModalComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    passwordForm: Form;

    @ViewChild('passwordInstance')
    passwordInstance!: NbFormComponent

    passwordErrors: WritableSignal<string[]> = signal([]);
    disablePasswordButton: WritableSignal<boolean> = signal(true);

    title = "Neues Passwort";

    constructor(
        @Inject(ACCOUNT_RECOVERY_SCHEMA) private passwordSchema: FormPage,
        private myAccountService: MyAccountService,
        private modalController: ModalController,
        private toastService: ToastService,
        private alertService: AlertsService,
        private loading: LoadingService,
        private cd: ChangeDetectorRef
    ) {
        const tmpPasswordSchema = cleanInjectedValue(passwordSchema);
        tmpPasswordSchema.header = null;

        this.passwordForm =  new Form();
        this.passwordForm.id = 3;
        this.passwordForm.schema = [tmpPasswordSchema];
        this.passwordForm.data = {};
    }

    onChangeForm() {
        if (this.passwordInstance) {
            this.validatePasswordForm();
        }
    }

    async clickUpdatePassword() {
        if (this.disablePasswordButton()) {
            return;
        }

        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie jetzt das Passwortes ändern?",
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (confirmation) {
            await this.loading.start();
            const data = this.passwordForm.data;
            const result = await this.myAccountService.updatePassword({
                password: data['newPassword'] as string
            }).catch(async (e) => {
                await this.loading.stop();
                await this.toastService.presentToast("Fehler beim Speichern des neuen Passwortes, bitte versuchen Sie es später erneut.");
                return null;
            });

            if (result) {
                await this.loading.stop();
                await this.toastService.presentToast("Ihr Passwort wurde erfolgreich aktualisiert.");
                await this.modalController.dismiss({
                    saved: true
                });
            }
        }
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    private validatePasswordForm() {
        if (this.passwordInstance) {
            const validator = this.passwordInstance.getValidator();
            const result = validator.validate();

            if (!result && validator.errorFields.length === 1) {
                const errorControl = validator.errorFields[0].control;
                const controlErrors = errorControl.errors as { [key: string]: string };
                const errorKeys = Object.keys(controlErrors);

                // NbFormBadRequest rausfiltern + die zutreffenden Fehlermeldungen zurückgeben
                const errorMessages = errorKeys.filter((key) => {
                    return (key !== "nbFormBadRequest" && isString(controlErrors[key]));
                }).map((key) => {
                    return controlErrors[key];
                })

                this.passwordErrors.set(errorMessages);
                validator.highlightErrors = true;
                validator.setValidationErrors({
                    password: "Bitte überprüfen Sie Ihre Eingabe."
                });
                this.disablePasswordButton.set(true);
            } else {
                this.passwordErrors.set([]);
                validator.highlightErrors = false;
                this.disablePasswordButton.set(false);
            }
            this.cd.detectChanges();
        }
    }
}
