@if(driver) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            @if (eavSkills) {
                <ion-row>
                    <ion-col>
                        <ion-list>
                            @for (eavSkill of eavSkills; track eavSkill.id) {
                                <ion-item>
                                    <ion-toggle
                                        [enableOnOffLabels]="true"
                                        [checked]="isSkillActive(eavSkill)"
                                        (ionChange)="onSkillChange($event)"
                                        labelPlacement="start"
                                        [value]="eavSkill.id"
                                    >{{ eavSkill.label }}</ion-toggle>
                                </ion-item>
                            }
                        </ion-list>
                    </ion-col>
                </ion-row>
            }
        </ion-col>
    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CLOSE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Schließen
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>

}
