
export enum IconEnum {
    BACK = 'action__back',
    CANCEL = 'action__cancel',
    CLOSE = 'action__close',
    DELETE = 'action__delete',
    DOWNLOAD = 'action__download',
    EDIT = 'action__edit',
    NEW = 'action__new',
    SAVE = 'action__save',
    SEARCH = 'action__search',
    SEARCH_CLEAR = 'action__search_clear',
    SEARCH_MANAGE = 'action__search_manage',
    SHARE = 'action__share',
    UPLOAD = 'action__upload',
    VERIFIED = 'action__verified',
    OPEN_ACCORDION = 'action__open_accordion',
    ADD_USER = 'action__add_user',
    MOVE_USER_TO_LIST = 'action__move_user_to_list',
    REJECT_DRIVER_IN_JOBLIST = 'action__reject_driver_in_joblist',
    PUSH_JOB = 'action__push_job',
    DOWNLOAD_NEXT_PAGE = 'action__download_next_page',
}
