@if(agencyGroup!== null) {
    <ion-row>
        <ion-col>
            <mat-form-field appearance="outline">
                <mat-label>Fahrer:in suchen</mat-label>
                <input type="text"
                       matInput
                       [matAutocomplete]="auto"
                       [formControl]="searchDriver"
                       autocomplete="off"
                       matAutocompletePosition="below"
                       #autocomplete
                >
                <button
                    mat-icon-button
                    matSuffix
                    (click)="clearSelection()">
                    <mat-icon
                        [icon]="IconEnum.SEARCH_CLEAR"
                        [theme]="IconTheme.INHERIT" >
                    </mat-icon>
                </button>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onDriverSelected($event)"
                >
                    @for (option of filteredOptions | async; track option) {
                        <mat-option [value]="option.key">{{option.value}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </ion-col>
    </ion-row>
}
