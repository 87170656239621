import { Pipe, PipeTransform } from '@angular/core';
import { DriverAgencyState } from '@nf-workforce/shared';

@Pipe({
    name: 'agencyStateColor',
    standalone: true
})
export class AgencyStateColorPipe implements PipeTransform {

    transform(value: number): string {

        switch (value) {
            case DriverAgencyState.NEW: {
                return "primary";
            }
            case DriverAgencyState.VALIDATE: {
                return "warning";
            }
            case DriverAgencyState.VALIDATED: {
                return "success";
            }
            default: {
                return "danger";
            }
        }
    }


}
