@if(showPlace()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header driver-account-header--masterdata">
                <h2 class="driver-account-header__text">
                    {{ user.firstName }}<br>
                    {{ user.lastName }}<br>
                    <div class="driver-account-header__links">
                        {{ user.username }}
                        @if (user.phone) {
                            <br>{{ user.phone }}
                        }
                    </div>
                </h2>
                <img class="driver-account-header__image"
                     [src]="profileImage"
                     alt="{{user | fullname }}"
                >
            </div>
        </ion-card-header>
    </ion-card>
    <br/>
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Ihr Account</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.ACCOUNT"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Benutzername</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"
                              (click)="clickEdit('profil-username')">
                    </mat-icon>
                </span>
                </label>
                <span class="driver-skill__skill">
                   {{ user.username }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Passwort setzen</span>
                    <span class="driver-skill__edit-button show-on-hover" role="button">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"
                              (click)="clickEdit('profil-password')">
                    </mat-icon>
                </span>
                </label>
            </div>
        </ion-card-content>
    </ion-card>
    <br/>
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">{{ agencyHeadline }}</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.DRIVER_AGENCY"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            @for (driverAgency of driverAgencies; track driverAgency.id) {
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">{{driverAgency.agencyName}}</span>
                    @if (canValidate(driverAgency)) {
                        <span class="driver-skill__edit-button show-on-hover" role="button">
                        <mat-icon [icon]="IconEnum.SHARE" [theme]="IconTheme.INHERIT"
                                  (click)="clickRequestValidation(driverAgency)">
                        </mat-icon>
                        </span>
                    }
                </label>
                <span class="driver-skill__skill">
                    <ion-text [color]="driverAgency.status | agencyStateColor">
                        {{ driverAgency.status | agencyState }}
                    </ion-text>
                </span>
                
                @if (driverAgency.status === 500) {
                    <br>
                    <span class="driver-skill__skill">
                        <ion-text [color]="driverAgency.status | agencyStateColor">
                            Grund: {{ driverAgency.rejectionNotice }}
                        </ion-text>
                    </span>
                }
            </div>
            <br>
            }
        </ion-card-content>
    </ion-card>
}
