import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { Router } from '@angular/router';
import { IconDirective, IconEnum, IconThemeEnum, JobListInterface, TitleIconEnum } from '@nf-workforce/shared';
import { CreateBulkMessageComponent } from '@nf-workforce/message';

@Component({
    selector: 'joblist-one-job-navigation',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
    ],
    templateUrl: './one-job-navigation.component.html',
    styleUrl: './one-job-navigation.component.scss',
})
export class OneJobNavigationComponent implements OnInit{
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    jobId!: number;
    isActive!: boolean;

    @Input()
    job!: JobListInterface | null;

    @Input()
    actView!: string;

    constructor(
        private router: Router,
        private modalController: ModalController
    ) {}


    async clickGoToList() {
        await this.router.navigate(['/jobs'])
    }

    async goToDrivers() {
        await this.router.navigate([`/jobs/${this.jobId}/workforce`])
    }

    async clickEditJob() {
        await this.router.navigate([`/jobs/${this.jobId}`]);
    }

    ngOnInit() {
        if (this.job) {
            this.jobId = this.job.id;
            this.isActive = this.job.isActive;
        }
    }

     /**
     * 
     * ClickHandler zum erstellen einer BulkMessage
     * 
     * @returns 
     */
     async clickCreateBulkMessage() {
         if (this.job === null) {
             console.warn("Kein Job verfügbar");
            return;
        }

        const modal = await this.modalController.create({
            component: CreateBulkMessageComponent,
            componentProps: {
                job: this.job
            }
        });

        await modal.present();
        await modal.onDidDismiss();
     }
    
    /**
     * 
     * @returns 
     */
    async clickNavigateToMessages() {
        if (!this.job) {
            return;
        }

        return this.router.navigate([`/message/${this.job.id}/list`]);
    }

}
