import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { hasProperty } from '../function/has-property.function';
import { inject } from '@angular/core';
import { isAllowedByAcc } from '../function/is-allowed-by-acc.function';
import { ToastService } from '../services/toast.service';

/**
 * Allows or denies access to ACC protected routes. ACC routes have to be
 * defined in the data property of the given route.
 *
 * Example with string:
 * ```
 * {
 *   path: 'agency',
 *   data: { protectedBy: 'agency_list' },
 *   // ...
 * }
 * ```
 * Example with array:
 * ```git branc
 * {
 *   path: 'logout',
 *   data: { protectedBy: ['app_logout', 'api_logout'] },
 *   // ...
 * }
 * ```
 *
 * @see AccRouteDefinitionType for type of route data property `protectedBy`
 * @see CanActivateFn
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AccGuard: CanActivateFn = async (
    next: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
): Promise<boolean> => {

    // dependencies
    const data = next.data;
    const authService = inject(AuthService);
    const toastService = inject(ToastService);
    const router = inject(Router);

    // early return if not one required route ID has been specified
    if (!hasProperty(data, 'protectedBy')) {
        await toastService.presentToast('AccGuard: MISSING ROUTE ID');
        return false;
    }

    if (isAllowedByAcc(data['protectedBy'], authService.acl)) {
        return true;
    }

    await toastService.presentToast('Sie haben keinen Zugriff auf diese Seite.');
    return router.navigate(['/account/my-account']);
}
