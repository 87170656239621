<ion-col>
    <ion-row>

        @if (messages().length > 0) {
            <div uiTable class="expand-xl fixed-header w-100" [changeEmitter]="changeEmitter">
                <div uiTableHead>
                    <div uiTableCell align="right">
                        #
                    </div>
                    <div uiTableCell>
                        Betreff
                    </div>
                    <div uiTableCell>
                        Nachricht
                    </div>
                    <div uiTableCell>
                        Sender
                    </div>
                    <div uiTableCell>
                        Empfänger
                    </div>
                </div>
    
                @for (message of messages(); track message.id) {
                    <div uiTableRow>
                        <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                            <div uiTableKicker># {{ message.id }}</div>
                            <div class="headline"><h3>{{ message.betreff }}</h3></div>
                        </div>
    
                        <div uiTableCell align="right" [hideOnCollapse]="true">
                            {{ message.id }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>
                                Betreff:
                            </span>
                            {{ message.betreff }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>
                                Nachricht:
                            </span> 
                            {{ message.nachricht }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>
                                Sender:
                            </span>
                            {{ message.sender.userName}}
                        </div>

                        <div uiTableCell>
                            <span uiTableCellLabel>
                                Empfänger:
                            </span>
                            {{ message.recipients | recipients }}
                        </div>
    
                    </div>
                }
    
                @if (enableMoreResults()) {
                    <div uiTableFooter>
                        <ion-button size="small" fill="outline" (click)="clickLoadNextPage()">
                            <ion-icon name="cloud-download-sharp" class="@todo-68"></ion-icon>
                            mehr Ergebnisse laden
                        </ion-button>
                    </div>
                }
            </div>
        } @else {
            <ion-col>
                <ion-text>
                    Keine Ergebnisse gefunden
                </ion-text>
            </ion-col>
        }
    
    </ion-row>
</ion-col>
