import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService, cleanInjectedValue, Form, FormPage, NbFormComponent, NbFormData } from 'nb-form';
import { AgencyInterface, ErrorService, IconDirective, IconEnum, IconThemeEnum, isString, LoadingService, ToastService } from '@nf-workforce/shared';
import { AGENCY_SCHEMA } from '../../../schema/agency-schema';
import { MyAgencyService } from '../../../services/my-agency.service';
import { IonicModule, ModalController } from '@ionic/angular';
import { UpdateAgencyRequestInterface } from '../../../interface/update-agency-request.interface';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-edit-agency',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
        IconDirective,
        MatIcon
    ],
    templateUrl: './edit-agency.component.html',
    styleUrl: './edit-agency.component.scss',
})
export class EditAgencyComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    agency!: AgencyInterface | null;

    agencyForm!: Form

    @ViewChild(NbFormComponent)
    agencyFormInstance!: NbFormComponent;

    constructor(
        private alertService: AlertsService,
        private loadingService: LoadingService,
        private myAgencyService: MyAgencyService,
        private toastService: ToastService,
        private modalController: ModalController,
        @Inject(AGENCY_SCHEMA) private agencySchema: FormPage,
        private errorService: ErrorService
    ) {}

    ngOnInit() {
        this.agencyForm = new Form();
        this.agencyForm.id = 2;
        const tmpSchema = cleanInjectedValue(this.agencySchema);
        tmpSchema.header = null;
        this.agencyForm.schema = [tmpSchema];
        this.agencyForm.data = this.createFormData();
    }


    createFormData(): NbFormData {
        return {
            name: this.agency?.name,
            fullName: this.agency?.fullName,
            description: this.agency?.description,
            phone: this.agency?.phone,
            web: this.agency?.web,
        };
    }

    formIsValid(): boolean {
        if (!this.agencyFormInstance) {
            alert('nö');
            return false;
        }

        return this.agencyFormInstance.getValidator().validate();
    }

    async clickSaveAgency() {
        if (!this.formIsValid()) {
            await this.toastService.presentToast("Bitte prüfen Sie Ihre Eingaben.");
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie jetzt die Änderungen speichern?",
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (confirmation) {
            await this.updateAgency();
        }

    }

    async updateAgency() {
        await this.loadingService.start();
        const result = await this.myAgencyService.updateAgency(this.createUpdatePayload())
            .catch(async (e) => {
                console.warn("Fehler bei der Anfrage: ", e);
                await this.toastService.presentToast("Fehler beim Aktualisieren der Agenturdaten, bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();

        if (result) {
            await this.toastService.presentToast("Die Agenturdaten wurden erfolgreich aktualisiert.");
            await this.modalController.dismiss({
                saved: true
            });
        } else {
            this.errorService.highlightFormErrors(this.agencyFormInstance, this.agencySchema);
        }
    }

    createUpdatePayload(): UpdateAgencyRequestInterface {
        const phone = (isString(this.agencyForm.data['phone'])) ?
            this.agencyForm.data['phone'] : null;
        const web = (isString(this.agencyForm.data['web'])) ?
            this.agencyForm.data['web'] : null;

        return {
            name: this.agencyForm.data['name'] as string,
            fullName: this.agencyForm.data['fullName']  as string,
            description: this.agencyForm.data['description']  as string,
            phone: phone,
            web: web
        };
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }


}
