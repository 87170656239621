import { Component, Inject, OnInit, ViewChild, ViewContainerRef, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService, Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { ENV, LoadingService, TitleService, ToastService, FileItem, isString, ErrorService, AgencyInterface, AgencyMemberInterface } from '@nf-workforce/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyService } from '../../services/agency.service';
import { AGENCY_SCHEMA } from '../../schema/agency-schema';
import { AgencyMemberComponent } from '../agency-member/agency-member.component';
import { EditAgencyMemberComponent } from '../edit-agency-member/edit-agency-member.component';
import { AGENCY_LOGO_SCHEMA } from '../../schema/agency-logo.schema';
import { AvatarComponent, PhotoCropperComponent } from '@nf-workforce/ui';
import { UpdateAgencyRequestInterface } from '../../interface/update-agency-request.interface';

@Component({
    selector: 'agency-one',
    standalone: true,
    imports: [
        AvatarComponent,
        CommonModule,
        IonicModule,
        NbFormComponent,
        AgencyMemberComponent,
        EditAgencyMemberComponent
    ],
    templateUrl: './agency-one.component.html',
    styleUrl: './agency-one.component.scss',
    providers: []
})
export class AgencyOneComponent implements OnInit {

    agencyId: number | null = null;
    agency: WritableSignal<AgencyInterface | null> = signal(null)
    agencyForm: Form | null = null;
    agencyLogoForm: Form | null = null;
    agencyMembers: WritableSignal<AgencyMemberInterface[]> = signal([]);
    editMember: WritableSignal<AgencyMemberInterface | null> = signal(null);

    get logoUrl(): string {
        if (this.agency()) {
            return `${ENV.apiUrl}/public/images/274e2d31-a2e7-461f-bda1-36c4d766c519/agency/${this.agency()?.id}/logo`;
        }
        return '';
    }

    @ViewChild('agencyFormInstance')
    private agencyFormInstance!: NbFormComponent

    @ViewChild('agencyLogoInstance')
    private logoFormInstance!: NbFormComponent

    /**
     * Gibt das Request zum aktualisieren der Agentur zurück
     */
    private get updateAgencyRequest(): UpdateAgencyRequestInterface {
        const description = (isString(this.agencyForm?.data['description'])) ?
            this.agencyForm?.data['description'] : null;
        
        const phone = (isString(this.agencyForm?.data['phone'])) ?
            this.agencyForm?.data['phone'] : null;
        
        const web = (isString(this.agencyForm?.data['web'])) ?
            this.agencyForm?.data['web'] : null;

        return {
            name: this.agencyForm?.data['name'] as string,
            fullName: this.agencyForm?.data['fullName'] as string,
            description: description,
            phone: phone,
            web: web
        };
    }

    constructor(
        private toastService: ToastService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(AGENCY_SCHEMA) private agencySchema: FormPage,
        @Inject(AGENCY_LOGO_SCHEMA) private agencyLogoSchema: FormPage,
        private agencyService: AgencyService,
        private loadingService: LoadingService,
        private alertService: AlertsService,
        private titleService: TitleService,
        private viewContainer: ViewContainerRef,
        private errorService: ErrorService
    ) { }

    async ngOnInit(): Promise<void> {
        this.agencyId = this.activatedRoute.snapshot.params['id'] as number;
        await this.loadAgency();
        if (this.agency()) {
            this.agencyForm = this.createForm(this.agency() as AgencyInterface);
            this.agencyLogoForm = this.createLogoForm();
        }
    }

    private async loadAgency() {
        if (!this.agencyId) {
            throw new Error("Keine Agentur-Id bekannt.");
        }

        await this.loadingService.start();
        const agency = await this.agencyService.getOne(this.agencyId);
        if (agency) {
            if (agency.members.length > 0) {
                this.agencyMembers.set(agency.members);
            }

            this.agency.set(agency);
            this.titleService.titleEmitter.emit(`${agency.name} (Agentur)`);
        }
        await this.loadingService.stop();
    }

    private createForm(agency: AgencyInterface) {
        const schema = cleanInjectedValue(this.agencySchema);
        // Header auf null setzen damit die NbForm überschrift ausgeblendet wird
        schema.header = null;

        const form = new Form()
        form.id = 1;
        form.schema = [schema];
        form.data = {
            name: agency.name,
            fullName: agency.fullName
        };

        return form;
    }

    private createLogoForm(): Form {
        const form = new Form();
        form.id = 2;
        form.schema = [cleanInjectedValue(this.agencyLogoSchema)];
        form.data = {};
        return form;
    }

    hasUploadedLogo() {
        return this.agency()?.logo !== null;
    }

    hasSelectedLogoFile() {
        return typeof this.agencyLogoForm?.data === 'object'
            && Array.isArray(this.agencyLogoForm.data['file']);
    }

    clickGoToList() {
        const urlList = '/admin/agency/list';
        this.router.navigate([urlList]);
    }

    onEditMember(member: AgencyMemberInterface) {
        this.editMember.set(member);
    }

    onCloseEdit() {
        this.editMember.set(null);
    }

    async clickSave() {
        const validator = this.agencyFormInstance.getValidator();
        if (!validator.validate()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyService.update(this.agencyId as number, this.updateAgencyRequest)
            .catch(async () => {
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Der Name der Agentur wurde erfolgreich aktualisiert");
            await this.loadAgency();
        } else {
            this.errorService.highlightFormErrors(this.agencyFormInstance, this.agencySchema);
        }
    }

    async clickUploadLogo() {
        if (!this.logoFormInstance.getValidator().validate()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }
        this.uploadFile(this.agencyLogoForm?.data['file'] as FileItem[]);
    }

    async uploadFile(theFile: FileItem[]) {
        await this.loadingService.start();
        const result = await this.agencyService.uploadLogo(this.agencyId as number, { file: theFile })
            .catch(async (e) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler bei der Anfrage.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Das Logo wurde erfolgreich hochgeladen.");
        }
    }

    async clickCropLogo() {
        if (!this.agencyLogoForm) {
            return;
        }
        if (!this.logoFormInstance.getValidator().validate()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }
        try {
            const fileItem = (this.agencyLogoForm.data['file'] as FileItem[])[0];
            this.showPhotoCropper(
                fileItem.data,
                fileItem.name,
                fileItem.description
            );
        } catch (error) {
            console.warn('corrupt image');
            return;
        }
    }

    showPhotoCropper(url?: string, fileName?: string, description?: string) {
        if (!url) {
            url = this.logoUrl;
        }
        if (!fileName) {
            fileName = this.getLogoFileName();
        }
        if (!description) {
            description = this.getLogoFileDescription();
        }
        const photoCropper = this.viewContainer.createComponent(PhotoCropperComponent);
        photoCropper.setInput('url', url);
        photoCropper.setInput('onCancel', () => {
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
            this.viewContainer.clear();
        });
        photoCropper.setInput('onSave', (data: string) => {
            this.uploadFile([{
                name: fileName,
                description: description,
                data: data,
            }]);
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
        });
    }

    getLogoFileName(): string {
        if (this.agency && this.agencyLogoForm) {
            try {
                return (this.agencyLogoForm.data['file'] as FileItem[])[0].name;
            } catch (error) {
                return `agency-${this.agency()?.id}-logo.png`;
            }
        }
        return '';
    }

    getLogoFileDescription(): string {
        if (this.agency() && this.agencyLogoForm) {
            const defaultDescr = `Agency ${this.agency()?.id} Logo`;
            try {
                return (this.agencyLogoForm.data['file'] as FileItem[])[0].description ?? defaultDescr;
            } catch (error) {
                return defaultDescr;
            }
        }
        return '';
    }

    async clickEnableAgency() {
        const confirmation = await this.askDisableOrEnable(false);
        if (!confirmation) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyService.enableAgency(this.agencyId as number)
            .catch(async (e) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler bei der Anfrage.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Die Agentur wurde erfolgreich aktiviert.");
            const agency = this.agency();

            if (agency) {
                agency.isActive = true;
                this.agency.set(agency)
            }
        }
    }

    async clickDisableAgency() {
        const confirmation = await this.askDisableOrEnable();
        if (!confirmation) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyService.disableAgency(this.agencyId as number)
            .catch(async (e) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler bei der Anfrage.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Die Agentur wurde erfolgreich deaktiviert.");
            const agency = this.agency();

            if (agency) {
                agency.isActive = false;
                this.agency.set(agency)
            }
        }
    }

    /**
     * Rückfrage zum aktivieren/deaktivieren der Agentur
     * 
     * @param isDisable 
     * @returns 
     */
    askDisableOrEnable(isDisable = true): Promise<boolean> {
        let msg = `Möchten Sie die Agentur ${this.agency()?.name} wirklich deaktivieren?`;

        if (!isDisable) {
            msg = `Möchten Sie die Agentur ${this.agency()?.name} wirklich aktivieren?`;
        }

        return this.alertService.confirm({
            text: msg
        });
    }

    async clickDeleteAgency() {
        const agency = this.agency();
        if (agency) {
            const result = await this.alertService.confirm({
                text: `Möchten Sie die Agentur ${agency.name} wirklich löschen?`
            });

            if (result) {
                await this.deleteAgency();
           }
        }
    }

    private async deleteAgency() {
        await this.loadingService.start();
        const result = await this.agencyService.deleteAgency(this.agencyId as number)
            .catch(async (e) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fehler bei der Anfrage.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Die Agentur wurde erfolgreich gelöscht.");
            const urlRedirect = '/admin/agency/list';
            this.router.navigate([urlRedirect]);
        }
    }

}
