import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import {
    BooleanToStringPipe,
    DocumentEavInterface,
    DriverAgencyInterface,
    DriverDataInterface,
    DriverDocumentInterface,
    EavDocumentId,
    FileHelper,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    PrintNbDatePipe
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { lastValueFrom } from 'rxjs';
import { saveAs } from 'file-saver';
import { AlertsService } from 'nb-form';

export interface AgencyAccess {
    idAgency: number,
    name: string,
    isValid: boolean,
}

@Component({
    selector: 'driver-my-profile-one-document',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        PrintNbDatePipe,
        BooleanToStringPipe
    ],
    templateUrl: './driver-my-profile-one-document.component.html',
    styleUrl: './driver-my-profile-one-document.component.scss',
    providers: []
})
export class DriverMyProfileOneDocumentComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    noAccessAgencies: AgencyAccess[] = [];
    accessAgencies: AgencyAccess[] = [];


    @Input()
    driver: DriverDataInterface | null | undefined = null;
    @Input()
    eavDocument: DocumentEavInterface | null | undefined = null;
    @Input()
    driverDocument: DriverDocumentInterface | null | undefined = null;
    @Input()
    driverAgencies: DriverAgencyInterface[] | null | undefined = null;


    @Output()
    download: EventEmitter<DriverDocumentInterface> = new EventEmitter();
    @Output()
    delete: EventEmitter<DriverDocumentInterface> = new EventEmitter();
    @Output()
    upload: EventEmitter<number> = new EventEmitter();
    @Output()
    editFahrerlaubnis: EventEmitter<unknown> = new EventEmitter();
    @Output()
    editAgencyAccess: EventEmitter<DriverDocumentInterface> = new EventEmitter();



    showPlace: WritableSignal<boolean> = signal(false);
    constructor(
        private alertService: AlertsService,
        private driverProfileService: DriverProfileService,
        private loadingService: LoadingService
    ) {

    }

    private sortAgencyAccess() {
        this.noAccessAgencies = [];
        this.accessAgencies = [];
        if (this.driver && this.eavDocument && this.driverDocument && this.driverAgencies) {
            const driverDocument = this.driverDocument;
            if (this.driverAgencies.length > 0) {
                const driverAgencies = this.driverAgencies;
                const agencyDocuments = driverDocument.agencyDocuments;
                if (agencyDocuments.length === 0) {
                    driverAgencies.forEach(driverAgency => {
                        this.noAccessAgencies.push({
                            idAgency: driverAgency.idAgency,
                            isValid: false,
                            name: driverAgency.agencyName
                        });
                    });
                } else {
                    driverAgencies.forEach(driverAgency => {
                        const agencyDocument = agencyDocuments.find(
                            function (document) {
                                return document.agencyId === driverAgency.idAgency;
                            });
                        if (typeof agencyDocument !== 'undefined') {
                            this.accessAgencies.push({
                                idAgency: driverAgency.idAgency,
                                isValid: agencyDocument.isValid,
                                name: driverAgency.agencyName
                            });
                        } else {
                            this.noAccessAgencies.push({
                                idAgency: driverAgency.idAgency,
                                isValid: false,
                                name: driverAgency.agencyName
                            });
                        }
                    });
                }
            }
        }
        this.showPlace.set(true);
    }

    ngOnInit(): void {
        this.sortAgencyAccess();
    }

    isDriverLicence(): boolean {
        return (this.driverDocument?.eav === EavDocumentId.DRIVER_LICENCE_ID)
    }

    getDriverFahrerlaubnisAsStrings(): string {
        const name: string[] = [];
        const allowed = this.driver?.driverUserDto.fahrerlaubnis;
        if (allowed && allowed.length > 0) {
            allowed.forEach((skill) => {
                name.push(skill.name);
            })
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return 'Keine Angaben';
    }



    hasEavCreationDate(): boolean {
        if (this.eavDocument) {
            return this.eavDocument.hasCreatedDate;
        }
        return false;
    }

    hasEavExpiredDate(): boolean {
        if (this.eavDocument) {
            return this.eavDocument.hasExpiredDate;
        }
        return false;
    }

    hasEavDates(): boolean {
        return (this.hasEavCreationDate() || this.hasEavExpiredDate());
    }

    async clickDelete() {
       if (this.driverDocument) {
           const question = `${this.eavDocument?.name} "${this.driverDocument.name}" löschen?`;
           const confirmation = await this.alertService.confirm({
               text: question,
               textOk: "Ja",
               textCancel: "Nein"
           });
           if (confirmation) {
               this.delete.emit(this.driverDocument);
           }
        }
    }

    clickEditFahrerlaubnis() {
        this.editFahrerlaubnis.emit();
    }

    clickEditAgencyAccess() {
        if (this.driverDocument) {
            this.editAgencyAccess.emit(this.driverDocument);
        }
    }

    async clickDownload(isBackSide: boolean) {
        if (this.driverDocument === undefined) {
            console.warn("Download nicht möglich - Document undefined");
            return;
        }

        await this.loadingService.start();
        const result = await lastValueFrom(
            this.driverProfileService.downloadDocument(this.driverDocument as DriverDocumentInterface, isBackSide)
        ).catch((e) => {
            console.debug("Fehler beim Download: ", e);
            return null;
        });
        await this.loadingService.stop();

        if (result) {
            saveAs(
                new Blob(
                    [result.body as Blob],
                    {type: FileHelper.getContentType(result)}
                ),
                FileHelper.getFileName(result),
                { autoBom: true }
            );
        }
    }

    getLabelDescription(): string {
        if (this.eavDocument) {
            switch (this.eavDocument.id) {
                case EavDocumentId.DRIVER_LICENCE_ID:
                    return 'Nr. des Führerscheins';
                case EavDocumentId.DRIVER_PSHEIN_ID:
                    return 'Nr. des P-Scheins';
                case EavDocumentId.DRIVER_PERSO_ID:
                    return 'Nr. des Ausweises';
                case EavDocumentId.DRIVER_AUFENTHALT_ID:
                    return 'Nr. des Aufenthaltstitel';
                default:
                    return 'Beschreibung';
            }
        }
        return 'Beschreibung:'
    }

}

