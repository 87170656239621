import { Component, effect, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DriverJoblistService } from '../../services/driver-joblist.service';
import {
    BadgeComponent,
    BooleanToStringPipe,
    DriverJobListInterface,
    DriverJobListType,
    hasProperty,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    TitleIconEnum
} from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import {
    TableDirective,
    TableHeadDirective,
    TableRowDirective,
    TableCellDirective,
    TableCellLabelDirective,
    TableFooterDirective,
    TableKickerDirective
} from '@nf-workforce/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'driver-joblist-my-jobs',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableDirective,
        TableHeadDirective,
        TableRowDirective,
        TableCellDirective,
        TableCellLabelDirective,
        TableFooterDirective,
        PrintNbDatePipe,
        BooleanToStringPipe,
        BadgeComponent,
        TableKickerDirective,
        PrintNbDateTimePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './my-jobs.component.html',
    styleUrl: './my-jobs.component.scss',
    providers: [
        DriverJoblistService
    ]
})
export class MyJobsComponent implements OnInit {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    nextPage = 1;
    limit = 30;
    changeEmitter: Observable<DriverJobListInterface[]>;

    hasMoreResults: WritableSignal<boolean> = signal(false);
    currentJobs: WritableSignal<DriverJobListInterface[]> = signal([]);

    currentList: WritableSignal<DriverJobListType | undefined> = signal(undefined);

    countWaiting = signal(0);
    countAccepted = signal(0);
    countRejected = signal(0);
    countPast = signal(0);

    constructor(
        private loadingService: LoadingService,
        private joblistService: DriverJoblistService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.changeEmitter = toObservable(this.currentJobs);
        effect(async () => {
            await this.fetchCounts();
        });
    }

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'type')) {
            const type = parseInt(params['type'], 10);
            const validTypes = [
                DriverJobListType.TYPE_ACTIVE,
                DriverJobListType.TYPE_WAITING,
                DriverJobListType.TYPE_REJECTED,
                DriverJobListType.TYPE_PAST,
            ];
            if (validTypes.includes(type)) {
                this.currentList.set(type);
            }
        }
        if (this.currentList() === undefined) {
            // Default-Route falls ein invalider Parameter übergeben wurde
            this.currentList.set(DriverJobListType.TYPE_ACTIVE);
            const route = `/my-jobs/overview/${DriverJobListType.TYPE_ACTIVE}`;
            await this.router.navigate([route], { replaceUrl: true });
        }

        const jobs = await this.loadCurrentJobs();
        this.currentJobs.set(jobs);
    }

    async fetchCounts() {
        const result = await this.joblistService.getJobCounts()
            .catch((e: HttpErrorResponse) => {
                console.warn("Fehler beim holen der Badge-Counter", e);
                return null;
            });

        if (result) {
            this.countWaiting.set(result.waiting);
            this.countAccepted.set(result.accepted);
            this.countRejected.set(result.rejected);
            this.countPast.set(result.past);
        }
    }

    /**
     * Laden der Jobliste für den derzeit eingeloggten Fahrer
     *
     * @returns
     */
    async loadCurrentJobs() {
        const currentList = this.currentList();
        if (currentList === undefined) {
            return [];
        }
        await this.loadingService.start();
        const result = await this.joblistService.getMyJobs(this.nextPage, this.limit, currentList)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage: ", e);
                return null;
            });
        await this.loadingService.stop();

        if (!result) {
            return [];
        }

        this.nextPage = result.page + 1;
        if (this.nextPage <= result.countResultPages) {
            this.hasMoreResults.set(true);
        } else {
            this.hasMoreResults.set(false);
        }

        return result.data;
    }

    /**
     * Nachladen der nächsten Seite
     */
    async clickNext() {
        const currentJobs = this.currentJobs();
        const jobs = await this.loadCurrentJobs();
        this.currentJobs.set(currentJobs.concat(jobs));
    }

    /**
     * Ändern der Ansicht und neuladen der Liste
     *
     * @param listType
     */
    async clickChangeList(listType: number) {
        this.currentList.set(listType);
        this.nextPage = 1;
        const jobs = await this.loadCurrentJobs();
        this.currentJobs.set(jobs);
    }

    /**
     * Gibt zurück, ob die derzeitige Liste aktiv ist
     *
     * @param listType
     * @returns
     */
    isCurrentList(listType: number): boolean {
        return (listType === this.currentList());
    }

    /**
     * Geht zur Einzelansicht der aktiven Jobs
     *
     * @param joblist
     */
    clickGoToJoblist(joblist: DriverJobListInterface) {
        this.router.navigate([`/my-jobs/one/${joblist.joblistId}`])
    }

}
