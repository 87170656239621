import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const ADD_DRIVER_SCHEMA = new InjectionToken<FormPage>('"Formular zum Anlegen neuer Fahrer"', {
    factory: () => {
        return {
            ber_reiter_id: 'add-driver',
            navigation_label: 'driver',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'add-driver-account-header',
                    type: 'sub-header',
                    label: 'Accountinformationen',
                    columnSize: 12
                },
                {
                    id: 'userName',
                    type: 'email',
                    model: 'username',
                    label: 'Benutzername',
                    required: true,
                    columnSize: 12
                },
                // {
                //     id: 'workingRelationship',
                //     type: 'nb-dropdown',
                //     model: 'workingRelationship',
                //     label: 'Art der Geschäftsbeziehung',
                //     required: true,
                //     onChange: {
                //         func: 'evaluateWorkingRelationship',
                //         parameters: ['workingRelationship']
                //     },
                //     options: [
                //         {
                //             key: DriverRelationship.IS_COMPANY,
                //             value: 'Selbstständig mit Firma'
                //         },
                //         {
                //             key: DriverRelationship.IS_FREE_AGENT,
                //             value: 'Selbstständig ohne Firma'
                //         },
                //         {
                //             key: DriverRelationship.IS_EMPLOYEE,
                //             value: 'Angestellter'
                //         }
                //     ],
                //     columnSize: 6
                // },
                {
                    id: 'add-driver-person-header',
                    type: 'sub-header',
                    label: 'Daten zur Person',
                    columnSize: 12
                },
                {
                    id: 'sex',
                    type: 'nb-dropdown',
                    model: 'sex',
                    label: 'Anrede',
                    options: [
                        {
                            key: 'f',
                            value: 'Frau'
                        },
                        {
                            key: 'm',
                            value: 'Herr'
                        },
                        {
                            key: 'd',
                            value: 'Divers'
                        }
                    ],
                    columnSize: 6
                },
                {
                    id: 'academic',
                    type: 'textarea',
                    model: 'academic',
                    label: 'Titel',
                    columnSize: 6
                },
                {
                    id: 'firstName',
                    type: 'textarea',
                    model: 'firstName',
                    label: 'Vorname',
                    rows: 1,
                    columnSize: 6
                },
                {
                    id: 'lastName',
                    type: 'textarea',
                    model: 'lastName',
                    label: 'Nachname',
                    rows: 1,
                    required: true,
                    columnSize: 6
                },
                {
                    id: 'phone',
                    type: 'phone',
                    model: 'phone',
                    label: 'Telefonnummer',
                    required: true,
                    columnSize: 6
                },
                {
                    id: 'sendMail',
                    type: 'nb-dropdown',
                    model: 'sendMail',
                    label: 'Willkommensmail absenden',
                    options: [
                        {
                            key: '0',
                            value: 'Nein'
                        },
                        {
                            key: '1',
                            value: 'Ja'
                        }
                    ],
                    columnSize: 6
                }
            ]
        };
    }
});
