import { NavigationNodeInterface } from "../interfaces/navigation-node.interface";
import { AccRouteDefinitionType } from "../types/acc-route-definition.type";

export class PageAction implements NavigationNodeInterface {

    id: string;
    childNodes?: NavigationNodeInterface[];
    type!: 'NavigationNodeInterface';

    private constructor(
        public clickFn: (() => void) | undefined,
        public label: string,
        public protectedBy: AccRouteDefinitionType | undefined,
        public routerLink: string | undefined,
        public title: string | undefined,
    ) {
        this.id = crypto.randomUUID();
    }

    public static click(
        label: string,
        clickFn: (() => void),
        protectedBy?: AccRouteDefinitionType,
    ) {
        return new PageAction(
            clickFn,
            label,
            protectedBy,
            undefined,
            undefined,
        );
    }

    public static route(
        label: string,
        routerLink: string,
        protectedBy?: AccRouteDefinitionType,
    ) {
        return new PageAction(
            undefined,
            label,
            protectedBy,
            routerLink,
            undefined,
        );
    }

    children(childNodes: NavigationNodeInterface[]): PageAction {
        this.childNodes = childNodes;
        return this;
    }
}
