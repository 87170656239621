import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortElementInterface } from '../../interfaces/filter-element.interface';
import { SelectionOptionInterface } from '../../interfaces/dropdown-option.interface';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { SortEventInterface } from '../../interfaces/sort-event.interface';

@Component({
    selector: 'shared-sort',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule
    ],
    templateUrl: './sort.component.html',
    styleUrl: './sort.component.scss',
})
export class SortComponent {

    options: SelectionOptionInterface[] = [
        {
            label: "Keine Auswahl",
            value: 0
        }, 
        {
            label: "Aufsteigend",
            value: 'desc'
        },
        {
            label: "Absteigend",
            value: 'asc'
        }
    ];

    control = new FormControl(0);

    @Input()
    sortElement!: SortElementInterface

    @Output()
    changeSort = new EventEmitter();

    /**
     * Ändern der Suche
     * 
     * @param $event 
     */
    onChange($event: unknown) {
        this.changeSort.emit({
            name: this.sortElement.name,
            order: $event
        } as SortEventInterface);
    }

    clickSelect() {
        console.debug("Überhaupt verwendet?");
    }
    
}
