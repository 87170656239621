import { Component} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, FormGroup, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { AlertsService} from 'nb-form';
import { Router } from '@angular/router';
import { IconDirective, IconEnum, IconThemeEnum, LoadingService, showFormError, ToastService } from '@nf-workforce/shared';
import { DriverGroupService } from '../../service/driver-group.service';
import { AddAgencyGroupRequest } from '../../interfaces/add-agency-group-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { FormErrorComponent } from '@nf-workforce/ui';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'driver-group-new',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        IconDirective,
        MatIcon,
        MatFormField,
        MatInput,
        MatLabel,
        FormErrorComponent
    ],
    providers: [
        DriverGroupService
    ],
    templateUrl: './driver-group-new.component.html',
    styleUrl: './driver-group-new.component.scss',
})
export class DriverGroupNewComponent {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
    form!: FormGroup;

    get payload(): AddAgencyGroupRequest {
        return {
            name: this.form.get('name')?.value
        };
    }

    showError = showFormError;

    constructor(
        private alertService: AlertsService,
        private groupService: DriverGroupService,
        private loadingService: LoadingService,
        private toastService: ToastService,
        private router: Router,
    ) {
        this.form = new FormGroup({
            name: new FormControl(null, [Validators.required])
        });
    }

    /**
     * Navigation zurück zur Liste
     */
    goBack() {
        this.router.navigate(['/driver-groups/list']);
    }

    async saveGroup() {
        if (this.form.invalid) {
            await this.toastService.presentToast("Bitte prüfen Sie Ihre Eingaben!");
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: 'Wollen Sie jetzt speichern',
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const result = await this.groupService.addGroup(this.payload)
                .catch(async (e: HttpErrorResponse) => {
                    console.debug("Fehler beim Speichern: ", e);
                    await this.toastService.presentToast("Fehler beim Speichern. Bitte versuchen Sei es später erneut.");
                    return null;
            });
            await this.loadingService.stop();
            if (result) {
                await this.toastService.presentToast("Die neue Gruppe wurde erfolgreich angelegt.");
                this.goBack();
            }
        }
    }
}
