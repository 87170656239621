import { Component, Input, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { FormErrorComponent } from '@nf-workforce/ui';
import { AlertsService } from 'nb-form';
import { JoblistFormHelperService } from '../../../../services/joblist-form-helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { JobListService } from '../../../../services/job-list.service';
import {
    JobListInterface,
    showFormError,
    ToastService,
    LoadingService,
    formatFormDate,
    getTimeZoneOptions,
    IconDirective,
    IconEnum,
    IconThemeEnum
} from '@nf-workforce/shared';
import { AddJobListInterface } from '../../../../interface/joblist/add-job-list.interface';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'joblist-edit-stammdaten-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        IconDirective,
        MatIcon,
        FormErrorComponent,
        MatSelectModule
    ],
    templateUrl: './edit-job-stammdaten-modal.component.html',
    styleUrl: './edit-job-stammdaten-modal.component.scss',
    providers: [
        JoblistFormHelperService,
        JobListService
    ]
})
export class EditJobStammdatenModalComponent implements OnInit {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    form!: FormGroup;

    @Input()
    job!: JobListInterface;
    @Input()
    formType!: string;

    minDate: string;
    maxDate: string;

    showDeadLine = false;

    showError = showFormError;

    isModerated = false;

    timezoneOptions = getTimeZoneOptions();

    constructor(
        private alertService: AlertsService,
        private formHelper: JoblistFormHelperService,
        private modalController: ModalController,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private jobListService: JobListService,
    ) {
        const now = new Date();
        const minDate = new Date(new Date(now).setDate(now.getDate() - 100));
        this.minDate = minDate.toISOString();
        const maxDate = new Date(new Date(now).setDate(now.getDate() + 100));
        this.maxDate = maxDate.toISOString();
    }

    ngOnInit() {
        if (this.job !== null && this.formType !== null) {
            this.formHelper.setJoblist(this.job);
            const formOption = this.formHelper.getFormOptions(this.formType);
            this.form = new FormGroup(formOption);
            this.showDeadLine = (this.job.deadline !== null);
            this.isModerated = this.job.moderated;
        }
    }

    get title(): string {
        switch (this.formType) {
            case 'laufzeit':
                return 'Laufzeit bearbeiten';
            case 'bewerbung':
                return 'Bewerbungsfrist bearbeiten';
            case 'places':
                return 'Anzahl der Plätze bearbeiten';
            default:
                return 'Name/Beschreibung bearbeiten';
        }
    }

    onToggleDeadline(event: CustomEvent) {
        this.showDeadLine = event.detail.checked;
        if (!this.showDeadLine) {
            this.form.get('deadline')?.setValue(null);
        } else {
            if (this.job.deadline !== null) {
                this.form.get('deadline')?.setValue(formatFormDate(new Date(this.job.deadline), true));
            } else {
                const max = new Date(this.job.startDate);
                const maxDate = new Date(new Date(max).setDate(max.getDate() - 1));
                this.form.get('deadline')?.setValue(formatFormDate(maxDate, true));
            }
        }
    }

    get payload(): AddJobListInterface {
        const payload = this.formHelper.defaultStammdatenPayload;
        if (this.formType === 'laufzeit' || this.formType === 'bewerbung') {
            payload.startDate = formatFormDate(new Date(this.form.get('startDate')?.value), false);
            payload.endDate = formatFormDate(new Date(this.form.get('endDate')?.value), false);
            payload.showDate = formatFormDate(new Date(this.form.get('showDate')?.value), true);
            payload.closeDate = formatFormDate(new Date(this.form.get('closeDate')?.value), true);
            if (this.showDeadLine) {
                payload.deadline = formatFormDate(new Date(this.form.get('deadline')?.value), true);
            } else {
                payload.deadline = null;
            }
        } else if (this.formType === 'places') {
            payload.freePlaces = this.form.get('freePlaces')?.value;
        } else {
            payload.name = this.form.get('name')?.value;
            payload.description = this.form.get('description')?.value;
            payload.moderated = this.form.get('moderated')?.value;
        }
        return payload;
    }

    async validateForm() {
        if (this.form.invalid) {
            await this.toastService.presentToast('Fehler: Prüfen Sie Ihre Eingaben!');
            return false;
        }
        const payload = this.formHelper.defaultStammdatenPayload;
        if (this.formType === 'laufzeit' || this.formType === 'bewerbung') {
            const startDate = new Date(payload.startDate);
            const endDate = new Date(payload.endDate);
            const showDate = new Date(payload.showDate);
            const closeDate = new Date(payload.closeDate);
            if (startDate.getTime() >= endDate.getTime()) {
                await this.toastService.presentToast('Fehler: Prüfen Sie die Laufzeit');
                return false;
            }
            if (showDate.getTime() >= closeDate.getTime()) {
                await this.toastService.presentToast('Fehler: Prüfen Sie die Bewerbungsfrist');
                return false;
            }
            if (startDate.getTime() <= closeDate.getTime()) {
                await this.toastService.presentToast('Fehler: Die Bewerbungsfrist liegt nach Start des Jobs');
                return false;
            }
            if (payload.deadline !== null) {
                const deadline = new Date(payload.deadline);
                if (deadline.getTime() >= startDate.getTime()) {
                    await this.toastService.presentToast('Fehler: Die Austragungsfrist liegt nach Start des Jobs');
                    return false;
                }
            }
        } else if (this.formType === 'places') {
            if (payload.freePlaces < 1) {
                await this.toastService.presentToast('Fehler: Anzahl Plätze muss größer "0" sein!');
                return false;
            }
        }
        return true;
    }

    async clickSave() {
        const formIsValid = await this.validateForm();
        if (!formIsValid) {
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: 'Wollen Sie jetzt speichern',
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (!confirmation) {
            return;
        }
        const jobId = this.job.id
        await this.loadingService.start();
        const result = await this.jobListService.updateJob(jobId, this.payload)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler beim Aktualisieren des Jobs: ", e);
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Die Jobliste wurde erfolgreich aktualisiert!");
            await this.modalController.dismiss({
                saved: true
            });
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }
}
