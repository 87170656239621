@if(driver && langForm) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <div [style]="(!showForm()) ? 'display:none;' : ''">
        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-header>
                        <ion-card-subtitle>
                            @if(isMotherLanguageForm()) {
                                Neue Muttersprache
                            } @else {
                                Neue weitere Sprache
                            }
                        </ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <form [formGroup]="langForm">
                            <ion-row>
                                <ion-col>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Sprache</mat-label>
                                        <input type="text" matInput [formControl]="language" [matAutocomplete]="auto" autocomplete="off">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                            @for (option of filteredOptions | async; track option) {
                                                <mat-option [value]="option">{{option.name}}</mat-option>
                                            }
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </ion-col>
                            </ion-row>
                            @if(!isMotherLanguageForm()) {
                                <ion-row>
                                    <ion-col>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Sprachlevel</mat-label>
                                            <mat-select formControlName="level">
                                                <mat-option value="A1">A1</mat-option>
                                                <mat-option value="A2">A2</mat-option>
                                                <mat-option value="B1">B1</mat-option>
                                                <mat-option value="B2">B2</mat-option>
                                                <mat-option value="C1">C1</mat-option>
                                                <mat-option value="C2">C2</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ion-col>
                                </ion-row>
                            }
                        </form>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
        </div>
        <div [style]="(showForm()) ? 'display:none;' : ''">
        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-header>
                        <ion-card-subtitle>
                            Ihre {{ getLanguageHeader(true) }}
                        </ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <div *ngIf="motherLanguages && motherLanguages.length > 0; else noMotherLanguage">
                            <ion-list>
                                @for (lang of motherLanguages; track lang.id) {
                                    <ion-item>
                                        {{lang.name}}
                                        <ion-buttons slot="end">
                                            <ion-button
                                                title="Sprache {{lang.name}} löschen"
                                                fill="clear"
                                                [color]="IconTheme.DANGER"
                                                (click)="clickDeleteLanguage(lang)"
                                            >
                                                <mat-icon
                                                    [icon]="IconEnum.DELETE"
                                                    [theme]="IconTheme.INHERIT"></mat-icon>
                                            </ion-button>
                                        </ion-buttons>
                                    </ion-item>
                                }
                            </ion-list>
                        </div>
                        <ng-template #noMotherLanguage>
                            <ion-text [color]="IconTheme.DANGER">
                                Bitte hinterlegen Sie eine Muttersprache!
                            </ion-text>
                        </ng-template>
                    </ion-card-content>
                    <ion-card-header>
                        <ion-card-subtitle>
                            {{ getLanguageHeader(false) }}
                        </ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <div *ngIf="otherLanguages && otherLanguages.length > 0; else noOtherLanguage">
                            <ion-list>
                                @for (lang of otherLanguages; track lang.id) {
                                    <ion-item>
                                        {{lang.name}} ({{lang.level}})
                                        <ion-buttons slot="end">
                                            <ion-button
                                                title="Sprache {{lang.name}} löschen"
                                                fill="clear"
                                                [color]="IconTheme.DANGER"
                                                (click)="clickDeleteLanguage(lang)"
                                            >
                                                <mat-icon
                                                    [icon]="IconEnum.DELETE"
                                                    [theme]="IconTheme.INHERIT"></mat-icon>
                                            </ion-button>
                                        </ion-buttons>
                                    </ion-item>
                                }
                            </ion-list>
                        </div>
                        <ng-template #noOtherLanguage>
                            <ion-text [color]="IconTheme.DANGER">
                                Keine weiteren Sprachkenntnisse hinterlegt!
                            </ion-text>
                        </ng-template>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
        </div>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            @if (showForm()) {
                <ion-buttons slot="start">
                    <ion-button (click)="clickHideFrom()" color="secondary">
                        <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                        &nbsp; Abbrechen
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button (click)="clickSave()" color="success" *ngIf="langForm.valid">
                        <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                        &nbsp; Speichern
                    </ion-button>
                </ion-buttons>
            } @else {
                <ion-buttons slot="start">
                    <ion-button (click)="clickCancel()" color="secondary">
                        <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                        &nbsp; Schließen
                    </ion-button>
                </ion-buttons>
                <ion-buttons slot="end">
                    <ion-button (click)="clickShowForm(true)" color="primary">
                        <mat-icon [icon]="IconEnum.NEW" [theme]="IconTheme.INHERIT" ></mat-icon>
                        &nbsp; Muttersprache
                    </ion-button>
                    <ion-button (click)="clickShowForm(false)" color="primary">
                        <mat-icon [icon]="IconEnum.NEW" [theme]="IconTheme.INHERIT" ></mat-icon>
                        &nbsp; Andere Sprache
                    </ion-button>
                </ion-buttons>
            }
        </ion-toolbar>
    </ion-footer>
}

