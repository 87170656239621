import { Injectable } from '@angular/core';
import {
    DriverDataInterface,
    DocumentEavInterface,
    EavService,
    EavDocumentId,
    DriverDocumentInterface } from '@nf-workforce/shared';

@Injectable()
export class DriverDocumentHelperService {

    eavDocuments: DocumentEavInterface[] = [];
    missingEavDocuments: DocumentEavInterface[] = [];
    driverDocuments: DriverDocumentInterface[] | null = null;

    driver: DriverDataInterface | null = null;

    constructor(
        private eavService: EavService,
    ) {
        const eavDocuments = this.eavService.getDocuments();
        if (eavDocuments) {
            this.eavDocuments = eavDocuments;
        } else {
            throw new Error('EavDocuments nicht gesetzt');
        }
    }

    setDriver(driver: DriverDataInterface) {
        this.driver = driver;
        this.driverDocuments = this.driver.driverUserDto.driverDocuments;
    }

    setMissingDocuments() {
        this.missingEavDocuments = [];
        const eavRequired = this.eavDocuments.filter(function(eav) {
            return (eav.isRequired && eav.onlyDriver);
        });
        eavRequired.forEach((eav) => {
            const document = this.driverDocuments?.find((doc) => {
                return doc.eav === eav.id;
            });
            if (typeof document === 'undefined') {
                this.missingEavDocuments.push(eav);
            }
        });
    }

    isSingleEavDocument(eavId: number) {
        return (eavId !== EavDocumentId.DRIVER_OTHERS_ID && eavId !== EavDocumentId.DRIVER_CERTS_ID);
    }

    hasDocument(eav: DocumentEavInterface): boolean {
        const document = this.findOneDocumentByEav(eav);
        return (document !== undefined);
    }

    findOneDocumentByEav(eav: DocumentEavInterface): DriverDocumentInterface | undefined {
        return this.driverDocuments?.find((doc) => {
            return (doc.eav === eav.id);
        });
    }

    findAllDocumentsByEav(eav: DocumentEavInterface): DriverDocumentInterface[] | undefined {
        return this.driverDocuments?.filter((doc) => {
            return (doc.eav === eav.id);
        });
    }
}
