import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const AGENCY_LOGO_SCHEMA = new InjectionToken<FormPage>('"Agentur Logoupload Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'agency',
            header: 'Logo hochladen',
            navigation_label: 'agency',
            elements: [
                {
                    id: 'agency-logo',
                    type: 'document',
                    model: 'file',
                    label: 'Ausgewähltes Logo',
                    accept: 'image/*',
                    multiple: false,
                    required: true,
                    buttonText: 'Datei öffnen',
                    icon: 'perm_media'
                }
            ]
        };
    }
});
