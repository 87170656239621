
import { isString } from '../type-guards/is-string';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fullname',
    standalone: true,
})
export class FullnamePipe implements PipeTransform {

    transform(
        value: {
            firstName: string | null,
            lastName: string,
            sex: string | null,
            academic: string | null,
        },
        mode: 'default' | 'short' = 'default',
        direction: 'default' | 'reversed' = 'default',
    ): string {
        const salutation = (mode === 'default' && isString(value.sex))
            ? this.getSalutation(value.sex)
            : '';
        const academic = (mode === 'default' && isString(value.academic))
            ? value.academic
            : '';
        const firstName = (isString(value.firstName))
            ? value.firstName.trim()
            : '';
        if (mode === 'short' && direction === 'reversed') {
            return firstName === ''
                ? value.lastName.trim()
                : `${value.lastName.trim()}, ${firstName}`;
        }
        return `${salutation} ${academic} ${firstName} ${value.lastName}`.trim();
    }

    private getSalutation(sex: string | null): string {
        switch (sex) {
            case 'w': {
                return 'Frau';
            }
            case 'm': {
                return 'Herr';
            }
            case 'd': {
                return 'Divers';
            }
            default: {
                return '';
            }
        }
    }
}
