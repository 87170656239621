@if(job) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{title}}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            @if (!selectedDriver()) {
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>Fahrersuche</mat-label>
                            <input type="text"
                                   matInput
                                   [matAutocomplete]="auto"
                                   [formControl]="searchDriver"
                                   autocomplete="off"
                                   matAutocompletePosition="below"
                                   #autocomplete
                            >
                            <mat-autocomplete
                                #auto="matAutocomplete"
                                [displayWith]="displayFn"
                                (optionSelected)="onDriverSelected($event)"
                            >
                                @for (option of filteredOptions | async; track option) {
                                    <mat-option [value]="option.key">{{option.value}}</mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </ion-col>
                </ion-row>
            } @else {
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>
                                <strong>Fahrer:innen {{selectedDriver()?.value}}</strong>
                            </ion-label>
                            <ion-button
                                (click)="clearSelection()"
                                size="small"
                                fill="clear"
                                [color]="IconTheme.INHERIT"
                            >
                                <mat-icon
                                    [icon]="IconEnum.SEARCH_CLEAR"
                                    [theme]="IconTheme.INHERIT" >
                                </mat-icon>
                            </ion-button>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-toggle
                                [enableOnOffLabels]="true"
                                [checked]="sendMail"
                                (ionChange)="onToggleSendMail($event)"
                                labelPlacement="start"
                            >Fahrer:in per E-Mail informieren</ion-toggle>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-select
                                label="Liste wählen"
                                placeholder="Bitte wählen Sie die Liste aus"
                                (ionChange)="onSelectList($event)"
                            >
                                <ion-select-option value="200">Fahrer:in zuweisen</ion-select-option>
                                <ion-select-option value="100">Fahrer:in auf Warteliste</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-col>
                </ion-row>
            }
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Schließen
                </ion-button>
            </ion-buttons>
            @if (selectedDriver()) {
            <ion-buttons slot="end">
                <ion-button (click)="clearSelection()">
                    <mat-icon [icon]="IconEnum.SEARCH_CLEAR" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp;Neue Suche
                </ion-button>
                @if (selectedList === 100 || selectedList === 200) {
                    <ion-button (click)="onClickAdd()" [color]="IconTheme.SUCCESS">
                        <mat-icon [icon]="IconEnum.ADD_USER" [theme]="IconTheme.SUCCESS" ></mat-icon>
                        &nbsp;Hinzufügen
                    </ion-button>
                }
            </ion-buttons>
            }
        </ion-toolbar>
    </ion-footer>
}


