
import { Component, ElementRef, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageActionInterface } from '../../interfaces/page-action.interface';
import { NavigationNodeInterface } from '../../interfaces/navigation-node.interface';

@Component({
    standalone: true,
    imports: [RouterModule],
    selector: 'shared-navigation-link',
    templateUrl: './navigation-link.component.html',
})
export class NavigationLinkComponent {

    @Input()
    level!: number;

    @Input()
    navItem!: PageActionInterface | NavigationNodeInterface;

    constructor(private elementRef: ElementRef) { }

    label(navItem: PageActionInterface | NavigationNodeInterface): string {
        return navItem.label;
        // if (this.unprotected(navItem)) {
        //     return `${navItem.label} ✅`;
        // }
        // return `${navItem.label} 🔓`;
    }

    title(navItem: PageActionInterface | NavigationNodeInterface): string {
        return navItem.title ?? '';
    }

    /**
     * @param event the original click event (will be discarded)
     * @param navItem the navigation item with a click function (will be called)
     */
    navItemClick(event: Event, navItem: NavigationNodeInterface | PageActionInterface) {
        event.preventDefault();
        if (navItem.clickFn) {
            this.dispatchCloseNavigationMenuEvent();
            navItem.clickFn();
        }
    }

    /**
     * In case of regular router links, we still want to close the menu. So
     * this method needs to be accessible by the template.
     */
    dispatchCloseNavigationMenuEvent(): void {
        this.elementRef.nativeElement.dispatchEvent(
            new CustomEvent('close_navigation_menu_event', { bubbles: true })
        )
    }
}
