import { ChangeDetectorRef, Directive, HostBinding, Inject, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[uiTableCell]',
    standalone: true
})
export class TableCellDirective implements OnInit {

    @HostBinding('class')
    elementClass = 'table-cell';

    @HostBinding('attr.aria-hidden')
    attrAriaHidden = false;

    @Input()
    align: 'left' | 'center' | 'right' = "left"
    
    @Input()
    hideOnExpand = false

    @Input()
    hideOnCollapse = false

    @Input()
    ariaHidden = false;

    constructor(
        @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.align === "right") {
            this.elementClass = this.elementClass + ' align-right';
        } else if (this.align === "center") {
            this.elementClass = this.elementClass + ' align-center'
        } else {
            this.elementClass = this.elementClass + ' align-left'
        }

        if (this.hideOnExpand) {
            this.elementClass = this.elementClass + ' hide-when-expanded';
        }

        if (this.hideOnCollapse) {
            this.elementClass = this.elementClass + ' hide-when-collapsed';
        }

        if (this.ariaHidden) {
            this.attrAriaHidden = true;
        }
        
        this.cd.detectChanges();
    }

}