
import { AccountService } from '../../services/account.service';
import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { RESET_PASSWORD_SCHEMA } from '../../schema/reset-password.schema';
import { Router } from '@angular/router';
import { ErrorService, ToastService } from '@nf-workforce/shared';

@Component({
    selector: 'lib-reset-password',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
    ],
    templateUrl: './reset-password.component.html',
    providers: []
})
export class ResetPasswordComponent {

    form: Form | null = null;

    @ViewChild(NbFormComponent)
    nbFormInstance!: NbFormComponent;

    constructor(
        @Inject(RESET_PASSWORD_SCHEMA) private resetPasswordSchema: FormPage,
        private accountService: AccountService,
        private router: Router,
        private toastService: ToastService,
        private errorService: ErrorService
    ) {
        this.form = new Form();
        this.form.id = 1;
        this.form.schema = [cleanInjectedValue(this.resetPasswordSchema)];
        this.form.data = {};
    }

    get disableButton(): boolean {
        if (this.nbFormInstance) {
            const validator = this.nbFormInstance.getValidator();
            return !validator.validate();
        }
        return true;
    }

    async clickResetPassword() {
        if (this.form) {
            const username = this.form.data['username'] as string;
            const result = await this.accountService.resetPassword(username)
                .catch((e: unknown) => {
                    console.warn("Fehler bei der Anfrage: ", e);
                    return null;
                });
            if (result) {
                await this.toastService.presentToast('Es wurde eine E-Mail mit Instruktionen zum Zurücksetzen des Accounts versendet. Bitte folgen Sie den Anweisungen in der E-Mail.');
            } else {
                this.errorService.highlightFormErrors(this.nbFormInstance, this.resetPasswordSchema);
            }
        }
    }

    clickLoginLink() {
        this.router.navigate(['/login']);
    }
}
