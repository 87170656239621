import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableKickerDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import {
    FullnamePipe,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobDriverInterface,
    JobListInterface,
    LoadingService,
    PageActionService,
    PageFilterAction,
    SearchMenuInterface,
    SearchQueryBuilder,
    SearchQueryInterface,
    TitleIconEnum,
    ToastService,
    UserListDtoInterface
} from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverListService } from '../../../services/driver-list.service';
import { driverJobListSearchConfig } from '../../../search/driver-joblist.config';
import { AlertsService } from 'nb-form';
import { RemoveDriverRequestInterface } from '../../../interface/workforce/remove-driver-request.interface';
import { Subscription } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { SearchDriverModalComponent } from './search-driver-modal/search-driver-modal.component';
import { ListDriverButtonModalComponent } from './list-driver-button-modal/list-driver-button-modal.component';

@Component({
    selector: 'joblist-workforce-list',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableDirective,
        TableHeadDirective,
        TableRowDirective,
        TableCellDirective,
        TableCellLabelDirective,
        TableKickerDirective,
        TableFooterDirective,
        FullnamePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './workforce-list.component.html',
    styleUrl: './workforce-list.component.scss',
})
export class WorkforceListComponent implements OnInit, OnDestroy {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    @Input()
    job!: JobListInterface | null;

    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    driverList: WritableSignal<JobDriverInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);

    title = 'Zugewiesene Fahrer:innen';

    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };

    listSub: Subscription;

    listTypeSub: Subscription;

    resultsSub: Subscription;

    displayedList = 200;

    constructor(
        private alertsService: AlertsService,
        private driverListService: DriverListService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private pageActionService: PageActionService,
        private toastService: ToastService,
        @Inject(driverJobListSearchConfig) public searchConfig: SearchMenuInterface,

    ) {

        this.listSub = this.driverListService.getDrivers()
            .subscribe((next) => {
                this.driverList.set(next);
            });

        this.listTypeSub = this.driverListService.getCurrentListType()
            .subscribe((next) => {
                this.displayedList = next;
            });

        this.resultsSub = this.driverListService.getHasMoreResults()
            .subscribe((next) => {
                this.enableMoreResults.set(next);
            });

        this.pageActionService.clear();
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) }
        ));
    }

    async ngOnInit(): Promise<void> {
        if (this.job) {
            await this.driverListService.loadDrivers(this.job.id, false, this.searchQuery);
        }
    }

    ngOnDestroy(): void {
        this.listSub.unsubscribe();
        this.listTypeSub.unsubscribe();
    }

    /**
     * Klick auf den Button "Mehr Ergebnisse"
     */
    async clickLoadNextPage() {
        if (this.job) {
            await this.driverListService.loadNextPage(this.job?.id, this.searchQuery);
        }
    }

    /**
     * Ändern der angezeigten Liste oer schnellFilter
     *
     * @param listType
     */
    changeList(listType: number) {
        if (this.job) {
            this.driverListService.changeListType(this.job.id, this.searchQuery, listType);
            if (listType === 200) {
                this.title = 'Zugewiesene Fahrer:innen';
            } else if (listType === 100) {
                this.title = 'Fahrer:innen auf Warteliste';
            } else if (listType === 500) {
                this.title = 'Abgelehnte Fahrer:innen';
            }
        }
    }

    /**
     * Gibt zurück, ob der übergebene Listen-Typ derzeit aktiv ist
     *
     * @param listType
     * @returns
     */
    isActive(listType: number): boolean {
        return (listType === this.displayedList);
    }

    /**
     * Änderung der Suche und anschließendes neu Laden der Liste
     *
     * @param $event
     */
    async searchChanged($event: SearchQueryInterface) {
        const queryClone = { ...$event };

        if (Object.keys(queryClone).length > 0) {
            const searchQueryBuilder = new SearchQueryBuilder(this.searchConfig.searchElements, queryClone);
            this.searchQuery = searchQueryBuilder.getSearchQuery();
            this.searchQuery.sort = queryClone.sort;

            if (this.job) {
                await this.driverListService.loadDrivers(this.job.id, false, this.searchQuery);
            }
        }
    }

    /**
     * Entfernen eines Benutzers von der Liste
     *
     * @param driver
     * @returns
     */
    async deleteFromList(driver: JobDriverInterface) {
        const confirm = await this.alertsService.confirm({
            text: `Möchten Sie ${driver.userListDto.firstName} ${driver.userListDto.firstName} wirklich von der Liste entfernen?`
        });

        if (!confirm || this.job === null) {
            return;
        }

        const sendMail = await this.askSendMail(driver.userListDto);
        const payload = {
            userId: driver.userListDto.id,
            sendMail: sendMail
        } as RemoveDriverRequestInterface;

        await this.loadingService.start();
        const removeResult = await this.driverListService.removeDriver(this.job.id, payload)
            .catch((e: HttpErrorResponse) => {
                console.warn("Fehler bei der Serveranfrage", e);
                return null;
            })
        await this.loadingService.stop();

        if (removeResult) {
            this.toastService.presentToast("Der Fahrer wurde erfolgreich von der Liste entfernt.");
            console.debug("Should reload");
            await this.driverListService.reloadList(this.job.id, this.searchQuery);
        }
    }

    /**
     * Nachfrage, ob der Benutzer beim Entfernen von der Liste per E-Mail Benachrichtigt werden soll
     *
     * @param user
     * @returns
     */
    private async askSendMail(user: UserListDtoInterface): Promise<boolean> {
        return await this.alertsService.confirm({
            text: `Soll ${user.firstName} ${user.firstName} per E-Mail benachrichtigt werden?`,
            textCancel: 'Nein',
            textOk: 'Ja'
        });
    }

    async onReload() {
        if (this.job) {
            await this.driverListService.loadDrivers(this.job.id, false, this.searchQuery);
        }
    }

    async clickShowDriverSearch() {
        const modal = await this.modalController.create({
            component: SearchDriverModalComponent,
            componentProps: {
                job: this.job
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    this.reload.emit();
                }
            }
        }
    }

    async clickMoveDriverToList(driver: JobDriverInterface, rejectDriver: boolean) {
        let newListType = (this.displayedList === 200) ? 100 : 200;
        if (rejectDriver) {
            newListType = 500;
        }
        const modal = await this.modalController.create({
            component: ListDriverButtonModalComponent,
            componentProps: {
                driver: driver.userListDto,
                job: this.job,
                newListType: newListType
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    this.reload.emit();
                }
            }
        }
    }


}
