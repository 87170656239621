import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule, PopoverController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePopoverComponent } from '../date-popover/date-popover.component';
import { hasProperty } from '../../function/has-property.function';
import { SearchEventInterface } from '../../interfaces/search-event.interface';
import { SearchDateInterface } from '../../interfaces/search-date.interface';
import { isString } from '../../type-guards/is-string';

@Component({
    selector: 'shared-search-date',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './search-date.component.html',
    styleUrl: './search-date.component.scss',
    providers: [
        DatePipe
    ]
})
export class SearchDateComponent implements OnInit {

    @Input()
    name!: string;

    @Input()
    type: 'single' | 'between' = 'single'

    @Input()
    presentation: 'date' | 'date-time' | undefined = "date";

    @Output()
    search: EventEmitter<SearchEventInterface<SearchDateInterface>> = new EventEmitter();

    pickedDates = {
        first: null,
        second: null
    } as SearchDateInterface;

    constructor(
        private popoverController: PopoverController,
        private cd: ChangeDetectorRef,
        private datePipe: DatePipe
    ) { }

    ngOnInit(): void {
        // Presentation neu Setzen, falls es mit einem nicht definierten Wert überschrieben wird
        if (this.presentation === undefined) {
            this.presentation = 'date';
        }
    }

    /**
     * Öffnet den popover mit den Datepickern
     */
    async clickOpenDatePicker(pickerNumber: 1 | 2) {
        const popover = await this.popoverController.create({
            component: DatePopoverComponent,
            cssClass: 'date-popover',
            componentProps: {
                presentation: this.presentation
            }
        });

        await popover.present();
        const result = await popover.onDidDismiss()

        if (result.role === 'selected' && hasProperty(result.data, 'selectedDate')) {
            const date = result.data.selectedDate;

            if (pickerNumber === 1) {
                this.pickedDates.first = date;
            } else {
                this.pickedDates.second = date;
            }

            this.search.emit({
                name: this.name,
                value: this.pickedDates
            });
        }
    }

    getDisplayValue(pickerNumber: 1 | 2): string {
        const displayDate = (pickerNumber === 1) ?
            this.pickedDates.first : this.pickedDates.second;
        
        if (displayDate instanceof Date) {
            let format = 'dd.MM.yyyy HH:mm';
            if (this.presentation === 'date') {
                format = 'dd.MM.yyyy';
            } 

            const formattedStr = this.datePipe.transform(displayDate, format);
            if (isString(formattedStr)) {
                return formattedStr;
            }
          
        }

        if (this.type === "single") {
            return "Datum";
        }

        if (this.type === "between") {

            if (pickerNumber === 1) {
                return "von";
            } else {
                return "bis";
            }

        }

        return '-';
    }

}
