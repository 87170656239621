import { Component, inject, Inject, Input, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { Form, FormElementOption, FormPage, NbFormComponent } from 'nb-form';
import { BULK_MESSAGE_SCHEMA } from '../../schema/bulk-message.schema';
import { MessageService } from '../../service/message.service';
import { createEmptyForm, JobDriverInterface, JobListInterface, LoadingService, SearchQueryInterface, ToastService } from '@nf-workforce/shared';
import { AgencyGroupInterface, DriverGroupService } from '@nf-workforce/driver-group';
import { driversToOptions } from '../../function/drivers-to-options.function';
import { groupsToOptions } from '../../function/group-to-options.functions';
import { BulkMessageRequestInterface } from '../../interfaces/bulk-message-request.interface';


@Component({
    selector: 'message-create-bulk-message',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent
    ],
    providers: [
        MessageService,
        DriverGroupService
    ],
    templateUrl: './create-bulk-message-modal.component.html',
    styleUrl: './create-bulk-message-modal.component.scss',
})
export class CreateBulkMessageComponent implements OnInit {

    private modalController = inject(ModalController);

    private groupService = inject(DriverGroupService);

    private loadingService = inject(LoadingService);

    private messageService = inject(MessageService);

    private toastService = inject(ToastService);

    form!: Form;

    @ViewChild(NbFormComponent)
    formInstance!: NbFormComponent;

    @Input()
    job!: JobListInterface;

    disableSave = signal(true);

    selectedUsers = signal<number[]>([]);

    selectedGroups = signal<number[]>([]);

    bulkIsPossible = signal(false);

    driverList: JobDriverInterface[] = [];

    groupList: AgencyGroupInterface[] = [];

    get helpers(): object {
        return {
            addGroupToMessage: async (parameters: unknown[]) => {
                if (typeof parameters[0] !== "number") {
                    return;
                }

                const currentSelection = this.selectedGroups();
                if (!currentSelection.includes(parameters[0] as number)) {
                    currentSelection.push(parameters[0] as number);
                    this.selectedGroups.set(currentSelection);
                }
            },
            addUserToMessage: async (parameters: unknown[]) => {
                if (typeof parameters[0] !== "number") {
                    return;
                }

                const currentSelection = this.selectedUsers();
                if (!currentSelection.includes(parameters[0] as number)) {
                    currentSelection.push(parameters[0] as number);
                    this.selectedUsers.set(currentSelection);
                }
            }
        };
    }

    /**
     * Gibt den Payload für das erstellen des BUlk-Jobs zurück
     */
    private get payload(): BulkMessageRequestInterface {
        const forceSending = (this.form.data['forceSending'] === '1') ?
            true : false;
        return {
            groupIds: this.selectedGroups(),
            userId: this.selectedUsers(),
            forceSending: forceSending
        }
    }

    constructor(
        @Inject(BULK_MESSAGE_SCHEMA) private formSchema: FormPage
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadingService.start();
        const driverResponse = await this.loadDrivers();
        const groupResponse = await this.loadGroups();
        let driverOptions: FormElementOption[] = [];
        let groupOptions: FormElementOption[] = [];

        if (this.job) {
            this.messageService.setJobId(this.job.id);
        }

        this.driverList = driverResponse.data;
        this.groupList = groupResponse.data;

        driverOptions = driversToOptions(driverResponse.data);
        groupOptions = groupsToOptions(groupResponse.data);
        this.createForm(driverOptions, groupOptions);



        await this.loadingService.stop();
        const result = await this.messageService.getIsBulkMessagePossible(this.job.id)

        if (result) {
            this.bulkIsPossible.set(true);
        } else {
            this.bulkIsPossible.set(false);
        }

    }

    /**
     * Funktion zum laden der Fahrer für die Nachricht des derzeitigen JObs 
     */
    private async loadDrivers() {
        return await this.messageService.getDriverList(this.job.id);
    }

     /**
     * Funktion zum laden der Gruppen für die Nachrichten
     */
     private async loadGroups() {
        return await this.groupService.getList(1, 500, {} as SearchQueryInterface);
    }

    /**
     * Erstellt das Formular um einen Bulk-Job zu erstellen
     * 
     * @param driverOptions 
     * @param groupOptions 
     */
    private createForm(driverOptions: FormElementOption[], groupOptions: FormElementOption[]) {
        if (this.formSchema.elements[1] && this.formSchema.elements[2]) {
            this.formSchema.elements[1].options = groupOptions;
            this.formSchema.elements[2].options = driverOptions;

        } else {
            throw new Error("Fehler beim bauen des Formulares - es wurden keine Elemente für die Fahrer oder Gruppenoption gefunden.");
        }
      
        this.form = createEmptyForm(1, this.formSchema);
    }

    onChangeForm() {
        if (this.formInstance) {
            if (this.formInstance.getValidator().validate()) {
                this.disableSave.set(false);
            } else {
                this.disableSave.set(true);
           }
       }
    }

    displayDriver(id: number) {
        const driver = this.driverList.find((driver) => {
            return driver.userListDto.id === id;
        });

        console.debug("displayDriver: ", {
            driver: driver,
            id: id
        });

        return `${driver?.userListDto.firstName} ${driver?.userListDto.lastName}`;
    }

    displayGroup(id: number) {
        const group = this.groupList.find((group) => {
            return group.id === id;
        });

        return group?.name;
    }

    /**
     * Speichern des BulkJobs
     */
    async clickSave() {
        await this.loadingService.start();
        const response = await this.messageService.createBulkMessage(this.job.id, this.payload)
            .catch((e) => {
                console.debug("e", e);
                return null;
            });
        
        await this.loadingService.stop();

        if (response) {
            await this.toastService.presentToast("Der Job wurde erfolgreich gepusht.");
            await this.modalController.dismiss({}, "saved");
        }
    }

    /**
     * Ausblenden des Modals
     */
    async clickClose() {
        await this.modalController.dismiss({}, 'cancel');
    }

}
