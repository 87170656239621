<ion-content>
    <ion-col>
        <ion-row>
            <ion-col>
                <ion-button (click)="clickGoBack()" fill="outline">
                    Zurück zur Liste
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                @if (jobListForm) {
                    <nb-form
                        [form]="jobListForm"
                        appearance="outline"
                        [delay]="0"
                        [debug]="false"
                    ></nb-form>
                }
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-button (click)="clickSaveNewJobList()">
                    Speichern
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-col>
</ion-content>
