import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'booleanToString',
    standalone: true
})
export class BooleanToStringPipe implements PipeTransform {
    transform(value: boolean | null): string {
        if (value === null) {
            return "-";
        }

        if (value) {
            return "Ja";
        } 
        
        return "Nein";
  }

}