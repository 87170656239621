
export enum TitleIconEnum {
    DOCUMENT = 'title__document',
    DRIVING_LICENSE = 'title__driving_license',
    ID_CARD = 'title__id_card',
    KNOWLEDGE = 'title__knowledge',
    ACCOUNT = 'title__account',
    DRIVER_AGENCY = 'title__driver_agency',
    DRIVER_VERIFIED = 'action__verified',
    SETTINGS = 'title__settings',
    STATISTIC = 'title__statistic',
    PUBLIC = 'title__public',
    MANAGE_ACCOUNTS = 'title_manage_accounts',
    JOBLIST_WAITING = 'title_joblist_waiting',
    JOBLIST_ACTIVE = 'title_joblist_active',
    JOBLIST_DENEYED = 'title_joblist_deneyed',
    JOBLIST_PAST = 'title_joblist_past',
    MESSAGES = 'title_messages',
}
