<ion-header>
    <ion-col>
        <ion-title>
            Neues Bild auswählen
        </ion-title>
    </ion-col>
</ion-header>
<ion-content>
    <ion-row>
        <ion-col>
            <shared-select-file-for-upload
                [config]="configSelectFileComponent"
                (selectedFile)="setSelectedFile($event)"
            ></shared-select-file-for-upload>
        </ion-col>
    </ion-row>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="clickCancel()" color="secondary">
                <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                &nbsp; Abbrechen
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>


