import { Directive, HostBinding} from '@angular/core';

@Directive({
    selector: '[uiTableKicker]',
    standalone: true
})
export class TableKickerDirective {

  @HostBinding('class')
  elementClass = 'kicker';

}