
import { Directive, Host, HostBinding, Input, OnInit, ViewContainerRef } from '@angular/core';
import { IconEnum } from '../../enum/icon.enum';
import { IconThemeEnum } from '../../enum/icon-theme.enum';
import { MatIcon } from '@angular/material/icon';
import { TitleIconEnum } from '../../enum/title-icon.enum';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'mat-icon[icon]',
    standalone: true,
})
export class IconDirective implements OnInit {

    @HostBinding('style.color') color!: string;
    @Input() icon!: IconEnum | TitleIconEnum;
    @Input() margin: 'left' | 'right' | null = null;
    @Input() theme: IconThemeEnum | null = null;

    constructor(
        @Host() private readonly matIcon: MatIcon,
        private readonly ref: ViewContainerRef,
    ) {
    }

    ngOnInit(): void {
        this.matIcon.svgIcon = this.icon;
        this.setColorTheme();
        this.setMargin();
    }

    private setColorTheme(): void {
        // theme is derived implicitly
        if (!this.theme) {
            switch(this.icon) {
                case IconEnum.DELETE:
                    this.color = 'var(--ion-color-danger)';
                    break;
                case IconEnum.SAVE:
                    this.color = 'var(--ion-color-primary)';
                    break;
                case IconEnum.CANCEL:
                    this.color = 'var(--ion-color-secondary)';
                    break;
                case IconEnum.CLOSE:
                    this.color = 'var(--ion-color-secondary)';
                    break;
            }
            return;
        }
        // theme has been set explicitly
        switch(this.theme) {
            case IconThemeEnum.DANGER:
                this.color = 'var(--ion-color-danger)';
                break;
            case IconThemeEnum.INHERIT:
                this.color = 'inherit';
                break;
            case IconThemeEnum.PRIMARY:
                this.color = 'var(--ion-color-primary)';
                break;
            case IconThemeEnum.SECONDARY:
                this.color = 'var(--ion-color-secondary)';
                break;
            case IconThemeEnum.SUCCESS:
                this.color = 'var(--ion-color-success)';
                break;
            case IconThemeEnum.WARNING:
                this.color = 'var(--ion-color-warning)';
                break;
        }
    }

    private setMargin(): void {
        if (this.margin === 'left') {
            (this.ref.element.nativeElement as HTMLElement).classList.add('icon-ml');
        }
        if (this.margin === 'right') {
            (this.ref.element.nativeElement as HTMLElement).classList.add('icon-mr');
        }
    }
}
