import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const jobListSearchConfig = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Agentur -> Jobliste"', {
    factory: () => {
        return {
            searchElements: [
                {
                    label: 'Id',
                    name: 'id',
                    type: 'text',
                    placeholder: 'id',
                    isNumber: true,
                    selection: []
                },
                {
                    label: 'Jobname',
                    name: 'name',
                    type: 'text',
                    placeholder: 'Name suchen',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Start Bewerbungsfrist',
                    name: 'showDate',
                    type: 'date',
                    placeholder: 'Start Bewerbungsfrist',
                    isNumber: false,
                    selection: [],
                    dateSingle: true,
                    datePresentation: 'date'
                },
                {
                    label: 'Ende Bewerbungsfrist',
                    name: 'closeDate',
                    type: 'date',
                    placeholder: 'Ende Bewerbungsfrist',
                    isNumber: false,
                    selection: [],
                    dateSingle: true,
                    datePresentation: 'date'
                },
                {
                    label: 'Start des Jobs',
                    name: 'startDate',
                    type: 'date',
                    placeholder: 'Start des Jobs',
                    isNumber: false,
                    selection: [],
                    dateSingle: true,
                    datePresentation: 'date-time'
                },
                {
                    label: 'Ende des Jobs',
                    name: 'endDate',
                    type: 'date',
                    placeholder: 'Ende des Jobs',
                    isNumber: false,
                    selection: [],
                    dateSingle: true,
                    datePresentation: 'date-time'
                },
                {
                    label: 'ist Aktiv',
                    name: 'isActive',
                    type: 'selection',
                    placeholder: 'Keine Auswahl',
                    isNumber: false,
                    selection: [
                        {
                            label: 'Ja',
                            value: true
                        }, 
                        {
                            label: 'Nein',
                            value: false
                        }
                    ]
                },
            ],
            sortElements: [
                {
                    label: 'Id',
                    name: 'id'
                },
                {
                    label: 'Jobname',
                    name: 'name'
                },
                {
                    label: 'Start Bewerbungsfrist',
                    name: 'showDate'
                },
                {
                    label: 'Ende Bewerbungsfrist',
                    name: 'closeDate'
                },
                {
                    label: 'Start des Jobs',
                    name: 'startDate'
                },
                {
                    label: 'Ende des Jobs',
                    name: 'endDate'
                },
                {
                    label: 'ist Aktiv',
                    name: 'isActive'
                },
            ]
        };
    }
});