import { AuthGuard } from '@nf-workforce/shared';
import { MyAgencyAccountsComponent } from './component/my-agency-accounts/my-agency-accounts.component';
import { MyAgencyAddDriverComponent } from './component/my-agency-add-driver/my-agency-add-driver.component';
import { MyAgencyAddMemberComponent } from './component/my-agency-add-member/my-agency-add-member.component';
import { MyAgencyDriversComponent } from './component/my-agency-drivers/my-agency-drivers.component';
import { MyAgencyEditComponent } from './component/my-agency-edit/my-agency-edit.component';
import { MyAgencyOneDriverComponent } from './component/my-agency-one-driver/my-agency-one-driver.component';
import { Route } from '@angular/router';
import { EditAgencyMemberComponent } from './component/edit-agency-member/edit-agency-member.component';
import { AgencyLogoComponent } from './component/my-agency-edit/agency-logo/agency-logo.component';

export const myAgencyRoutes: Route[] = [
    {
        path: '',
        component: MyAgencyEditComponent,
        title: 'Meine Agentur',
    },
    {
        path: 'agency-logo',
        component: AgencyLogoComponent,
        pathMatch: 'full',
        title: 'Agentur-Logo',
    },
    {
        path: 'accounts',
        component: MyAgencyAccountsComponent,
        pathMatch: 'full',
        title: 'Accounts',
        canActivate: [AuthGuard]
    },
    {
        path: 'add-member',
        component: MyAgencyAddMemberComponent,
        title: 'Neuer Account',
        canActivate: [AuthGuard]
    },
    {
        path: 'workforce',
        component: MyAgencyDriversComponent,
        pathMatch: 'full',
        title: 'Fahrer:innen',
        canActivate: [AuthGuard]
    },
    {
        path: 'add-driver',
        component: MyAgencyAddDriverComponent,
        title: 'Neue Fahrer:in',
        canActivate: [AuthGuard]
    },
    {
        path: 'driver/:id',
        component: MyAgencyOneDriverComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: 'accounts/:id',
        component: EditAgencyMemberComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
];
