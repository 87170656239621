import { Directive, HostBinding} from '@angular/core';

@Directive({
    selector: '[uiTableRow]',
    standalone: true
})
export class TableRowDirective {

  @HostBinding('class')
  elementClass = 'table-row';

}