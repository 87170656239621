<ion-content>
    <ion-row>

        @if (agencies().length > 0) {
            <div uiTable class="expand-xl fixed-header w-100">
                <div uiTableHead>
                    <div uiTableCell align="right">
                        #
                    </div>
                    <div uiTableCell>
                        Agentur
                    </div>
                    <div uiTableCell>
                        Agentur (Voller Name)
                    </div>
                    <div uiTableCell>
                        Ist aktiv
                    </div>
                    <div uiTableCell></div>
                </div>
    
                @for (agency of agencies(); track agency) {
                    <div uiTableRow>
                        <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                            <div uiTableKicker># {{ agency.id }}</div>
                            <div class="headline"><h3>{{ agency.name }}</h3></div>
                        </div>
    
                        <div uiTableCell align="right" [hideOnCollapse]="true">
                            {{ agency.id }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Name: </span>
                            {{ agency.name }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Voller Name: </span>
                            {{ agency.fullName }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel >Derzeit aktiv:</span>
                            {{ agency.isActive | booleanToString}}
                        </div>
    
                        <div uiTableCell>
                            <ion-button size="small" fill="outline" (click)="goToAgency(agency)">
                                <ion-icon name="pencil"></ion-icon>
                            </ion-button>
                        </div>
                    </div>
                }
    
                @if (enableMoreResults()) {
                    <div uiTableFooter>
                        <ion-button size="small" fill="outline" (click)="clickLoadNextPage()">
                            <ion-icon name="cloud-download-sharp" class="@todo-68"></ion-icon>
                            mehr Ergebnisse laden
                        </ion-button>
                    </div>
                }
            </div>
        } @else {
            <ion-col>
                <ion-text>
                    Keine Ergebnisse gefunden
                </ion-text>
            </ion-col>
        }

    </ion-row>
</ion-content>
