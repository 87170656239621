@if (job()) {
    <ion-content>
        <joblist-one-job-navigation
            [job]="job()"
            [actView]="'personal'"
        ></joblist-one-job-navigation>
        <ion-row>
            <ion-col>
                <joblist-workforce-job [job]="job()"></joblist-workforce-job>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <joblist-workforce-list
                    [job]="job()"
                    (reload)="reloadJobList()"
                ></joblist-workforce-list>
            </ion-col>
        </ion-row>
    </ion-content>
} @else {
    <ion-content>
        <ion-col>
            <ion-row>
                <ion-col>
                    Bitte warten ...
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-content>
}
