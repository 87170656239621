<ion-col>
    <ion-row>

        @if (accounts().length > 0) {
            <div uiTable class="expand-xl fixed-header w-100">
                <div uiTableHead>
                    <div uiTableCell>#</div>
                    <div uiTableCell>Benutzername</div>
                    <div uiTableCell>Name</div>
                    <div uiTableCell>Telefon</div>
                    <div uiTableCell>Validiert</div>
                    <div uiTableCell>Login erlaubt</div>
                    <div uiTableCell>Letzte E-Mail</div>
                    <div uiTableCell></div>
                </div>
    
                @for (account of accounts(); track account.id) {
                    <div uiTableRow>
                        <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                            <div uiTableKicker># {{ account.id }}</div>
                            <div class="headline">
                                <h3>{{ account | fullname }}</h3>
                            </div>
                        </div>
    
                        <div uiTableCell [hideOnCollapse]="true">
                            {{ account.id }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Benutzername: </span>
                            {{ account.username }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Name: </span>
                            {{ account | fullname }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Telefon: </span>
                            {{ account.phone }}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Benutzer ist validiert: </span>
                            {{ account.isValidated | booleanToString}}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Login ist aktiv: </span>
                            {{ account.loginActive | booleanToString}}
                        </div>
    
                        <div uiTableCell>
                            <span uiTableCellLabel>Letzte E-Mail: </span>
                            @if (account.lastMail !== null) {
                                {{ account.lastMail | printNbDateTime }}
                            } @else {
                                Noch nicht versendet
                            }
                        </div>
    
                        <div uiTableCell>
    
                            <ion-buttons>
                                @if (account.isCurrentUser) {
                                    <ion-button (click)="clickGoToMyAccount()" fill="outline" size="small">
                                        Mein Account
                                    </ion-button>
                                } @else {
                                    <ion-button (click)="clickGoToAccount(account)" fill="outline" size="small">
                                        Details
                                    </ion-button>
    
                                    <ion-button (click)="clickSendPassword(account.id)" fill="outline" size="small">
                                        Passwort
                                    </ion-button>
    
                                    <ion-button (click)="clickSendWelcome(account.id)" fill="outline" size="small">
                                        Willkommen
                                    </ion-button>
    
                                    @if (account.loginActive) {
                                        <ion-button (click)="clickDisableLogin(account.id)" fill="outline" size="small">
                                            Login verbieten
                                        </ion-button>
                                    } @else {
                                        <ion-button (click)="clickEnableLogin(account.id)" fill="outline" size="small">
                                            Login erlauben
                                        </ion-button>
                                    }
    
                                    <ion-button (click)="clickDelete(account.id)" fill="outline" color="danger" size="small">
                                        Löschen
                                    </ion-button>
                                }
    
                            </ion-buttons>
                        </div>
                    </div>
                }
    
                @if (enableMoreResults()) {
                    <div uiTableFooter>
                        <ion-button
                            size="small"
                            fill="outline"
                            (click)="clickLoadNextPage()">
                            <ion-icon name="cloud-download-sharp" class="@todo-68"></ion-icon>
                            mehr Ergebnisse laden
                        </ion-button>
                    </div>
                }
            </div>
        } @else {
            <ion-col>
                <ion-text>
                    Keine Ergebnisse gefunden
                </ion-text>
            </ion-col>
        }

    </ion-row>
</ion-col>
