
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { DriverJoblistService } from '../../../services/driver-joblist.service';
import {
  hasProperty,
  IconDirective,
  IconEnum,
  IconThemeEnum,
  JobListInterface,
  LoadingService,
  PrintNbDatePipe,
  PrintNbDateTimePipe,
  TitleIconEnum,
  TitleService,
  ToastService
} from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { AlertsService } from 'nb-form';
import { OneJobStammdatenCardComponent } from '../../driver-job-card/jobdaten-card/one-job-stammdaten-card.component';
import { OneJobKnowledgeCardComponent } from '../../driver-job-card/knowledge-card/one-job-knowledge-card.component';

@Component({
    selector: 'driver-joblist-driver-job',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        PrintNbDateTimePipe,
        IconDirective,
        MatIcon,
        OneJobStammdatenCardComponent,
        OneJobKnowledgeCardComponent
    ],
    templateUrl: './driver-job.component.html',
    styleUrl: './driver-job.component.scss',
    providers: [
        DriverJoblistService
    ]
})
export class DriverJobComponent implements OnInit {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    private jobId = 0;
    job!: JobListInterface;

    driverJob: WritableSignal<JobListInterface | null> = signal(null);

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: AlertsService,
        private joblistService: DriverJoblistService,
        private loadingService: LoadingService,
        private router: Router,
        private titleService: TitleService,
        private toastService: ToastService,
    ) {}

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'id')) {
            this.jobId = params['id'] as number;
            const job = await this.loadOneJob();
            if (job) {
                this.job = job;
                this.driverJob = signal(job);
                this.titleService.titleEmitter.emit(`Job #${job.id} "${job.name}"`);
            }
        }
    }

    /**
     * Laden eines einzelnen Jobs
     *
     * @returns
     */
    async loadOneJob() {
        const result = await this.joblistService.getOneOpenJobList(this.jobId)
            .catch((e: HttpErrorResponse) => {
                console.debug('Fehler bei der Anfrage', e);
                return null;
            });
        if (result && result.data.length === 1) {
            return result.data[0];
        }
        throw new Error(`Es konnte kein Job mit der Id: ${this.jobId} gefunden werden.`);
    }

    jobHasFreePlaces(): boolean {
        if (this.job) {
            return (this.job.freePlaces -this.job.countAccepted > 0);
        }
        return false;

    }

    async askApplyToJob() {
        let question = (this.jobHasFreePlaces()) ?
            `Wollen Sie sich jetzt für den Job "${this.job.name}" bewerben?`
            : `Wollen Sie sich jetzt auf die Warteliste des Jobs "${this.job.name}" eintragen?`;
        if (this.job.deadline === null) {
            question += (this.jobHasFreePlaces()) ?
                ` Ihre Bewerbung können Sie nur im direkten Gespräch mit der Agentur ändern!`
                : ` Nur die Agentur kann Sie von der Warteliste entfernen!`;
        }
        return await this.alertService.confirm({
            text: question,
            textOk: "Ja, jetzt bewerben",
            textCancel: "Abbrechen"
        });
    }
    /**
     * Bewerbung bei Job
     */
    async applyToJob() {
        if (this.job) {
            const confirmation = await this.askApplyToJob();
            if (!confirmation) {
                return;
            }
            await this.loadingService.start();
            const driverJob = this.job as JobListInterface;
            const result = await this.joblistService.applyForJob(driverJob)
                .catch((e: HttpErrorResponse) => {
                    console.debug('Fehler bei der Anfrage', e);
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                const answer = (this.jobHasFreePlaces()) ?
                    'Sie haben sich erfolgreich auf den Job beworben.'
                    : 'Sie wurden erfolgreich zur Warteliste hinzugefügt.';
                await this.toastService.presentToast(answer);
                const frontendUrl = `/my-jobs/one/${this.jobId}`;
                await this.router.navigate([frontendUrl]);
            }
        }
    }

    async clickGoToList() {
        await this.router.navigate(['/my-jobs/open']);
    }
}
