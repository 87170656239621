@if (showStatus()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Status Bewerbung</h2>

                @if (driver.status === DriverAgencyState.VALIDATED) {
                    <mat-icon class="driver-account-header__icon"
                    [icon]="Icon.VERIFIED"
                    [theme]="IconTheme.SUCCESS"
                    ></mat-icon>
                } @else {
                    <mat-icon class="driver-account-header__icon"
                    [icon]="Icon.VERIFIED"
                    [theme]="IconTheme.WARNING"
                    ></mat-icon>
                }

            </div>
        </ion-card-header>

        <ion-card-content>
            <ion-text [color]="(driver.status === DriverAgencyState.VALIDATED) ? IconTheme.SUCCESS : IconTheme.DANGER">
                    {{ driver.status | driverStatusToString }}
            </ion-text>
            <br/>

            @if (canValidate()) {
                <br/>
                <div class ="driver-skill">
                    <ion-buttons>
                        <ion-button fill="outline" size="small" color="primary" (click)="clickAcceptProfile()">
                            Profil bestätigen
                        </ion-button>
                        <ion-button fill="outline" size="small" color="danger" (click)="clickRejectProfile()">
                            Profil ablehnen
                        </ion-button>
                    </ion-buttons>
                </div>
            } @else {


                @if (driver.status === DriverAgencyState.VALIDATED) {
                    <div class="driver-skill">
                        <label class="driver-skill__label">Validierung am</label>
                        <span class="driver-skill__skill">
                        {{ driver.validUntil | printNbDateTime }}
                    </span>
                    </div>
                    <br/>
                    <div class="driver-skill">
                        <label class="driver-skill__label">Validierung von</label>
                        <span class="driver-skill__skill">
                       {{ driver.validatedBy }}
                    </span>
                    </div>
                } 

                @if (driver.status === DriverAgencyState.REJECTED) {
                    <br>
                    <div class="driver-skill">
                        <label class="driver-skill__label">Ablehnungsgrund:</label>
                        <span class="driver-skill__skill">
                            {{ driver.rejectionNotice }}
                    </span>
                    </div>
                }

            }
        </ion-card-content>
    </ion-card>
}
