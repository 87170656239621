/* eslint-disable @typescript-eslint/no-explicit-any */

import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { ErrorModalComponent } from '../component/error-modal/error-modal.component';
import { createErrorParser } from '../function/factory/error-parser.factory';
import { ErrorService } from '../services/error.service';
import { ERROR_MODAL_ENABLED } from '../token/show-error-dialog.token';

export const ValidationErrorInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const modalController = inject(ModalController);
    const errorService = inject(ErrorService);
    
    return next(req).pipe(
        catchError(err => {
            if (err.status === 400 || err.status === 422) {
                const errorParser = createErrorParser(err);
                errorService.setErrors(errorParser.getErrors());

                if (req.context.has(ERROR_MODAL_ENABLED) && req.context.get(ERROR_MODAL_ENABLED)) {
                    modalController.create({
                        component: ErrorModalComponent,
                        componentProps: {
                            errorParser: errorParser
                        }
                    }).then((modal) => {
                        modal.present()
                    });
                }
          }
          return throwError(err);
        })
      );  
};