import { Injectable } from '@angular/core';
import { FormElement, FormPage } from 'nb-form';

@Injectable()
export class EavFormService {
    private colSize = 4;

    buildFahrerlaubnisSchema(
        options: { key: string, value: string }[],
        activeIds: number[],
        showHeader = false,
        columnSize = 4
    ): FormPage {
        this.colSize = columnSize;

        let header = '';
        if (showHeader) {
            header = 'Fahrerlaubnis';
        }

        return {
            ber_reiter_id: 'fahrerlaubnis',
            header: header,
            navigation_label: 'fahrerlaubnis',
            useGrid: true,
            gridSize: 12,
            elements: this.buildElements(options, 'saveFahrerlaubnis', activeIds)
        } as FormPage;
    }

    buildSkillSchema(options: { key: string, value: string }[], activeIds: number[], colSize = 4): FormPage  {
        if (colSize !== this.colSize) {
            this.colSize = colSize;
        }

        return {
            ber_reiter_id: 'skills',
            header: '',
            navigation_label: 'skills',
            useGrid: true,
            gridSize: 12,
            elements: this.buildElements(options, 'saveSkill', activeIds)
        } as FormPage;
    }

    private buildElements(options: { key: string, value: string }[], funcName: string, activeIds: number[]): FormElement[] {
        return options.map((opt) => {
            const id = `${opt.value}-toggle`;
            const modelName = `${opt.value}Model`;
            const eavId = opt.key;

            return {
                id: id,
                type: 'checkbox',
                model: modelName,
                label: `${opt.value}`,
                onChange: {
                    func: funcName,
                    parameters: [eavId, `${modelName}`]
                },
                columnSize: this.colSize,
                default: activeIds.includes(parseInt(eavId))
            }
        });
    }
}