import { Component, Input } from '@angular/core';
import { PageActionInterface } from '../../interfaces/page-action.interface'
import { NavigationLinkComponent } from '../navigation-link/navigation-link.component';

@Component({
    standalone: true,
    imports: [NavigationLinkComponent],
    selector: 'shared-page-actions',
    templateUrl: './page-actions.component.html',
})
export class PageActionsComponent {
    @Input() pageActions?: PageActionInterface[];
}
