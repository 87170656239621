@if (job) {
    <ion-content>
        <ion-row>
            <ion-col>
                <ion-card class="show-on-hover-trigger">
                    <ion-card-header>
                        <div class="driver-account-header">
                            <h2 class="driver-account-header__text">
                                {{ job.name }}
                            </h2>
                                <mat-icon
                                    (click)="applyToJob()"
                                    class="driver-account-header__icon"
                                    [icon]="IconEnum.ADD_USER" [theme]="IconTheme.INHERIT"></mat-icon>
                        </div>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                <driver-joblist-one-job-stammdaten-card
                                    [job]="job"
                                    [canRemove]="false"
                                    [isDriverJob]="false"
                                ></driver-joblist-one-job-stammdaten-card>
                            </ion-col>
                            <ion-col>
                                <driver-joblist-one-job-knowledge-card
                                    [job]="job"
                                ></driver-joblist-one-job-knowledge-card>
                                <ion-toolbar>
                                    <ion-buttons slot="end">
                                        <ion-button
                                            size="small"
                                            fill="outline"
                                            [color]="IconTheme.INHERIT"
                                            (click)="applyToJob()"
                                            title="Bewerben"
                                            aria-label="Bewerben"
                                        ><mat-icon
                                            [icon]="IconEnum.ADD_USER"
                                            [theme]="IconTheme.INHERIT">
                                        </mat-icon>
                                            &nbsp;Bewerben
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-content>
} @else {
    <ion-content>
        <ion-col>
            <ion-text>
                Bitte warten: Job wird geladen!
            </ion-text>
        </ion-col>
    </ion-content>
}
