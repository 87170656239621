<ion-content>
    @if (job()) {
        <joblist-one-job-navigation
            [job]="job()"
            [actView]="'settings'"
        ></joblist-one-job-navigation>
    }
    <ion-col>
        @if (job()) {
        <ion-row>
            <ion-col>
                <div class="driver-account-grid">
                    <joblist-stammdaten-card
                        [job]="job()"
                        (editStammdaten)="clickEditJobStammdaten($event)">
                    </joblist-stammdaten-card>
                    <joblist-knowledge-card
                        [job]="job()"
                        (editKnowledge)="clickEditJobKnowledge($event)">
                    </joblist-knowledge-card>

                    <ion-card class="show-on-hover-trigger">
                        <ion-card-header>
                            <div class="driver-account-header">
                                <h2 class="driver-account-header__text">Status</h2>
                                <mat-icon class="driver-account-header__icon"
                                          [icon]="Icon.PUBLIC"
                                          [theme]="(isActive)?IconTheme.SUCCESS: IconTheme.DANGER"
                                ></mat-icon>
                            </div>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-toggle
                                            (ionChange)="toggleJobList()"
                                            labelPlacement="start"
                                            [checked]="isActive">
                                            @if(isActive) {
                                                Veröffentlicht: Fahrer können sich bewerben
                                            } @else {
                                                Nicht Veröffentlicht
                                            }
                                        </ion-toggle>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-card-content>
                        <ion-card-header>
                            <div class="driver-account-header">
                                <h2 class="driver-account-header__text">Statistik</h2>
                                <mat-icon class="driver-account-header__icon"
                                          [icon]="Icon.STATISTIC"
                                          [theme]="IconTheme.INHERIT"
                                ></mat-icon>
                            </div>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-label>Plätze insgesamt:</ion-label>
                                        {{ job()?.freePlaces }}
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-label>Zugewiesen:</ion-label>
                                        {{ job()?.countAccepted }}
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-label>Warteliste:</ion-label>
                                        {{ job()?.countWaiting }}
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-item>
                                        <ion-label>Abgelehnt:</ion-label>
                                        {{ job()?.countRejected }}
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-card-content>
                    </ion-card>
                </div>
            </ion-col>
        </ion-row>
        }
    </ion-col>
</ion-content>
