import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const DRIVER_REFERENCE_SCHEMA = new InjectionToken<FormPage>('"Fahrer-Referenz Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'driver-reference',
            header: '',
            navigation_label: 'driver-reference',
            elements: [
                {
                    id: 'reference',
                    type: 'textarea',
                    model: 'reference',
                    label: 'Referenz',
                    required: true,
                    placeholder: 'Referenz eintragen'
                }
            ]
        };
    }
});
