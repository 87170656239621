@if(driver) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <driver-my-profile-fahrerlaubnis [driver]="driver"></driver-my-profile-fahrerlaubnis>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CLOSE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Schließen
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}

