import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
    DocumentEavInterface,
    DriverDataInterface, DriverDocumentInterface,
    EavDocumentId,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    SelectedFileInterface,
    SelectFileForUploadComponent,
    ToastService
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';
import { AlertsService } from 'nb-form';
import { DriverProfileService } from '../../../../../service/driver-profile.service';
import { UploadDocumentRequestInterface } from '../../../../../interface/requests/upload-document-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';


@Component({
    selector: 'driver-document-upload-wizard-upload-files',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        MatIcon,
        ReactiveFormsModule,
        IconDirective,
        SelectFileForUploadComponent
    ],
    templateUrl: './upload-files.component.html',
    styleUrl: './upload-files.component.scss',
})
export class UploadFilesComponent implements OnInit, OnDestroy {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    @Input()
    driver: DriverDataInterface | null = null;
    @Input()
    currentEav: DocumentEavInterface | null = null;
    @Input()
    hasJustOneFile: boolean | null = null;
    @Output()
    document: EventEmitter<DriverDocumentInterface> = new EventEmitter();

    frontSide: SelectedFileInterface | null = null;
    backSide: SelectedFileInterface | null = null;

    title = 'Dokument hochladen';
    heute: string | null = null;
    morgen: string | null = null;

    form!: FormGroup;
    doUploadDocument: Subscription;

    constructor(
        private alertService: AlertsService,
        private driverProfileService: DriverProfileService,
        private toastService: ToastService,
        private loadingService: LoadingService,
    ) {
        const now = new Date();
        this.heute = now.toISOString();
        const morgen = new Date(new Date(now).setFullYear(now.getFullYear() + 1));
        this.morgen = morgen.toISOString();
        this.doUploadDocument = this.driverProfileService.doUploadDocument.subscribe(
            async () => {
                await this.onUpload();
            }
        ) as Subscription;
    }

    async ngOnInit() {
        if (this.driver && this.currentEav && this.hasJustOneFile !== null) {
            this.title = this.currentEav?.name + ' hochladen';
            this.buildForm();
        }

    }

    showForm(): boolean {
        if (this.frontSide !== null) {
            if (this.hasJustOneFile || this.backSide !== null) {
                return true;
            }
        }
        return false
    }

    onDateChange(event: CustomEvent, formFieldName: string) {
        const dateTime = event.detail.value.substring(0,10);
        this.form.get(formFieldName)?.setValue(dateTime);
    }

    getLabelDescription(): string {
        if (this.currentEav) {
            switch (this.currentEav.id) {
                case EavDocumentId.DRIVER_LICENCE_ID:
                    return 'Nr. des Führerscheins*:';
                case EavDocumentId.DRIVER_PSHEIN_ID:
                    return 'Nr. des P-Scheins*:';
                case EavDocumentId.DRIVER_PERSO_ID:
                    return 'Nr. des Ausweises*:';
                case EavDocumentId.DRIVER_AUFENTHALT_ID:
                    return 'Nr. des Aufenthaltstitel*:';
                default:
                    return (this.currentEav.noticeRequired) ? 'Beschreibung*:' : 'Beschreibung:';
            }
        }
        return 'Beschreibung:'
    }

    private buildForm() {
        this.form = new FormGroup({});
        if (this.currentEav) {
            const currentEav = this.currentEav;
            const notice = (currentEav.noticeRequired) ? new FormControl('', [Validators.required]) : new FormControl('');
            this.form.addControl('description', notice);

            if (currentEav.hasCreatedDate) {
                const createdDate = new FormControl(this.heute, [Validators.required]);
                this.form.addControl('createdDate', createdDate);
            }
            if (currentEav.hasExpiredDate) {
                const expireDate = new FormControl(this.morgen, [Validators.required]);
                this.form.addControl('expireDate', expireDate);
            }
        }
    }

    private getInputLabelForSelectFileComponent(isBackSide: boolean) {
        if (this.currentEav) {
            if (this.hasJustOneFile) {
                return `Wählen Sie Ihr Dokument "${this.currentEav.name}" aus.`;
            }
            const sideType = (isBackSide) ? 'Rückseite ' : 'Vorderseite ';
            return `${sideType} von "${this.currentEav.name}" auswählen.`;
        }
        return `Wählen Sie Ihr Dokument aus.`;
    }

    getConfigSideSelectFileComponent(isBackSide: boolean) {
        return {
            inputLabel: this.getInputLabelForSelectFileComponent(isBackSide),
            MIME_types_accepted: "image/png,image/jpeg,application/pdf"
        };
    }

    setFrontSideFile(frontSide: SelectedFileInterface | null) {
        this.frontSide = frontSide;
    }

    cancelFrontSideFile() {
        this.frontSide = null;
    }

    cancelBackSideFile() {
        this.backSide = null;
    }

    setBackSideFile(backSide: SelectedFileInterface | null) {
        this.backSide = backSide;
    }

    async onUpload() {
        const isValid = await this.isFormValid();
        if (!isValid) {
            return;
        }
        if (this.currentEav) {
            const question = `${this.currentEav.name} hochladen`;
            const confirmation = await this.alertService.confirm({
                text: question,
                textOk: "Ja",
                textCancel: "Abbrechen"
            });
            if (confirmation) {
                const frontSide = await this.uploadDocument(false);
                if (!frontSide) {
                    this.driverProfileService.uploadCompleted.emit(false);
                    return;
                }
                if (!this.hasJustOneFile) {
                    const backSide = await this.uploadDocument(true);
                    if (!backSide) {
                        this.driverProfileService.uploadCompleted.emit(false);
                        return;
                    }
                }
                this.driverProfileService.uploadCompleted.emit(true);
            }
        }
    }

    private async isFormValid(): Promise<boolean> {
        if (!this.form.valid) {
            await this.toastService.presentToast('Bitte prüfen Sie Ihre Eingaben!');
            return false;
        }
        if (this.frontSide === null) {
            const label = `Fehler: ${this.getInputLabelForSelectFileComponent(false)}`;
            await this.toastService.presentToast(label);
            return false;
        }
        if (this.backSide === null && !this.hasJustOneFile) {
            const label = `Fehler: ${this.getInputLabelForSelectFileComponent(true)}`;
            await this.toastService.presentToast(label);
            return false;
        }
        return true;
    }

    private buildPayload(isBackSide: boolean) {
        const datei = (isBackSide) ? this.backSide : this.frontSide;
        if (datei === null || this.currentEav === null) {
            return null;
        }
        datei.description = this.form.get('description')?.value;
        const payload = {
            eavId: this.currentEav.id,
            file: [datei],
            isBackSide: isBackSide,
        } as UploadDocumentRequestInterface;
        if (this.currentEav.hasCreatedDate) {
            payload.createdDate =  this.form.get('createdDate')?.value;
        }
        if (this.currentEav.hasExpiredDate) {
            payload.expireDate =  this.form.get('expireDate')?.value;
        }
        return payload;
    }

    private async uploadDocument(isBackSide: boolean): Promise<boolean> {
        const payload = this.buildPayload(isBackSide);
        if (null === payload) {
            console.error('Payload kann nicht gebaut werden!');
            await this.toastService.presentToast('Interner Fehler: Bitte starten Sie den Wizard neu!');
            return false;
        }
        if (this.hasJustOneFile) {
            await this.loadingService.start('Bitte warten ... Dokument wird hochgeladen!');
        } else {
            const sideMsg = (isBackSide) ? 'Vorderseite' : 'Rückseite';
            const msg = `Bitte warten ... ${sideMsg} wird hochgeladen!`;
            await this.loadingService.start(msg);
        }
        const result = await this.driverProfileService.uploadDocument(payload)
            .catch(async (e: HttpErrorResponse) => {
                console.debug('Fehler bei der Anfrage: ', e);
                await this.toastService.presentToast(
                    "Fehler bei der Anfrage, bitte versuchen Sie es später erneut"
                );
                return null;
            });
        this.loadingService.stop();
        if (result) {
            this.document.emit(result.data);
            return true;
        }
        return false;

    }

    ngOnDestroy(): void {
        this.frontSide = null;
        this.backSide = null;
        this.currentEav = null;
        if (this.doUploadDocument) {
            this.doUploadDocument.unsubscribe();
        }
    }

}
