/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';

export const FORCE_REFRESH_HEADER = 'x-nb-force-refresh';
const cache: Map<string, HttpResponse<unknown>> = new Map();

export const CacheInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {

    // never cache results from write requests
    if (req.method !== 'GET') {
        return next(req);
    }

    // handle force-refresh
    if (req.headers.get(FORCE_REFRESH_HEADER)) {
        // remove force-refresh header
        const newHeaders = req.headers.delete(FORCE_REFRESH_HEADER)
        req = req.clone({ headers: newHeaders });
        // delete cached result, if set
        cache.delete(req.urlWithParams);
    }

    // destroy the cache on logout
    if ((new URL(req.url)).pathname === '/api/logout') {
        cache.clear();
        return next(req);
    }

    // return cached response if available
    const cachedResponse = cache.get(req.urlWithParams);
    if (cachedResponse) {
        return of(cachedResponse);
    }

    // make actual call to server, if cached response is not available
    return next(req).pipe(
        tap((httpEvent) => {
            if (httpEvent instanceof HttpResponse) {
                cache.set(req.urlWithParams, httpEvent);
            }
        })
    );
};
