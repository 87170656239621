@if(showPlace()) {
    <ion-content>
        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>Ihr aktuelles Agentur-Logo</ion-card-title>
                        @if(!hasProfileBild()) {
                            @if(!hasFileSelected()) {
                                <ion-card-subtitle>
                                    <ion-text [color]="IconTheme.DANGER">
                                        Sie haben kein Agentur-Logo hinterlegt.
                                    </ion-text>
                                </ion-card-subtitle>
                            } @else {
                                <ion-card-subtitle>
                                    <ion-text [color]="IconTheme.PRIMARY">
                                        Sie haben ein Agentur-Logo ausgewählt. Sie können es nun bearbeiten und hochladen.
                                    </ion-text>
                                </ion-card-subtitle>
                            }
                        }
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                @if(!hasProfileBild() || !hasFileSelected()) {
                                    <ion-button
                                        (click)="clickSelectNewImage()"
                                        size="small"
                                        fill="outline"
                                        title="Neues Bild auswählen">
                                        <mat-icon [icon]="IconEnum.NEW"
                                                  [theme]="IconTheme.INHERIT" ></mat-icon>
                                        &nbsp; Neues Bild auswählen
                                    </ion-button>
                                }
                                @if(hasProfileBild() || hasFileSelected()) {
                                    <ion-button
                                        (click)="showPhotoCropper()"
                                        size="small"
                                        fill="outline"
                                        title="Bild bearbeiten">
                                        <mat-icon [icon]="IconEnum.EDIT"
                                                  [theme]="IconTheme.INHERIT" ></mat-icon>
                                        &nbsp; Bild Bearbeiten
                                    </ion-button>
                                }
                                @if(hasFileSelected()) {
                                    <ion-button
                                        (click)="clickUploadImage()"
                                        size="small"
                                        fill="outline"
                                        title="Bild bearbeiten">
                                        <mat-icon [icon]="IconEnum.UPLOAD"
                                                  [theme]="IconTheme.INHERIT" ></mat-icon>
                                        &nbsp; Bild hochladen
                                    </ion-button>
                                }
                                @if(hasProfileBild()) {
                                    <ion-button
                                        (click)="clickDeleteImage()"
                                        size="small"
                                        fill="outline"
                                        [color]="IconTheme.DANGER"
                                        title="Bild löschen">
                                        <mat-icon [icon]="IconEnum.DELETE"
                                                  [theme]="IconTheme.DANGER" ></mat-icon>
                                        &nbsp; Bild löschen
                                    </ion-button>
                                }
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <img class="current-image" [src]="currentImageSource" alt="Ihr aktuelles Profilbild">
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-content>
}
