export class FileHelper {
    /**
     * Gibt den Datei-Namen der heruntergeladenenen Datei zurück
     *
     * @returns string
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static getFileName(response: any): string {
      const contentDisposition = response.headers.get('content-disposition');

      const headerParts = contentDisposition.split('=');
      const fileName = headerParts[1] || 'untitled';
      return fileName.replaceAll('"', '');
    }

    /**
     * Gibt den Content-Type der heruntergeladenen Datei zurück
     *
     * @returns string
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static getContentType(response: any): string {
      const contentType = response.headers.get('content-type');

      return contentType;
    }
}
