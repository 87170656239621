import { Injectable } from '@angular/core';
import { FormElementButton, FormElementDate, FormElementDocument, FormPage } from 'nb-form';
import { DocumentEavInterface } from '@nf-workforce/shared';

@Injectable()
export class DriverDocumentSchemaService {

    /**
     * Element für den Upload
     */
    documentElement: FormElementDocument = {
        id: 'driver-document',
        type: 'document',
        model: 'file',
        label: 'Bitte wählen Sie das Dokument aus',
        multiple: false,
        required: true,
        columnSize: 12
    };

    /**
     * Element für das Erstellungsdatum
     */
    createdDateElement: FormElementDate = {
        id: 'driver-document-created',
        type: 'date',
        model: 'createdDate',
        label: 'Ausgestellt am',
        required: true,
        range: {
            year: {
                min: '-20',
                max: '+0'
            }
        },
        columnSize: 12
    };

    expireDateElement: FormElementDate = {
        id: 'driver-document--expires',
        type: 'date',
        model: 'expireDate',
        label: 'Gültig bis',
        required: true,
        range: {
            year: {
                min: '+0',
                max: '+15'
            }
        },
        columnSize: 12
    };

    uploadButton: FormElementButton = {
        id: 'driver-document--button',
        type: 'button',
        label: 'Hochladen',
        buttonText: 'Hochladen',
        click: { func: 'uploadDocument', parameters: [] },
        columnSize: 12
    };

    /**
     *
     * Baut das Schema für den Dokumenten-Upload und gibt es zurück
     *
     * @param agencies
     * @param documentEav
     * @returns
     */
    buildSchema(documentEav: DocumentEavInterface): FormPage {
        this.documentElement.label = `Bitte wählen Sie Ihr Dokument "${documentEav.name}" aus`;
        const elements = [this.documentElement];

        // // Wenn beide Date-Felder da sind, dann nebeneinander anzeigen, sonst volle Länge
        // const colSize = (documentEav.hasCreatedDate && documentEav.hasExpiredDate) ? 6 : 12;
        //
        // this.createdDateElement.columnSize = colSize;
        // this.expireDateElement.columnSize = colSize;
        if (documentEav.hasCreatedDate === true) {
            elements.push(this.createdDateElement);
        }

        if (documentEav.hasExpiredDate === true) {
            elements.push(this.expireDateElement);
        }

        elements.push(this.uploadButton);

        return {
            ber_reiter_id: "driver-document",
            //header: documentEav.name + " hochladen",
            navigation_label: "Dokumente",
            useGrid: true,
            gridSize: 12,
            elements: elements
        };
    }

}
