import { PageActionInterface } from "./page-action.interface";

export interface NavigationNodeInterface extends PageActionInterface {

    readonly type: 'NavigationNodeInterface',

    /**
     * Optional child nodes (typically for drop down menus).
     */
    childNodes?: NavigationNodeInterface[],
}
