import { Injectable } from '@angular/core';
import { DriverAutocompleteInterface, ENV, HttpService, PaginatorResponse, UserListDtoInterface } from '@nf-workforce/shared';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DriverAutocompleteService {

    private get autocompleteRoute(): string {
        
        return `${ENV.apiUrl}/agency-driver-autocomplete`;
    }

    constructor(
        private http: HttpService,
        private httpClient: HttpClient
    ) { }

    /**
     * Suche per Autocomplete nach Fahrer
     * 
     * @param payload 
     * @returns
     */
    searchDrivers(jobId: number, payload: DriverAutocompleteInterface) {
        const route = `${ENV.apiUrl}/joblist/${jobId}/driver-autocomplete`;
        return this.http.getBypassCache<PaginatorResponse<UserListDtoInterface>>(route, {
            ...payload,
            page: 1,
            limit: 10000
        });
    }

}