import { Directive, HostBinding } from '@angular/core';

@Directive({
    selector: '[uiTableCellLabel]',
    standalone: true
})
export class TableCellLabelDirective {

  @HostBinding('class')
  elementClass = 'label hide-when-expanded';

}