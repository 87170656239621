@if (driver() && profile() && driverAgencies()) {
<ion-content>
    <div class="driver-account-grid">
        <driver-my-profile-image-card
            [driver]="driver()"
            [profile]="profile()"
            [driverAgencies]="driverAgencies()"
            (edit)="editStammdaten($event)"
            (reload)="reload()"
        >
        </driver-my-profile-image-card>
        <driver-my-profile-stammdaten-card
            [profile]="profile()"
            (edit)="editStammdaten($event)"
        >
        </driver-my-profile-stammdaten-card>
        <driver-my-profile-knowledge-card
            [driver]="driver()"
            (edit)="editKnowledge($event)"
            (newReference)="newReference()"
            (editReference)="editReference($event)"
            (reload)="reload()"
        >
        </driver-my-profile-knowledge-card>
    </div>
    <div class="driver-account-document-grid">
        <driver-my-profile-documents-card
            [driver]="driver()"
            [driverAgencies]="driverAgencies()"
            (upload)="newUpload($event)"
            (reload)="reload()"
            (editFahrerlaubnis)="editKnowledge('fahrerlaubnis')"
            (editAgencyAccess)="editDocumentAgencyAccess($event)"
        >
        </driver-my-profile-documents-card>
    </div>
</ion-content>
}
