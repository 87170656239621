import { Component, Inject, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent, cleanInjectedValue, AlertsService } from 'nb-form';
import { IonicModule, ModalController } from '@ionic/angular';
import { USERNAME_SCHEMA } from '../../../schema/username-form.schema';
import { MyAccountService } from '../../../services/my-account.service';
import { AuthService, LoginResponseInterface, ToastService, LoadingService, ErrorService, IconDirective, IconEnum, IconThemeEnum } from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'lib-my-account-username-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        NbFormComponent,
        MatIcon
    ],
    templateUrl: './profil-username-modal.component.html',
    styleUrl: './profil-username-modal.component.scss',
    providers: [
        MyAccountService
    ]
})
export class ProfilUsernameModalComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    usernameForm: Form;

    @ViewChild('usernameInstance')
    usernameInstance!: NbFormComponent

    disableUserNameButton: WritableSignal<boolean> = signal(true);

    title = "Ihr Benutzername";

    constructor(
        @Inject(USERNAME_SCHEMA) private usernameSchema: FormPage,
        private myAccountService: MyAccountService,
        private modalController: ModalController,
        private authService: AuthService,
        private toastService: ToastService,
        private alertService: AlertsService,
        private loading: LoadingService,
        private errorService: ErrorService
    ) {
        const session = this.authService.getSession() as LoginResponseInterface;
        this.usernameForm =  new Form();
        this.usernameForm.id = 1;
        const tmpSchema = cleanInjectedValue(usernameSchema);
        tmpSchema.header = null;
        this.usernameForm.schema = [tmpSchema];
        this.usernameForm.data = {
            username: session.username
        };
    }

    onChangeForm() {
        if (this.usernameInstance) {
            const validator = this.usernameInstance.getValidator();
            this.disableUserNameButton.set(!validator.validate());
        } else {
            console.log('Not valid');
        }
    }

    async clickUpdateUserName() {
        if (this.disableUserNameButton()) {
            console.debug("Kein Request - Username-Formular ist ungültig");
            return;
        }

        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie jetzt den Benutzernamen ändern?",
            textOk: "Ja",
            textCancel: "Nein"
        });

        if (confirmation) {
            await this.loading.start();
            const data = this.usernameForm.data;
            const result = await this.myAccountService.updateUserName({
                username: data['username'] as string
            }).catch(async (e) => {
                console.debug("Fehler beim Aktualisieren des Benutzernamens: ", e);
                await this.loading.stop();
                await this.toastService.presentToast("Fehler beim Speichern des Benutzernamens, bitte versuchen Sie es später erneut.");
                return null;
            });

            if (result) {
                await this.loading.stop();
                await this.toastService.presentToast("Die Anfrage zum ändern des Benutzernamens wurde erfolgreich gesendet. Weitere Instruktionen folgen per E-Mail.");
                await this.modalController.dismiss({
                    saved: true
                });
            } else {
                this.errorService.highlightFormErrors(this.usernameInstance, this.usernameSchema);
            }
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }


}
