import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import {
    DocumentEavInterface,
    DriverAgencyInterface,
    DriverDocumentInterface,
    EavService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';
import { ProfileDocumentAgencyComponent } from '../document-agency/profile-document-agency.component';

@Component({
    selector: 'driver-my-profile-document-agency-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ProfileDocumentAgencyComponent
    ],
    templateUrl: './profile-document-agency-modal.component.html',
    styleUrl: './profile-document-agency-modal.component.scss',
})
export class ProfileDocumentAgencyModalComponent implements OnInit{

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    title = 'Freigabe bearbeiten';
    smallTitle = 'Freigabe bearbeiten';
    eavDocuments: DocumentEavInterface[] = [];

    @Input()
    document!: DriverDocumentInterface | null | undefined;
    @Input()
    agencies!: DriverAgencyInterface[];

    constructor(
        private eavService: EavService,
        private modalController: ModalController,
    ) {
        const eavDocuments = this.eavService.getDocuments();
        if (eavDocuments) {
            this.eavDocuments = eavDocuments;
        } else {
            throw new Error('EavDocuments nicht gesetzt');
        }
    }

    ngOnInit() {
        if (this.document  && this.agencies) {
            const currentDoc = this.document;
            const eav = this.eavDocuments.find(eav => {
                return (eav.id === currentDoc.eav);
            });
            if (eav) {
                this.smallTitle = eav.name;
                this.title = eav.name + ' - Freigabe bearbeiten';
            }
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

}
