import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    DocumentEavInterface, DriverAgencyInterface, DriverDocumentInterface, EavService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    ToastService
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverDocumentSchemaService } from '../../../../service/driver-document-schema.service';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-document-agency',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon
    ],
    providers: [
        DriverDocumentSchemaService
    ],
    templateUrl: './profile-document-agency.component.html',
    styleUrl: './profile-document-agency.component.scss',
})
export class ProfileDocumentAgencyComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    title = 'Freigabe bearbeiten';
    eavDocuments: DocumentEavInterface[] = [];

    @Input()
    document!: DriverDocumentInterface | null | undefined;
    @Input()
    agencies!: DriverAgencyInterface[];

    constructor(
        private driverProfileService: DriverProfileService,
        private eavService: EavService,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
        const eavDocuments = this.eavService.getDocuments();
        if (eavDocuments) {
            this.eavDocuments = eavDocuments;
        } else {
            throw new Error('EavDocuments nicht gesetzt');
        }
    }

    ngOnInit() {
        if (this.document  && this.agencies) {
            const currentDoc = this.document;
            const eav = this.eavDocuments.find(eav => {
                return (eav.id === currentDoc.eav);
            });
            if (eav) {
                this.title = eav.name + ' - Freigabe bearbeiten';
            }
        }
    }

    /**
     * Handler bei Aktivieren/Deaktivieren der Dokumentenfreigabe an eine Agentur
     *
     * @param event
     * @param driverAgency
     */
    async changeToggle(event: CustomEvent, driverAgency: DriverAgencyInterface) {
        if (this.document === undefined) {
            throw new Error(`Es wurde kein Dokument gesetzt`);
        }
        if (event.detail.checked) {
            if (document !== undefined) {
                await this.toggleAgencyDocument(driverAgency);
            }
        } else {
            if (document !== undefined) {
                await this.toggleAgencyDocument(driverAgency);
            }
        }
    }

    isOn(agency: DriverAgencyInterface): boolean {
        if (this.document && this.document.agencyDocuments.length > 0) {
            const agencyDocument = this.document.agencyDocuments.find((doc) => {
                return (doc.agencyId === agency.id);
            });

            return (agencyDocument !== undefined);
        }

        return false;
    }

    private async toggleAgencyDocument(driverAgency: DriverAgencyInterface) {
        if (this.document === undefined || this.document === null) {
            return;
        }

        await this.loadingService.start();

        const payload = {
            eavId: this.document.eav,
            agencyId: driverAgency.idAgency,
        };
        const result = await this.driverProfileService.toggleAgency(payload)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage: ", e);
                return null;
            });
        if (result) {
            console.debug("Result", result);
            this.toastService.presentToast("Erfolgreich freigeschaltet");
        }

        await this.loadingService.stop();
    }


}
