
import { ACCOUNT_RECOVERY_SCHEMA } from '../../schema/account-recovery.schema';
import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { ErrorService, ToastService, hasProperty, isString } from '@nf-workforce/shared';

@Component({
    selector: 'lib-account-recovery',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
    ],
    templateUrl: './account-recovery.component.html',
    providers: [],
})
export class AccountRevoveryComponent implements OnInit, AfterViewChecked {

    @ViewChild(NbFormComponent)
    nbFormInstance!: NbFormComponent;
    private recoveryCodeKey = 'nonce';
    private recoveryCode = '';
    form: Form;
    errors: WritableSignal<string[]> = signal([]);

    get formIsValid(): boolean {
        if (this.nbFormInstance) {
            return this.nbFormInstance.getValidator().validate();
        }
        return false;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Inject(ACCOUNT_RECOVERY_SCHEMA) private recoverySchema: FormPage,
        private accountService: AccountService,
        private toastService: ToastService,
        private cdr: ChangeDetectorRef,
        private errorService: ErrorService
    ) {
        this.form = new Form();
        this.form.schema = [this.recoverySchema];
        this.form.data = {
            password: null
        };
        this.cdr.detach();
    }

    ngOnInit(): void {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (hasProperty(queryParams, this.recoveryCodeKey) && isString(queryParams[this.recoveryCodeKey])) {
            this.recoveryCode = queryParams[this.recoveryCodeKey];
        }
    }

    ngAfterViewChecked(): void {
        this.cdr.reattach();
        this.cdr.detectChanges();
    }

    async clickNewPassword() {
        if (!this.nbFormInstance.getValidator().validate()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        const result = await this.accountService.recoverAccount({
            nonce: this.recoveryCode,
            newPassword: this.form.data['newPassword'] as string
        }).catch(async (e) => {
            return null;
        });

        if (result) {
            await this.toastService.presentToast(
                'Die Wiederherstellung war erfolgreich. Du kannst Dich jetzt mit Deinem neuen Passwort anmelden.'
            );
            this.router.navigate(['/login']);
        } else {
            this.errorService.highlightFormErrors(this.nbFormInstance, this.recoverySchema);
        }
    }

    formChanged() {
        this.validateForm();
        this.cdr.markForCheck();
    }

    /**
     * Funktion zum Validieren des Formulares und setzen der Extra-Nachrichten
     */
    private validateForm() {
        if (this.nbFormInstance) {
            const validator = this.nbFormInstance.getValidator();
            const result = validator.validate();

            if (!result && validator.errorFields.length === 1) {
                const errorControl = validator.errorFields[0].control;
                const controlErrors = errorControl.errors as { [key: string]: string };
                const errorKeys = Object.keys(controlErrors);

                // NbFormBadRequest rausfiltern + die zutreffenden Fehlermeldungen zurückgeben
                const errorMessages = errorKeys.filter((key) => {
                    return (key !== 'nbFormBadRequest' && isString(controlErrors[key]));
                }).map((key) => {
                    return controlErrors[key];
                })

                this.errors.set(errorMessages);
                validator.highlightErrors = true;
                validator.setValidationErrors({
                    password: 'Bitte überprüfen Sie Ihre Eingabe.'
                });
            } else {
                this.errors.set([]);
                validator.highlightErrors = false;
            }
        }
    }
}
