<ion-card>
    <ion-card-header>
        <div class="driver-account-header">
            <h2 class="driver-account-header__text">Dokumente</h2>
            <mat-icon class="driver-account-header__icon" [icon]="Icon.DOCUMENT" [theme]="IconTheme.INHERIT"></mat-icon>
        </div>
        <ion-card-subtitle>
            <ion-button title="Upload: Neues Dokument" fill="clear" size="small" (click)="clickNewDocument()">
                <mat-icon [icon]="IconEnum.UPLOAD" [theme]="IconTheme.INHERIT"></mat-icon>
                <span class="ion-hide-md-down">Dokument hochladen</span>
            </ion-button>
        </ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
        @if(showPlace()) {
        <div class="driver-account-document-list-grid">
            @for (eavDoc of eavDocuments; track eavDoc.id) {
            @if (hasDocument(eavDoc)) {
            @if (isSingleEavDocument(eavDoc.id)) {

            <driver-my-profile-one-document [driver]="driver" [driverAgencies]="driverAgencies"
                [eavDocument]="eavDoc" [driverDocument]="findOneDocumentByEav(eavDoc)"
                (delete)="deleteDocument($event)" (editFahrerlaubnis)="clickEditFahrerlaubnis()"
                (editAgencyAccess)="clickEditAgencyAccess($event)"></driver-my-profile-one-document>

            } @else {
            @for (driverDocument of findAllDocumentsByEav(eavDoc); track driverDocument.id) {

            <driver-my-profile-one-document [driver]="driver" [driverAgencies]="driverAgencies"
                [eavDocument]="eavDoc" [driverDocument]="driverDocument" (delete)="deleteDocument($event)"
                (editAgencyAccess)="clickEditAgencyAccess($event)"></driver-my-profile-one-document>

            }
            }
            }
            }
        </div>
        }
    </ion-card-content>
</ion-card>
