import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const agencyGroupSearch = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Agentur -> Fahrer"', {
    factory: () => {
        return {
            searchElements: [
                {
                    label: 'Id',
                    name: 'id',
                    type: 'text',
                    placeholder: 'Id',
                    isNumber: true,
                    selection: []
                },
                {
                    label: 'Name',
                    name: 'name',
                    type: 'text',
                    placeholder: 'Gruppenname',
                    isNumber: false,
                    selection: []
                }
            ],
            sortElements: [
                {
                    label: 'Id',
                    name: 'id'
                },
                {
                    label: 'Name',
                    name: 'name'
                }
            ]
        }
        
        
        
        
        
    }
});
