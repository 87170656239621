<ion-content>
    <ion-col>
    @if (account() && driver()) {
        @if (enableProfile()) {
            <ion-row>
                <div class="driver-account-grid">
                    @if (profileImage) {
                        <agency-driver-profile-image-card
                            [account]="account()!!"
                            [profileImage]="profileImage"
                            [driver]="driver()!!"
                            (reload)="reloadDriver()"
                        ></agency-driver-profile-image-card>
                    }
                    <agency-driver-stammdaten-card
                        [account]="account()!!"
                        [driver]="driver()!!"
                    ></agency-driver-stammdaten-card>
                    <agency-driver-knowledge-card
                        [account]="account()!!"
                        [driver]="driver()!!"
                    >
                    </agency-driver-knowledge-card>
                </div>
            </ion-row>
            <ion-row>
                <ion-col>
                    <agency-driver-document
                        [account]="account()"
                        (reload)="reloadDriver()"
                    ></agency-driver-document>
                </ion-col>
            </ion-row>
        } @else {
            @if (profileImage) {
                <agency-driver-profile-disabled-card
                    [account]="account()!!"
                    [profileImage]="profileImage"
                    [driver]="driver()!!"
                    [enableProfile]="enableProfile()"
                ></agency-driver-profile-disabled-card>
            }
        }

    } @else {
        <ion-row>
            <ion-col>
                <ion-text>
                    Es wurde kein Fahrer gefunden!
                </ion-text>
            </ion-col>
        </ion-row>
    }

    </ion-col>
</ion-content>
