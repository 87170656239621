import { Injectable } from '@angular/core';
import { ENV, HttpService, JobListInterface, PaginatorResponse, SearchQueryInterface } from '@nf-workforce/shared';

@Injectable()
export class MessageJobService {
    constructor(
        private httpService: HttpService
    ) {}

    get jobListRoute(): string {
        return `${ENV.apiUrl}/joblist`;
    }

    /**
     * Holen der Liste der JobLists
     *
     * @param page
     * @param limit
     * @returns
     */
    async getList(page: number, limit: number, searchQuery: SearchQueryInterface) {
        const query = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        };

        return this.httpService
            .get<PaginatorResponse<JobListInterface>>(this.jobListRoute, query);
    }

    /**
     * Suche einer Jobliste anhand der übergebenen ID
     *
     * @param id
     * @returns
     */
    async getOne(id: number) {
        return this.httpService
            .getBypassCache<PaginatorResponse<JobListInterface>>(this.jobListRoute, {
                page: 1,
                limit: 1,
                id: id
            });
    }

}