import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormControl, FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { DriverProfileInterface, EavService, IconDirective, IconEnum, IconThemeEnum, LoadingService, showFormError, ToastService } from '@nf-workforce/shared';
import { FormErrorComponent } from '@nf-workforce/ui';
import { DriverProfileService } from '../../../../../service/driver-profile.service';
import { DriverFormStammdatenService } from '../../../../../service/driver-form-stammdaten.service';
import { AlertsService } from 'nb-form';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'driver-my-profile-wohnort-form-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AsyncPipe,
        IconDirective,
        MatIcon,
        FormErrorComponent
    ],
    templateUrl: './wohnort-form.component.html',
    providers:[DriverFormStammdatenService]
})
export class DriverWohnortFormComponent implements OnInit {

    form!: FormGroup;
    filteredOptions!: Observable<{country: string}[]>;
    country!: FormControl;
    countries: {country: string}[] = [];

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    profile: DriverProfileInterface | null = null;

    showError = showFormError;

    constructor(
        private alertService: AlertsService,
        private eavService: EavService,
        private formHelper: DriverFormStammdatenService,
        private modalController: ModalController,
        private toastService: ToastService,
        private driverProfileService: DriverProfileService,
        private loadingService: LoadingService,
    ) {
        const eavCountries = this.eavService.getCountries();
        if (eavCountries) {
            eavCountries.forEach(eav => {
                this.countries.push({country: eav.country});
            });
        }
    }


    ngOnInit() {
        if (this.profile && this.countries) {
            this.formHelper.setDriverProfile(this.profile);
            const opt = this.formHelper.configWohnort;
            this.form = new FormGroup(opt);
            this.country = opt.country;
            this.filteredOptions = this.country.valueChanges.pipe(
                startWith(''),
                map(value => {
                    const name = typeof value === 'string' ? value : value?.name;
                    return name ? this._filter(name as string) : this.countries.slice();
                }),
            );
        }
    }

    displayFn(country: {country: string}): string {
        return country && country.country ? country.country : '';
    }

    private _filter(name: string): {country: string}[] {
        const filterValue = name.toLowerCase();
        return this.countries.filter(option => option.country.toLowerCase().includes(filterValue));
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    async clickSave() {
        if (this.form.invalid) {
            await this.toastService.presentToast("Bitte prüfen Sie Ihre Eingaben!");
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: 'Wollen Sie jetzt speichern',
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const payload = this.formHelper.getPayloadForWohnort(this.form);
            const result = await this.driverProfileService.patchDriverProfile(payload)
                .catch((e: HttpErrorResponse) => {
                    console.debug("Fehler beim Speichern: ", e);
                    this.toastService.presentToast("Fehler beim Speichern. Bitte versuchen Sei es später erneut.");
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                await this.modalController.dismiss({
                    saved: true
                });
            }
        }
    }
}
