import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService, Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { ErrorService, IconDirective, IconEnum, IconThemeEnum, LoadingService, TitleIconEnum, ToastService, createEmptyForm, formatPhoneNumber, isString } from '@nf-workforce/shared';
import { Router } from '@angular/router';
import { ADD_DRIVER_SCHEMA } from '../../schema/add-driver.schema';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { AddDriverRequestInterface } from '../../interface/add-driver-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-my-agency-add-driver',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
        IconDirective,
        MatIcon
    ],
    templateUrl: './my-agency-add-driver.component.html',
    styleUrl: './my-agency-add-driver.component.scss',
    providers: []
})
export class MyAgencyAddDriverComponent implements OnInit {

    driverForm: Form;

    @ViewChild(NbFormComponent)
    driverFormInstance!: NbFormComponent

    /**
     * Gibt die Helper für das Formular zurück
     * In Ausbaustufe 1 erlauben wir nur selbständige Fahrer
     */
    get helpers(): object {
        return {};
        // return {
        //     // Funktion zum setzen der Werte, wie vom Server erwartet., anhand der im NbDropdown getroffenen Auswahl
        //     evaluateWorkingRelationship: (parameters: unknown[], scope: Scope) => {
        //         const selectedValue = parameters[0];
        //
        //         // Nur etwas tun, wenn der Wert truthy ist, sonst überspringen
        //         if (typeof selectedValue === "number") {
        //             if (selectedValue === DriverRelationship.IS_COMPANY) {
        //                 scope.write('isCompany', true);
        //                 scope.write('isEmployee', false);
        //             } else if (selectedValue === DriverRelationship.IS_FREE_AGENT) {
        //                 scope.write('isCompany', false);
        //                 scope.write('isEmployee', false);
        //             } else if (selectedValue === DriverRelationship.IS_EMPLOYEE) {
        //                 scope.write('isCompany', false);
        //                 scope.write('isEmployee', true);
        //             }
        //         }
        //     }
        // };
    }

    constructor(
        private toastService: ToastService,
        private router: Router,
        @Inject(ADD_DRIVER_SCHEMA) private driverSchema: FormPage,
        private loadingService: LoadingService,
        private alertService: AlertsService,
        private memberService: MyAgencyMemberService,
        private errorService: ErrorService
    ) {
        this.driverForm = createEmptyForm(1, cleanInjectedValue(this.driverSchema));
    }

    async ngOnInit(): Promise<void> {
        console.debug("OnINit", {
            driverFOrm: this.driverForm
        });
    }

    /**
     * Geht zurück zur vorherigen Ansicht: Fahrerliste in der Agenturübersicht
     */
    async clickGoBack() {
        await this.router.navigate(['/my-agency/workforce']);
    }

    async clickSaveDriver() {
        if (!this.formIsValid) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ijre Eingaben.");
            return;
        }

        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie den neuen Fahrer jetzt anlegen?",
            textOk: "Ja",
            textCancel: "Nein"
        });

        if (confirmation) {
            const creationResult = await this.memberService.addNewDriver(this.payload)
                .catch(async (e: HttpErrorResponse) => {
                    console.debug("Fehler beim erstelle ndes Fahrers: ", e);
                    await this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut");
                    return null;
                });

            if (creationResult) {
                await this.toastService.presentToast("Der neue Fahrer wurde erfolgreich erstellt.");
                this.router.navigate(['/my-agency/workforce'], {replaceUrl: true});
            } else {
                this.errorService.highlightFormErrors(this.driverFormInstance, this.driverSchema);
            }
        }
    }

    private get payload(): AddDriverRequestInterface {
        const academic = (isString(this.driverForm.data['academic'])) ?
            this.driverForm.data['academic'] : null;
        const sendMail = (this.driverForm.data['sendMail'] === "1");
        const phone = formatPhoneNumber(this.driverForm.data['phone'] as string);
        // In Ausbaustufe 1 erlauben wir nur selbständige Fahrer
        return {
            username: this.driverForm.data['username'] as string,
            isCompany: false,
            isEmployee: false,
            sex: this.driverForm.data['sex'] as string,
            academic: academic,
            firstName: this.driverForm.data['firstName'] as string,
            lastName: this.driverForm.data['lastName'] as string,
            phone: phone,
            sendMail: sendMail,
            language: null
        };
    }

    /**
     * Gibt zurück, ob das Formular valide ist
     */
    get formIsValid(): boolean {
        if (!this.driverFormInstance) {
            return false;
        }

        return this.driverFormInstance.getValidator().validate();
    }

  protected readonly Icon = TitleIconEnum;
  protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
}
