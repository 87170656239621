@if(job) {
    <ion-card>
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">{{title}}</h2>
            </div>
            <ion-card-subtitle>
                <ion-row>
                    <ion-col>
                        <ion-button
                            [fill]="isActive(200)? 'outline' : 'clear'"
                            [color]="isActive(200)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                            (click)="changeList(200)"
                            aria-label="Liste Zugewiesen"
                        ><mat-icon [icon]="Icon.JOBLIST_ACTIVE" [theme]="IconTheme.SECONDARY" ></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;Zugewiesen</ion-text>
                        </ion-button>
                        <ion-button
                            [fill]="isActive(100)? 'outline' : 'clear'"
                            [color]="isActive(100)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                            (click)="changeList(100)"
                        ><mat-icon [icon]="Icon.JOBLIST_WAITING" [theme]="IconTheme.SECONDARY" ></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;WarteListe</ion-text>
                        </ion-button>
                        <ion-button
                            [fill]="isActive(500)? 'outline' : 'clear'"
                            [color]="isActive(500)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                            (click)="changeList(500)"
                        ><mat-icon [icon]="Icon.JOBLIST_DENEYED" [theme]="IconTheme.SECONDARY" ></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;Abgelehnt</ion-text>
                        </ion-button>
                    @if (displayedList && displayedList !== 500) {
                        <ion-button
                            [fill]="'clear'"
                            [color]="IconTheme.SECONDARY"
                            (click)="clickShowDriverSearch()"
                        ><mat-icon [icon]="IconEnum.ADD_USER" [theme]="IconTheme.SECONDARY" ></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;Hinzufügen</ion-text>
                        </ion-button>
                    }
                    </ion-col>
                </ion-row>
            </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
            <ion-row>
            @if(driverList().length > 0) {
                <ion-col>
                    <div uiTable class="expand-xl">
                        <div uiTableHead>
                            <div uiTableCell>
                                #
                            </div>
                            <div uiTableCell>
                                Name
                            </div>

                            <div uiTableCell>
                                Benutzername
                            </div>

                            <div uiTableCell></div>
                        </div>

                        @for (driver of driverList(); track driver.userListDto.id) {
                            <div uiTableRow>

                                <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                    <div class="headline"><h3>{{ driver.userListDto | fullname }}</h3></div>
                                </div>

                                <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                    <span uiTableCellLabel>Id: </span>
                                    {{ driver.userListDto.id }}
                                </div>

                                <div uiTableCell [hideOnCollapse]="true">
                                    {{ driver.userListDto.id }}
                                </div>

                                <div uiTableCell [hideOnCollapse]="true">
                                    <span uiTableCellLabel>Name: </span>
                                    {{ driver.userListDto | fullname }}
                                </div>

                                <div uiTableCell>
                                    <span uiTableCellLabel>Benutzername: </span>
                                    {{ driver.userListDto.username }}
                                </div>

                                <div uiTableCell>
                                    <ion-button
                                        [color]="IconTheme.INHERIT"
                                        (click)="clickMoveDriverToList(driver, false)"
                                        fill="clear"
                                        size="small">
                                        <mat-icon
                                            [icon]="IconEnum.MOVE_USER_TO_LIST"
                                            [theme]="IconTheme.SECONDARY" >
                                        </mat-icon>
                                    </ion-button>
                                    <ion-button
                                        [color]="IconTheme.INHERIT"
                                        (click)="clickMoveDriverToList(driver, true)"
                                        fill="clear"
                                        size="small">
                                        <mat-icon
                                            [icon]="IconEnum.REJECT_DRIVER_IN_JOBLIST"
                                            [theme]="IconTheme.SECONDARY" >
                                        </mat-icon>
                                    </ion-button>
                                    <ion-button
                                        [color]="IconTheme.DANGER"
                                        (click)="deleteFromList(driver)"
                                        fill="clear"
                                        size="small">
                                        <mat-icon
                                            [icon]="IconEnum.DELETE"
                                            [theme]="IconTheme.DANGER" >
                                        </mat-icon>
                                    </ion-button>
                                </div>
                            </div>
                        }

                        @if(enableMoreResults()) {
                            <div uiTableFooter>
                                <ion-col class ="ion-text-center">
                                    <ion-button size="small" fill="outline" (click)="clickLoadNextPage()">
                                        <ion-icon name="cloud-download-sharp" class="@todo-68"></ion-icon>
                                        mehr Ergebnisse laden
                                    </ion-button>
                                </ion-col>
                            </div>
                        }
                    </div>
                </ion-col>
            } @else {
                <ion-col>
                    <ion-text color="danger">
                        @if (isActive(500)) {
                            Abgelehnt:
                        } @else if (isActive(100)) {
                            Warteliste:
                        } @else {
                            Zugewiesen:
                        }
                        &nbsp;
                        Es wurden keine Ergebnisse gefunden.
                    </ion-text>
                </ion-col>
            }
        </ion-row>
        </ion-card-content>
    </ion-card>
} @else {
    <ion-card>
        <ion-card-content>
            <ion-col class ="ion-text-center">
              Die Personalliste konnte nicht geladen werden, bitte versuchen Sie es später erneut
            </ion-col>
        </ion-card-content>
    </ion-card>
}

