@if(job) {

    @if(!showContent()) {
        <ion-card class="show-on-hover-trigger" (click)="onClickShowContent()">
            <ion-card-header>
                <div class="driver-account-header">
                    <h2 class="driver-account-header__text">
                        {{ job.name }}
                    </h2>
                    <mat-icon class="driver-account-header__icon"
                              [icon]="IconEnum.OPEN_ACCORDION"
                              [theme]="IconTheme.INHERIT"
                    ></mat-icon>
                </div>
            </ion-card-header>
            <ion-card-content>
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Status</span>
                    </label>
                    <span class="driver-skill__skill">
                        @if(isActive) {
                            Veröffentlicht: Fahrer können sich bewerben
                        } @else {
                            Nicht Veröffentlicht
                        }
                    </span>
                </div>
            </ion-card-content>
        </ion-card>
    } @else {
        <ion-card class="show-on-hover-trigger" >
            <ion-card-header>
                <div class="driver-account-header">
                    <h2 class="driver-account-header__text">
                        {{ job.name }}
                    </h2>
                    <mat-icon class="driver-account-header__icon"
                              [icon]="Icon.PUBLIC"
                              [theme]="(isActive)?IconTheme.SUCCESS: IconTheme.DANGER"
                    ></mat-icon>
                </div>
            </ion-card-header>
            <ion-card-content>
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Status</span>
                    </label>
                    <span class="driver-skill__skill">
                        @if(isActive) {
                            Veröffentlicht: Fahrer können sich bewerben
                        } @else {
                            Nicht Veröffentlicht
                        }
                    </span>
                </div>
                <br>
                <ion-row>
                    <ion-col>
                        <div class="driver-skill">
                            <label class="driver-skill__label">
                                <span class="driver-skill__label-text">Bewerbungsfrist</span>
                            </label>
                            <span class="driver-skill__skill">
                                Start: {{ job.showDate | printNbDate }}<br>
                                Ende: {{ job.closeDate  | printNbDate }}<br>
                                @if (job.deadline !== null) {
                                    Austragen bis: {{ job.deadline  | printNbDate }}
                                } @else {
                                    Fahrer dürfen sich nicht selbst austragen!
                                }
                            </span>
                        </div>
                    </ion-col>
                    <ion-col>
                        <div class="driver-skill">
                            <label class="driver-skill__label">
                                <span class="driver-skill__label-text">Laufzeit Job</span>
                            </label>
                            <span class="driver-skill__skill">
                                Start: {{ job.startDate | printNbDateTime }}<br />
                                Ende: {{ job.endDate  | printNbDateTime }}
                            </span>
                        </div>
                    </ion-col>
                    <ion-col>
                        <div class="driver-skill">
                            <label class="driver-skill__label">
                                <span class="driver-skill__label-text">Plätze</span>
                            </label>
                            <span class="driver-skill__skill">
                                Gesamt: {{ job.freePlaces }}<br />
                                Zugewiesen: {{ job.countAccepted }}<br>
                                Warteliste: {{ job.countWaiting }}<br>
                                Abgelehnt: {{ job.countRejected }}
                            </span>
                        </div>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-toolbar>
                            <ion-buttons slot="end">
                                <ion-button
                                    fill="clear"
                                    [color]="IconTheme.SECONDARY"
                                    (click)="onClickShowContent()"
                                >
                                    <mat-icon class="driver-account-header__icon"
                                              [icon]="IconEnum.CLOSE"
                                              [theme]="IconTheme.INHERIT"
                                    ></mat-icon>
                                    <ion-text class="ion-hide-md-down">&nbsp;Schließen</ion-text>
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-col>
                </ion-row>

            </ion-card-content>
        </ion-card>
    }
} @else {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Status</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.PUBLIC"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <ion-col class ="ion-text-center">
                <ion-text>
                    Job konnte nicht geladen werden. Bitte versuchen Sie es erneut.
                </ion-text>
            </ion-col>
        </ion-card-content>
    </ion-card>
}
