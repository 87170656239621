
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AlertsService } from 'nb-form';
import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { DriversComponent } from '../joblist-drivers/joblist-drivers.component';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule, ModalController } from '@ionic/angular';
import { JobListService } from '../../services/job-list.service';
import { MatIcon } from '@angular/material/icon';
import { EditJobStammdatenModalComponent } from './stammdaten-card/edit-job-stammdaten/edit-job-stammdaten-modal.component';
import { StammdatenCardComponent } from './stammdaten-card/stammdaten-card.component';
import { KnowledgeCardComponent } from './knowledge-card/knowledge-card.component';
import { EditJobKnowledgeModalComponent } from './knowledge-card/edit-job-knowledge/edit-job-knowledge-modal.component';
import { OneJobNavigationComponent } from '../one-job-navigation/one-job-navigation.component';
import {
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    LoadingService,
    TitleIconEnum,
    TitleService,
    ToastService,
    hasProperty
} from '@nf-workforce/shared';

@Component({
    selector: 'joblist-one-job',
    standalone: true,
    imports: [
        CommonModule,
        DriversComponent,
        IonicModule,
        IconDirective,
        RouterModule,
        MatIcon,
        StammdatenCardComponent,
        KnowledgeCardComponent,
        OneJobNavigationComponent
    ],
    templateUrl: './one-job.component.html',
    styleUrl: './one-job.component.scss',
    providers: [
        JobListService,
    ]
})
export class OneJobComponent implements OnInit {

    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    job: WritableSignal<JobListInterface | null> = signal(null);
    jobId: number | null = null;
    isActive!: boolean;
    messageRoute!: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: AlertsService,
        private jobListService: JobListService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private router: Router,
        private titleService: TitleService,
        private toastService: ToastService,
    ) {}

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'id')) {
            this.jobId = parseInt(params['id']);
            this.messageRoute = `/message/${this.jobId}`;
            await this.loadJob();
        }
    }

    async loadJob() {
        this.job.set(null);
        const response = await this.jobListService.getOne(this.jobId as number)
            .catch((e: HttpErrorResponse) => {
                console.error('Fehler beim Laden der Jobliste: ', e);
                return null;
            });
        if (!response || response.data.length !== 1) {
            throw Error('Es wurden keine oder zu viele Jobs geladen!');
        } else {
            this.job.set(response.data[0]);
            this.isActive = response.data[0].isActive;
            this.titleService.titleEmitter.emit(`Job #${this.job()?.id} "${this.job()?.name}"`);
        }
    }

    async goToDrivers() {
        await this.router.navigate([`/jobs/${this.jobId}/workforce`])
    }

    async clickEditJobStammdaten(formType: string) {
        const modal = await this.modalController.create({
            component: EditJobStammdatenModalComponent,
            componentProps: {
                job: this.job(),
                formType: formType
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    await this.reloadJob();
                }
            }
        }
    }

    async clickEditJobKnowledge(formType: string) {
        const modal = await this.modalController.create({
            component: EditJobKnowledgeModalComponent,
            componentProps: {
                job: this.job(),
                formType: formType
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            await this.reloadJob();
        }
    }

    async reloadJob() {
        await this.loadingService.start();
        await this.loadJob();
        await this.loadingService.stop();
    }

    /**
     * Job aktivieren oder deaktivieren, anhand änderungen im toggle
     *
     * @returns
     */
    async toggleJobList() {
        const job = this.job();
        if (!job) {
            return;
        }
        this.isActive = !this.isActive;
        const oldJobState = job.isActive;
        const confirmation = await this.askToggleJob(oldJobState);
        if (confirmation) {
            await this.loadingService.start();
            const result = await this.jobListService.toggleJoblist(job.id)
                .catch(async (e: HttpErrorResponse) => {
                    console.error('Fehler bei der Anfrage', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut.');
                    return null;
                });
            if (result) {
                const activeStr = (!oldJobState) ? 'veröffentlicht' : 'nicht veröffentlicht';
                await this.toastService.presentToast(`Der Job wurde erfolgreich ${activeStr}.`);
            }
            await this.loadingService.stop();
        }
    }

    async askToggleJob(isActive: boolean) {
        const toDoStr = (!isActive) ? 'veröffentlichen' : 'nicht veröffentlichen';
        const driverStr = (!isActive) ? 'zur Bewerbung angeboten!' : 'nicht mehr angeboten!';
        const question = `Wollen Sie den Job ${toDoStr}? Er wird den Fahrern dann ${driverStr}`;
        return await this.alertService.confirm({
            text: question,
            textOk: 'Ja',
            textCancel: 'Nein',
        }).catch(() => {
            this.isActive = !this.isActive;
        });
    }

   
}
