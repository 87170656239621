import { Injectable } from '@angular/core';
import { LoginServiceInterface, HttpService, LoginResponseInterface, AccountRecoveryPayloadInterface, ENV } from '@nf-workforce/shared';
import { PasswordRequestInterface } from '../interfaces/password-request.interface';
import { ChangeUserNameRequestInterface } from '../interfaces/change-username.request.interface';
import { HttpResponse } from '@angular/common/http';
import { ChangeUserNameResponseInterface } from '../interfaces/change-username.response.interface';

@Injectable()
export class AccountService implements LoginServiceInterface {

    constructor(
        private httpService: HttpService
    ) {}

    get apiLoginUrl(): string {
        return `${ENV.apiUrl}/public/login`;
    }

    get apiLogoutUrl(): string {
        return `${ENV.apiUrl}/api/logout`;
    }

    get frontendLoginUrl(): string {
        return '/login';
    }

    get urlValidateAccount(): string {
        return '/account/validate-account';
    }

    get redirectFrontendAfterLogin(): string {
        return "/account/my-account";
    }

    /**
     *
     * Request für den Login, an den Server schicken
     *
     * @param username
     * @param password
     * @returns
     */
    sendLogin(username: string, password: string): Promise<void | LoginResponseInterface> {
        return this.httpService.post(this.apiLoginUrl, {
            username: username,
            password: password
        });
    }

    /**
     * Logout an den Server senden
     *
     * @returns
     */
    sendLogout(): Promise<void | object> {
        return this.httpService.getBypassCache(this.apiLogoutUrl);
    }

    /**
     * Passwort zurücksetzen
     *
     * @param username
     * @returns
     */
    resetPassword(username: string): Promise<unknown> {
        const resetPasswordRoute = `${ENV.apiUrl}/public/initiate-account-recovery`;
        return this.httpService.post(resetPasswordRoute, {
            username: username
        });
    }

    /**
     * Account wiederherrstellen
     *
     * @param payload
     * @returns
     */
    recoverAccount(
        payload: AccountRecoveryPayloadInterface
    ): Promise<unknown> {
        const recoveryRoute = `${ENV.apiUrl}/public/account-recovery`;
        return this.httpService.post(recoveryRoute, payload);
    }

    /**
     * Abschliießen der UserName Änderung
     * 
     * @param payload 
     * @returns 
     */
    changeUserName(
        payload: ChangeUserNameRequestInterface
    ) {
        const changeUserNameUrl = `${ENV.apiUrl}/public/change-username`;
        return this.httpService
            .post<ChangeUserNameResponseInterface>(changeUserNameUrl, payload);
    }
}
