import { Component, EventEmitter, Input, OnChanges, Output, signal, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyGroupInterface } from '../../../interfaces/agency-group.interface';
import { BooleanToStringPipe, IconDirective, IconEnum, IconThemeEnum, LoadingService, TitleIconEnum, ToastService } from '@nf-workforce/shared';
import { DriverGroupService } from '../../../service/driver-group.service';
import { IonicModule } from '@ionic/angular';
import { GroupMemberInterface } from '../../../interfaces/group-member.interface';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableHeadlineDirective,
    TableKickerDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { AlertsService } from 'nb-form';
import { HttpErrorResponse } from '@angular/common/http';
import { AddDriverComponent } from '../add-driver/add-driver.component';
import { MatIcon } from '@angular/material/icon';
import { DriverAgencyProfileStatePipe } from '@nf-workforce/agency';

@Component({
    selector: 'driver-group-members',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableHeadlineDirective,
        TableKickerDirective,
        TableRowDirective,
        AddDriverComponent,
        IconDirective,
        MatIcon,
        BooleanToStringPipe,
        DriverAgencyProfileStatePipe
    ],
    templateUrl: './members.component.html',
    styleUrl: './members.component.scss',
})
export class MembersComponent implements OnChanges {
    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    @Input()
    agencyGroup: AgencyGroupInterface | null = null;

    @Output()
    reload = new EventEmitter();

    agencyMembers = signal<GroupMemberInterface[]>([]);


    constructor(
        private toastService: ToastService,
        private loadingService: LoadingService,
        private groupService: DriverGroupService,
        private alertsService: AlertsService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['agencyGroup'] && this.agencyGroup) {
            this.agencyMembers.set(this.agencyGroup.members);
        }
    }

    /**
     * Entfernen des übergebenen Mitglieds aus der Gruppe
     *
     * @param member
     */
    async removeMember(member: GroupMemberInterface) {
        const group = this.agencyGroup;
        if (group === null) {
            return;
        }

        const confirmation = await this.alertsService.confirm({
            text: `Möchten Sie ${member.fullName} wirklich aus dieser Gruppe Entfernen?`
        });

        if (confirmation) {
            await this.loadingService.start();
            const result = await this.groupService.removeMember(group.id, {
                userId: member.id
            }).catch(async (e: HttpErrorResponse) => {
                console.warn("Fehler bei der ANfrage: ", e);
                await this.toastService.presentToast("Fehler bei der Anfrage. Bitte versuchen Sie es später erneut");
                return null;
            });
            await this.loadingService.stop();
            if (result) {
                await this.toastService.presentToast(`Das MItglied ${member.fullName} wurde erfolgreich aus der Gruppe entfernt.`);
                this.reload.emit(true);
            }

        }
    }


}
