<ion-content>
    <ion-col>
        
        <ion-row>
            <ion-col class ="ion-padding">
                <ion-button fill="outline" color="secondary" (click)="clickGoToList()">
                  Zurück zur Liste
                </ion-button>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-content>
                        @if (agencyForm) {
                        <nb-form
                            #formAgencyInstance
                            [form]="agencyForm"
                            appearance="outline"
                            [delay]="0"
                            [debug]="false"
                        ></nb-form>
                        }
                    </ion-card-content>
                </ion-card>
    
                <ion-card>
                    <ion-card-content>
                        @if (agencyUserForm) {
                        <nb-form
                            #formAgencyUserInstance
                            [form]="agencyUserForm"
                            appearance="outline"
                            [delay]="0"
                            [debug]="false"
                        ></nb-form>
                        }
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
        
        <ion-row>
            <ion-col>
                <ion-button fill="outline" expand="block" (click)="clickAddNewAgency()">
                    Neue Agentur jetzt anlegen
                </ion-button>
            </ion-col>
        </ion-row>
    
    </ion-col>
</ion-content>
