import { AgencyGroupInterface } from "@nf-workforce/driver-group";
import { FormElementOption } from "nb-form";

export function groupsToOptions(groups: AgencyGroupInterface[]): FormElementOption[] {
    return groups.map((group) => {
        return {
            key: group.id,
            value: group.name
        } as FormElementOption;
    }) as FormElementOption[];
}