import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const driverJobListSearchConfig = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Jobliste -> Fahrer"', {
    factory: () => {
        return {
            searchElements:  [
                {
                    label: 'Id',
                    name: 'id',
                    type: 'text',
                    placeholder: 'id',
                    isNumber: true,
                    selection: []
                },
                {
                    label: 'Vorname',
                    name: 'firstName',
                    type: 'text',
                    placeholder: 'Vorname suchen',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Nachname',
                    name: 'lastName',
                    type: 'text',
                    placeholder: 'Nachname suchen',
                    isNumber: false,
                    selection: []
                }
            ],
            sortElements: [
                {
                    label: 'Id',
                    name: 'id'
                },
                {
                    label: 'Vorname',
                    name: 'firstName'
                },
                {
                    label: 'Nachname',
                    name: 'lastName'
                }
            ]
        };
        
        
       
    }
});