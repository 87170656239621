@if (job) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div [class]="headerClass">
                <h2 class="driver-account-header__text">
                    Jobdaten
                    @if (job.description) {
                        <ion-card-subtitle>
                            <ion-text class="whitespace-pre-wrap">
                                {{ job.description }}
                            </ion-text>
                        </ion-card-subtitle>
                    }
                </h2>
                @if (logoUrl) {
                    <img class="driver-account-header__image"
                         [src]="logoUrl"
                         alt="{{ agency.name}}"
                    >
                }
            </div>
        </ion-card-header>
    <ion-card-content>
        @if (isDriverJob === false) {
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Freie Plätze</span>
                </label>
                @if (jobHasFreePlaces()) {
                    <span class="driver-skill__skill">Ja</span>
                } @else {
                    <span class="driver-skill__skill">
                        Nein, aber Sie können sich auf die Warteliste eintragen.
                    </span>
                }
            </div>
            <br>
        }
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Laufzeit</span>
            </label>
            <span class="driver-skill__skill">
                Start: {{ job.startDate | printNbDateTime }}<br />
                Ende: {{ job.endDate  | printNbDateTime }}
            </span>
          
            @if (job.timezone !== null) {
                <br>
                <span class="driver-skill__skill">
                    Zeitzone: {{ job.startDate | printNbDateTime }}
                </span>
            }
        </div>
        <br>
        @if (isDriverJob === false) {
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Bewerbungsfrist</span>
                </label>
                <span class="driver-skill__skill">
                    Start: {{ job.showDate | printNbDate }}<br />
                    Ende: {{  job.closeDate  | printNbDate }}<br>
                    @if ( job.deadline !== null) {
                        Sie dürfen Ihre Bewerbung ändern bis:
                        {{ job.deadline  | printNbDate }}
                    } @else {
                        Änderungen an Ihrer Bewerbung nur über die Agentur!
                    }
                </span>
            </div>
        } @else {
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Bewerbung</span>
                </label>
                <span class="driver-skill__skill">
                    @if (canRemove && job.deadline !== null) {
                        Sie dürfen Ihre Bewerbung ändern bis:
                        {{  job.deadline  | printNbDate }}
                    } @else {
                        Änderungen an Ihrer Bewerbung nur über die Agentur!
                    }
                </span>
            </div>
        }
        <br>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Anbieter</span>
            </label>
            <span class="driver-skill__skill">
                <ion-text class="whitespace-pre-wrap">
                    {{ job.agency?.name }}
                </ion-text>
            </span>
            <br>
            <span class="driver-skill__skill">
                <ion-text class="whitespace-pre-wrap">
                    {{ job.agency?.phone }}
                </ion-text>
            </span>
        </div>
    </ion-card-content>
</ion-card>
}
