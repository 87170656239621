
import { ActivatedRoute, Router } from '@angular/router';
import { AgencyMemberService } from '../../services/agency-member.service';
import { AlertsService, Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { AgencyMemberInterface, ErrorService, FullnamePipe, LoadingService, TitleService, ToastService, formatPhoneNumber, hasProperty, isString } from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { PERSON_SCHEMA } from '../../schema/person.schema';
import { UpdatePersonRequest } from '../../interface/person-request.interface';
import { UpdateUsernameRequest } from '../../interface/username-request.interface';
import { USERNAME_SCHEMA } from '../../schema/username.schema';

@Component({
    selector: 'agency-edit-agency-member',
    standalone: true,
    imports: [
        CommonModule,
        FullnamePipe,
        IonicModule,
        NbFormComponent,
    ],
    templateUrl: './edit-agency-member.component.html',
    styleUrl: './edit-agency-member.component.scss',
    providers: [
        FullnamePipe,
        MyAgencyMemberService,
    ]
})
export class EditAgencyMemberComponent implements OnInit {

    member: WritableSignal<AgencyMemberInterface | null> = signal(null);
    memberId = 0;
    personForm!: Form;
    usernameForm!: Form;

    @ViewChild('usernameInstance')
    usernameInstance!: NbFormComponent;

    @ViewChild('personInstance')
    personInstance!: NbFormComponent;

    constructor(
        @Inject(PERSON_SCHEMA) private personSchema: FormPage,
        @Inject(USERNAME_SCHEMA) private userNameSchema: FormPage,
        private activatedRoute: ActivatedRoute,
        private agencyMemberService: MyAgencyMemberService,
        private alertService: AlertsService,
        private fullNamePipe: FullnamePipe,
        private loadingService: LoadingService,
        private memberService: AgencyMemberService,
        private router: Router,
        private titleService: TitleService,
        private toastService: ToastService,
        private errorService: ErrorService
    ) {
        this.usernameForm = this.createForm(1, userNameSchema);
        this.personForm = this.createForm(2, personSchema);
    }

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'id')) {
            this.memberId = parseInt(params['id']);
        }
        const member = await this.loadMember();
        if (member) {
            this.member.set(member);
            this.titleService.titleEmitter.emit(`${this.fullNamePipe.transform(
                {
                    academic: null,
                    firstName: member.firstName,
                    lastName: member.lastName,
                    sex: null,
                },
                'short',
                'reversed'
            )}`);
            this.initFormValues();
        }
    }

    /**
     * Initialisiert die Formularwerte für das derzeit gesetzte Agenturmitglied
     */
    private initFormValues() {
        const member = this.member();
        if (member) {
            this.usernameForm.data = { username: member.username };
            this.personForm.data = {
                academic: member.academic,
                firstName: member.firstName,
                lastName: member.lastName,
                phone: member.phone,
                sex: member.sex,
            }
        }
    }

    /**
     * Laden des Agentur-Mitglieds
     *
     * @returns
     */
    private async loadMember(): Promise<AgencyMemberInterface | null> {
        const result = await this.agencyMemberService.getOneUser(this.memberId)
            .catch((e: HttpErrorResponse) => {
                console.debug('Fehler bei der Anfrage, bitte versuchen Sie es später erneut', e);
                return null;
            });
        if (result && result.data.length === 1) {
            return result.data[0];
        }
        return null;
    }

    /**
     * Erstellt ein Formular mit der übergebenen Id anhand des übergebenen FormPage-Schemas
     *
     * @param id
     * @param schema
     * @returns
     */
    private createForm(id: number, schema: FormPage) {
        const form = new Form();
        form.id = id;
        form.schema = [cleanInjectedValue(schema)];
        return form;
    }

    /**
     * Zusenden eines neuen Passwort
     */
    async clickNewPassword() {
        const member = this.member();
        if (member) {
            await this.loadingService.start();
            const passwordResult = await this.agencyMemberService.sendNewPassword(member.id)
                .catch(async (e) => {
                    console.error('Fehler beim Request', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage. Bitte versuchen Sie es später erneut.');
                    return false;
                });

            await this.loadingService.stop();
            if (passwordResult) {
                await this.toastService.presentToast('Das Passwort wurde erfolgreich zurückgesetzt und wird dem Benutzer in Kürze zugesendet.');
            }
        }
    }

    /**
     * Zusenden einer neuen Willkommensmail
     */
    async clickSendWelcome() {
        const member = this.member();
        if (member) {
            await this.loadingService.start();
            const welcomeResult = await this.agencyMemberService.sendWelcome(member.id)
                .catch(async (e) => {
                    console.error('Fehler beim Request', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage. Bitte versuchen Sie es später erneut.');
                    return false;
                });
            if (welcomeResult) {
                await this.toastService.presentToast('Die Willkommensmail wird dem Benutzer in Kürze zugesendet.');
            }
        }
        await this.loadingService.stop();
    }

    /**
     * Löschen des Benutzers
     */
    async clickDelete() {
        const member = this.member();
        if (member === null) {
            console.warn('Das Agenturmitglied wurde nicht gefunden');
            return;
        }
        const result = await this.alertService.confirm({
            text: `Möchten sie den Benutzer ${member.lastName} wirklich löschen?`
        });
        if (result) {
            await this.deleteUser(member);
        }
    }

    /**
     * Löschen des übergebenen Benutzers
     *
     * @param member
     */
    private async deleteUser(member: AgencyMemberInterface) {
        await this.loadingService.start();
        const deleteResult = await this.agencyMemberService.deleteAgencyAccount(member.id)
            .catch(async (e: HttpErrorResponse) => {
                if (e.status === 400 && isString(e.error['message'])) {
                    console.error('BadRequest', e);
                    const errorMessage = `Fehler: ${e.error['message']}`;
                    await this.toastService.presentToast(errorMessage);
                } else {
                    console.error('Fehler beim Request', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage. Bitte versuchen Sie es später erneut.');
                }
                return false;
            });
        await this.loadingService.stop();
        if (deleteResult) {
            await this.toastService.presentToast('Der Benutzer wurde erfolgreich entfernt.');
            await this.router.navigate(['/my-agency/accounts'])
        }
    }

    /**
     * Aktualisierung des Benutzernamens
     */
    async clickUpdateUsername() {
        const member = this.member();
        if (await this.validateForm(this.usernameInstance) && member) {
            await this.loadingService.start();
            const requestPayload = {
                username: this.usernameForm.data['username'] as string
            } as UpdateUsernameRequest;
            const updateResult = await this.memberService
                .updateUserName(member.id, requestPayload)
                .catch(async (e) => {
                    console.error('Fehler beim Request', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut');
                    return false;
                });
            await this.loadingService.stop();

            if (updateResult) {
                await this.toastService.presentToast('Der Benutzer erhält weitere Instruktionen per E-Mail.');
            } else {
                this.errorService.highlightFormErrors(this.usernameInstance, this.userNameSchema);
            }
        }
    }

    /**
     * Aktualisierung der Personen-Stammdaten
     */
    async clickUpdatePerson() {
        const member = this.member();
        if (await this.validateForm(this.personInstance) && member !== null) {
            await this.loadingService.start();
            const updateResult = await this.memberService
                .updatePerson(member.id, this.createPersonRequest())
                .catch(async (e) => {
                    console.error('Fehler beim Request', e);
                    await this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut');
                    return false;
                });
            await this.loadingService.stop();
            if (updateResult) {
                await this.toastService.presentToast('Die Stammdaten wurden erfolgreich aktualisiert.');
            } else {
                this.errorService.highlightFormErrors(this.personInstance, this.personSchema);
            }
        }
    }

    /**
     * Erstellt den UpdatePersonRequest und gibt ihn zurück
     *
     * @returns
     */
    private createPersonRequest(): UpdatePersonRequest {
        const sex = (isString(this.personForm.data['sex'])) ? this.personForm.data['sex'] : null;
        const academic = (isString(this.personForm.data['academic'])) ? this.personForm.data['academic'] : null;
        const phone = formatPhoneNumber(this.personForm.data['phone'] as string)
        return {
            firstName: this.personForm.data['firstName'] as string,
            lastName: this.personForm.data['lastName'] as string,
            academic: academic,
            sex: sex,
            phone: phone,
            language: null,
        };
    }

    /**
     *
     * Funktion zum Validieren der übergebenen NBForm-Instanz
     *
     * @param formInstance
     * @returns
     */
    private async validateForm(formInstance: NbFormComponent): Promise<boolean> {
        const validator = formInstance.getValidator();
        if (!validator.validate()) {
            await this.toastService.presentToast('Bitte überprüfen Sie Ihre Eingaben.');
            return false;
        }
        return true;
    }
}
