
export function getRandomInt(min: number, max: number): number {

  // validate input
  if (min >= max) {
    throw new Error('`min` cannot be greater than or equal to `max`.');
  }

  // create a range to compute the random value
  const range = max - min;

  // randomly round to lower or higher integer
  if (Math.random() >= 0.5) {
    return min + Math.ceil(Math.random() * range)
  }
  return min + Math.floor(Math.random() * range);
}
