import { Injectable } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { formatFormDate, JobListInterface } from '@nf-workforce/shared';

@Injectable()
export class JoblistFormHelperService {

    joblist!: JobListInterface;

    setJoblist(joblist: JobListInterface) {
        this.joblist = joblist;
    }

    get defaultStammdatenPayload() {
        return {
            name: this.joblist.name as string,
            description: this.joblist.description as string,
            showDate: this.joblist.showDate as string,
            closeDate: this.joblist.closeDate as string,
            startDate: this.joblist.startDate as string,
            endDate: this.joblist.endDate as string,
            freePlaces: this.joblist.freePlaces as number,
            deadline: this.joblist.deadline,
            moderated: this.joblist.moderated,
            timezone: this.joblist.timezone
        }
    }

    getFormOptions(formType: string) {
        const startDate = formatFormDate(new Date(this.joblist.startDate), false);
        const endDate = formatFormDate(new Date(this.joblist.endDate), false);
        const showDate = formatFormDate(new Date(this.joblist.showDate), true);
        const closeDate = formatFormDate(new Date(this.joblist.closeDate), true);
        const deadline = (this.joblist.deadline !== null) ? formatFormDate(new Date(this.joblist.deadline), true) : null;
        switch (formType) {
            case 'laufzeit':
            case 'bewerbung':
                return {
                    startDate: new FormControl(startDate, [Validators.required]),
                    endDate: new FormControl(endDate, [Validators.required]),
                    showDate: new FormControl(showDate, [Validators.required]),
                    closeDate: new FormControl(closeDate, [Validators.required]),
                    deadline: new FormControl(deadline),
                    timezone: new FormControl(this.joblist.timezone)
                };
            case 'places':
                return {
                    freePlaces: new FormControl(this.joblist.freePlaces, [Validators.required]),
                };
            default:
                return {
                    name: new FormControl(this.joblist.name, [Validators.required]),
                    description: new FormControl(this.joblist.description, [Validators.required]),
                    moderated: new FormControl(this.joblist.moderated)
                };
        }
    }

}
