@if (job) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">
                    Wissen
                </h2>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Fahrerlaubnis</span>
                </label>
                <span class="driver-skill__skill">
                    {{ getFahrerlaubnisStrings() }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Skills</span>
                </label>
                <span class="driver-skill__skill">
                    {{ getSkillsStrings() }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Sprachen</span>
                </label>
                <span class="driver-skill__skill">
                    {{ getLanguageStrings() }}
                </span>
            </div>
        </ion-card-content>
    </ion-card>
}
