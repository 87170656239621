import { Injectable } from '@angular/core';
import { ENV, HttpService, PaginatorResponse, QueryParamsInterface, SearchQueryInterface } from '@nf-workforce/shared';
import { AgencyGroupInterface } from '../interfaces/agency-group.interface';
import { AddAgencyGroupRequest } from '../interfaces/add-agency-group-request.interface';
import { GroupMemberRequestInterface } from '../interfaces/add-group-member-request.interface';

@Injectable()
export class DriverGroupService {

    get groupUrl(): string {
        return `${ENV.apiUrl}/agency-driver-group`;;
    }

    constructor(
        private httpService: HttpService
    ) { }

    /**
     * 
     * Holt die Liste der Gruppen vom Server ab
     * 
     * @param page 
     * @param limit 
     * @param searchQuery 
     * 
     * @returns 
     */
    getList(page: number, limit: number, searchQuery: SearchQueryInterface) {
        const query = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        } as QueryParamsInterface;
        
        return this.httpService.getBypassCache<PaginatorResponse<AgencyGroupInterface>>(this.groupUrl, query);
    }

    /**
     * Lädt eine einzelne Gruppe
     * 
     * @param id 
     * @returns 
     */
    async loadOne(id: number) {
        const query = {
            id: id,
            page: 1,
            limit: 1
        } as QueryParamsInterface;
        return this.httpService.getBypassCache<PaginatorResponse<AgencyGroupInterface>>(this.groupUrl, query);
    }

    /**
     * Sendet das Request zum hinzufügen einer neuen Gruppe an den SErver
     * 
     * @param payload 
     * @returns 
     */
    addGroup(payload: AddAgencyGroupRequest) {
        return this.httpService.post(this.groupUrl, payload);
    }

    /**
     * Aktualisierung der AgencyGroup mit der übergebenen Id
     * 
     * @param groupId 
     * @param payload 
     * @returns 
     */
    updateGroup(groupId: number, payload: AddAgencyGroupRequest) {
        const route = `${this.groupUrl}/${groupId}`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Löschen der Fahrergruppe
     * 
     * @param groupId 
     * @returns 
     */
    deleteGroup(groupId: number) {
        const route = `${this.groupUrl}/${groupId}`;
        return this.httpService.delete(route);
    }

    /**
     * Fügt einen Fahrer anhand des übergebenen Payloads zur Gruppe hinzu
     * 
     * @param idGroup 
     * @param payload 
     * @returns 
     */
    addGroupMember(idGroup: number, payload: GroupMemberRequestInterface) {
        const url = `${this.groupUrl}/${idGroup}/driver`;
        return this.httpService.post(url, payload);
    }

    /**
     * Entfernen eines Gruppenmitglieds
     * 
     * @param idGroup 
     * @param payload 
     * @returns 
     */
    removeMember(idGroup: number, payload: GroupMemberRequestInterface) {
        const url = `${this.groupUrl}/${idGroup}/driver`;
        return this.httpService.deleteWithBody(url, payload as unknown as {[key: string]: unknown});
    }

}
