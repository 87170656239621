import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const agencyDriverSearch = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Agentur -> Fahrer"', {
    factory: () => {
        return {
            searchElements: [
                {
                    label: 'Suche Id',
                    name: 'id',
                    type: 'text',
                    placeholder: 'id',
                    isNumber: true,
                    selection: []
                },
                {
                    label: 'Suche Benutzername',
                    name: 'username',
                    type: 'text',
                    placeholder: 'Benutzername',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Suche Vorname',
                    name: 'firstName',
                    type: 'text',
                    placeholder: 'Vorname',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Suche Nachname',
                    name: 'lastName',
                    type: 'text',
                    placeholder: 'Nachname',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Suche ist Aktiv',
                    name: 'isActive',
                    type: 'selection',
                    placeholder: 'Aktiv',
                    isNumber: false,
                    selection: [
                        {
                            label: 'Ja',
                            value: true
                        },
                        {
                            label: 'Nein',
                            value: false
                        }
                    ]
                }
            ],
            sortElements: [
                {
                    label: 'Id',
                    name: 'id'
                },
                {
                    label: 'Benutzername',
                    name: 'username'
                },
                {
                    label: 'Vorname',
                    name: 'firstName'
                },
                {
                    label: 'Nachname',
                    name: 'lastName'
                },
                {
                    label: 'ist Aktiv',
                    name: 'isActive'
                }
            ]
        }
        
        
        
        
        
    }
});
