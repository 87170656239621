<ion-header>
    <ion-toolbar>
        <ion-title>
            {{ title }}: {{ errorMessage() }}
        </ion-title>
            
        <ion-button slot="end" (click)="closeModal()" fill="outline">
            <ion-icon matSuffix name="close-outline" (click)="closeModal()"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
    <ion-col>

        <ion-list>
            
        @for (error of errorRows(); track error) {
            <ion-item>
                <ion-col>
                    <ion-text>
                        {{ error | error}}
                    </ion-text>
                </ion-col>
            </ion-item>
        }
        </ion-list>

    </ion-col>

</ion-content>

<ion-footer>
    <ion-row>
        <ion-col>
            <ion-button fill="outline" (click)="closeModal()">
                Schließen
            </ion-button>
        </ion-col>
    </ion-row>
</ion-footer>
