import { Pipe, PipeTransform } from '@angular/core';
import { DriverInterface } from '@nf-workforce/shared';

@Pipe({
    name: 'driverBusinessRelationshipToString',
    standalone: true
})
export class DriverBusinessRelationshipToStringPipe implements PipeTransform {
    transform(driver: DriverInterface | null): string {
        if (driver !== null) {
            if (driver.isEmployee) {
                return 'Angestellter';
            }
            if (driver.isCompany) {
                return 'Firma';
            }
            return 'Selbstständig';
        }
        return "-";
  }

}
