@if(showPlace()) {
<ion-content>
    <ion-col>
        <ion-row>
            <ion-col>
                <ion-card class="show-on-hover-trigger">
                    <ion-card-header>
                        <div class="driver-account-header driver-account-header--masterdata">
                            <h2 class="driver-account-header__text">
                                {{ user.firstName }}
                                {{ user.lastName }}<br>
                            </h2>
                            <img class="driver-account-header__image"
                                 [src]="profileImage"
                                 alt="{{user | fullname }}"
                                 (click)="clickGoToProfileImage()"
                            >
                        </div>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                <ion-card class="show-on-hover-trigger" (click)="clickEdit('profil-person')">
                                    <ion-card-content>
                                        <div class="driver-skill">
                                            <label class="driver-skill__label">
                                                <span class="driver-skill__label-text">Ihre Person</span>
                                                <span class="driver-skill__edit-button show-on-hover" role="button">
                                                <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                                            </span>
                                            </label>
                                            <span class="driver-skill__skill">
                                            {{ user.firstName }} {{ user.lastName }}
                                                @if (user.phone) {
                                                    <br>{{ user.phone }}
                                                }
                                            </span>
                                        </div>
                                    </ion-card-content>
                                </ion-card>
                            </ion-col>
                            <ion-col>
                                <ion-card class="show-on-hover-trigger" (click)="clickEdit('profil-username')">
                                    <ion-card-content>
                                        <div class="driver-skill">
                                            <label class="driver-skill__label">
                                                <span class="driver-skill__label-text">Benutzername</span>
                                                <span class="driver-skill__edit-button show-on-hover" role="button">
                                                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                                                </span>
                                            </label>
                                            <span class="driver-skill__skill">
                                                {{ user.username }}
                                            </span>
                                        </div>
                                    </ion-card-content>
                                </ion-card>
                                <ion-card class="show-on-hover-trigger" (click)="clickEdit('profil-password')">
                                    <ion-card-content>
                                        <div class="driver-skill">
                                            <label class="driver-skill__label">
                                                <span class="driver-skill__label-text">Passwort setzen</span>
                                                <span class="driver-skill__edit-button show-on-hover" role="button">
                                                <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                                            </span>
                                            </label>
                                        </div>
                                    </ion-card-content>
                                </ion-card>
                                @if(authService.hasPermission('my_account_delete')) {
                                    @if (canDriverDeleteAccount) {
                                        <ion-card class="show-on-hover-trigger" (click)="clickDeleteAccount()">
                                            <ion-card-content>
                                                <div class="driver-skill">
                                                    <label class="driver-skill__label">
                                                        <span class="driver-skill__label-text">Account löschen</span>
                                                        <span class="driver-skill__edit-button show-on-hover" role="button">
                                                <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.DANGER"></mat-icon>
                                            </span>
                                                    </label>
                                                </div>
                                            </ion-card-content>
                                        </ion-card>
                                    } @else {
                                        <ion-card class="show-on-hover-trigger">
                                            <ion-card-content>
                                                <div class="driver-skill">
                                                    <label class="driver-skill__label">
                                                        <span class="driver-skill__label-text">Account löschen</span>
                                                    </label>
                                                    <span class="driver-skill__skill">
                                                        Sie können Ihren Account nicht löschen<br>
                                                        Sie sind noch auf Jobs gebucht oder befinden sich auf Wartelisten.
                                                    </span>
                                                </div>
                                            </ion-card-content>
                                        </ion-card>
                                    }
                                }
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-col>
</ion-content>
}
