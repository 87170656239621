@if(showView()) {
    <ion-content>
        @if (driverJoblists().length > 0) {
            <ion-row>
                <ion-col>
                    <div uiTable class="expand-xl fixed-header w-100">
                        <div uiTableHead>
                            <div uiTableCell align="right">
                                #
                            </div>
                            <div uiTableCell>
                                Name
                            </div>
                            <div uiTableCell>
                                Agentur
                            </div>
                            <div uiTableCell>
                                Laufzeit
                            </div>
                            <div uiTableCell>
                                Bewerbung
                            </div>
                            <div uiTableCell>
                                freie Plätze
                            </div>
                            <div uiTableCell></div>
                        </div>

                        @for(job of driverJoblists(); track job.id) {
                            <div uiTableRow>
                                <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                    <div uiTableKicker># {{ job.id }}</div>
                                    <div class="headline">
                                        <h3>
                                            {{ job.name }}
                                        </h3>
                                    </div>
                                </div>

                                <div uiTableCell align="right" [hideOnCollapse]="true">
                                    {{ job.id }}
                                </div>

                                <div uiTableCell>
                                    <span uiTableCellLabel>Name: </span>
                                    {{ job.name }}
                                </div>

                                <div uiTableCell>
                                    <span uiTableCellLabel>Agentur: </span>
                                    {{ job.agency?.name }}
                                </div>

                                <div uiTableCell>
                                <span uiTableCellLabel>
                                   Laufzeit
                                </span>
                                    Start: {{ job.startDate | printNbDateTime }}<br>
                                    Ende: {{ job.endDate | printNbDateTime }}
                                </div>

                                <div uiTableCell>
                                <span uiTableCellLabel>
                                    Bewerbung bis:
                                </span>
                                    {{ job.closeDate | printNbDate }}
                                </div>

                                <div uiTableCell>
                                <span uiTableCellLabel>
                                 Freie Plätze:
                                </span>
                                    {{ jobHasFreePlaces(job) | booleanToString}}
                                </div>

                                <div uiTableCell>
                                    <ion-toolbar>
                                        <ion-buttons slot="end">
                                            <ion-button
                                                size="small"
                                                fill="clear"
                                                title="Job anzeigen"
                                                aria-label="Job anzeigen"
                                                (click)="goToJob(job)">
                                                <mat-icon [icon]="IconEnum.SEARCH_MANAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                                                Details
                                            </ion-button>
                                        </ion-buttons>
                                    </ion-toolbar>

                                </div>
                            </div>
                        }

                        @if(hasMoreResults()) {
                            <div uiTableFooter>
                                <ion-button
                                    size="small"
                                    fill="outline"
                                    [color]="IconTheme.INHERIT"
                                    (click)="clickLoadNextPage()"
                                    title="Mehr Ergebnisse laden"
                                    aria-label="Mehr Ergebnisse laden"
                                ><mat-icon [icon]="IconEnum.DOWNLOAD_NEXT_PAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                                    &nbsp;mehr Ergebnisse laden
                                </ion-button>
                            </div>
                        }
                    </div>
                </ion-col>
            </ion-row>
        } @else {
            <ion-row>
                <ion-col>
                    <ion-card>
                        <ion-card-header>
                            <ion-card-subtitle color="danger">
                                Aktuell gibt es keine offenen Jobs!
                            </ion-card-subtitle>
                        </ion-card-header>
                        <ion-card-content>
                            Sie können sich nur auf offene Jobs bewerben, wenn
                            <ol>
                                <li>die Agentur offene Jobs anbietet</li>
                                <li>Ihr Profil von der Agentur freigeschaltet wurde.</li>
                            </ol>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        }
    </ion-content>
}

