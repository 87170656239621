@for(navItem of menu; track navItem.id) {
@if(allowed(navItem)) {
<li class="level-{{ level }}">
    <shared-navigation-link [navItem]="navItem" [level]="level"></shared-navigation-link>
    @if(navItem.childNodes) {
    <ul class="level-{{ level + 1}}">
        <shared-navigation-item [menu]="navItem.childNodes" [level]="level + 1"></shared-navigation-item>
    </ul>
    }
</li>
}
}
