import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonDatetime, IonicModule, PopoverController } from '@ionic/angular';


@Component({
    selector: 'shared-date-popover',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule
    ],
    templateUrl: './date-popover.component.html',
    styleUrl: './date-popover.component.scss',
    providers: []
})
export class DatePopoverComponent {

    @Input()
    presentation = 'date';

    @ViewChild(IonDatetime)
    ionDateTime!: IonDatetime;

    dateValue: Date | null = null;

    constructor(
        private popoverController: PopoverController
    ) {}
    
    changeDate() {
        const value = this.ionDateTime.value as string;
        this.dateValue = new Date(value);
    }

    /**
     * Bestätigung des Datums
     */
    async clickConfirm() {
        this.proccessDate();
        await this.popoverController.dismiss({
            selectedDate: this.dateValue
        }, 'selected');
    }

    /**
     * Abbrechen ohne Auswahl
     */
    async clickCancel() {
        await this.popoverController.dismiss(null, 'cancelled');
    }

    /**
     * bereitet das Datum für die Rückgabe vor
     */
    private proccessDate() {
        // Wenn nur das Datum ausgewählt wird, dann die Zeit auf 00:00:00 setzen
        if (this.presentation === "date" && this.dateValue instanceof Date) {
            this.dateValue.setHours(0);
            this.dateValue.setMinutes(0);
            this.dateValue.setSeconds(0);
        } 

    }

}
