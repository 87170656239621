import { Component, OnInit, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController, ModalOptions } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import {
    EavService,
    LanguageEavInterface,
    DriverAgencyInterface,
    DriverDataInterface,
    DriverReferencesInterface,
    DriverCompanyInterface,
    DriverProfileInterface,
    DriverDocumentInterface
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../service/driver-profile.service';
import { ProfileImageCardComponent } from './profile-image-card/profile-image-card.component';
import { ProfileStammdatenComponent } from './profile-stammdaten-card/profile-stammdaten.component';
import { ProfileKnowledgeCardComponent } from './profil-knowledge-card/profile-knowledge-card.component';
import { ProfileSkillModalComponent } from './profil-knowledge-card/skill-modal/profile-skill-modal.component';
import { ProfileLanguageModalComponent } from './profil-knowledge-card/language-modal/profile-language-modal.component';
import {
    ProfileReferenceModalComponent
} from './profil-knowledge-card/reference-modal/profile-reference-modal.component';
import {
    ProfileFahrerlaubnisModalComponent
} from './profile-documents-card/fahrerlaubnis-modal/profile-fahrerlaubnis-modal.component';
import {
    DriverMyProfileDocumentsCardComponent
} from './profile-documents-card/driver-my-profile-documents-card.component';
import {
    ProfileDocumentAgencyModalComponent
} from './profile-documents-card/document-agency-modal/profile-document-agency-modal.component';
import { DriverWohnortFormComponent } from './profile-stammdaten-card/stammdaten-modal/wohnort/wohnort-form.component';
import {
    DriverAbrechnungFormComponent
} from './profile-stammdaten-card/stammdaten-modal/abrechnung/abrechnung-form.component';
import { DriverGeburtFormComponent } from './profile-stammdaten-card/stammdaten-modal/geburt/geburt-form.component';
import {
    ProfilPersonModalComponent,
    ProfilUsernameModalComponent,
    ProfilPasswordModalComponent
} from '@nf-workforce/account';
import {
    ProfileDocumentUploadWizardModalComponent
} from './profile-documents-card/document-upload-wizard/profile-document-upload-wizard-modal.component';

@Component({
    selector: 'driver-my-profile',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        ProfileImageCardComponent,
        ProfileStammdatenComponent,
        ProfileKnowledgeCardComponent,
        DriverMyProfileDocumentsCardComponent
    ],
    providers: [
    ],
    templateUrl: './driver-my-profile.component.html',
    styleUrl: './driver-my-profile.component.scss',
})
export class DriverMyProfileComponent implements OnInit {

    eavLanguages: LanguageEavInterface[] | null = null;

    driver: WritableSignal<DriverDataInterface | null> = signal(null);
    profile: WritableSignal<DriverProfileInterface | null> = signal(null);
    driverCompanyProfile: WritableSignal<DriverCompanyInterface | null> = signal(null);
    driverAgencies: WritableSignal<DriverAgencyInterface[]> = signal([]);

    showCompanyProfile: WritableSignal<boolean> = signal(false);

    constructor(
        private driverProfileService: DriverProfileService,
        private eavService: EavService,
        private modalController: ModalController,
    ) {
        const eavLanguages = this.eavService.getLanguages();
        if (eavLanguages) {
            this.eavLanguages = eavLanguages;
        } else {
            throw new Error('EavSprachen nicht gesetzt');
        }
    }

    async ngOnInit(): Promise<void> {
        await this.reload();
    }

    async loadDriver() {
        this.driver.set(null);
        this.profile.set(null);
        this.driverAgencies.set([]);
        const result = await this.driverProfileService.getDriverData()
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler beim Laden", e);
                return null;
            });

        if (result) {
            this.driver.set(result as DriverDataInterface);
            this.profile.set(result.driverUserDto.profile);
            this.driverCompanyProfile.set(result.driverUserDto.driverCompany);
            this.driverAgencies.set(result.driverUserDto.driverAgencies);
            this.showCompanyProfile.set(result.driverUserDto.isCompoany);
        }
    }

    /**
     * Nachladen der Daten zum Fahrerprofil
     */
    async reload() {
        await this.loadDriver();
    }

    private getModalOptions(profileType: string) {
        if (profileType === 'profil-person') {
            return  {
                component: ProfilPersonModalComponent,
            }
        }
        if (profileType === 'profil-username') {
            return  {
                component: ProfilUsernameModalComponent,
            }
        }
        if (profileType === 'profil-password') {
            return  {
                component: ProfilPasswordModalComponent,
            }
        }
        if (profileType === 'wohnort') {
            return  {
                component: DriverWohnortFormComponent,
                componentProps: {
                    profile: this.profile()
                }
            }
        }
        if (profileType === 'abrechnung') {
            return {
                component: DriverAbrechnungFormComponent,
                componentProps: {
                    profile: this.profile()
                }
            }
        }
        if (profileType === 'birthday') {
            return {
                component: DriverGeburtFormComponent,
                componentProps: {
                    profile: this.profile()
                }
            }
        }
        if (profileType === 'skills') {
            return {
                component: ProfileSkillModalComponent,
                componentProps: {
                    driver: this.driver(),
                }
            };
        }
        if (profileType === 'language') {
            return {
                component: ProfileLanguageModalComponent,
                componentProps: {
                    driver: this.driver(),
                }
            };
        }
        if (profileType === 'fahrerlaubnis') {
            return {
                component: ProfileFahrerlaubnisModalComponent,
                componentProps: {
                    driver: this.driver(),
                }
            };
        } else {
            return null;
        }
    }

    async editStammdaten(profileType: string) {
        const opt = this.getModalOptions(profileType);
        if (opt === null) {
            return;
        }
        await this.showModal(profileType, opt);
    }

    async editDocumentAgencyAccess( document: DriverDocumentInterface) {
        const opt = {
            component: ProfileDocumentAgencyModalComponent,
            componentProps: {
                document: document,
                agencies: this.driverAgencies(),
            }
        };
        await this.showModal('documentAgencies', opt);
    }

    async editKnowledge(profileType: string) {
        const opt = this.getModalOptions(profileType);
        if (opt === null) {
            return;
        }
        await this.showModal(profileType, opt);
    }

    async newReference() {
        const opt = {
            component: ProfileReferenceModalComponent
        };
        await this.showModal('new-reference', opt);
    }

    async newUpload(eavId: number) {
        const opt = {
            component: ProfileDocumentUploadWizardModalComponent,
            componentProps: {
                driver: this.driver(),
                currentEavId: eavId
            }
        };
        await this.showModal('new-document', opt);
    }

    async editReference(reference: DriverReferencesInterface) {
        const opt = {
            component: ProfileReferenceModalComponent,
            componentProps: {
                reference: reference
            }
        };
        await this.showModal('reference', opt);
    }

    async showModal(profileType: string, opt: ModalOptions) {
        const modal = await this.modalController.create(opt);
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (profileType === 'skills'
                || profileType === 'fahrerlaubnis'
                || profileType === 'documentAgencies'
                || profileType === 'language'
                || profileType === 'reference'
            )  {
                // Modals mit Toggle => refresh auch bei einfachen close
                await this.reload();
                return;
            }
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    await this.reload();
                }
            }
        }
    }

}
