import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const USERNAME_SCHEMA = new InjectionToken<FormPage>('"Benutzername Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'username',
            header: 'Benutzername',
            navigation_label: 'username',
            elements: [
                {
                    id: 'username',
                    type: 'email',
                    model: 'username',
                    label: 'Benutzername',
                    required: true
                }
            ]
        };
    }
});
