import { Directive, HostBinding} from '@angular/core';

@Directive({
    selector: '[uiTableHeadline]',
    standalone: true
})
export class TableHeadlineDirective {

  @HostBinding('class')
  elementClass = 'headline';

}