import { Injectable } from '@angular/core';
import {
    AgencyInterface,
    ENV,
    HttpService,
    PaginatorResponse,
    QueryParamsInterface,
    SearchQueryInterface,
    UploadImageRequest,
} from '@nf-workforce/shared';
import { NewAgencyRequestInterface } from '../interface/new-agency-request.interface';


@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    constructor(
        private httpService: HttpService
    ) {}

    get agencyListRoute(): string {
        return `${ENV.apiUrl}/agency-list`;
    }

    addNewAgency(payload: NewAgencyRequestInterface): Promise<unknown> {
        const newAgencyRoute = `${ENV.apiUrl}/agency`;
        return this.httpService.post(newAgencyRoute, payload);
    }

    getList(page: number, limit: number, searchQuery: SearchQueryInterface) {
        const query = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        } as QueryParamsInterface;

        return this.httpService
            .getBypassCache<PaginatorResponse<AgencyInterface>>(this.agencyListRoute, query);
    }

    async getOne(agencyId: number): Promise<AgencyInterface> {
        const queryParams = {
            page: 1,
            limit: 1,
            id: agencyId
        } as QueryParamsInterface;

        const result = await this.httpService
            .getBypassCache<PaginatorResponse<AgencyInterface>>(this.agencyListRoute, queryParams);

        return result.data[0];
    }

    async update(agencyId: number, payload: { name: string }) {
        const updateRoute = ENV.apiUrl + `/agency/${agencyId}/name`;
        return this.httpService.patch(updateRoute, payload);
    }

    async uploadLogo(agencyId: number, payload: UploadImageRequest) {
        const updateRoute = ENV.apiUrl + `/nb/agency/${agencyId}/logo`;
        return this.httpService.post(updateRoute, payload);
    }

    async enableAgency(agencyId: number) {
        const enableRoute = ENV.apiUrl + `/agency/${agencyId}/active`;
        return this.httpService.post(enableRoute, {});
    }

    async disableAgency(agencyId: number) {
        const disableRoute = ENV.apiUrl + `/agency/${agencyId}/active`;
        return this.httpService.delete(disableRoute);
    }

    async deleteAgency(agencyId: number) {
        const deleteRoute = ENV.apiUrl + `/agency/${agencyId}`;
        return this.httpService.delete(deleteRoute);
    }

}
