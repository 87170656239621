import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MembersComponent } from './driver-group-members/members.component';
import { Subscription } from 'rxjs';
import { AgencyGroupInterface } from '../../interfaces/agency-group.interface';
import { DriverGroupService } from '../../service/driver-group.service';
import { MatIcon } from '@angular/material/icon';
import { AddDriverComponent } from './add-driver/add-driver.component';
import { DriverGroupEditComponent } from './driver-group-edit/driver-group-edit.component';
import {
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    PageActionService,
    TitleIconEnum,
    TitleService
} from '@nf-workforce/shared';

@Component({
    selector: 'driver-group-one',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        MembersComponent,
        IconDirective,
        MatIcon,
        AddDriverComponent,
        DriverGroupEditComponent
    ],
    providers: [
        DriverGroupService,
    ],
    templateUrl: './driver-group-one.component.html',
    styleUrl: './driver-group-one.component.scss',
})
export class DriverGroupOneComponent implements OnInit, OnDestroy {
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
    protected readonly Icon = TitleIconEnum;


    agencyGroup = signal<AgencyGroupInterface | null>(null);
    showEdit = signal(false);
    showSearch = signal(false);
    showView = signal(false);

    private id = signal(0);
    private routeSub: Subscription;


    constructor(
        private activatedRoute: ActivatedRoute,
        private groupService: DriverGroupService,
        private loadingService: LoadingService,
        private pageActionService: PageActionService,
        private router: Router,
        private titleService: TitleService,
    ) {
        this.routeSub = this.activatedRoute.params.subscribe((params) => {
            if (params['id'] && !isNaN(params['id'])) {
                this.id.set(parseInt(params['id']));
            }
        });
        this.pageActionService.clear();
    }

    async ngOnInit() {
        if (typeof this.id() === 'number' && this.id() !== 0) {
            const group = await this.loadGroup();
            this.agencyGroup.set(group);
            this.titleService.titleEmitter.emit(`Gruppe "${group.name}"`);
            this.showView.set(true);
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    /**
     * Lädt die Gruppe anhand der gesetzten Id
     *
     * @returns
     */
    private async loadGroup(): Promise<AgencyGroupInterface> {
        await this.loadingService.start();
        const result = await this.groupService.loadOne(this.id())
            .catch((e: HttpErrorResponse) => {
                console.warn('Fehler bei der Anfrage: ', e);
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            return result.data[0];
        }
        throw new Error('Fehler beim Laden der Gruppe');
    }

    /**
     * Handler für das neu Laden der Gruppe
     */
    async onReload() {
        const group = await this.loadGroup();
        this.agencyGroup.set(group);
        this.titleService.titleEmitter.emit(`Gruppe "${group.name}"`);
        this.showEdit.set(false);
        this.showView.set(true);

    }

    clickShowEdit() {
        this.showEdit.set(!this.showEdit());
    }

    clickShowSearch() {
        this.showSearch.set(!this.showSearch());
    }

    /**
     * Routing zurück zur Liste der Gruppen
     */
    backToList() {
        this.router.navigate(['/driver-groups/list']);
    }

}
