import { Component, Inject, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { cleanInjectedValue, Form, FormPage, NbFormComponent } from 'nb-form';
import { ACCEPT_DRIVER_VALIDATION_SCHEMA } from '../../../schema/accept-driver-validation.schema';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgencyMemberInterface, createEmptyForm, DriverInterface, ErrorService, formatFormDate, LoadingService, ToastService } from '@nf-workforce/shared';
import { DriverValidationRequest } from '../../../interface/driver-validation-request.interface';
import { MyAgencyMemberService } from '../../../services/my-agency-member.service';
import { HttpErrorResponse } from '@angular/common/http';
import { REJECT_DRIVER_VALIDATION_SCHEMA } from '../../../schema/reject-driver-validation.schema';
import { RejectDriverValidationRequest } from '../../../interface/reject-driver-validation-request.interface';

@Component({
    selector: 'agency-driver-status-modal',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        NbFormComponent
    ],
    templateUrl: './driver-status-modal.component.html',
    styleUrl: './driver-status-modal.component.scss',
})
export class DriverStatusModalComponent implements OnInit {

    form!: Form;

    title: WritableSignal<string> = signal('Fahrerprofil validieren')

    @Input()
    account!: AgencyMemberInterface

    @Input()
    driver!: DriverInterface

    @ViewChild(NbFormComponent)
    formInstance!: NbFormComponent

    @Input()
    isAccept!: boolean

    constructor(
        private modalController: ModalController,
        @Inject(ACCEPT_DRIVER_VALIDATION_SCHEMA) private acceptFormSchema: FormPage,
        @Inject(REJECT_DRIVER_VALIDATION_SCHEMA) private rejectFormSchema: FormPage,
        private toastService: ToastService,
        private loadingService: LoadingService,
        private memberService: MyAgencyMemberService,
        private errorService: ErrorService
    ) { }

    ngOnInit(): void {
        if (this.isAcception()) {
            this.form = createEmptyForm(1, cleanInjectedValue(this.acceptFormSchema));
        } else {
            this.title.set("Fahrerprofil ablehnen");
            this.form = createEmptyForm(1, cleanInjectedValue(this.rejectFormSchema));
        }
    }

    /**
     * Bestätigen der Suchauswahl
     */
    async confirm() {
        if (!this.formIsValid()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        await this.saveDriverValidation();
    }

    /**
     * Modal mit Klick auf abbrechen schließen
     */
    async cancel() {
        await this.modalController.dismiss({}, 'cancel');
    }

    /**
     * Funktion zum Validieren des Formulares
     * 
     * @returns 
     */
    private formIsValid(): boolean {
        if (!this.formInstance) {
            return false;
        }

        const validator = this.formInstance.getValidator();
        return validator.validate();
    }

    /**
     * Erstellt den Request-Payload anhand des Kriteriums, ob es sich um ein Akzeptrieren oder ABlehnen handelt
     * 
     * @returns 
     */
    private createPayload(): DriverValidationRequest | RejectDriverValidationRequest {
        if (this.isAcception()) {
            return {
                validUntil: formatFormDate(this.form.data['validUntil'] as Date)
            } as DriverValidationRequest;
        } else {
            return {
                message: this.form.data['message'] as string
            } as RejectDriverValidationRequest;
        }
    }

    /**
     * Funktion zum Speichern der Fahrer-Validierung
     */
    private async saveDriverValidation() {
        await this.loadingService.start();
        const result = await this.sendRequest()
            .catch((e: HttpErrorResponse) => {
                console.warn("Fehler bei der Anfrage", e);
                return null;
            });
        await this.loadingService.stop();

        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert");
            await this.modalController.dismiss({}, 'saved');
        } else {
            this.errorService.highlightFormErrors(this.formInstance, this.acceptFormSchema);
        }
    }

    /**
     * Sendet die Requests an den Server und gibt sie zurück
     * 
     * @returns 
     */
    private sendRequest() {
        const payload = this.createPayload();
        if (this.isAcception()) {
            return this.memberService.acceptDriverProfile(this.account.id, payload as DriverValidationRequest);
        }

        return this.memberService.rejectDriverProfile(this.account.id, payload as RejectDriverValidationRequest);
    }

    /**
     * Gibt zurück ob es sich um eine Validierung (return = true)
     * Oder um eine Ablehnung handelt (return = false)
     * 
     * @returns 
     */
    private isAcception(): boolean {
        if (typeof this.isAccept == "boolean" && this.isAccept) {
            return true;
        }

        return false;
    }

}
