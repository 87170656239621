@if(profile && form) {
    <ion-header>
        <ion-col>
            <ion-title>
                Ihre Geburt und Staatsangehörigkeit
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <form [formGroup]="form">
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>
                                Geboren am<sup>*</sup>:
                            </ion-label>
                            <ion-datetime-button datetime="datetime-birthday"></ion-datetime-button>
                            <ion-modal [keepContentsMounted]="true">
                                <ng-template>
                                    <ion-datetime
                                        id="datetime-birthday"
                                        formControlName="birthday"
                                        presentation="date"
                                        [max]="minBirthDay"
                                    >
                                        <span slot="title">Geboren am</span>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Geburtsort
                                <ui-form-error [showError]="showError(form, 'birthplace')">
                                    Geburtsort darf nicht leer sein.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput formControlName="birthplace" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-toggle
                                [enableOnOffLabels]="true"
                                [checked]="(profile.nationality === 'Deutschland' || !showAutocomplete)"
                                (ionChange)="onNationalityChange($event)"
                                labelPlacement="start"
                            >Ich besitze die deutsche Staatsangehörigkeit</ion-toggle>
                        </ion-item>
                    </ion-col>
                </ion-row>
                <ion-row [style]="(!showAutocomplete)? 'display: none;' : ''">
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Staatsangehörigkeit
                                <ui-form-error [showError]="showError(form, 'nationality')">
                                   Bitte wählen Sie eine Nationalität aus.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput [matAutocomplete]="auto" [formControl]="nationality" autocomplete="off">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                @for (option of filteredOptions | async; track option) {
                                    <mat-option [value]="option">{{option.country}}</mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                @if(profile.nationality) {

                }
            </form>
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>

            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickSave()" color="success" *ngIf="form.valid">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}


