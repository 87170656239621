import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { AlertsService } from 'nb-form';
import {
    DriverDataInterface, DriverLanguageInterface,
    EavService, IconDirective, IconEnum, IconThemeEnum, LanguageEavInterface,
    LoadingService,
    ToastService
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { DriverLanguageRequestInterface } from '../../../../interface/requests/driver-language-request.interface';
import { MatIcon } from '@angular/material/icon';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { DeleteDriverEavRequestInterface } from '../../../../interface/requests/delete-driver-eav-request.interface';
import { DriverLanguageHelperService } from '../../../../service/driver-language-helper.service';

@Component({
    selector: 'driver-my-profile-language-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ReactiveFormsModule,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatFormField,
        MatInputModule,
        MatLabel,
        MatOption,
        MatSelectModule
    ],
    templateUrl: './profile-language-modal.component.html',
    styleUrl: './profile-language-modal.component.scss',
    providers: [
        DriverLanguageHelperService
    ]
})
export class ProfileLanguageModalComponent implements OnInit {

    langForm: FormGroup | null = null;
    language!: FormControl;
    _langs: {name: string}[] = [];
    filteredOptions!: Observable<{name: string}[]>;
    eavLanguages: LanguageEavInterface[] = [];
    selectedEavLanguage: LanguageEavInterface | undefined = undefined;
    title = "Ihre Sprachkenntnisse";


    showForm: WritableSignal<boolean> = signal(false);
    isMotherLanguageForm: WritableSignal<boolean> = signal(true);

    @Input()
    driver: DriverDataInterface | null = null;

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    constructor(
        private alertService: AlertsService,
        private driverProfileService: DriverProfileService,
        private eavService: EavService,
        private languageHelper: DriverLanguageHelperService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private toastService: ToastService
    ) {
        const eavLanguages = this.eavService.getLanguages();
        if (eavLanguages) {
            eavLanguages.forEach(eav => {
                this._langs.push({name: eav.name});
            });
            this.eavLanguages = eavLanguages;
        } else {
            throw new Error('EavLanguages konnten nicht geladen werden!');
        }
    }

    private _filter(name: string): {name: string}[] {
        const filterValue = name.toLowerCase();
        return this._langs.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    private setSelectedEavLanguage() {
        this.selectedEavLanguage = this.eavLanguages.find(eav => eav.name === this.language.value.name);
    }

    private get payload() {
        this.setSelectedEavLanguage();
        if (this.selectedEavLanguage) {
            return  {
                eavId: this.selectedEavLanguage.id,
                isMotherLanguage: this.isMotherLanguageForm(),
                level: (this.isMotherLanguageForm()) ? null : this.langForm?.get('level')?.value,
            } as DriverLanguageRequestInterface;
        }
        return false;
    }

    ngOnInit(): void {
        if (this.driver && this._langs.length > 0 && this.eavLanguages.length > 0) {
            this.languageHelper.setDriver(this.driver);
            this.buildForm();
        }
        this.loadingService.stop();
    }

    get driverLanguages(): DriverLanguageInterface[] | null {
        return this.languageHelper.driverLanguages;
    }

    get motherLanguages(): DriverLanguageInterface[] | null {
        return this.languageHelper.motherLanguages;
    }

    get otherLanguages(): DriverLanguageInterface[] | null {
        return this.languageHelper.otherLanguages;
    }

    getLanguageHeader(isMotherLanguage: boolean): string {
        return this.languageHelper.getLanguageHeader(isMotherLanguage);
    }

    buildForm() {
        const opt = {
            language: new FormControl('', [Validators.required]),
            level: new FormControl('', [Validators.required]),
        }
        this.langForm = new FormGroup(opt);
        this.language = opt.language;
        this.filteredOptions = this.language.valueChanges.pipe(
            startWith(''),
            map(value => {
                const name = typeof value === 'string' ? value : value?.name;
                return name ? this._filter(name as string) : this._langs.slice();
            }),
        );
    }

    displayFn(lang: {name: string}): string {
        return lang && lang.name ? lang.name : '';
    }

    clickShowForm(isMother: boolean) {
        this.language.setValue(null);
        this.selectedEavLanguage = undefined;
        this.langForm?.get('level')?.setValue(null);
        this.isMotherLanguageForm.set(isMother);
        if (isMother) {
            this.langForm?.get('level')?.setValue('C2');
        }
        this.showForm.set(true);
    }

    clickHideFrom() {
        this.showForm.set(false);
    }


    async clickSave() {
        if (!this.langForm) {
            throw new Error('Formular nicht ermittelt!');
        }
        const payload = this.payload;
        if (!payload) {
            throw new Error('Formular-Daten nicht ermittelt!');
        }
        const question = (this.isMotherLanguageForm()) ? `Muttersprache jetzt speichern?` : `Sprache jetzt speichern?`;
        const confirmation = await this.alertService.confirm({
            text: question,
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const result = await this.driverProfileService.saveDriverLanguage(this.payload)
                .catch((e) => {
                    console.debug("Fehler bei der Anfrage.", e);
                    this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut.");
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                this.languageHelper.addLanguage(result.data);
                this.showForm.set(false);
                await this.toastService.presentToast("Sprache erfolgreich hinzugefügt!");
            }
        }
    }

    async clickDeleteLanguage(language: DriverLanguageInterface) {
        const langLabel = (language.isMotherLanguage) ? `Muttersprache ${language.name}` : `Sprache ${language.name}`;
        const question = `${langLabel} löschen?`;
        const confirmation = await this.alertService.confirm({
            text: question,
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const payload = { id: language.id } as DeleteDriverEavRequestInterface;
            const result = await this.driverProfileService.deleteDriverLanguage(payload)
                .catch((e) => {
                    console.debug("Fehler bei der Anfrage.", e);
                    this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut.");
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                this.languageHelper.removeLanguage(language);
                await this.toastService.presentToast(`${langLabel} erfolgreich entfernt!`);
            }
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }


}
