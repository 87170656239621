@if (job) {
    <ion-toolbar>
        <ion-buttons slot="end">
            @if(actView === 'personal') {
                <ion-button
                    fill="clear"
                    (click)="clickEditJob()">
                    <mat-icon [icon]="Icon.SETTINGS" [theme]="IconTheme.INHERIT"></mat-icon>
                    <ion-text class="ion-hide-md-down">&nbsp;Einstellungen</ion-text>
                </ion-button>
            } @else {
                <ion-button
                    fill="clear"
                    (click)="goToDrivers()">
                    <mat-icon [icon]="Icon.MANAGE_ACCOUNTS" [theme]="IconTheme.INHERIT"></mat-icon>
                    <ion-text class="ion-hide-md-down">&nbsp;Personalliste</ion-text>
                </ion-button>
            }

            @if (isActive) {
                <ion-button fill="clear" (click)="clickNavigateToMessages()">
                    <mat-icon [icon]="Icon.MESSAGES" [theme]="IconTheme.INHERIT"></mat-icon>
                    <ion-text class="ion-hide-md-down">&nbsp;Nachrichten</ion-text>
                </ion-button>

                <ion-button fill="clear" (click)="clickCreateBulkMessage()">
                    <mat-icon [icon]="IconEnum.PUSH_JOB" [theme]="IconTheme.INHERIT"></mat-icon>
                    <ion-text class="ion-hide-md-down">&nbsp;Job pushen</ion-text>
                </ion-button>
            }
            <ion-button
                fill="clear"
                (click)="clickGoToList()">
                <mat-icon [icon]="IconEnum.BACK" [theme]="IconTheme.INHERIT"></mat-icon>
                <ion-text class="ion-hide-md-down">&nbsp;Liste</ion-text>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
}
