import { SkillEavInterface } from './dto/skill-eav.interface';
import { CountryEavInterface } from './dto/country-eav.interface';
import { LanguageEavInterface } from './dto/language-eav.interface';
import { FahrerlaubnisEavInterface } from './dto/fahrerlaubnis-eav.interface';
import { DocumentEavInterface } from './dto/document-eav.interface';

export interface AclModel {
    route: string;
    method: string;
    reasonType: ReasonType;
}

export interface PreferenceModel {
    id?: number;
    code?: string;
    data?: unknown;
}

export enum ReasonType {
    forbidden = 0,
    allowed = 100,
    required = 200,
}

export interface LoginResponseInterface {
    id: number;
    token: string;
    redirectTo?: string;
    roles?: number[];
    shortcut?: string;
    username?: string;
    isSwitchRole?: boolean;
    language?: string;
    isValidated?: boolean;
    isHidden?: boolean;
    acl?: AclModel[];
    preferences?: PreferenceModel[];

    // Neu hinzugekommene Benutzerinformationen
    firstName: string,
    lastName: string,
    academic: string,
    sex: string,
    phone: string,

    // Neu hinzugekommene EAVs
    eavSkills: SkillEavInterface[],
    eavCountries: CountryEavInterface[],
    eavLanguages: LanguageEavInterface[],
    eavFahrerlaubnis: FahrerlaubnisEavInterface[],
    eavDocuments: DocumentEavInterface[],
    eavDriverLicenceDocument: DocumentEavInterface,
    eavPScheinDocument: DocumentEavInterface,

    // Neu Logo
    logo: number | null,
    $logoMimeType: string | null,
}
