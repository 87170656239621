import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const AGENCY_USER_SCHEMA = new InjectionToken<FormPage>('"Agentur-Benutzer Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'agency-user',
            header: 'Benutzer der Agentur',
            navigation_label: 'agency-user',
            elements: [
                {
                    id: 'userName',
                    type: 'email',
                    model: 'username',
                    label: 'Benutzername',
                    required: true
                },
                {
                    id: 'sex',
                    type: 'nb-dropdown',
                    model: 'sex',
                    label: 'Anrede',
                    options: [
                        {
                            key: 'f',
                            value: 'Frau'
                        },
                        {
                            key: 'm',
                            value: 'Herr'
                        },
                        {
                            key: 'd',
                            value: 'Divers'
                        }
                    ]
                },
                {
                    id: 'academic',
                    type: 'textarea',
                    model: 'academic',
                    label: 'Titel'
                },
                {
                    id: 'firstName',
                    type: 'textarea',
                    model: 'firstName',
                    label: 'Vorname',
                    rows: 1
                },
                {
                    id: 'lastName',
                    type: 'textarea',
                    model: 'lastName',
                    label: 'Nachname',
                    rows: 1,
                    required: true
                },
                {
                    id: 'phone',
                    type: 'phone',
                    model: 'phone',
                    label: 'Telefonnummer',
                    required: true
                },
                {
                    id: 'sendMail',
                    type: 'nb-dropdown',
                    model: 'sendMail',
                    label: 'Willkommensmail absenden',
                    options: [
                        {
                            key: '0',
                            value: 'Nein'
                        },
                        {
                            key: '1',
                            value: 'Ja'
                        }
                    ]
                }
            ]
        };
    }
});
