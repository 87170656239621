import { Routes } from '@angular/router';
import { AccountRevoveryComponent, ChangeUsernameComponent, LoginComponent, accountRoutes } from '@nf-workforce/account';
import { agencyRoutes, myAgencyRoutes } from '@nf-workforce/agency';
import { NotFoundComponent } from './component/not-found/not-found.component';
import { AuthGuard, AccGuard } from '@nf-workforce/shared';
import { jobListRoutes } from '@nf-workforce/joblist';
import { driverRoutes } from '@nf-workforce/driver';
import { driverJoblistRoutes } from '@nf-workforce/driver-joblist';
import { driverGroupRoutes } from '@nf-workforce/driver-group';
import { messageRoutes, myMessageRoutes } from '@nf-workforce/message';

export const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'account-recovery',
        component: AccountRevoveryComponent,
        pathMatch: 'full',
        title: 'Passwort zurücksetzen',
        data: { hideNav: true },
    },
    {
        path: 'change-username',
        component: ChangeUsernameComponent,
        pathMatch: 'full',
        title: 'Passwort zurücksetzen',
        data: { hideNav: true },
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
        title: 'Login',
        data: { hideNav: true },
    },
    {
        path: 'account',
        children: accountRoutes
    },
    // Admin-Routen zum Verwalten der Agenturen
    {
        path: 'admin',
        children: [
            {
                path: 'agency',
                children: agencyRoutes,
            }
        ],
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'agency_list' },
    },
    // Routen zum Verwalten der eigenen Agentur
    {
        path: 'my-agency',
        children: myAgencyRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'agency_index' },
    },
    // Routen zum Verwalten der Joblisten
    {
        path: 'jobs',
        children: jobListRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'joblist_list' }
    },
    // Fahrerrouten
    {
        path: 'driver',
        children: driverRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'driver_profile_patch' }
    },
    // Fahrer-Jobs
    {
        path: 'my-jobs',
        children: driverJoblistRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'driver_joblist_open' }
    },
    {
        path: 'driver-groups',
        children: driverGroupRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'agency_one_driver_group_list' }
    },
    {
        path: 'message',
        children: messageRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'joblist_message_list' }
    },
    {
        path: 'my-messages',
        children: myMessageRoutes,
        canActivate: [AuthGuard, AccGuard],
        canActivateChild: [AuthGuard, AccGuard],
        data: { protectedBy: 'joblist_driver_message_list' }
    },
    {
        path: '**',
        component: NotFoundComponent,
        data: { hideNav: true },
    },
];