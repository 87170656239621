<ion-content>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button
                [fill]="isCurrentList(200)? 'outline' : 'clear'"
                [color]="isCurrentList(200)? IconTheme.PRIMARY : IconTheme.SECONDARY"
                (click)="clickChangeList(200)"
                aria-label="Liste Zugewiesen"
            >
                <mat-icon [icon]="Icon.JOBLIST_ACTIVE"
                          [theme]="IconTheme.SECONDARY" ></mat-icon>
                <ion-text class="ion-hide-md-down">&nbsp;Aktiv ({{ countAccepted() }})</ion-text>
            </ion-button>
            <ion-button
                [fill]="isCurrentList(100)? 'outline' : 'clear'"
                [color]="isCurrentList(100)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                (click)="clickChangeList(100)"
                aria-label="Warteliste"
            ><mat-icon [icon]="Icon.JOBLIST_WAITING" [theme]="IconTheme.SECONDARY" ></mat-icon>
                <ion-text class="ion-hide-md-down">&nbsp;WarteListe ({{ countWaiting() }})</ion-text>
            </ion-button>
            <ion-button
                [fill]="isCurrentList(500)? 'outline' : 'clear'"
                [color]="isCurrentList(500)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                (click)="clickChangeList(500)"
                aria-label="Liste Abgelehnt"
            ><mat-icon [icon]="Icon.JOBLIST_DENEYED" [theme]="IconTheme.SECONDARY" ></mat-icon>
                <ion-text class="ion-hide-md-down">&nbsp;Abgelehnt ({{ countRejected() }})</ion-text>
            </ion-button>
            <ion-button
                [fill]="isCurrentList(1000)? 'outline' : 'clear'"
                [color]="isCurrentList(1000)? IconTheme.PRIMARY :IconTheme.SECONDARY"
                (click)="clickChangeList(1000)"
                aria-label="Liste Erledigt"
            ><mat-icon [icon]="Icon.JOBLIST_PAST" [theme]="IconTheme.SECONDARY" ></mat-icon>
                <ion-text class="ion-hide-md-down">&nbsp;Erledigt ({{ countPast() }})</ion-text>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="ion-hide-md-up">
            <ion-button
                [fill]="'clear'"
                [color]="IconTheme.SECONDARY"
            ><ion-text>
                @if (isCurrentList(200)) {
                    Aktiv ({{ countAccepted() }})
                } @else if (isCurrentList(100)) {
                    WarteListe ({{ countWaiting() }})
                } @else if (isCurrentList(500)) {
                    Abgelehnt ({{ countRejected() }})
                } @else if (isCurrentList(1000)) {
                    Erledigt ({{ countPast() }})
                }
            </ion-text>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
   <ion-col>

    @if (currentJobs().length > 0) {
        <div uiTable class="expand-xl fixed-header w-100" [changeEmitter]="changeEmitter">
            <div uiTableHead>
                <div uiTableCell align="right">
                    #
                </div>
                <div uiTableCell>
                    Name
                </div>
                <div uiTableCell>
                    Anbieter
                </div>
                <div uiTableCell>
                    Laufzeit
                </div>
                <div uiTableCell>
                    Bewerbung
                </div>
                <div uiTableCell></div>
            </div>

            @for (job of currentJobs(); track job.joblistId) {
                <div uiTableRow>
                    <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                        <div uiTableKicker># {{ job.joblistId }}</div>
                        <div class="headline">
                            <h3>
                                {{ job.name }}
                            </h3>
                        </div>
                    </div>

                    <div uiTableCell align="right" [hideOnCollapse]="true">
                        {{ job.joblistId }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>Name: </span>
                        {{ job.name }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>Anbieter: </span>
                        {{ job.agency?.name }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>
                            Laufzeit:
                        </span>
                        <span class="ion-hide-md-up"><br></span>
                        Start: {{ job.startDate | printNbDateTime }}<br>
                        Ende: {{ job.endDate | printNbDateTime }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>
                            Bewerbung bis:
                        </span>
                        {{ job.closeDate | printNbDate }}
                    </div>

                    <div uiTableCell>
                        <ion-toolbar>
                            <ion-buttons slot="end">
                                <ion-button
                                    size="small"
                                    fill="clear"
                                    title="Job anzeigen"
                                    aria-label="Job anzeigen"
                                    (click)="clickGoToJoblist(job)">
                                    <mat-icon [icon]="IconEnum.SEARCH_MANAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                                    Details
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </div>
                </div>
            }

            @if(hasMoreResults()) {
                <div uiTableFooter>
                    <ion-button
                        size="small"
                        fill="outline"
                        [color]="IconTheme.INHERIT"
                        (click)="clickNext()"
                        title="Mehr Ergebnisse laden"
                        aria-label="Mehr Ergebnisse laden"
                    ><mat-icon [icon]="IconEnum.DOWNLOAD_NEXT_PAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                        &nbsp;mehr Ergebnisse laden
                    </ion-button>
                </div>
            }
        </div>
    } @else {
        <ion-row>
            <ion-col class="ion-text-center">
                <ion-text color="danger">
                    Es wurden keine Ergebnisse gefunden
                </ion-text>
            </ion-col>
        </ion-row>
    }



   </ion-col>
</ion-content>
