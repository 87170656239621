import { formatFormDate } from "../function/format-form-date.function";
import { SearchElementInterface } from "../interfaces/search-element.interface";
import { SearchQueryInterface } from "../interfaces/search-query.interface";

export class SearchQueryBuilder {

    private transformedQuery: SearchQueryInterface;

    constructor(
        private searchConfig: SearchElementInterface[],
        searchQuery: SearchQueryInterface
    ) {
        this.transformedQuery = this.normalizeSearchQuery(searchQuery);
    }

    /**
     * Wandelt die Dates von den Datepickern in strings um
     *
     * @param query
     * @returns
     */
    normalizeSearchQuery(query: SearchQueryInterface): SearchQueryInterface {
        const searchKeys = Object.keys(query.search);
        const localQuery = {
            search: {},
            sort: query.sort
        } as SearchQueryInterface;

        for (const searchKey of searchKeys) {
            const config = this.searchConfig.find((conf) => {
                return (conf.name === searchKey);
            });

            if (config) {
                if (this.isDate(config)) {
                    const dateQuery = query.search[searchKey] as { first: Date | null, second: Date | null };
                    const ignoreTime = (config.datePresentation !== 'date-time');
                    const dateObj = {} as {first: string | null, second: string | null};

                    if (dateQuery.first instanceof Date) {
                        dateObj.first = this.transformDate(dateQuery.first, ignoreTime);
                    }

                    if (dateQuery.second instanceof Date) {
                        dateObj.second = this.transformDate(dateQuery.second, ignoreTime);
                    }

                    if (this.dateBetween(config)) {
                        // @TODO STUB -> implementieren, wenn es benötigt wird
                    } else if (dateObj.first) {
                        localQuery.search[searchKey] = dateObj.first;
                    }
                } else {
                    localQuery.search[searchKey] = query.search[searchKey];
                }
            }
        }

        return localQuery;
    }

    /**
     * Transformiert den übergebenen Wert in einen String
     * Wird null reingegeben, so kommt kein string zurück, sondern null
     *
     */
    private transformDate(date: Date | null, ignoreTime: boolean): string | null {
        if (date) {
            return formatFormDate(date, ignoreTime);
        }

        return null;
    }

    /**
     * Gibt zurück, ob das Datumssfeld ein einzelner Datepicker (single) oder zwei Datepicer (Between) sind
     *
     * @param searchElement
     * @returns
     */
    private dateBetween(searchElement: SearchElementInterface): boolean {
        if (!this.isDate(searchElement)) {
            throw new Error("Es wurde kein Date-Element übergeben");
        }

        if (searchElement.dateSingle === undefined) {
            return false;
        }

        return !searchElement.dateSingle;
    }


    /**
     * Gibt zurück, ob das übergebene Element ein Datum ist
     *
     * @param conf
     * @returns
     */
    private isDate(conf: SearchElementInterface): boolean {
        return conf.type === 'date';

    }

    /**
     * Gibt die transformierte SearchQuery zurück
     *
     * @returns
     */
    getSearchQuery(): SearchQueryInterface {
        return this.transformedQuery;
    }

}
