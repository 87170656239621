import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const agencyListSearch = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Netbrothers -> Agentur"', {
    factory: () => {
        return {
                searchElements: [
                    {
                        label: 'Suche Id',
                        name: 'id',
                        type: 'text',
                        placeholder: 'id',
                        isNumber: true,
                        selection: []
                    },
                    {
                        label: 'Suche Agenturname',
                        name: 'name',
                        type: 'text',
                        placeholder: 'Name suchen',
                        isNumber: false,
                        selection: []
                    },
                    {
                        label: 'Suche Voller Agenturname',
                        name: 'fullName',
                        type: 'text',
                        placeholder: 'Voller Name',
                        isNumber: false,
                        selection: []
                    },
                    {
                        label: 'Suche ist Aktiv',
                        name: 'isActive',
                        type: 'selection',
                        placeholder: 'Aktiv',
                        isNumber: false,
                        selection: [
                            {
                                label: 'Ja',
                                value: true
                            }, 
                            {
                                label: 'Nein',
                                value: false
                            }
                        ]
                    }
                ],
                sortElements: [
                    {
                        label: 'Id',
                        name: 'id',
                    },
                    {
                        label: 'Agenturname',
                        name: 'name'
                    },
                    {
                        label: 'Voller Agenturname',
                        name: 'fullName'
                    },
                    {
                        label: 'ist Aktiv',
                        name: 'isActive'
                    }
                ]
            } as SearchMenuInterface;
    }
});