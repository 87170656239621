import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { ErrorService, ToastService, formatPhoneNumber, isString } from '@nf-workforce/shared';
import { Router } from '@angular/router';
import { AgencyService } from '../../services/agency.service';
import { AGENCY_USER_SCHEMA } from '../../schema/agency-user.schema';
import { AGENCY_SCHEMA } from '../../schema/agency-schema';
import { NewAgencyRequestInterface } from '../../interface/new-agency-request.interface';

@Component({
    selector: 'agency-new',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
    ],
    templateUrl: './agency-new.component.html',
    styleUrl: './agency-new.component.scss',
    providers: []
})
export class AgencyNewComponent {

    agencyForm: Form;

    agencyUserForm: Form;

    @ViewChild('formAgencyInstance')
    private formAgencyInstance!: NbFormComponent

    @ViewChild('formAgencyUserInstance')
    private formAgencyUserInstance!: NbFormComponent

    constructor(
        private toastService: ToastService,
        private router: Router,
        @Inject(AGENCY_USER_SCHEMA) private agencyUserSchema: FormPage,
        @Inject(AGENCY_SCHEMA) private agencySchema: FormPage,
        private agencyService: AgencyService,
        private errorService: ErrorService
    ) {

        this.agencyForm = new Form();
        this.agencyForm.id = 1;
        this.agencyForm.schema = [cleanInjectedValue(agencySchema)];
        this.agencyForm.data = {};

        this.agencyUserForm = new Form();
        this.agencyUserForm.id = 2;
        this.agencyUserForm.schema = [cleanInjectedValue(agencyUserSchema)];
        this.agencyUserForm.data = {};
    }

    async clickAddNewAgency() {
        if (!this.formIsValid()) {
            console.warn("Eins der Formulare ist nicht valide");
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        const payload = this.getNewAgencyRequest();
        const result = await this.agencyService.addNewAgency(payload)
            .catch(async (e) => {
                console.error("Fehler beim Request: ", e);
                return null;
            });
        
        if (result) {
            await this.toastService.presentToast("Die neue Agentur wurde erfolgreich angelegt.");
            this.clickGoToList();
        } else {
            this.errorService.highlightFormErrors(this.formAgencyInstance, this.agencySchema);
            this.errorService.highlightFormErrors(this.formAgencyUserInstance, this.agencyUserSchema);
        }
    }

    private getNewAgencyRequest(): NewAgencyRequestInterface {
        const sendMail = (this.agencyUserForm.data['sendMail'] === '1') ? true : false;
        const academic = (isString(this.agencyUserForm.data['academic'])) ?
            this.agencyUserForm.data['academic'] : null;
        const phone = formatPhoneNumber(this.agencyUserForm.data['phone'] as string)

        return {
            name: this.agencyForm.data['name'] as string,
            fullName: this.agencyForm.data['fullName'] as string,
            sendMail: sendMail,
            username: this.agencyUserForm.data['username'] as string,
            firstName: this.agencyUserForm.data['firstName'] as string,
            lastName: this.agencyUserForm.data['lastName'] as string,
            academic: academic,
            phone: phone,
            language: null // Vorerst hardkodiert als null
        };
    }

    /**
     * 
     * Validiert die Formulare zum erstellen einer neuen Agentur
     * 
     * @returns 
     */
    private formIsValid(): boolean {
        if (!this.formAgencyInstance || !this.formAgencyUserInstance) {
            return false;
        }

        const agencyValidator = this.formAgencyInstance.getValidator();
        const agencyUserValidator = this.formAgencyUserInstance.getValidator();
        if (agencyValidator.validate() && agencyUserValidator.validate()) {
            return true;
        }

        return false;
    }

    clickGoToList() {
        this.router.navigate(['/admin/agency/list']);
    }

}