import { AccountService } from '../../services/account.service';
import { AuthService, isString, showFormError, ToastService } from '@nf-workforce/shared';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { passwordLengthValidator } from '../../validators/password-length.validator';
import { passwordFormatValidator } from '../../validators/password-format.validator';
import { FormErrorComponent } from '@nf-workforce/ui';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { Subscription } from 'rxjs';
import { AutofillEvent } from '@angular/cdk/text-field';

@Component({
    selector: 'lib-login',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        FormErrorComponent,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule
    ],
    templateUrl: './login.component.html',
    providers: []
})
export class LoginComponent implements OnDestroy {

    loginForm = new FormGroup({
        username: new FormControl('', Validators.required),
        password: new FormControl('', [
            Validators.required,
            passwordLengthValidator,
            passwordFormatValidator
        ])
    })

    disableLogin = signal(true);

    private accountService = inject(AccountService);

    private authService = inject(AuthService);

    private toastService = inject(ToastService);

    private router = inject(Router);

    showError = showFormError;

    hidePassword = signal(true);

    changeSub: Subscription;

    constructor() {
        this.changeSub = this.loginForm.valueChanges.subscribe(() => {
            this.disableLogin.set(!this.loginForm.valid);
        });
    }

    ngOnDestroy(): void {
        this.changeSub.unsubscribe();
    }

    /**
     * Klickhandler für das Einloggen des Benutzers
     */
    async clickLogin() {
        if (this.loginForm.valid) {
            this.disableLogin.set(true);
            const formData = this.loginForm.value;

            if (isString(formData.username) && isString(formData.password)) {
                const result = await this.accountService.sendLogin(formData.username, formData.password)
                    .catch(async (e) => {
                        console.debug('Fehler beim Login', e);
                        await this.toastService.presentToast('Ihr Benutzername und/oder das Passwort sind falsch.');
                    });

                if (result) {
                    this.authService.setSession(result);
                    if (this.authService.isValidated) {
                        await this.toastService.presentToast('Sie wurden erfolgreich eingeloggt.');
                        await this.router.navigate([
                            this.accountService.redirectFrontendAfterLogin
                        ]);
                    } else {
                        await this.router.navigate([
                            this.accountService.urlValidateAccount
                        ]);
                    }
                }
            }
        }
    }

    /**
     * Klickhandler zur Weiterleitung auf "Passwort zurücksetzen"
     */
    clickResetPassword() {
        this.router.navigate(['/account/reset-password']);
    }

    /**
     * Ein/Ausblenden des Passwortes
     */
    toggleVisibility() {
        this.hidePassword.set(!this.hidePassword());
    }

    onAutofill($event: AutofillEvent) {
        if ($event.isAutofilled) {
            this.disableLogin.set(false);
        }
    }

}
