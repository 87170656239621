import { Routes } from '@angular/router';
import { DriverMessagesComponent } from './components/driver-messages/driver-messages.component';

export const myMessageRoutes: Routes = [
    {
        path: '',
        component: DriverMessagesComponent,
        pathMatch: 'full',
        data: { protectedBy: 'joblist_driver_message_list'}
    }
];
