@if (showView()) {
    <ion-content>
        <ion-toolbar>
            <ion-buttons slot="end">
                @if (!showEdit()) {
                    <ion-button
                        fill="clear"
                        (click)="clickShowSearch()">
                        <mat-icon [icon]="IconEnum.ADD_USER" [theme]="IconTheme.INHERIT" ></mat-icon>
                        <ion-text class="ion-hide-md-down">&nbsp;Hinzufügen</ion-text>
                    </ion-button>
                    <ion-button
                        fill="clear"
                        (click)="clickShowEdit()">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                        <ion-text class="ion-hide-md-down">&nbsp;Name ändern</ion-text>
                    </ion-button>
                    <ion-button
                        fill="clear"
                        (click)="backToList()">
                        <mat-icon [icon]="IconEnum.BACK" [theme]="IconTheme.INHERIT"></mat-icon>
                        <ion-text class="ion-hide-md-down">&nbsp;Liste</ion-text>
                    </ion-button>
                } @else {
                    <ion-button
                        fill="clear"
                        (click)="clickShowEdit()">
                        <mat-icon [icon]="Icon.MANAGE_ACCOUNTS" [theme]="IconTheme.INHERIT"></mat-icon>
                        <ion-text class="ion-hide-md-down">&nbsp;Mitglieder verwalten</ion-text>
                    </ion-button>
                }
            </ion-buttons>
        </ion-toolbar>

        <ion-row [style]="(!showEdit()) ? 'display:none;':''">
            <ion-col>
                <ion-card class="ion-padding">
                    <ion-card-header>
                        <ion-card-title>
                            Gruppe bearbeiten
                        </ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <driver-group-edit
                            [agencyGroup]="agencyGroup()"
                            (reload)="onReload()"
                            (cancel)="showEdit.set(false)"
                        ></driver-group-edit>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>

        <ion-row [style]="(this.showEdit()) ? 'display:none;':''">
            <ion-col>
                <ion-card>
                    <ion-card-header>
                        <div class="driver-account-header">
                            <h2 class="driver-account-header__text">Mitglieder</h2>
                        </div>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row [style]="(!this.showSearch()) ? 'display:none':''">
                            <ion-col>
                                <driver-group-add-driver
                                    [agencyGroup]="agencyGroup()"
                                    (reload)="onReload()"
                                >
                                </driver-group-add-driver>
                            </ion-col>
                        </ion-row>
                        <driver-group-members [agencyGroup]="agencyGroup()" (reload)="onReload()"></driver-group-members>
                    </ion-card-content>
                </ion-card>


            </ion-col>
        </ion-row>

    </ion-content>
}


