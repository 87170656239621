<div class="background-wrapper">
    <div class="image-wrapper">
        <img id="ffc6f838b9ae41799eb9" [src]="url">
    </div>
    <ion-toolbar>
        <ion-buttons slot="secondary">
            <ion-button (click)="_onCancel()">Abbrechen</ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
            <ion-button (click)="_onSave()" [disabled]="!ready" color="primary">Speichern</ion-button>
        </ion-buttons>
    </ion-toolbar>
</div>
