import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent, cleanInjectedValue, AlertsService } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { ErrorService, formatPhoneNumber, IconDirective, IconEnum, IconThemeEnum, isString, ToastService } from '@nf-workforce/shared';
import { Router } from '@angular/router';
import { ADD_AGENCY_USER_SCHEMA } from '../../schema/add-agency-member.schema';
import { AddAgencyMemberRequestInterface } from '../../interface/add-agency-member-request.interface';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-my-agency-add-member',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
        IconDirective,
        MatIcon
    ],
    templateUrl: './my-agency-add-member.component.html',
    styleUrl: './my-agency-add-member.component.scss',
    providers: []
})
export class MyAgencyAddMemberComponent {

    agencyMemberForm: Form;

    @ViewChild(NbFormComponent)
    private formAddMemberInstance!: NbFormComponent

    constructor(
        private toastService: ToastService,
        private router: Router,
        @Inject(ADD_AGENCY_USER_SCHEMA) private agencyUserSchema: FormPage,
        private agencyMemberService: MyAgencyMemberService,
        private alertService: AlertsService,
        private errorService: ErrorService
    ) {
        this.agencyMemberForm = new Form();
        this.agencyMemberForm.id = 1;
        this.agencyMemberForm.schema = [cleanInjectedValue(agencyUserSchema)];
        this.agencyMemberForm.data = {};
    }

    /**
     * Sendet das Request zum Abspeichern des Benutzers an das Backend
     *
     * @returns
     */
    async clickSave() {
        if (!this.formIsValid()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie den neuen Mitarbeiter jetzt anlegen?",
            textOk: "Ja",
            textCancel: "Nein"
        });

        if (confirmation) {
            const result = await this.agencyMemberService.addNewMember(this.buildRequestPayload())
                .catch((e: HttpErrorResponse) => {
                    console.debug("Fehler beim Anlegen des Benutzers: ", e);
                    return null
                });

            if (result) {
                await this.toastService.presentToast("Der Mitarbeiter wurde erfolgreich angelegt");
                await this.router.navigate(['/my-agency/accounts'])
            } else {
                this.errorService.highlightFormErrors(this.formAddMemberInstance, this.agencyUserSchema);
            }
        }

    }

    /**
     * Baut das Request anhand der FOrmular-Daten zum absenden an den Server
     *
     * @returns AddAgencyMemberRequestInterface
     */
    private buildRequestPayload(): AddAgencyMemberRequestInterface {
        const sendMail = (this.agencyMemberForm.data['sendMail'] !== "0");
        const academic = (isString(this.agencyMemberForm.data['academic'])) ?
            this.agencyMemberForm.data['academic'] : null;
        const phone = formatPhoneNumber(this.agencyMemberForm.data['phone'] as string);

        return {
            username: this.agencyMemberForm.data['username'] as string,
            academic: academic,
            sex: this.agencyMemberForm.data['sex'] as string,
            firstName: this.agencyMemberForm.data['firstName'] as string,
            lastName: this.agencyMemberForm.data['lastName'] as string,
            roleId: this.agencyMemberForm.data['roleId'] as number,
            phone: phone,
            sendMail: sendMail,
            language: null
        };
    }

    /**
     *
     * Validiert die Formulare zum erstellen einer neuen Agentur
     *
     * @returns
     */
    private formIsValid(): boolean {
        return this.formAddMemberInstance.getValidator().validate();
    }

    /**
     * Zurück zur Agentur-Übersichtsseite
     */
    clickGoBack() {
        this.router.navigate(['/my-agency/accounts']);
    }


  protected readonly IconTheme = IconThemeEnum;
  protected readonly IconEnum = IconEnum;
}
