import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ENV } from '../../environment/environment';

@Injectable()
export class ProfileImageService {
    constructor(
        private httpService: HttpService
    ) {}

    getProfileImage(userId: number) {
        const url = `${ENV.apiUrl}/profile/image/${userId}`;
        return this.httpService.getBypassCache(url);
    }
}
