@if(passwordForm) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <ion-row>
                <ion-col>
                    <nb-form
                    #passwordInstance
                    [form]="passwordForm"
                    appearance="outline"
                    [delay]="0"
                    [debug]="false"
                    (changeData)="onChangeForm()"
                ></nb-form>
                </ion-col>
            </ion-row>
          

            @for (error of passwordErrors(); track error) {
                <ion-row>
                    <ion-col>
                        <ion-text color="danger">
                            {{error}}
                        </ion-text>
                    </ion-col>
                </ion-row>
            }
        </ion-col>
    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickUpdatePassword()" color="success" [disabled]="disablePasswordButton()">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>

}
