<ion-content>
    <ion-row>

        @if (agencyGroups().length > 0) {
            <div uiTable class="expand-xl fixed-header w-100">
                <div uiTableHead>
                    <div uiTableCell align="right">
                        #
                    </div>

                    <div uiTableCell>
                        Name
                    </div>

                    <div uiTableCell>
                        Mitglieder
                    </div>

                    <div uiTableCell></div>
                </div>

                @for (group of agencyGroups(); track group) {
                    <div uiTableRow>
                        <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                            <div uiTableKicker># {{ group.id }}</div>
                            <div class="headline" (click)="goToGroup(group)">
                                <h3>{{ group.name }}</h3>
                            </div>
                        </div>

                        <div uiTableCell align="right" [hideOnCollapse]="true">
                            {{ group.id }}
                        </div>

                        <div uiTableCell [hideOnCollapse]="true">
                            <span uiTableCellLabel>Name: </span>
                            {{ group.name }}
                        </div>

                        @if (group.members.length === 0) {
                            <div uiTableCell>
                                <span uiTableCellLabel>Mitglieder:</span>
                                <ion-text>
                                    Keine Mitglieder vorhanden.
                                </ion-text>
                            </div>
                        } @else {
                            <div uiTableCell [hideOnCollapse]="true">
                                <span uiTableCellLabel>Mitglieder:</span>
                                <ion-row class="ion-show-md-up">
                                    <table style="width: 100%;">
                                        <thead style="text-align: left;">
                                        <tr>
                                            <th style="width: 10%"><b>Id</b></th>
                                            <th><b>Name</b></th>
                                            <th><b>Benutzername</b></th>
                                            <th style="width: 10%"><b>Login</b></th>
                                            <th><b>Profil</b></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            @for (member of group.members; track member.id) {
                                                <tr>
                                                    <td>{{ member.id }}</td>
                                                    <td>{{ member.fullName }}</td>
                                                    <td>{{ member.userName }}</td>
                                                    <td>{{ member.isActive | booleanToString }}</td>
                                                    <td>{{ member.driverProfileStatus | driverAgencyProfileState }}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </ion-row>
                            </div>
                            <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                <span uiTableCellLabel>Mitglieder:</span>
                                @for (member of group.members; track member.id) {
                                    <ion-card>
                                        <ion-card-header>
                                            <ion-card-subtitle>
                                                {{ member.lastName }}
                                                @if(member.firstName !== null && member.firstName !== '') {
                                                    <br/>
                                                    {{member.firstName}}
                                                }
                                            </ion-card-subtitle>
                                        </ion-card-header>
                                        <ion-card-content>
                                                    <ion-row>
                                                        <ion-col>
                                                            <b>Benutzername</b>
                                                            <br/>
                                                            {{ member.userName }}
                                                        </ion-col>
                                                    </ion-row>
                                                    <ion-row>
                                                        <ion-col><b>Login</b>
                                                        <br/>
                                                        {{ member.isActive | booleanToString }}</ion-col>
                                                        <ion-col>
                                                            <b>Profil</b>
                                                            <br/>
                                                        {{ member.driverProfileStatus | driverAgencyProfileState }}
                                                        </ion-col>
                                                    </ion-row>
                                                </ion-card-content>
                                    </ion-card>
                                }
                            </div>
                        }

                        <div uiTableCell>
                            <ion-buttons slot="end">
                                <ion-button
                                    size="small"
                                    fill="clear"
                                    title="Einstellungen"
                                    aria-label="Einstellungen"
                                    (click)="goToGroup(group)">
                                    <mat-icon [icon]="Icon.SETTINGS" [theme]="IconTheme.INHERIT"></mat-icon>
                                </ion-button>
                                <ion-button
                                    size="small"
                                    fill="clear"
                                    title="Personalliste"
                                    aria-label="Personalliste"
                                    [color]="IconTheme.DANGER"
                                    (click)="deleteGroup(group)">
                                    <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.INHERIT"></mat-icon>
                                </ion-button>
                            </ion-buttons>
                        </div>
                    </div>
                }

                @if(enableMoreResults()) {
                    <div uiTableFooter>
                        <ion-button
                            size="small"
                            fill="outline"
                            [color]="IconTheme.INHERIT"
                            (click)="loadNextPage()"
                            title="Mehr Ergebnisse laden"
                            aria-label="Mehr Ergebnisse laden"
                        ><mat-icon [icon]="IconEnum.DOWNLOAD_NEXT_PAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                            &nbsp;mehr Ergebnisse laden
                        </ion-button>
                    </div>
                }
            </div>
        } @else {
            <ion-col>
                <ion-text>
                    Keine Ergebnisse gefunden
                </ion-text>
            </ion-col>
        }
    </ion-row>
</ion-content>
