import { InjectionToken } from "@angular/core";
import { SearchMenuInterface } from "@nf-workforce/shared";

export const messageListSearch = new InjectionToken<SearchMenuInterface>('"Konfiguration der Suche in Job -> Nachrichten"', {
    factory: () => {
        return {
            searchElements: [
                {
                    label: 'Suche Id',
                    name: 'id',
                    type: 'text',
                    placeholder: 'id',
                    isNumber: true,
                    selection: []
                },
                {
                    label: 'Suche Betreff',
                    name: 'betreff',
                    type: 'text',
                    placeholder: 'Betreff',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Suche Vorname',
                    name: 'firstName',
                    type: 'text',
                    placeholder: 'Vorname',
                    isNumber: false,
                    selection: []
                },
                {
                    label: 'Suche Nachname',
                    name: 'lastName',
                    type: 'text',
                    placeholder: 'Nachname',
                    isNumber: false,
                    selection: []
                }
            ],
            sortElements: []
        }
    }
});
