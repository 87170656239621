@if (driver) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Wissen</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.KNOWLEDGE"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Skills</span>
                    <span
                        class="driver-skill__edit-button show-on-hover"
                        role="button"
                        (click)="clickEdit('skills')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    {{ getSkillsStrings() }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Sprachen</span>
                    <span
                        class="driver-skill__edit-button show-on-hover"
                        role="button"
                        (click)="clickEdit('language')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    <div *ngIf="driverLanguages && driverLanguages.length > 0; else divNoLang">
                        {{getLanguagesAsString()}}
                    </div>
                    <ng-template #divNoLang>
                        Keine Sprachkenntnisse hinterlegt.
                    </ng-template>
                </span>
            </div>
            <br>
            <div *ngIf="references && references.length > 0; else noReference">
                @for (reference of references; track reference.id) {
                    <div class="driver-skill">
                        <label class="driver-skill__label">
                            <span class="driver-skill__label-text">{{ $index + 1 }}.&nbsp;Referenz:</span>
                            <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDeleteReference(reference, $index)">
                                <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.INHERIT" ></mat-icon>
                            </span>
                            <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditReference(reference)">
                                <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT" ></mat-icon>
                            </span>
                        </label>
                        <span class="driver-skill__skill" style="white-space: pre-line">
                            {{ reference.data }}
                        </span>
                    </div>
                }
                <br>
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Neue Referenzen</span>
                        <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickNewReference()">
                        <mat-icon [icon]="IconEnum.NEW" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                    </label>
                </div>
            </div>
            <ng-template #noReference>
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Referenzen</span>
                        <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickNewReference()">
                        <mat-icon [icon]="IconEnum.NEW" [theme]="IconTheme.INHERIT" ></mat-icon>
                    </span>
                    </label>
                    <span class="driver-skill__skill" style="white-space: pre-line">
                        Keine Referenz hinterlegt
                    </span>
                </div>
            </ng-template>
        </ion-card-content>
    </ion-card>
}



