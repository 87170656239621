 @if (type === 'between') {
    <ion-item [button]="true" (click)="clickOpenDatePicker(1)">
        <ion-label>
            {{ getDisplayValue(1) }}
        </ion-label>
    </ion-item>

    <ion-item-divider></ion-item-divider>

    <ion-item [button]="true" (click)="clickOpenDatePicker(2)">
        <ion-label>
            {{ getDisplayValue(2) }}
        </ion-label>
    </ion-item>
} @else {
    <ion-item [button]="true" (click)="clickOpenDatePicker(1)">
        <ion-label>
            {{ getDisplayValue(1) }}
        </ion-label>
    </ion-item>
}
