import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const BULK_MESSAGE_SCHEMA = new InjectionToken<FormPage>('"Schema Einzelnachricht"', {
    factory: () => {
        return {
            ber_reiter_id: 'message',
            header: '',
            navigation_label: 'bulk-message',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'forceSending',
                    type: 'nb-dropdown',
                    model: 'forceSending',
                    label: 'Senden erzwingen',
                    required: true,
                    options: [
                        {
                            key: '0',
                            value: 'Nein'
                        },
                        {
                            key: '1',
                            value: 'Ja'
                        },
                    ],
                    columnSize: 12
                },
                {
                    id: 'showAdd',
                    type: 'checkbox',
                    model: 'showAdd',
                    label: 'Empfänger selbst auswählen',
                    columnSize: 12,
                    default: false
                },
                {
                    id: 'addGroup',
                    type: 'nb-dropdown',
                    model: 'addGroup',
                    label: 'Gruppe hinzufügen',
                    options: [],
                    onChange: {
                        func: 'addGroupToMessage',
                        parameters: ['addGroup']
                    },
                    hideOn: [
                        {
                            func: 'notEqual', parameters: [
                                true, "showAdd"
                            ]
                        }
                    ],
                    columnSize: 6
                },
                {
                    id: 'addUser',
                    type: 'nb-dropdown',
                    model: 'addUser',
                    label: 'Benutzer hinzufügen',
                    options: [],
                    onChange: {
                        func: 'addUserToMessage',
                        parameters: ['addUser']
                    },
                    hideOn: [
                        {
                            func: 'notEqual', parameters: [
                                true, "showAdd"
                            ]
                        }
                    ],
                    columnSize: 6
                },
                
            ]
        };
    }
});