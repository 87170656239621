import { Injectable } from '@angular/core';
import {
    EavService,
    FahrerlaubnisEavInterface,
    JobListEavInterface,
    JobListInterface,
    LanguageEavInterface,
    SkillEavInterface
} from '@nf-workforce/shared';

@Injectable()
export class JobKnowledgeHelperService {

    job!: JobListInterface;
    eavSkills: SkillEavInterface[] = [];
    eavFahrerlaubnisse: FahrerlaubnisEavInterface[] = [];
    eavLanguages: LanguageEavInterface[] = [];

    constructor(
        private eavService: EavService,
    ) {
        const eavSkills = this.eavService.getSkills();
        if (eavSkills) {
            this.eavSkills = eavSkills;
        } else {
            throw new Error('EavSkills nicht gesetzt');
        }
        const fahrerlaubnisse = this.eavService.getFahrerlaubnis();
        if (fahrerlaubnisse) {
            this.eavFahrerlaubnisse = fahrerlaubnisse;
        } else {
            throw new Error('EavFahrerlaubnis nicht gesetzt');
        }
        const eavLanguages = this.eavService.getLanguages();
        if (eavLanguages) {
            this.eavLanguages = eavLanguages;
        } else {
            throw new Error('EavLanguages nicht gesetzt');
        }
    }

    setJob( job: JobListInterface) {
        this.job = job;
    }

    getSkillsStringByJobListEavInterface(skills: JobListEavInterface[] | [] = []): string {
        const name: string[] = [];
        if (skills && skills.length > 0) {
            const eavSkills = this.eavSkills;
            skills.forEach(function (jobSkill) {
                const eavSkill = eavSkills.find(function(eav) {
                    return eav.id === jobSkill.eavId;
                });
                if (eavSkill) {
                    name.push(eavSkill.label);
                }
            });
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return 'Es sind keine Skills gefordert';
    }

    getSkillsStrings(): string {
        if (this.job && this.job.skills.length > 0) {
            return this.getSkillsStringByJobListEavInterface(this.job.skills);
        }
        return 'Es sind keine Skills gefordert';
    }

    getFahrerlaubnisStringByJobListEavInterface(skills: JobListEavInterface[] | [] = []): string {
        const name: string[] = [];
        if (skills && skills.length > 0) {
            const eavSkills = this.eavFahrerlaubnisse;
            skills.forEach(function (jobSkill) {
                const eavSkill = eavSkills.find(function(eav) {
                    return eav.id === jobSkill.eavId;
                });
                if (eavSkill) {
                    name.push(eavSkill.label);
                }
            });
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return 'Es sind keine Führerschein-Klassen gefordert';
    }

    getFahrerlaubnisStrings(): string {
        if (this.job && this.job.driverLicenceDto.length > 0) {
            return this.getFahrerlaubnisStringByJobListEavInterface(this.job.driverLicenceDto);
        }
        return 'Es sind keine Führerschein-Klassen gefordert';
    }

    getLanguageStringsByJobListEavInterface(skills: JobListEavInterface[] | [] = []): string {
        const name: string[] = [];
        if (skills && skills.length > 0) {
            const eavSkills = this.eavLanguages;
            skills.forEach(function (jobSkill) {
                const eavSkill = eavSkills.find(function(eav) {
                    return eav.id === jobSkill.eavId;
                });
                if (eavSkill) {
                    name.push(eavSkill.name);
                }
            });
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return 'Es sind keine Sprachen gefordert';
    }

    getLanguageStrings(): string {
        if (this.job && this.job.languages.length > 0) {
            return this.getLanguageStringsByJobListEavInterface(this.job.languages);
        }
        return 'Es sind keine Sprachen gefordert';
    }

    isEavActivated(eavId: number, eavType: string) {
        if (eavType === 'fahrererlaubnis') {
            if (this.job && this.job.driverLicenceDto.length > 0) {
                const result = this.job.driverLicenceDto.find(jobSkill => {
                    return jobSkill.eavId === eavId;
                });
                return !!result;
            }
        } else if (eavType === 'skills') {
            if (this.job && this.job.skills.length > 0) {
                const result = this.job.skills.find(jobSkill => {
                    return jobSkill.eavId === eavId;
                });
                return !!result;
            }
        } else if (eavType === 'languages') {
            if (this.job && this.job.languages.length > 0) {
                const result = this.job.languages.find(jobSkill => {
                    return jobSkill.eavId === eavId;
                });
                return !!result;
            }
        }
        return false;
    }

    getSkillName(eavId: number, eavType: string) {
        if (eavType === 'fahrererlaubnis') {
            const result = this.eavFahrerlaubnisse.find(eav => {
                return eav.id === eavId;
            });
            if (result) {
                return result.label;
            }
        } else if (eavType === 'skills') {
            const result = this.eavSkills.find(eav => {
                return eav.id === eavId;
            });
            if (result) {
                return result.label;
            }
        } else if (eavType === 'languages') {
            const result = this.eavLanguages.find(eav => {
                return eav.id === eavId;
            });
            if (result) {
                return result.name;
            }
        }
        return '';
    }
}
