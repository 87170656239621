import { Injectable } from '@angular/core';
import { InternalErrorInterface } from '../interfaces/error/internal-error.interface';
import { FormElement, FormPage, NbFormComponent } from 'nb-form';
import { NbFormErrors } from '../interfaces/error/nb-form-errors.interface';
import { isString } from '../type-guards/is-string';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    private currentErrors: InternalErrorInterface[] = [];

    /**
     * Setzt die Fehler im BehaviorSubject
     * 
     * 
     * @param errors 
     */
    setErrors(errors: InternalErrorInterface[]) {
        this.currentErrors = errors;
    }

    /**
     * Gibt die derzeit gespeicherten Fehler zurück
     * 
     * @returns 
     */
    getErrors() {
        return this.currentErrors;
    }

    /**
     * Funktion zum zurücksetzen der Fehlernachrichten
     */
    clearErrors() {
        this.currentErrors = [];
    }
    
    /**
     * Gibt zurück, ob derzeit Fehler vorhanden sind
     * 
     * @returns 
     */
    hasErrors() {
        return (this.currentErrors.length > 0);
    }

    /**
     * Gibt die Fehler für NbForm aufbereitet zurück
     * 
     * @param schema 
     * @returns 
     */
    private getFormErrorsBySchema(schema: FormPage[] | FormPage) {
        return this.transformErrorsForForm(schema);
    }

    
    private transformErrorsForForm(schema: FormPage[] | FormPage): NbFormErrors {
        const errorObj = {} as NbFormErrors;
        
        for (const error of this.getErrors()) {
            let possibleElement;
            if (Array.isArray(schema)) {
    
                for (const page of schema) {
                    possibleElement = this.findElementByModel(error.field, page);
    
                    if (possibleElement) {
                        break;
                    }
                }
            } else {
                possibleElement = this.findElementByModel(error.field, schema);
            }
            
            if (typeof possibleElement === "object") {
                errorObj[possibleElement.id] = error.message;
            }
            
        }
    
        return errorObj;
    }

    /**
     * Sucht ein Element anhand des Modelnamens aus der übergebenen FormPage heraus
     * 
     * @param modelName 
     * @param page 
     * @returns 
     */
    private findElementByModel(modelName: string, page: FormPage): FormElement | undefined {
        return page.elements.find((element) => {
            return (isString(element.model) && element.model === modelName);
        });
    }

    /**
     * 
     * 
     * @param instance 
     * @param schema 
     */
    highlightFormErrors(instance: NbFormComponent, schema: FormPage | FormPage[]) {
        if (this.hasErrors()) {
            const validator = instance.getValidator();
            validator.setValidationErrors(this.getFormErrorsBySchema(schema));
        }
    }

}
