import { Component, inject, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormElementOption, FormPage, NbFormComponent } from 'nb-form';
import { ToastService, LoadingService, createEmptyForm, SearchQueryInterface, JobDriverInterface } from '@nf-workforce/shared';
import { SINGLE_MESSAGE_SCHEMA } from '../../schema/single-message.schema';
import { IonicModule } from '@ionic/angular';
import { MessageService } from '../../service/message.service';
import { AgencyGroupInterface, DriverGroupService } from '@nf-workforce/driver-group';
import { SingleMessageRequestInterface } from '../../interfaces/single-message-request.interface';
import { MessageJobService } from '../../service/message-job.service';
import { Router } from '@angular/router';

@Component({
    selector: 'message-add-single-message',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent
    ],
    providers: [
        MessageJobService,
        DriverGroupService
    ],
    templateUrl: './add-single-message.component.html',
    styleUrl: './add-single-message.component.scss',
})
export class AddSingleMessageComponent implements OnInit {

    private messageService = inject(MessageService);

    private groupService = inject(DriverGroupService);

    private toastService = inject(ToastService);

    private loadingService = inject(LoadingService);

    private router = inject(Router);

    form!: Form;

    @ViewChild(NbFormComponent)
    formInstance!: NbFormComponent;

    disableSave = signal(true);

    selectedUsers = signal<number[]>([]);

    selectedGroups = signal<number[]>([]);

    driverList: JobDriverInterface[] = [];

    groupList: AgencyGroupInterface[] = [];

    get helpers(): object {
        return {
            addGroupToMessage: async (parameters: unknown[]) => {
                if (typeof parameters[0] !== "number") {
                    return;
                }

                const currentSelection = this.selectedGroups();
                if (!currentSelection.includes(parameters[0] as number)) {
                    currentSelection.push(parameters[0] as number);
                    this.selectedGroups.set(currentSelection);
                }
            },
            addUserToMessage: async (parameters: unknown[]) => {
                if (typeof parameters[0] !== "number") {
                    return;
                }

                const currentSelection = this.selectedUsers();
                if (!currentSelection.includes(parameters[0] as number)) {
                    currentSelection.push(parameters[0] as number);
                    this.selectedUsers.set(currentSelection);
                }
            }
        };
    }

    get payload(): SingleMessageRequestInterface {
        return {
            betreff: this.form.data['betreff'] as string,
            nachricht: this.form.data['nachricht'] as string,
            userIds: this.selectedUsers(),
            groupIds: this.selectedGroups()
        };
    }

    constructor(
        @Inject(SINGLE_MESSAGE_SCHEMA) private formSchema: FormPage
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadingService.start();
        const driverResponse = await this.loadDrivers();
        const groupResponse = await this.loadGroups();
        let driverOptions: FormElementOption[] = [];
        let groupOptions: FormElementOption[] = [];

        if (driverResponse.data.length > 0) {
            this.driverList = driverResponse.data;
            driverOptions = driverResponse.data.map((driver) => {
                return {
                    key: driver.userListDto.id,
                    value: `${driver.userListDto.firstName} ${driver.userListDto.lastName}`
                } as FormElementOption;
            }) as FormElementOption[];
        }

        if (groupResponse.data.length > 0) {
            this.groupList = groupResponse.data.filter((group) => {
                return (group.members.length > 0);
            });

            groupOptions = this.groupList.map((group) => {
                return {
                    key: group.id,
                    value: group.name
                } as FormElementOption;
            }) as FormElementOption[];
        }

        this.createForm(driverOptions, groupOptions);
        await this.loadingService.stop();
    }

    private createForm(driverOptions: FormElementOption[], groupOptions: FormElementOption[]) {
        this.formSchema.elements[2].options = groupOptions;
        this.formSchema.elements[3].options = driverOptions;
        this.form = createEmptyForm(1, this.formSchema);
    }

    /**
     * Funktion zum laden der Fahrer für die Nachricht des derzeitigen JObs 
     */
    private async loadDrivers() {
        return await this.messageService.getDriverList(this.messageService.getJobId());
    }

     /**
     * Funktion zum laden der Gruppen für die Nachrichten
     */
     private async loadGroups() {
        return await this.groupService.getList(1, 500, {} as SearchQueryInterface);
    }
    
    onChangeForm() {
        if (this.formInstance) {
            if (this.formInstance.getValidator().validate()) {
                this.disableSave.set(false);
            } else {
                this.disableSave.set(true);
           }
       }
    }

    displayDriver(id: number) {
        const driver = this.driverList.find((driver) => {
            return driver.userListDto.id === id;
        });

        return `${driver?.userListDto.firstName} ${driver?.userListDto.lastName}`;
    }

    displayGroup(id: number) {
        const group = this.groupList.find((group) => {
            return group.id === id;
        });

        return group?.name;
    }

    /**
     * Speichern einer neuen Nachricht
     */
    async clickSave() {
        await this.loadingService.start();
        const jobId = this.messageService.getJobId();
        const response = await this.messageService.createMessage(jobId, this.payload)
            .catch((error) => {
                console.debug("Error", error);
                return null;
            });
        await this.loadingService.stop();

        if (response) {
            this.toastService.presentToast("Die Nachricht wird in Kürze versendet");
            const listUrl = `/message/${jobId}/list`;
            this.router.navigate([listUrl]);
        }
    }

}
