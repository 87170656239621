<ion-content class="ion-padding">
        <ion-datetime [presentation]="presentation" (ionChange)="changeDate()" size="cover" firstDayOfWeek="1">
            <ion-label slot="time-label">
                Uhrzeit
            </ion-label>

           
        </ion-datetime>

     
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="primary">
            <ion-button fill="outline" (click)="clickConfirm()">Auswählen</ion-button>
            <ion-button fill="outline" (click)="clickCancel()">Abbrechen</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>