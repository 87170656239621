import { Form, FormPage } from "nb-form";

/**
 * Funktion zum erstellen einer leeren NbForm-INstanz anhand der übergebenen Id und der übergebenen FormPage
 * 
 * 
 * @param id 
 * @param schema 
 * @returns 
 */
export function createEmptyForm(id: number, schema: FormPage): Form {
    const form = new Form();
    form.id = id;
    form.schema = [schema];
    form.data = {};
    return form;
}