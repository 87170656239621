import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DriverMessageService } from '../../service/driver-message.service';
import { LoadingService, SearchQueryInterface, TitleService } from '@nf-workforce/shared';
import { MessageInterface } from '../../interfaces/message.interface';
import { toObservable } from '@angular/core/rxjs-interop';
import { RecipientsPipe } from '../../pipes/recipients.pipe';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { Router } from '@angular/router';

@Component({
    selector: 'message-driver-messages',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableDirective,
        TableHeadDirective,
        TableRowDirective,
        TableCellDirective,
        TableCellLabelDirective,
        TableFooterDirective,
        RecipientsPipe
    ],
    providers: [
        DriverMessageService
    ],
    templateUrl: './driver-messages.component.html',
    styleUrl: './driver-messages.component.scss',
})
export class DriverMessagesComponent implements OnInit {

    private myMesageService = inject(DriverMessageService)

    private loadingService = inject(LoadingService);

    private titleService = inject(TitleService);

    private router = inject(Router);

    messages = signal<MessageInterface[]>([]);

    changeEmitter = toObservable(this.messages);
    
    enableMoreResults: WritableSignal<boolean> = signal(false);
    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };
    
    private nextPage = 1;
    private limit = 25;

    async ngOnInit(): Promise<void> {
        this.titleService.titleEmitter.next("Meine Nachrichten");
        this.messages.set(await this.loadMyMessages());
    }

    /**
     * Funktion zum Laden der Nachrichten des derzeitig eingeloggten Fahrers
     * 
     * @returns 
     */
    private async loadMyMessages(): Promise<MessageInterface[]> {
        await this.loadingService.start();
        const result = await this.myMesageService.getMyMessages(this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.debug("Fehler beim Laden der Nachrichten", e);
                return null;
            });
        
        await this.loadingService.stop();
        if (result) {
            this.nextPage = result.page + 1;

            if (this.nextPage <= result.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return result.data;
        }

        return [];
    }

    /**
     * Nachladen der nächsten Nachrichten
     */
    async clickLoadNextPage() {
        const current = this.messages();
        const next = await this.loadMyMessages();
        this.messages.set(current.concat(next));
    }

    /**
     * Navigation zum betreffenden Job der Nachricht
     * 
     * @param message 
     */
    async clickViewJob(message: MessageInterface) {
        return this.router.navigate([`/my-jobs/one/${message.jobId}`]);
    }

}
