@if (document) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-header class="ion-hide-md-up">
                        <ion-card-title>{{ smallTitle }}</ion-card-title>
                        <ion-card-subtitle>Freigabe bearbeiten</ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <driver-my-profile-document-agency
                            [document]="document"
                            [agencies]="agencies"
                        ></driver-my-profile-document-agency>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CLOSE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Schließen
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}

