import { Pipe, PipeTransform } from '@angular/core';
import { isString } from '../type-guards/is-string';

@Pipe({
    name: 'printNbDate',
    standalone: true
})
export class PrintNbDatePipe implements PipeTransform {

    private date: Date | null = null;

    get year(): string {
        if (this.date instanceof Date) {
            return this.date.getFullYear().toString();
        }

        return "0000";
    }

    get month(): string {
        if (this.date instanceof Date) {
            const month = this.date.getMonth() + 1;
            return month.toString().padStart(2, "0");
        }

        return "00";
    }

    get day(): string {
        if (this.date instanceof Date) {
            return this.date.getDate().toString().padStart(2, "0");
        }

        return "00";
    }

    get time(): string {
        if (this.date instanceof Date) {
            const hourStr = this.date.getHours()
                .toString().padStart(2, "0");

            const minutesStr = this.date.getMinutes()
                .toString().padStart(2, "0");

            return `${hourStr}:${minutesStr}`;
        }

        return '00:00:00';
    }

    transform(date: string | null | undefined): string {
        if (!isString(date)) {
            return '-';
        }
        this.date = new Date(date);
        return `${this.day}.${this.month}.${this.year}`;

    }

}
