@if (document) {
    @for (agency of agencies; track agency.id) {
    <ion-row>
        <ion-col>
            <ion-item>
                    <ion-toggle (ionChange)="changeToggle($event, agency)"
                                [checked]="isOn(agency)">
                        {{ agency.agencyName }}
                    </ion-toggle>
            </ion-item>
        </ion-col>
    </ion-row>
    }
}
