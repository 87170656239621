import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { SelectedFileInterface } from '../../interfaces/frontend/selected_file.interface';
import { SelectFileForUploadComponent } from '../select-file-for-upload/select-file-for-upload.component';
import { MatIcon } from '@angular/material/icon';
import { IconDirective } from '../../directives/icon/icon.directive';
import { IconThemeEnum } from '../../enum/icon-theme.enum';
import { IconEnum } from '../../enum/icon.enum';

@Component({
    selector: 'shared-upload-image-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        SelectFileForUploadComponent
    ],
    templateUrl: './upload-image-modal.component.html',
    styleUrl: './upload-image-modal.component.scss',
})
export class UploadImageModalComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    selected_file: SelectedFileInterface | null = null;

    constructor(
        private modalController: ModalController,
    ) {}

    get inputLabel() {
        return `Bitte wählen Sie ein Bild aus.`;
    }

    get configSelectFileComponent() {
        return {
            inputLabel: this.inputLabel,
            MIME_types_accepted: "image/*"
        };
    }

    async setSelectedFile(selected_file: SelectedFileInterface | null) {
        this.selected_file = selected_file;
        await this.modalController.dismiss({
            saved: true,
            selectedFile: this.selected_file,
        });
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false,
            selectedFile: null,
        });
    }

}
