
import { agencyDriverSearch } from '../../search/agency-driver-list.config';
import { AgencyMemberInterface, PageAction, PageActionService, PageFilterAction, SearchMenuInterface } from '@nf-workforce/shared';
import { Component, OnInit, WritableSignal, signal, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService } from 'nb-form';
import {
    BooleanToStringPipe,
    FullnamePipe,
    LoadingService,
    PrintNbDateTimePipe,
    SearchQueryInterface,
    ToastService
} from '@nf-workforce/shared';
import { Router } from '@angular/router';
import { AgencyMemberComponent } from '../agency-member/agency-member.component';
import { EditAgencyMemberComponent } from '../edit-agency-member/edit-agency-member.component';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { TableDirective, TableHeadDirective, TableCellDirective, TableCellLabelDirective, TableFooterDirective, TableRowDirective, TableKickerDirective } from '@nf-workforce/ui';

@Component({
    selector: 'agency-my-agency-accounts',
    standalone: true,
    imports: [
        AgencyMemberComponent,
        BooleanToStringPipe,
        CommonModule,
        EditAgencyMemberComponent,
        FullnamePipe,
        IonicModule,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableKickerDirective,
        TableRowDirective,
        FullnamePipe,
        BooleanToStringPipe,
        TableKickerDirective,
        PrintNbDateTimePipe
    ],
    templateUrl: './my-agency-accounts.component.html',
    styleUrl: './my-agency-accounts.component.scss',
    providers: [],
})
export class MyAgencyAccountsComponent implements OnInit {

    private limit = 25;
    private nextPage = 1;
    private searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };

    accounts: WritableSignal<AgencyMemberInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);

    constructor(
        @Inject(agencyDriverSearch) public searchConfig: SearchMenuInterface,
        private agencyMemberService: MyAgencyMemberService,
        private alertService: AlertsService,
        private loadingService: LoadingService,
        private pageActionService: PageActionService,
        private router: Router,
        private toastService: ToastService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.accounts.set(await this.loadAccounts());
        this.pageActionService.addAction(PageAction.route(
            'Neuer Account',
            '/my-agency/add-member',
            'agency_member_new'
        ));
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) }
        ));
    }

    private async loadAccounts() {
        const agencyMemberResponse = await this.agencyMemberService.getUserList(this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.log("Fehler bei der Anfrage", e);
            });

        if (agencyMemberResponse) {
            this.nextPage = agencyMemberResponse.page + 1;
            const results = agencyMemberResponse.data.filter((account) => {
                return (account.driver === null);
            });

            if (this.nextPage <= agencyMemberResponse.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return results;
        }

        return [];
    }

    private async reload() {
        this.nextPage = 1;
        this.accounts.set(await this.loadAccounts());
    }

    /**
     * Laden weiterer Ergebnisse
     */
    async clickLoadNextPage() {
        const members = await this.loadAccounts();
        const currentAccounts = this.accounts();
        this.accounts.set(currentAccounts.concat(members));
    }

    async clickSendPassword(idAccount: number) {
        const msg = `Möchten Sie dem Benutzer wirklich ein neues Passwort zusenden?`;
        const confirmResult = await this.confirm(msg);

        if (!confirmResult) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyMemberService.sendNewPassword(idAccount)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler beim versenden des Passwortes:", e);
                await this.toastService.presentToast("Fehler beim versenden des Passwortes. Bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Dem Benutzer wird in Kürze ein neues Passwort zugesendet.");
            await this.reload();
        }
    }

    async clickSendWelcome(idAccount: number) {
        const msg = `Möchten Sie dem Benutzer eine neue Willkommens-Mail zusenden?`;
        const confirmResult = await this.confirm(msg);

        if (!confirmResult) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyMemberService.sendWelcome(idAccount)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler beim versenden der Willkommens-Mail:", e);
                await this.toastService.presentToast("Fehler beim versenden der Willkommens-Mail. Bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Dem Benutzer wird in Kürze die E-Mail zugesandt.");
            await this.reload();
        }
    }

    /**
     * Öffnet einen Confirmation-Dialog mit dem übergebenen Text
     *
     * @param text
     * @returns
     */
    private async confirm(text: string): Promise<boolean> {
        return await this.alertService.confirm({
            text: text,
            textOk: 'Ja',
            textCancel: 'Nein'
        });
    }

    /**
     * ClickHandler zum verbieten des Logins
     *
     * @param idAccount
     * @returns
     */
    async clickDisableLogin(idAccount: number) {
        const msg = `Möchten Sie dem Benutzer den Login verbieten?`;
        const confirmResult = await this.confirm(msg);

        if (!confirmResult) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyMemberService.disableLogin(idAccount)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler beim verbieten des Logins:", e);
                await this.toastService.presentToast("Der Login des Benutzers konnte nicht deaktiviert werden. Bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Der Login des Benutzers wurde erfolgreich deaktiviert.");
            await this.reload();
        }
    }

    /**
     * ClickHandler zum wieder erlauben des Logins
     *
     * @param idAccount
     * @returns
     */
    async clickEnableLogin(idAccount: number) {
        const msg = `Möchten Sie dem Benutzer den Login erlauben?`;
        const confirmResult = await this.confirm(msg);

        if (!confirmResult) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyMemberService.enableLogin(idAccount)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler beim erlauben des Logins:", e);
                await this.toastService.presentToast("Der Login des Benutzers konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Der Login des Benutzers wurde erfolgreich aktiviert.");
            await this.reload();
        }
    }

    /**
     * Clickhandler zum Löschen des Benutzers mit der übergebenen Id
     *
     * @param idAccount
     * @returns
     */
    async clickDelete(idAccount: number) {
        const msg = `Möchten Sie den Benutzer wirklich löschen?`;
        const confirmResult = await this.confirm(msg);

        if (!confirmResult) {
            return;
        }

        await this.loadingService.start();
        const result = await this.agencyMemberService.deleteAgencyAccount(idAccount)
        .catch(async (e: HttpErrorResponse) => {
            console.debug("Fehler beim erlauben des Logins:", e);
            await this.toastService.presentToast("Der Login des Benutzers konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut.");
            return null;
        });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Der Login des Benutzers wurde erfolgreich aktiviert.");
            await this.reload();
        }
    }

    /**
     * Weiterleitung auf die Seite "Mein Account"
     */
    async clickGoToMyAccount() {
        await this.router.navigate(['/account/my-account']);
    }

    /**
     * Navigation zu der Einzelansicht des übergebenen Agenturmitglieds
     *
     * @param agencyMember
     */
    async clickGoToAccount(agencyMember: AgencyMemberInterface) {
        const route = `/my-agency/accounts/${agencyMember.id}`;
        await this.router.navigate([route]);
    }

    async searchChanged($event: SearchQueryInterface) {
        this.nextPage = 1;
        this.searchQuery = $event;
        this.accounts.set(await this.loadAccounts());
    }
}
