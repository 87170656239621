import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { DriverProfileInterface, IconDirective, IconEnum, IconThemeEnum, LoadingService, ToastService } from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../../service/driver-profile.service';
import { DriverFormStammdatenService } from '../../../../../service/driver-form-stammdaten.service';
import { AlertsService } from 'nb-form';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'driver-my-profile-abrechnung-form-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        AsyncPipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './abrechnung-form.component.html',
    providers:[DriverFormStammdatenService]
})
export class DriverAbrechnungFormComponent implements OnInit {
    form!: FormGroup;
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    profile: DriverProfileInterface | null = null;

    constructor(
        private alertService: AlertsService,
        private formHelper: DriverFormStammdatenService,
        private modalController: ModalController,
        private toastService: ToastService,
        private driverProfileService: DriverProfileService,
        private loadingService: LoadingService,
    ) {}

    ngOnInit() {
        if (this.profile) {
            this.formHelper.setDriverProfile(this.profile);
            const opt = this.formHelper.configAbrechnung;
            this.form = new FormGroup(opt);
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    async clickSave() {
        if (this.form.invalid) {
            await this.toastService.presentToast("Bitte prüfen Sie Ihre Eingaben!");
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: 'Wollen Sie jetzt speichern',
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const payload = this.formHelper.getPayloadForAbrechnung(this.form);
            const result = await this.driverProfileService.patchDriverProfile(payload)
                .catch(async (e: HttpErrorResponse) => {
                    console.debug("Fehler beim Speichern: ", e);
                    await this.toastService.presentToast("Fehler beim Speichern. Bitte versuchen Sei es später erneut.");
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                await this.modalController.dismiss({
                    saved: true
                });
            }
        }
    }
}
