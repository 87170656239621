import { AccountService } from '@nf-workforce/account';
import { APP_MENU } from './const/app-menu.const';
import { AuthService, IconDirective, IconEnum, IconThemeEnum, NavigationComponent, NavigationLinkComponent, NavigationNodeInterface, PageAction, PageActionInterface, PageActionsComponent, PageActionService, PageFilterAction, registerIonIcons, SearchMenuComponent, TitleService, ToastService, } from '@nf-workforce/shared';
import { Component, HostListener, Inject, OnDestroy, OnInit, signal, } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { EventType, Router, RouterModule, } from '@angular/router';
import { initIcons } from '@nf-workforce/ui';
import { IonicModule } from '@ionic/angular';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIcon, MatIconRegistry, } from '@angular/material/icon';
import { MenuController } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
    imports: [
        IconDirective,
        IonicModule,
        MatIcon,
        NavigationComponent,
        NavigationLinkComponent,
        PageActionsComponent,
        RouterModule,
        SearchMenuComponent,
    ],
    selector: 'app-root',
    styleUrl: './app.component.scss',
    templateUrl: './app.component.html',
    providers: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    ],
})
export class AppComponent implements OnInit, OnDestroy {

    Icon = IconEnum;
    IconTheme = IconThemeEnum;

    backAction = signal<PageActionInterface | null>(null);
    filterAction = signal<PageFilterAction | null>(null);
    pageActions = signal<PageActionInterface[]>([]);
    pageTitle = signal<string>('');
    showNav = true;
    splitPaneVisible = false;

    menu: NavigationNodeInterface[] = APP_MENU.concat(
        PageAction.click('Logout', () => {
            this.accountService.sendLogout()
                .then(async () => {
                    this.authService.clearSession();
                    await this.router.navigate(['/login']);
                })
                .catch(err => this.toastService.presentToast(err));
        })
    );

    get isFiltered(): boolean {
        return this.filterAction() !== null ? this.pageActionService.isFiltered() : false;
    }

    private pageActionSub: Subscription | null = null;
    private routerEventsSub: Subscription | null = null;
    private titleSub: Subscription | null = null;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private accountService: AccountService,
        private authService: AuthService,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private menuController: MenuController,
        private pageActionService: PageActionService,
        private router: Router,
        private titleService: TitleService,
        private toastService: ToastService,
    ) { }

    ngOnInit(): void {
        this.document.documentElement.lang = 'de';
        initIcons(this.domSanitizer, this.matIconRegistry);
        registerIonIcons();
        this.initSubscriptions();
        this.initRouterSubscription();
    }

    ngOnDestroy(): void {
        this.pageActionSub?.unsubscribe();
        this.routerEventsSub?.unsubscribe();
        this.titleSub?.unsubscribe();
    }

    closeNavigationMenu() {
        this.menuController.get('navMenu').then(menu => menu?.close());
    }

    closeSearchMenu() {
        this.menuController.get('searchMenu').then(menu => menu?.close());
    }

    @HostListener('close_navigation_menu_event', ['$event'])
    onCloseNavigationMenuEvent() {
        // close the navigation menu, if it is not always displayed anyway
        if (!this.splitPaneVisible) {
            this.closeNavigationMenu();
        }
    }

    openSearchMenu() {
        this.menuController.open('searchMenu').then(opened => {
            if (!opened) {
                throw new Error('Error 0c986a6c-0ff7-4542-880c-25bac6f6561d');
            }
        })
    }

    watchSplitPaneVisibility(event: CustomEvent) {
        if (typeof event.detail['visible'] === 'boolean') {
            this.splitPaneVisible = event.detail['visible'];
        }
    }

    private initSubscriptions(): void {
        this.pageActionSub = this.pageActionService.updates().subscribe(() => {
            this.backAction.set(this.pageActionService.getBackAction());
            this.filterAction.set(this.pageActionService.getFilterAction());
            this.pageActions.set(this.pageActionService.getPageActions());
        });
        this.titleSub = this.titleService.titleEmitter.subscribe(pageTitle => {
            this.pageTitle.set(pageTitle);
        });
    }

    private initRouterSubscription(): void {
        this.routerEventsSub = this.router.events.subscribe(routingEvent => {
            if (routingEvent.type === EventType.ActivationEnd) {
                /**
                 * `ActivationEnd` are fired multiple times during one
                 * navigation change. We have to skip them, if they don't have
                 * not yet established the page title.
                 */
                if (
                    !routingEvent.snapshot.routeConfig
                    || typeof routingEvent.snapshot.routeConfig['title'] === 'undefined'
                ) {
                    return; // skip until we have a useful event object
                }

                // Clear the page action service before receiving a new config.
                this.pageActionService.clear();
                this.backAction.set(null);
                this.filterAction.set(null);
                this.pageActions.set([]);

                /**
                 * Set the page title from routing. Can be overwritten by a
                 * component via the `TitleService`.
                 */
                this.pageTitle.set(routingEvent.snapshot.routeConfig['title'] as string);

                /**
                 * Toggle the navigation by an explicit setting or defaulting
                 * to showing the navigation.
                 */
                if (typeof routingEvent.snapshot.data['hideNav'] === 'boolean') {
                    this.showNav = !routingEvent.snapshot.data['hideNav'];
                    return;
                }
                this.showNav = true;
            }
        });
    }
}
