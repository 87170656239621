@if(showPlace() && driverDocument && eavDocument) {
<ion-card class="show-on-hover-trigger">
    <ion-card-header>
        <ion-card-title>{{eavDocument.name}}</ion-card-title>
    </ion-card-header>
    <ion-card-content>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Dokument</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDelete()">
                    <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
        </div>
        @if (driverDocument.hasBackSide) {
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">Vorderseite</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(false)">
                    <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
        </div>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">Rückseite</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(true)">
                    <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
        </div>
        } @else {
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">Datei</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(false)">
                    <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
        </div>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">&nbsp;</span>
                <span class="driver-skill__edit-button show-on-hover" role="button">
                    &nbsp;
                </span>
            </label>
        </div>
        }
        <br />
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Gültigkeit</span>
            </label>
            <span class="driver-skill__skill">
                Ausgestellt am:
                @if (hasEavCreationDate()) {
                {{driverDocument.createdDate | printNbDate }}
                } @else {
                -
                }
            </span>
            <br />
            <span class="driver-skill__skill">
                Gültig bis:
                @if (hasEavExpiredDate()) {
                {{driverDocument.expireDate | printNbDate }}
                } @else {
                -
                }
            </span>
        </div>
        <br />
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">{{getLabelDescription()}}</span>
            </label>
            <span class="driver-skill__skill">
                @if(driverDocument.notice !== null && driverDocument.notice !== '') {
                <ion-text class="whitespace-pre-wrap">
                    {{driverDocument.notice}}
                </ion-text>
                } @else {
                -
                }
            </span>
        </div>
        <br />
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Freigaben</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditAgencyAccess()">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
        </div>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">Leserechte</span>
            </label>
            <span class="driver-skill__skill">
                @if(accessAgencies.length > 0) {
                @for (agency of accessAgencies; track agency.idAgency) {
                &nbsp;&ndash;&nbsp;{{agency.name}} &nbsp;|&nbsp;
                Akzeptiert: {{agency.isValid | booleanToString }}
                @if(!$last) {<br />}
                }
                } @else {
                -
                }
            </span>
        </div>
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text" style="font-weight: normal;">Keine Rechte</span>
            </label>
            <span class="driver-skill__skill">
                @if(noAccessAgencies.length > 0) {
                @for (agency of noAccessAgencies; track agency.idAgency) {
                &nbsp;&ndash;&nbsp;{{agency.name}}
                @if(!$last) {<br />}
                }
                } @else {
                -
                }
            </span>
        </div>
        @if (isDriverLicence()) {
        <div class="driver-skill">
            <label class="driver-skill__label">
                <span class="driver-skill__label-text">Klassen</span>
                <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickEditFahrerlaubnis()">
                    <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                </span>
            </label>
            <span class="driver-skill__skill">{{getDriverFahrerlaubnisAsStrings()}}</span>
        </div>
        <br />
        }
    </ion-card-content>
</ion-card>
}
