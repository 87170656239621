import { Injectable } from '@angular/core';
import {
    ENV,
    HttpService,
    PaginatorResponse,
    QueryParamsInterface,
    SearchQueryInterface
} from '@nf-workforce/shared';
import { MessageInterface } from '../interfaces/message.interface';

@Injectable()
export class DriverMessageService {
    constructor(
        private httpService: HttpService
    ) {}

    /**
     * Gibt die Liste der Nachrichten für die Jobliste mit der übergebenen Id zurück
     * 
     * @param jobId 
     * @param page 
     * @param limit 
     * @param searchQuery 
     * @returns 
     */
    getMyMessages(
        page: number,
        limit: number,
        searchQuery: SearchQueryInterface
    ) {
        const query = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        } as QueryParamsInterface;
        const url = `${ENV.apiUrl}/joblist/driver/communication`;
        return this.httpService
            .getBypassCache<PaginatorResponse<MessageInterface>>(url, query);
    }

}
