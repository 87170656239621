import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'shared-badge',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule
    ],
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss',
})
export class BadgeComponent {

    @Input()
    title!: string;

    @Input()
    count!: number;

    @Input()
    disabled!: boolean;

    @Output()
    clicked = new EventEmitter();

    clickButton() {
        this.clicked.emit();
    }

}
