<ion-row class="form">
    <ion-col>
        @if (form) {
            <nb-form #nbFormInstance [form]="form" appearance="outline" [delay]="0" (changeData)="formChanged()"></nb-form>
        }
        @for (error of errors(); track error) {
            <ion-row>
                <ion-col>
                    <ion-text color="danger">{{ error }}</ion-text>
                </ion-col>
            </ion-row>
        }
        <ion-row class="buttons">
            <ion-button expand="full" [disabled]="!formIsValid" (click)="clickChangeUserName()">
               Benutzername Ändern
            </ion-button>
        </ion-row>
    </ion-col>
</ion-row>
