
import { addClass, removeClass } from '../../function/toggle-class.function';
import { AfterViewInit, Directive, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
    selector: '[uiTable]',
    standalone: true,
})
export class TableDirective implements AfterViewInit, OnDestroy {

    private static SELECT_FIRST_ROW = '.table-row:not(.table-head)';
    private static SELECT_HEADER_ROW = '.table-row.table-head';
    private static SELECT_VISIBLE_CELLS = '.table-cell:not(.hide-when-expanded)';

    @Input()
    changeEmitter: Observable<unknown> | undefined;

    @HostBinding('class')
    elementClass = 'table';

    private changeSub: Subscription | undefined;
    private firstRow: Element | null = null;
    private firstRowCells: NodeListOf<Element> | undefined;
    private headerCells: NodeListOf<Element> | undefined;
    private headerRow: Element | null = null;
    private scrollObserver: IntersectionObserver;
    private tableElement: Element;

    constructor(private elementRef: ElementRef) {
        this.tableElement = this.elementRef.nativeElement;
        this.scrollObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    addClass(this.headerRow, 'table-head--fixed');
                    this.syncColumnWidths();
                } else {
                    removeClass(this.headerRow, 'table-head--fixed');
                }
            });
        });
    }

    ngAfterViewInit(): void {
        this.initTable();
        // when table data changes ...
        this.changeSub = this.changeEmitter?.subscribe(() => {
            this.unobserve(); // ... immediately stop observing table scrolling
            // ... give table some time to re-render
            setTimeout(() => {
                this.initTable(); // ... now we can re-init
            }, 100);
        });
    }

    ngOnDestroy(): void {
        this.unobserve();
        this.changeSub?.unsubscribe();
    }

    private initTable() {
        if (!this.tableElement.classList.contains('fixed-header')) {
            return;
        }
        this.firstRow = this.tableElement.querySelector(TableDirective.SELECT_FIRST_ROW);
        this.firstRowCells = this.firstRow?.querySelectorAll(TableDirective.SELECT_VISIBLE_CELLS);
        this.headerRow = this.tableElement.querySelector(TableDirective.SELECT_HEADER_ROW);
        this.headerCells = this.headerRow?.querySelectorAll(TableDirective.SELECT_VISIBLE_CELLS);
        if (!this.firstRow) {
            console.warn('Warning eaa6ff35-ba54-4327-9a0e-357f195462f0');
            return;
        }
        if (!this.firstRowCells) {
            console.warn('Warning d92d6d22-863f-40c3-b8d7-4cc2b5561d55');
            return;
        }
        if (!this.headerRow) {
            console.warn('Warning 1279fbd6-b70a-4784-b21e-0b0bdc7b85ac');
            return;
        }
        if (this.firstRow) {
            this.scrollObserver?.observe(this.firstRow);
        }
    }

    private syncColumnWidths() {
        if (!this.firstRowCells) {
            console.warn('Warning 2a913e50-42dd-4e13-9f2b-1a78e772b9f5');
            return;
        }
        if (!this.headerCells) {
            console.warn('Warning f1f9dcb0-7b59-4c7f-ba6a-1a453ea0396d');
            return;
        }
        if (this.headerCells.length !== this.firstRowCells.length) {
            console.warn('Warning f6cd8251-7e77-4ec0-a081-04ac80f37b3b');
            return;
        }
        for (let i = 0; i < this.headerCells.length; i++) {
            this.headerCells[i].setAttribute('style',
                `max-width: ${this.firstRowCells[i].scrollWidth}px !important;
                width: ${this.firstRowCells[i].scrollWidth}px !important;`
            );
        }
    }

    private unobserve() {
        if (this.firstRow) {
            this.scrollObserver?.unobserve(this.firstRow);
        }
    }
}
