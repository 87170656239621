import { Component, Inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ErrorService, LoadingService, ToastService, createEmptyForm, formatFormDate, isString } from '@nf-workforce/shared';
import { Router } from '@angular/router';
import { JobListService } from '../../services/job-list.service';
import { JOB_LIST_SCHEMA } from '../../schema/job-list.schema';
import { Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { AddJobListInterface } from '../../interface/joblist/add-job-list.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'joblist-add-job-list',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent
    ],
    templateUrl: './add-job-list.component.html',
    styleUrl: './add-job-list.component.scss',
    providers: [
        JobListService
    ]
})
export class AddJobListComponent {

    jobListForm: Form;

    @ViewChild(NbFormComponent)
    jobListInstance!: NbFormComponent;

    private get payload(): AddJobListInterface {
        const showDate = formatFormDate(this.jobListForm.data['showDate'] as Date, true);
        const closeDate = formatFormDate(this.jobListForm.data['closeDate'] as Date, true);
        const startDate = formatFormDate(this.jobListForm.data['startDate'] as Date, false);
        const endDate = formatFormDate(this.jobListForm.data['endDate'] as Date, false);

        const timezone = (isString(this.jobListForm.data['timezone']))
            ? this.jobListForm.data['timezone']
            : null;

        let deadline = null;
        if (this.jobListForm.data['deadline'] instanceof Date) {
            deadline = formatFormDate(this.jobListForm.data['deadline'], true);
        }
        
        return {
            name: this.jobListForm.data['name'] as string,
            description: this.jobListForm.data['description'] as string,
            showDate: showDate,
            closeDate: closeDate,
            startDate: startDate,
            endDate: endDate,
            moderated: this.jobListForm.data['moderated'] as boolean,
            freePlaces: this.jobListForm.data['freePlaces'] as number,
            deadline: deadline,
            timezone: timezone
        };
    }

    constructor(
        private toastService: ToastService,
        private loadingService: LoadingService,
        private router: Router,
        private jobListService: JobListService,
        @Inject(JOB_LIST_SCHEMA) private jobListSchema: FormPage,
        private errorService: ErrorService
    ) {
        this.jobListForm = createEmptyForm(1, cleanInjectedValue(this.jobListSchema));
    }

    clickGoBack() {
        this.router.navigate(['/jobs'])
    }

    async clickSaveNewJobList() {
        if (!this.formIsValid()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben!");
            return;
        }

        const result = await this.jobListService.addNewJob(this.payload)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage: ", e);
                return null;
         });
        
        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert");
            this.router.navigate(['/jobs'])
        } else {
            this.errorService.highlightFormErrors(this.jobListInstance, this.jobListSchema);
        }

    }

    private formIsValid(): boolean {
        if (this.jobListInstance) {
            return this.jobListInstance.getValidator().validate();
        }

        return false;
    }

}
