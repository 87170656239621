import { Injectable } from '@angular/core';
import { AgencyMemberInterface, ENV, HttpService, PaginatorResponse, QueryParamsInterface, RoleEnum, SearchQueryInterface } from '@nf-workforce/shared';
import { HttpResponse } from '@angular/common/http';
import { DriverValidationRequest } from '../interface/driver-validation-request.interface';
import { RejectDriverValidationRequest } from '../interface/reject-driver-validation-request.interface';

@Injectable({
    providedIn: 'root'
})
export class MyAgencyMemberService {

    private agencyAdminRoleId = RoleEnum['AGENCY'];
    private agencyFdlRoleId = RoleEnum['AGENCY_FDL'];
    private driverRoleId = RoleEnum['DRIVER'];

    private get userListUrl(): string {
        return `${ENV.apiUrl}/agency-user-list`;
    }

    private getOneUserUrl(idAccount: number): string {
        return `${ENV.apiUrl}/agency-user/${idAccount}`;
    }

    constructor(
        private httpService: HttpService
    ) { }

    async getDriverList(page: number, limit: number, searchQuery: SearchQueryInterface) {
        const queryParams = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit,
            roleId: this.driverRoleId
        } as QueryParamsInterface;

        return this.httpService
            .getBypassCache<PaginatorResponse<AgencyMemberInterface>>(this.userListUrl, queryParams);
    }

    /**
    * Gibt die Liste der Agency-Benutzer zurück
    *
    * @returns
    */
    async getUserList(page: number, limit: number,  searchQuery: SearchQueryInterface): Promise<PaginatorResponse<AgencyMemberInterface>> {
        const queryParams = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit,
            'roleId[]': [this.agencyAdminRoleId, this.agencyFdlRoleId]
        } as QueryParamsInterface;

        return this.httpService
            .getBypassCache<PaginatorResponse<AgencyMemberInterface>>(this.userListUrl, queryParams);
    }

    /**
     * Sendet das Request zum hinzufügen eines neuen Benutzers an das Backend
     *
     * @param payload
     * @returns
     */
    async addNewMember(payload: unknown): Promise<HttpResponse<unknown>> {
        const route = `${ENV.apiUrl}/agency-member`;
        return this.httpService.post(route, payload);
    }

    /**
     * Sendet das Request zum erstellen eines neuen Fahrer-Accounts an das Backend
     *
     * @param payload
     * @returns
     */
    async addNewDriver(payload: unknown): Promise<HttpResponse<unknown>> {
        const route = `${ENV.apiUrl}/agency-driver`;
        return this.httpService.post(route, payload);
    }

    /**
     * Sendet das Request zum zusenden eines neuen Passwortes
     *
     * @param idAccount
     * @returns
     */
    async sendNewPassword(idAccount: number): Promise<HttpResponse<unknown>> {
        const url = `${ENV.apiUrl}/agency-user/${idAccount}/password`;
        return this.httpService.getBypassCache(url);
    }

    /**
     * Sendet das Request zum zusenden einer Willkommensmail an das Backend
     *
     * @param idAccount
     * @returns
     */
    async sendWelcome(idAccount: number): Promise<HttpResponse<unknown>>  {
        const url = `${ENV.apiUrl}/agency-user/${idAccount}/welcome`;
        return this.httpService.getBypassCache(url);
    }

    /**
     * Sendet das Request zum aktivieren des Logins für den übergebenen Benutzer an das Backend
     *
     * @param idAccount
     * @returns
     */
    async enableLogin(idAccount: number): Promise<HttpResponse<unknown>> {
        return this.httpService
            .post(this.getUrlChangeLogin(idAccount), {});
    }

    getProfileImage(idAccount: number): Promise<HttpResponse<string>> {
        return this.httpService
            .get(this.getProfileImageUrl(idAccount), {});
    }

    getProfileImageUrl(idAccount: number): string {
       return `${ENV.apiUrl}/profile/image/${idAccount}`;
    }

    /**
     * Sendet das Request zum deaktivieren des Logins an den Server
     *
     * @param idAccount
     * @returns
     */
    async disableLogin(idAccount: number): Promise<HttpResponse<unknown>> {
        return this.httpService
            .delete(this.getUrlChangeLogin(idAccount));
    }

    /**
     * Sendet das Request zum löschen des Accounts an das BAckend
     *
     * @param idAccount
     * @returns
     */
    async deleteAgencyAccount(idAccount: number): Promise<HttpResponse<unknown>> {
        const url = `${ENV.apiUrl}/agency-user/${idAccount}`
        return this.httpService.delete(url);
    }

    /**
     * Sendet das Request zum suchen eines Benutzers an das Backend
     *
     * @param idAccount
     * @returns
     */
    async getOneUser(idAccount: number): Promise<PaginatorResponse<AgencyMemberInterface>> {
        return this.httpService.get(this.userListUrl, {
            page: 1,
            limit: 1,
            id: idAccount
        });
    }

    async getOneDriver(idAccount: number): Promise<AgencyMemberInterface> {
        const url = this.getOneUserUrl(idAccount);
        return this.httpService.getBypassCache(url);
    }

    /**
     * Gibt die Url zum aktivieren oder deaktivieren der Url zurück
     *
     * @param idAccount
     * @returns
     */
    private getUrlChangeLogin(idAccount: number): string {
        return `${ENV.apiUrl}/agency-user/${idAccount}/login`;
    }

    private getDriverValidationUrl(idDriver: number): string {
        return `${ENV.apiUrl}/agency/driver/${idDriver}/validation`;
    }

    /**
     * Sendet das Request zum akzeptieren (validierung) Des Fahrerprofils an den Server
     *
     * @param idDriver
     * @returns
     */
    async acceptDriverProfile(idDriver: number, payload: DriverValidationRequest) {
        const url = this.getDriverValidationUrl(idDriver);
        return this.httpService.post(url, payload);
    }

    /**
     * Sendet den Request zum ablehnen eines Fahrerprofils an den Server
     *
     * @param idDriver
     * @returns
     */
    async rejectDriverProfile(idDriver: number, payload: RejectDriverValidationRequest) {
        const url = this.getDriverValidationUrl(idDriver);
        return this.httpService.deleteWithBody(url, payload as unknown as {[key: string]: unknown});
    }

}
