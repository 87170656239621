import { Pipe, PipeTransform } from '@angular/core';
import { InternalErrorInterface } from '../interfaces/error/internal-error.interface';

@Pipe({
    name: 'error',
    standalone: true
})
export class ErrorPipe implements PipeTransform {
    transform(value: InternalErrorInterface): string {
        const fieldName = value.field;

        if (value.message.includes(fieldName)) {
            return value.message;
        } else {
            return `${value.field}: ${value.message}`;
        }
  }

}