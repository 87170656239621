import { Component, Input } from '@angular/core';
import { NavigationLinkComponent } from '../navigation-link/navigation-link.component';
import { AuthService } from '../../services/auth.service';
import { NavigationNodeInterface } from '../../interfaces/navigation-node.interface';
import { isAllowedByAcc } from '../../function/is-allowed-by-acc.function';

@Component({
    standalone: true,
    imports: [NavigationLinkComponent],
    selector: 'shared-navigation-item',
    templateUrl: './navigation-item.component.html',
})
export class NavigationItemComponent {

    constructor(private authService: AuthService) { }

    /**
     * Nesting level.
     */
    @Input()
    level!: number;

    /**
     * The navigation menu.
     */
    @Input() menu: NavigationNodeInterface[] = [];

    allowed(navItem: NavigationNodeInterface): boolean {
        return this.unprotected(navItem)
            || isAllowedByAcc(navItem.protectedBy, this.authService.acl);
    }

    private unprotected(navItem: NavigationNodeInterface): boolean {
        return !navItem.protectedBy;
    }
}
