@if(showPlace()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header driver-account-header--masterdata">
                <h2 class="driver-account-header__text">
                    {{ account.firstName }}<br>
                    {{ account.lastName }}
                    <div class="driver-account-header__links">
                        <a href="mailto:{{ account.username }}">{{ account.username }}</a>
                        @if (account.phone) {
                            <br><a href="tel:{{ account.phone }}">{{ account.phone }}</a>
                        }
                    </div>
                </h2>
                <img class="driver-account-header__image"
                     [src]="profileImage"
                     alt="{{account | fullname }}"
                >
            </div>
        </ion-card-header>
        <ion-card-content>
           <ion-row>
               <ion-col>
                   <ion-card>
                       <ion-card-header>
                           <div class="driver-account-header">
                               <h2 class="driver-account-header__text">
                                   <ion-text [color]="IconTheme.DANGER">
                                       Status: {{ driver.status | driverStatusToString }}
                                   </ion-text>
                               </h2>
                               <mat-icon class="driver-account-header__icon"
                                         [icon]="TitleIconEnum.DRIVER_VERIFIED"
                                         [theme]="IconTheme.DANGER"
                               ></mat-icon>
                           </div>
                       </ion-card-header>
                       <ion-card-content>
                           <div class="driver-skill">
                               <label class="driver-skill__label">
                                   <span class="driver-skill__label-text">Login erlaubt</span>
                               </label>
                               <span class="driver-skill__skill">
                                    {{ driver.isActive | booleanToString }}
                                </span>
                           </div>
                           <br>
                           <div class="driver-skill">
                               <label class="driver-skill__label">
                                   <span class="driver-skill__label-text">Fahrer hat sich validiert</span>
                               </label>
                               <span class="driver-skill__skill">
                                    {{ account.isValidated | booleanToString }}
                                </span>
                           </div>
                           <br>
                           <div class="driver-skill">
                               <label class="driver-skill__label">
                                   <span class="driver-skill__label-text">Letzte Passwort-Mail:</span>
                               </label>
                               <span class="driver-skill__skill">
                                     @if (account.lastMail) {
                                         {{ account.lastMail | printNbDateTime }}
                                     } @else {
                                         -
                                     }
                                </span>
                           </div>
                       </ion-card-content>
                   </ion-card>
               </ion-col>
           </ion-row>
        </ion-card-content>
    </ion-card>
}
