import { HttpErrorResponse } from "@angular/common/http";
import { ErrorParserInterface } from "../../interfaces/error/error-parser.interface";
import { SymfonyErrorParser } from "../../classes/symfony-error-parser";
import { ErrorParser } from "../../classes/error-parser";
import { ErrorResponse } from "../../interfaces/error/error-response.interface";
import { SymfonyErrorResponseInterface } from "../../interfaces/error/symfony-error-response.interface";

/**
 * Factory-Funktion zum erstellen des korrekten Fehlerparsers
 * 
 * @param errorResponse 
 * @returns 
 */
export function createErrorParser(errorResponse: HttpErrorResponse): ErrorParserInterface {
    const isSymfonyError = (errorResponse.status === 422);

    if (isSymfonyError) {
        return new SymfonyErrorParser(errorResponse.error as SymfonyErrorResponseInterface);
    }

    return new ErrorParser(errorResponse.error as ErrorResponse);
}