import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormElementOption } from 'nb-form';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { debounceTime } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { isString } from '../../type-guards/is-string';

@Component({
    selector: 'shared-autocomplete',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule
    ],
    templateUrl: './autocomplete.component.html',
    styleUrl: './autocomplete.component.scss',
})
export class AutocompleteComponent {

    @Input()
    label!: string;

    @Input()
    options: FormElementOption[] = [];

    @Input()
    deboounceInterval = 500;

    @Output()
    searchTermChanged: EventEmitter<string> = new EventEmitter();

    @Output()
    valueSelected: EventEmitter<FormElementOption | null> = new EventEmitter();

    formControl = new FormControl();

    selectedOption: FormElementOption | null = null;

    constructor() {
        this.formControl.valueChanges
            .pipe(
                debounceTime(this.deboounceInterval)
            ).subscribe((value) => {
                if (value !== null && isString(value)) {
                    this.searchTermChanged.emit(value);
                }
            });
    }

    /**
     * Handler zum bestätigen der ausgewählten Option
     */
    optionSelected() {
        const selection = this.options.find((opt) => {
            return (opt.value === this.formControl.value);
        });

        if (selection !== undefined) {
            this.selectedOption = selection;
            this.valueSelected.emit(this.selectedOption);
        }
    }

    /**
     * Zurücksetzen des Autocompletes
     */
    resetAutocomplete() {
        this.formControl.setValue(null);
        this.selectedOption = null;
        this.options = [];
        this.valueSelected.emit(null);
    }

}
