import { Injectable } from '@angular/core';
import { AgencyInterface, AgencyMemberInterface, ENV, HttpService, UploadImageRequest } from '@nf-workforce/shared';
import { HttpResponse } from '@angular/common/http';
import { UpdateAgencyRequestInterface } from '../interface/update-agency-request.interface';


@Injectable({
    providedIn: 'root'
})
export class MyAgencyService {
    constructor(
        private httpService: HttpService
    ) {}

    get agencyListRoute(): string {
        return `${ENV.apiUrl}/agency-list`;
    }

    async getAgency(): Promise<AgencyInterface> {
        const url = `${ENV.apiUrl}/agency/index`;
        return this.httpService.getBypassCache(url);
    }

    async getCachedAgency(): Promise<AgencyInterface> {
        const url = `${ENV.apiUrl}/agency/index`;
        return this.httpService.get(url);
    }

    async uploadLogo(payload: UploadImageRequest) {
        const updateRoute = `${ENV.apiUrl}/agency-logo`;
        return this.httpService.post<HttpResponse<unknown>>(updateRoute, payload);
    }

    async deleteLogo() {
        const route = `${ENV.apiUrl}/agency-logo`;
        return this.httpService.delete<HttpResponse<unknown>>(route);
    }

    /**
     * Gibt einen AgenturNutzer anhand der Id zurück
     *
     *
     * @param idUser
     * @returns
     */
    async getAgencyUser(idUser: number): Promise<HttpResponse<AgencyMemberInterface>> {
        const agencyUserRoute = `${ENV.apiUrl}/agency-user/${idUser}`;
        return await this.httpService.get<HttpResponse<AgencyMemberInterface>>(agencyUserRoute);
    }

    /**
     * Aktualisierung der Daten zureigenen Agentur
     *
     * @param payload
     * @returns
     */
    updateAgency(payload: UpdateAgencyRequestInterface) {
        const url = `${ENV.apiUrl}/agency/index`;
        return this.httpService.patch(url, payload);
    }

}
