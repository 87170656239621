@if (form && groupId) {
    <form [formGroup]="form">
        <ion-row>
            <ion-col>
                <mat-form-field appearance="outline">
                    <mat-label>
                        Name
                        <ui-form-error [showError]="showError(form, 'name')">
                            Name darf nicht leer sein.
                        </ui-form-error>
                    </mat-label>
                    <input type="text" matInput formControlName="name" autocomplete="off">
                </mat-form-field>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-toolbar>
                    <ion-buttons slot="start">
                        <ion-button
                            fill="outline"
                            [color]="IconTheme.SECONDARY"
                            (click)="goBack()">
                            <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT"></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;Abbrechen</ion-text>
                        </ion-button>
                    </ion-buttons>
                    <ion-buttons slot="end">
                        <ion-button
                            fill="outline"
                            expand="block"
                            (click)="saveGroup()"
                            [disabled]="form.invalid"
                            [color]="IconTheme.SUCCESS"
                        ><mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT"></mat-icon>
                            <ion-text class="ion-hide-md-down">&nbsp;Speichern</ion-text>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-col>
        </ion-row>
    </form>
}
