import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DocumentEavInterface,
    DriverDataInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
} from '@nf-workforce/shared';
import { DriverDocumentHelperService } from '../../../../../service/driver-document-helper.service';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'driver-document-upload-wizard-select-eav-document',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        MatIcon,
        ReactiveFormsModule,
        IconDirective
    ],
    templateUrl: './select-eav-document.component.html',
    styleUrl: './select-eav-document.component.scss',
    providers: [
        DriverDocumentHelperService
    ]
})
export class SelectEavDocumentComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
    @Input()
    driver: DriverDataInterface | null = null;

    @Output()
    currentEavId: EventEmitter<number> = new EventEmitter();


    constructor(
        private documentHelper: DriverDocumentHelperService,
    ) {}

    async ngOnInit() {
        if (this.driver) {
            this.documentHelper.setDriver(this.driver);
            this.documentHelper.setMissingDocuments();
        }
    }

    get eavDocuments() {
        return this.documentHelper.eavDocuments;
    }

    isSingleEavDocument(eavId: number): boolean {
        return this.documentHelper.isSingleEavDocument(eavId);
    }

    hasDocument(eav: DocumentEavInterface): boolean {
        return this.documentHelper.hasDocument(eav);
    }

    onClickEavDoc(id: number) {
        this.currentEavId.emit(id);
    }

}
