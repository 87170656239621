import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DocumentEavInterface,
    DriverAgencyInterface,
    DriverDataInterface,
    DriverDocumentInterface,
    EavDocumentId,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    SelectFileForUploadComponent,
} from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { DriverDocumentHelperService } from '../../../../service/driver-document-helper.service';
import { SelectEavDocumentComponent } from './wizard-1-select-eav-document/select-eav-document.component';
import {  SelectDocumentHasTwoFilesComponent } from './wizard-2-select-has-to-files/select-document-has-two-files.component';
import { UploadFilesComponent } from './wizard-3-upload-files/upload-files.component';
import { Subscription } from 'rxjs';
import { ProfileFahrerlaubnisComponent } from '../fahrerlaubnis/profile-fahrerlaubnis.component';
import { ProfileDocumentAgencyComponent } from '../document-agency/profile-document-agency.component';


@Component({
    selector: 'driver-my-profile-document-upload-wizard-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ReactiveFormsModule,
        SelectFileForUploadComponent,
        SelectEavDocumentComponent,
        SelectDocumentHasTwoFilesComponent,
        UploadFilesComponent,
        ProfileFahrerlaubnisComponent,
        ProfileDocumentAgencyComponent
    ],
    templateUrl: './profile-document-upload-wizard-modal.component.html',
    styleUrl: './profile-document-upload-wizard-modal.component.scss',
    providers: [
        DriverDocumentHelperService
    ]
})
export class ProfileDocumentUploadWizardModalComponent implements OnInit, OnDestroy {
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    showSelectUpload: WritableSignal<boolean> = signal(false);

    title = 'Dokument hochladen';
    wizardStep = 1;

    currentEav: DocumentEavInterface | null = null;
    hasJustOneFile: boolean | null = null;
    finishedUploadDocument: Subscription;
    driverAgencies: DriverAgencyInterface[] | [] = [];
    document: DriverDocumentInterface | null | undefined = null;

    @Input()
    driver: DriverDataInterface | null = null;
    @Input()
    currentEavId: number | null = null;

    constructor(
        private documentHelper: DriverDocumentHelperService,
        private driverProfileService: DriverProfileService,
        private modalController: ModalController,
    ) {
        this.finishedUploadDocument = this.driverProfileService.uploadCompleted.subscribe(
            async (success) => {
                console.log(success);
                if (!success) {
                    this.wizardStep = 500;
                    return;
                }
                await this.onUploadCompleted();
            }
        ) as Subscription;

    }

    async ngOnInit() {
        if (this.driver) {
            this.driverAgencies = this.driver.driverUserDto.driverAgencies;
            this.documentHelper.setDriver(this.driver);
            this.documentHelper.setMissingDocuments();
            this.showSelectUpload.set(false);
        }
    }

    get eavDocuments() {
        return this.documentHelper.eavDocuments;
    }

    /**
     * Step2, wenn Vorderseite und Rückseite benötigt werden
     * @param hasJustOneFile
     */
    setHasJustOneFile(hasJustOneFile: boolean) {
        this.hasJustOneFile = hasJustOneFile;
        this.wizardStep = 3;
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    async clickSuccess() {
        await this.modalController.dismiss({
            saved: true
        });
    }

    clickUpload() {
        if (this.wizardStep === 3) {
            this.driverProfileService.doUploadDocument.emit(true);
        }
    }

    wizardBack() {
        const previousStep = this.wizardStep - 1;
        if (previousStep > 2 ) {
            this.wizardStep = previousStep;
        } else if (previousStep === 2) {
            if (this.currentEav && this.currentEav.hasBackSide) {
                this.wizardStep = previousStep;
            } else {
                this.wizardStep = 1;
            }
        } else {
            this.wizardStep = 1;
        }
    }

    onClickEavDoc(id: number) {
        this.currentEavId = id;
        this.setCurrentEav();
        if (this.currentEav) {
            this.title = this.currentEav?.name + ' hochladen';
            if (this.currentEav.hasBackSide) {
                this.wizardStep = 2;
            } else {
                this.hasJustOneFile = true;
                this.wizardStep = 3;
            }
        }
    }

    async onUploadCompleted() {
        if (this.currentEav) {
            if (this.currentEav.id === EavDocumentId.DRIVER_LICENCE_ID) {
                this.wizardStep = 4;
                return;
            }
            if (this.document) {
                this.wizardStep = 5;
                return;
            }
        }
        await this.modalController.dismiss({
            saved: true
        });
    }

    ngOnDestroy() {
        if (this.finishedUploadDocument) {
            this.finishedUploadDocument.unsubscribe();
        }
    }

    setCurrentEav() {
        if (this.currentEavId) {
            const currentEav = this.eavDocuments.find((doc) => {
                return doc.id === this.currentEavId;
            });
            if (currentEav) {
                this.currentEav = currentEav;
            } else {
                throw new Error('EavDocument nicht gefunden!');
            }
        }
    }

    setDocument(document: DriverDocumentInterface) {
        this.document = document;
    }



}
