import { Injectable } from '@angular/core';
import { ENV, HttpService, JobDriverInterface, LoadingService, PaginatorResponse, QueryParamsInterface, SearchQueryInterface } from '@nf-workforce/shared';
import { AddDriverToJobInterface } from '../interface/workforce/add-driver-to-job.interface';
import { RemoveDriverRequestInterface } from '../interface/workforce/remove-driver-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DriverListService {

    nextPage = 1;
    limit = 25;

    private hasMoreResults:BehaviorSubject<boolean> = new BehaviorSubject(false);
    private currentDrivers: BehaviorSubject<JobDriverInterface[]> = new BehaviorSubject([] as JobDriverInterface[]);
    private currentListType:BehaviorSubject<number> = new BehaviorSubject(200);

    constructor(
        private http: HttpService,
        private loadingService: LoadingService
    ) {
    }

    /**
     * Lädt die Liste der Fahrer im Job
     * 
     * @param jobId 
     * @returns 
     */
    private loadDriverJobList(
        jobId: number,
        searchQuery: SearchQueryInterface
    ) {
        const queryParams = {
            ...searchQuery.search,
            ...searchQuery.sort,
            listType: this.currentListType.getValue(),
            page: this.nextPage,
            limit: this.limit
        } as QueryParamsInterface;

        const route = `${ENV.apiUrl}/joblist/${jobId}/driver`;
        return this.http.getBypassCache<PaginatorResponse<JobDriverInterface>>(route, queryParams);
    }

    /**
     * Hinzufügen eines Fahrers zur Jobliste mit der übergebenen Id
     * 
     * @param jobId 
     * @param payload 
     * @returns 
     */
    addDriver(jobId: number, payload: AddDriverToJobInterface) {
        const route = `${ENV.apiUrl}/joblist/${jobId}/driver`;
        return this.http.post(route, payload);
    }

    /**
     * Sendet das Request zum entfernen des Fahrers von der Liste
     * 
     * @param jobId 
     * @param payload 
     * @returns 
     */
    removeDriver(jobId: number, payload: RemoveDriverRequestInterface) {
        const route = `${ENV.apiUrl}/joblist/${jobId}/driver`;
        return this.http.deleteWithBody(route, payload as unknown as { [key: string]: unknown });
    }

    /**
     * Lädt die Liste der Fahrer und setzt sie im Service
     * 
     * @param jobId 
     * @param addResults 
     * @param searchQuery 
     */
    async loadDrivers(jobId: number, addResults: boolean, searchQuery: SearchQueryInterface) {
        await this.loadingService.start();

        const driverResult = await this.loadDriverJobList(
            jobId, searchQuery
        ).catch((e: HttpErrorResponse) => {
            console.warn("Fehler beim Laden der Fahrerliste", e);
            return null;
        });
        await this.loadingService.stop();
      
        if (driverResult) {
            if (addResults === false) {
                this.currentDrivers.next(driverResult.data);
            } else {
                const currentValue = this.currentDrivers.getValue();
                this.currentDrivers.next(currentValue.concat(driverResult.data));
            }

            const hasMoreResults = (this.nextPage < driverResult.countResultPages) ?
                true : false;
            this.hasMoreResults.next(hasMoreResults);
        }
    }

    /**
     * 
     * @param jobId 
     * @param searchQuery 
     */
    async loadNextPage(jobId: number, searchQuery: SearchQueryInterface) {
        this.nextPage = this.nextPage + 1;
        await this.loadDrivers(jobId, true, searchQuery);
    }

    /**
     * Gibt die derzeitige Fahrerliste als Observable zurück
     * 
     * @returns 
     */
    getDrivers(): Observable<JobDriverInterface[]> {
        return this.currentDrivers.asObservable();
    }

    /**
     * Ändert die derzeit angezeigte Liste
     * 
     * @param type 
     */
    async changeListType(jobId: number, searchQuery: SearchQueryInterface, type: number) {
        this.currentListType.next(type);
        await this.loadDrivers(jobId, false, searchQuery);
    }

    /**
     * Gibt den derzeitigen Listen-Typen zurück
     * 
     * @returns 
     */
    getCurrentListType(): Observable<number> {
        return this.currentListType.asObservable();
    }

    /**
     * Gibt das Observable mit dem boolean für die Anzeige des Buttons "Weitere Ergebnisse" zurück
     * 
     * @returns 
     */
    getHasMoreResults(): Observable<boolean> {
        return this.hasMoreResults.asObservable();
    }

    /**
     * Nachladen der Fahrerliste
     * 
     * @param jobId 
     * @param searchQuery 
     */
    async reloadList(jobId: number, searchQuery: SearchQueryInterface) {
        await this.loadDrivers(jobId, false, searchQuery);
    }

}