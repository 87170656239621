<nav>
    @if (pageActions) {
        <ul class="level-1">
            @for(pageAction of pageActions; track pageAction.id) {
                <li class="level-1">
                    <shared-navigation-link [navItem]="pageAction" [level]="1"></shared-navigation-link>

                </li>
            }
        </ul>
    }
</nav>
