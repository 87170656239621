import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    AgencyInterface,
    DriverJobListInterface,
    ENV,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    PrintNbDatePipe,
    PrintNbDateTimePipe
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-joblist-one-job-stammdaten-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        IconDirective,
        MatIcon,
        PrintNbDateTimePipe
    ],
    templateUrl: './one-job-stammdaten-card.component.html',
    styleUrl: './one-job-stammdaten-card.component.scss',
})
export class OneJobStammdatenCardComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    job!: DriverJobListInterface | JobListInterface;
    @Input()
    canRemove!: boolean;
    @Input()
    isDriverJob!: boolean;

    agency!: AgencyInterface;
    logoUrl!: string;

    jobHasFreePlaces(): boolean {
        if (this.job && 'freePlaces' in this.job && 'countAccepted' in this.job) {
            return (this.job.freePlaces - this.job.countAccepted > 0);
        }
        return false;

    }

    async ngOnInit() {
        if (this.job && this.job.agency) {
            this.agency = this.job.agency;
            if (this.agency.logo && this.agency.logo > 0) {
                const id = this.job.agency.id;
                this.logoUrl = `${ENV.apiUrl}/public/images/274e2d31-a2e7-461f-bda1-36c4d766c519/agency/${id}/logo`;
            }
        }
    }

    get headerClass() {
        if (this.logoUrl) {
            return 'driver-account-header driver-account-header--masterdata';
        }
        return 'driver-account-header';
    }
}
