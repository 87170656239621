import { ErrorParserInterface } from "../interfaces/error/error-parser.interface";
import { InternalErrorInterface } from "../interfaces/error/internal-error.interface";
import { SymfonyErrorResponseInterface } from "../interfaces/error/symfony-error-response.interface";

export class SymfonyErrorParser implements ErrorParserInterface {

    constructor(private symfonyError: SymfonyErrorResponseInterface) { }
    
    /**
     * Rückgabe des Fehlertitles des SymfonyFehlers
     * 
     * @returns 
     */
    getErrorMessage(): string {
        return this.symfonyError.title;
    }

    /**
     * Gibt das Array der Symfony Validierungsfehler zurück
     * 
     * @returns 
     */
    getErrors(): InternalErrorInterface[] {
        return this.symfonyError.violations.map((violation) => {
            return {
                field: violation.propertyPath,
                message: violation.title
            };
        });
    }

}