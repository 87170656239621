import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { forceRefreshHeaders } from '../function/force-refresh-headers.function';
import { QueryParamsInterface } from '../interfaces/query-params.interface';
import { ERROR_MODAL_ENABLED } from '../token/show-error-dialog.token';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
        public http: HttpClient
    ) { }

    /**
     * Gibt den HttpContext fü die Anzeige des Fehlermodals zurück
     * 
     * @param showModal 
     * @returns 
     */
    private getErrorModalContext(showModal: boolean): HttpContext {
        return new HttpContext().set(ERROR_MODAL_ENABLED, showModal);
    }

    async get<T>(url: string, queryParams: QueryParamsInterface | undefined = undefined, showErrorDialog = false): Promise<T> {
        if (queryParams !== undefined) {
            return lastValueFrom(
                this.http
                    .get<T>(url, {
                        params: queryParams,
                        context: this.getErrorModalContext(showErrorDialog)
                    })
            );
        }

        return lastValueFrom(
            this.http
                .get<T>(url, { context: this.getErrorModalContext(showErrorDialog) })
        );
    }

    /**
     * same as `get()` but bypassing the local cache
     */
    async getBypassCache<T>(url: string, queryParams: QueryParamsInterface | undefined = undefined, showErrorDialog = false): Promise<T> {
        if (queryParams !== undefined) {
            return lastValueFrom(
                this.http
                    .get<T>(url, {
                        params: queryParams,
                        headers: forceRefreshHeaders(),
                        context: this.getErrorModalContext(showErrorDialog)
                    })
            );
        }

        return lastValueFrom(
            this.http
                .get<T>(url, {
                    headers: forceRefreshHeaders(),
                    context: this.getErrorModalContext(showErrorDialog)
                })
        );
    }

    async post<T>(url: string, payload: unknown, showErrorDialog = false): Promise<T> {
        return lastValueFrom(
            this.http.post<T>(url, payload, {
                context: this.getErrorModalContext(showErrorDialog)
            })
        );
    }

    async patch<T>(url: string, payload: unknown, showErrorDialog = false): Promise<T | void> {
        return lastValueFrom(
            this.http.patch<T>(url, payload, {
                    context: this.getErrorModalContext(showErrorDialog)
                })
        );
    }

    async delete<T>(url: string, showErrorDialog = false): Promise<T> {
        return lastValueFrom(
            this.http.delete<T>(url, {
                    context: this.getErrorModalContext(showErrorDialog)
                })
        );
    }

    async deleteWithBody<T>(url: string, body: {[key: string]: unknown}, showErrorDialog = false): Promise<T>{
        return lastValueFrom(
            this.http.delete<T>(url, {
                body: body,
                context: this.getErrorModalContext(showErrorDialog)
            })
        );
    }


}
