import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from 'nb-form';
import {
    AgencyDriverProfileInterface,
    AgencyMemberInterface,
    BooleanToStringPipe,
    DriverInterface,
    DriverStatusToStringPipe,
    FullnamePipe,
    hasProperty,
    isString,
    LoadingService, PageAction, PageActionService,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    PrintStringPipe,
    TitleService,
    ToastService
} from '@nf-workforce/shared';
import { ChangeDetectorRef, Component, OnInit, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverBusinessRelationshipToStringPipe } from '../../pipes/driver-business-relationship-to-string.pipe';
import { DriverDocumentComponent } from './driver-document/driver-document.component';
import { DriverKnowledgeCardComponent } from './driver-knowledge-card/driver-knowledge-card.component';
import { DriverProfileComponent } from '../driver-profile/driver-profile.component';
import { DriverProfileImageCardComponent } from './driver-profile-image-card/driver-profile-image-card.component';
import { HttpErrorResponse } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { MyAgencyMemberService } from '../../services/my-agency-member.service';
import { DriverProfileDisabledComponent } from './driver-profile-disabled/driver-profile-disabled.component';
import { DriverStammdatenCardComponent } from './driver-stammdaten-card/driver-stammdaten-card.component';

@Component({
    selector: 'agency-my-agency-one-driver',
    standalone: true,
    imports: [
        BooleanToStringPipe,
        CommonModule,
        DriverBusinessRelationshipToStringPipe,
        DriverDocumentComponent,
        DriverKnowledgeCardComponent,
        DriverProfileComponent,
        DriverProfileImageCardComponent,
        DriverStatusToStringPipe,
        FullnamePipe,
        IonicModule,
        PrintNbDatePipe,
        PrintNbDateTimePipe,
        PrintStringPipe,
        DriverProfileDisabledComponent,
        DriverStammdatenCardComponent
    ],
    templateUrl: './my-agency-one-driver.component.html',
    styleUrl: './my-agency-one-driver.component.scss',
    providers: [
        FullnamePipe,
        PrintNbDatePipe,
    ]
})
export class MyAgencyOneDriverComponent implements OnInit {

    private idDriver = '0';

    account: WritableSignal<AgencyMemberInterface | null> = signal(null);
    driver: WritableSignal<DriverInterface | null> = signal(null);
    driverProfile: WritableSignal<AgencyDriverProfileInterface | null> = signal(null);
    profileImage: string | null = null;
    showPlace: WritableSignal<boolean> = signal(false);

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: AlertsService,
        private cd: ChangeDetectorRef,
        private fullNamePipe: FullnamePipe,
        private loadingService: LoadingService,
        private memberService: MyAgencyMemberService,
        private router: Router,
        private titleService: TitleService,
        private toastService: ToastService,
        private pageActionService: PageActionService,
    ) {}

    setPageActions() {
        this.pageActionService.clear();
        if (this.driver()) {
            if (this.driver()?.isActive) {
                this.pageActionService.addAction(PageAction.click(
                    'Neues Passwort',
                    () => {
                        this.clickSendNewPassword()
                    }
                ));
                this.pageActionService.addAction(PageAction.click(
                    'Willkommen senden',
                    () => {
                        this.clickSendWelcome()
                    }
                ));
                this.pageActionService.addAction(PageAction.click(
                    'Login verbieten',
                    () => {
                        this.clickDisableLogin()
                    }
                ));
            } else {
                this.pageActionService.addAction(PageAction.click(
                    'Login erlauben',
                    () => {
                        this.clickEnableLogin()
                    }
                ));
                this.pageActionService.addAction(PageAction.click(
                    'Account Löschen',
                    () => {
                        this.clickDelete()
                    }
                ));
            }
        }
    }

    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (!hasProperty(params, 'id') || !isString(params['id'])) {
            throw new Error('Es wurde keine valide Id übergeben');
        }
        this.idDriver = params['id'];
        await this.loadDriver(this.idDriver);
    }

    async getProfileImage(idUser: number) {
        this.profileImage = null;
        const result =  await this.memberService.getProfileImage(idUser)
            .catch((e: HttpErrorResponse) => {
                console.error('Fehler bei der Anfrage: ', e);
                return null;
            });
        if (result) {
            if (typeof result === 'string') {
                this.profileImage = result;
            }
        }
    }

    private async loadDriver(idDriver: string) {
        await this.loadingService.start();
        const result = await this.memberService.getOneDriver(parseInt(idDriver))
            .catch((e: HttpErrorResponse) => {
                console.error('Fehler bei der Anfrage: ', e);
                return null;
            });
        if (result) {
            this.account.set(result);
            this.driver.set(result.driver as DriverInterface);
            if (this.driver) {
                const profile = this.driver()?.profile as AgencyDriverProfileInterface;
                this.driverProfile.set(profile);
            }
            this.titleService.titleEmitter.emit(`${this.fullNamePipe.transform(
                {
                    academic: null,
                    firstName: result.firstName,
                    lastName: result.lastName,
                    sex: null,
                },
                'short',
                'reversed'
            )}`);
            this.setPageActions();
            await this.getProfileImage(result.id);
            this.cd.detectChanges();
        }
        await this.loadingService.stop();
    }

    enableProfile(): boolean {
        const driver = this.driver();
        if (driver) {
            if (driver.isEmployee) {
                return true;
            }
           return (driver.status > 100);
        }
        return true;
    }

    async clickSendNewPassword() {
        const account = this.account();
        if (!account) {
            return
        }
        const confirmation = await this.alertService.confirm({
            text: `Neues Passwort an ${account.lastName} senden?`,
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.memberService.sendNewPassword(account.id)
        .catch(async (e: HttpErrorResponse) => {
            console.debug('Fehler beim versenden des Passwortes:', e);
            await this.toastService.presentToast('Fehler beim versenden des Passwortes. Bitte versuchen Sie es später erneut.');
            return null;
        });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Dem Fahrer wird in Kürze ein neues Passwort zugesendet.');
            await this.loadDriver(this.idDriver);
        }
    }

    async clickSendWelcome() {
        const account = this.account();
        if (!account) {
            return
        }
        const confirmation = await this.alertService.confirm({
            text: `Neues Passwort setzen und Willkommens-Email an ${account.lastName} senden?`,
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.memberService.sendWelcome(account.id)
            .catch(async (e: HttpErrorResponse) => {
                console.debug('Fehler beim versenden der Willkommens-Mail:', e);
                await this.toastService.presentToast('Fehler beim versenden der Willkommens-Mail. Bitte versuchen Sie es später erneut.');
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Dem Benutzer wird in Kürze die E-Mail zugesandt.');
            await this.loadDriver(this.idDriver);
        }
    }

    async clickDisableLogin() {
        const account = this.account();
        if (!account) {
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: `Login für ${account.lastName} verbieten?`,
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.memberService.disableLogin(account.id)
            .catch(async (e: HttpErrorResponse) => {
                console.debug('Fehler beim verbieten des Logins:', e);
                await this.toastService.presentToast('Der Login des Benutzers konnte nicht deaktiviert werden. Bitte versuchen Sie es später erneut.');
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Der Login des Benutzers wurde erfolgreich deaktiviert.');
            await this.loadDriver(this.idDriver);
        }
    }

    async clickEnableLogin() {
        const account = this.account();
        if (!account) {
            return
        }
        const confirmation = await this.alertService.confirm({
            text: `Login erlauben: Neues Passwort setzen und an ${account.lastName} senden?`,
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.memberService.enableLogin(account.id)
            .catch(async (e: HttpErrorResponse) => {
                console.debug('Fehler beim erlauben des Logins:', e);
                await this.toastService.presentToast('Der Login des Benutzers konnte nicht aktiviert werden. Bitte versuchen Sie es später erneut.');
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Der Login des Benutzers wurde erfolgreich aktiviert.');
            await this.loadDriver(this.idDriver);
        }
    }

    async clickDelete() {
        const account = this.account();
        if (!account) {
            return
        }
        const confirmation = await this.alertService.confirm({
            text: `Möchten Sie den Fahrer ${account.id} wirklich löschen?`,
            textCancel: 'Nein',
            textOk: 'Ja'
        });
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.memberService.deleteAgencyAccount(account.id)
            .catch(async (e: HttpErrorResponse) => {
                console.debug('Fehler beim erlauben des Logins:', e);
                await this.toastService.presentToast('Der Benutzer konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut.');
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Der Benutzer wurde erfolgreich gelöscht.');
            await this.clickGoToList();
        }
    }

    async clickGoToList() {
        await this.router.navigate(['/my-agency/workforce']);
    }

    async reloadDriver() {
        if (isString(this.idDriver)) {
            await this.loadDriver(this.idDriver);
        }
    }
}
