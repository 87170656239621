import { AfterViewInit, Component, Input } from '@angular/core';
import { default as Cropper } from 'cropperjs';
import { IonicModule } from '@ionic/angular';
import { LoadingService } from '@nf-workforce/shared';

@Component({
    imports: [IonicModule],
    selector: 'ui-photo-cropper',
    standalone: true,
    styleUrl: './photo-cropper.component.scss',
    templateUrl: './photo-cropper.component.html',
})
export class PhotoCropperComponent implements AfterViewInit {

    // inputs
    @Input()
    onCancel: (() => void) | undefined;
    @Input()
    onSave: ((data: string) => void) | undefined;
    @Input()
    url!: string;

    // variables
    private cropper: Cropper | undefined;
    ready = false;

    constructor(private loadingService: LoadingService) { }

    ngAfterViewInit(): void {
        this.cropper = new Cropper(
            document.getElementById('ffc6f838b9ae41799eb9') as HTMLImageElement,
            {
                aspectRatio: 1,
                scalable: false,
                zoomable: false,
                ready: () => {
                    this.ready = true;
                },
            }
        );
    }

    _onCancel(): void {
        if (this.onCancel) {
            this.onCancel();
        }
    }

    _onSave(): void {
        if (!this.cropper || !this.onSave) {
            return;
        }
        this.onSave(this.cropper.getCroppedCanvas().toDataURL());
    }
}
