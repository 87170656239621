@if (job) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Stammdaten</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.SETTINGS"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Name/ Beschreibung</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobStammdaten('stammdaten')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">{{ job.name }}</span>
                @if (job.description) {
                    <br>
                    <span class="driver-skill__skill">
                        <ion-text class="whitespace-pre-wrap">{{ job.description }}</ion-text>
                    </span>
                }

                <br>
                <span class="driver-skill__skill">
                    <ion-text>
                        moderierte Bewerbung: {{ job.moderated | booleanToString }}
                    </ion-text>
                </span>

               
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Laufzeit</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobStammdaten('laufzeit')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    Start: {{ job.startDate | printNbDateTime }}<br />
                    Ende: {{ job.endDate  | printNbDateTime }}
                </span>

                @if (job.timezone !== null) {
                    <br>
                    <span class="driver-skill__skill">
                        Zeitzone: {{ job.timezone }}
                    </span>
                }
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Bewerbung</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobStammdaten('bewerbung')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    Start: {{ job.showDate | printNbDate }}<br />
                    Ende: {{ job.closeDate  | printNbDate }}<br>
                    @if (job.deadline !== null) {
                        Austragen bis: {{ job.deadline  | printNbDate }}
                    } @else {
                        Fahrer dürfen sich nicht selbst austragen!
                    }
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Plätze</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobStammdaten('places')">
                            <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    Gesamt: {{ job.freePlaces }}
                </span>
            </div>
        </ion-card-content>
    </ion-card>
}
