import {
    Component,
    WritableSignal,
    signal,
    OnInit,
    OnDestroy, ViewContainerRef
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbFormComponent, AlertsService } from 'nb-form';
import { IonicModule, ModalController } from '@ionic/angular';
import { MyAccountService } from '../../../services/my-account.service';
import {
    ToastService,
    LoadingService,
    AuthService,
    LoginResponseInterface,
    ProfileImageService,
    FullnamePipe,
    SelectedFileInterface,
    UploadImageRequest,
    UploadImageModalComponent,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { PhotoCropperComponent } from '@nf-workforce/ui';
import { MatIcon } from '@angular/material/icon';
import { HttpErrorResponse } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-my-account-logo',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        NbFormComponent,
        MatIcon,
        FullnamePipe,
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './my-account-logo.component.html',
    styleUrl: './my-account-logo.component.scss',
    providers: [
        MyAccountService,
        ProfileImageService,
    ]
})
export class MyAccountLogoComponent implements OnInit, OnDestroy {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly TitleIcon = TitleIconEnum;

    user!: LoginResponseInterface;
    profileImage!: string;

    showPlace: WritableSignal<boolean> = signal(false);
    hasProfileBild: WritableSignal<boolean> = signal(false);
    hasFileSelected: WritableSignal<boolean> = signal(false);

    selected_file: SelectedFileInterface | null = null;

    constructor(
        public authService: AuthService,
        private myAccountService: MyAccountService,
        private modalController: ModalController,
        private toastService: ToastService,
        private alertService: AlertsService,
        private loadingService: LoadingService,
        private profileImageService: ProfileImageService,
        private viewContainer: ViewContainerRef,
        private router: Router,
    ) {
        this.user = this.authService.getSession() as LoginResponseInterface;
        this.hasProfileBild.set((this.user.logo !== null));
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    async ngOnInit() {
        await this.reload();
    }

    async reload() {
        this.showPlace.set(false);
        this.user = this.authService.getSession() as LoginResponseInterface;
        await this.setProfileImage();
        this.hasProfileBild.set((this.user.logo !== null));
        this.hasFileSelected.set(false);
        this.showPlace.set(true);
    }

    async setProfileImage() {
        const result = await this.profileImageService.getProfileImage(this.user.id)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler beim Laden", e);
                return null;
            });
        if (result) {
            this.profileImage = result as string;
        }
    }

    get currentImageSource() {
        if (this.hasFileSelected() && this.selected_file) {
            return this.selected_file.data
        }
        return this.profileImage;
    }


    async clickSelectNewImage() {
        const modal = await this.modalController.create({
            component: UploadImageModalComponent,
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                if (result.data['saved'] && result.data['saved'] === true) {
                    if (result.data['selectedFile'] && result.data['selectedFile'] !== null) {
                        this.selected_file = result.data['selectedFile'];
                        this.hasFileSelected.set(true);
                    }
                }
            }
        }
    }

    async showPhotoCropper() {
        const photoCropper = this.viewContainer.createComponent(PhotoCropperComponent);
        photoCropper.setInput('url', this.currentImageSource.replace(/\s/g, ''));
        photoCropper.setInput('onCancel', () => {
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
            this.viewContainer.clear();
        });
        photoCropper.setInput('onSave', (data: string) => {
            if (this.selected_file) { // nach upload
                this.selected_file.data = data;
            } else {
                this.selected_file = {
                    name: "profileImage",
                    data: data,
                }
                this.hasFileSelected.set(true);
            }
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
        });
    }

    async clickUploadImage() {
        if (this.selected_file) {
            let fileName = this.selected_file.name;
            if (fileName === "profileImage") {
                const type = this.selected_file.data.split(';')[0].split('/')[1];
                fileName = `${this.user.id}-profileImage.${type}`;
            }
            const FileItem = {
                name: fileName,
                data: this.selected_file.data,
            }
            const payload = {file: [FileItem]} as UploadImageRequest;
            const confirmation = await this.alertService.confirm({
                text: "Ihr Profilbild jetzt hochladen?",
                textOk: "Ja",
                textCancel: "Nein"
            });
            if (confirmation) {
                await this.uploadImage(payload);
            }
        } else {
            await this.toastService.presentToast('Keine Datei zum Hochladen vorhanden!');
        }
    }

    async uploadImage(payload: UploadImageRequest) {
        await this.loadingService.start();
        const result = await this.myAccountService.uploadProfileImage(payload)
            .catch((e: HttpErrorResponse) => {
                console.debug(e);
                this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut.');
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Ihr Profilbild wurde erfolgreich hochgeladen.');
            this.authService.setValueInSession('logo', result.data.id);
            const mimeType = (typeof result.data.mimeType === 'undefined') ? null :result.data.mimeType;
            this.authService.setValueInSession('logoMimeType', mimeType );
            await this.router.navigate(['/account/my-account']);
        }
    }

    async clickDeleteImage() {
        if (this.hasProfileBild()) {
            const confirmation = await this.alertService.confirm({
                text: "Ihr Profilbild jetzt löschen?",
                textOk: "Ja",
                textCancel: "Nein"
            });
            if (confirmation) {
                await this.loadingService.start();
                const result = await this.myAccountService.deleteProfileImage()
                    .catch((e: HttpErrorResponse) => {
                        console.debug(e);
                        this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut.');
                        return null;
                    });
                await this.loadingService.stop();
                if (result) {
                    await this.toastService.presentToast('Ihr Profilbild wurde erfolgreich gelöscht.');
                    this.authService.setValueInSession('logo', null);
                    this.authService.setValueInSession('logoMimeType', null);
                    await this.reload();
                }
            }
        }
    }


    ngOnDestroy(): void {

    }


}
