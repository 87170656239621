@if(profile && countries && form) {
    <ion-header>
        <ion-col>
            <ion-title>
               Ihr Wohnort
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <form [formGroup]="form">
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Straße
                                <ui-form-error [showError]="showError(form, 'street')">
                                    Bitte geben Sie eine Straße ein.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput formControlName="street" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Plz
                                <ui-form-error [showError]="showError(form, 'zipCode')">
                                    Bitte geben Sie eine Plz ein.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput formControlName="zipCode" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Stadt
                                <ui-form-error [showError]="showError(form, 'city')">
                                    Bitte geben Sie eine Stadt ein.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput formControlName="city" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline" >
                            <mat-label>
                                Bundesland
                                <ui-form-error [showError]="showError(form, 'area')">
                                    Bitte geben Sie ein Bundesland ein.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput formControlName="area" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>
                                Land
                                <ui-form-error [showError]="showError(form, 'country')">
                                    Bitte wählen Sie ein Land aus.
                                </ui-form-error>
                            </mat-label>
                            <input type="text" matInput [formControl]="country" [matAutocomplete]="auto" autocomplete="off">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                @for (option of filteredOptions | async; track option) {
                                    <mat-option [value]="option">{{option.country}}</mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </ion-col>
                </ion-row>
            </form>
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>

            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickSave()" color="success" *ngIf="form.valid">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}


