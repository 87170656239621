import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    AgencyMemberInterface,
    BooleanToStringPipe,
    calculateAge,
    DriverInterface,
    DriverStatusToStringPipe,
    FullnamePipe,
    IconDirective,
    IconThemeEnum,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    TitleIconEnum
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-driver-profile-disabled-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        FullnamePipe,
        IconDirective,
        MatIcon,
        BooleanToStringPipe,
        PrintNbDateTimePipe,
        DriverStatusToStringPipe
    ],
    templateUrl: './driver-profile-disabled.component.html',
    styleUrl: './driver-profile-disabled.component.scss',
    providers: [
        PrintNbDatePipe,
        FullnamePipe,
    ]
})
export class DriverProfileDisabledComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly TitleIconEnum = TitleIconEnum;
    protected readonly Icon = TitleIconEnum;

    @Input()
    account!: AgencyMemberInterface;
    @Input()
    driver!: DriverInterface;
    @Input()
    profileImage!: string | null;
    @Input()
    enableProfile: boolean | null = false;
    showPlace: WritableSignal<boolean> = signal(false);

    constructor(
        private printNbDatePipe: PrintNbDatePipe,
    )
    {}
    ngOnInit(): void {
        if (this.account !== null && this.driver !== null && this.profileImage !== null && this.enableProfile !== null) {
            this.showPlace.set(true);
        }
    }

    age(): string {
        let html = '';
        const driverProfile = this.driver?.profile;
        if (driverProfile) {
            if (driverProfile.birthday !== null && driverProfile.birthday !== '') {
                const driverBirthday = new Date(driverProfile.birthday);
                html = calculateAge(driverBirthday).toString();
                if (this.enableProfile) {
                    const birthday = this.printNbDatePipe.transform(driverProfile.birthday);
                    html += ` / ${birthday}`;
                }
            }
            if (this.enableProfile) {
                if (driverProfile.birthplace !== null && driverProfile.birthplace !== '') {
                    html += ` / ${driverProfile.birthplace}`;
                }
            }
        }
        return html;
    }

}
