import { Injectable } from '@angular/core';
import { DriverDataInterface, DriverLanguageInterface } from '@nf-workforce/shared';

@Injectable()
export class DriverLanguageHelperService {
    driver: DriverDataInterface | null = null;
    driverLanguages: DriverLanguageInterface[] | null = null;
    motherLanguages: DriverLanguageInterface[] = [];
    otherLanguages: DriverLanguageInterface[] = [];

    setDriver(driver: DriverDataInterface) {
        this.driver = driver;
        this.driverLanguages = this.driver.driverUserDto.driverLanguages;
        this.motherLanguages = this.driverLanguages.filter(lang => lang.isMotherLanguage);
        this.otherLanguages = this.driverLanguages.filter(lang => !lang.isMotherLanguage);
    }

    getLanguageHeader(isMotherLanguage: boolean): string {
        if (isMotherLanguage) {
            if (this.motherLanguages) {
                if (this.motherLanguages?.length > 1) {
                    return 'Muttersprachen:';
                } else if (this.motherLanguages.length === 1) {
                    return 'Muttersprache:';
                }
            }
            return 'Muttersprache:';
        } else {
            if (this.otherLanguages) {
                if (this.otherLanguages.length > 1) {
                    return 'Weitere Sprachen:';
                } else if (this.otherLanguages.length === 1) {
                    return 'Weitere Sprache:';
                }
            }
            return 'Weitere Sprache:';
        }
    }

    removeLanguage(language: DriverLanguageInterface) {
        if (language.isMotherLanguage) {
            this.motherLanguages = this.motherLanguages.filter(lang => (lang.id !== language.id));
        } else {
            this.otherLanguages = this.otherLanguages.filter(lang => (lang.id !== language.id));
        }
    }

    addLanguage(language: DriverLanguageInterface) {
        if (language.isMotherLanguage) {
            this.motherLanguages.push(language);
        } else {
            this.otherLanguages.push(language);
        }
    }
}
