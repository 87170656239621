import { Pipe, PipeTransform } from '@angular/core';
import { isString } from '../type-guards/is-string';

@Pipe({
    name: 'printString',
    standalone: true
})
export class PrintStringPipe implements PipeTransform {
    transform(value: string | null): string {
        if (isString(value)) {
            return value;
        } 
        
        return "-";
  }

}