import { Component, EventEmitter, OnDestroy, Output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { DisplayedElementsInterface } from '../../interfaces/displayed-elements.interface';
import { PageFilterAction } from '../../classes/page-filter-action.class';
import { SearchElementInterface } from '../../interfaces/search-element.interface';
import { SearchEventInterface } from '../../interfaces/search-event.interface';
import { SearchQueryInterface } from '../../interfaces/search-query.interface';
import { PageActionService } from '../../services/page-action.service';
import { SearchDateComponent } from '../search-date/search-date.component';
import { SearchSelectionComponent } from '../search-selection/search-selection.component';
import { SearchTextComponent } from '../search-text/search-text.component';
import { SearchMenuInterface } from '../../interfaces/search-menu.interface';
import { SortComponent } from '../sort/sort.component';
import { SortEventInterface } from '../../interfaces/sort-event.interface';

@Component({
    imports: [
        IonicModule,
        SearchDateComponent,
        SearchTextComponent,
        SearchSelectionComponent,
        SortComponent
    ],
    selector: 'shared-search-menu',
    standalone: true,
    templateUrl: './search-menu.component.html',
})
export class SearchMenuComponent implements OnDestroy {

    @Output()
    closeEmitter: EventEmitter<void> = new EventEmitter();

    displayedSearchElements: DisplayedElementsInterface = {};
    displayedSortElements: DisplayedElementsInterface = {};

    filterConfig!: SearchMenuInterface | null;
    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };

    showSort = signal(false);
    showSearch = signal(false);

    private filterAction: PageFilterAction | null = null;
    private pageActionSub: Subscription;

    constructor(
        private pageActionService: PageActionService
    ) {
        this.pageActionSub = pageActionService.updates().subscribe(() => {
            this.filterAction = this.pageActionService.getFilterAction();
            if (this.filterAction) {
                this.filterConfig = this.filterAction.filterConfig;
                this.initFilters();
            } else {
                this.filterConfig = null;
            }
        });
    }

    async clickApply() {
        if (this.filtersAreEmpty()) {
            return this.clickReset(); // search has been reset manually
        }
        
        this.pageActionService.setFilterState('filtered');
        await this.filterAction?.onFilter(this.searchQuery);
        this.closeEmitter.emit();
    }

    async clickReset() {
        this.pageActionService.setFilterState('unfiltered');
        await this.filterAction?.onReset();
        this.closeEmitter.emit();
    }

    /**
     * Gibt den Typ für das Datums-Element anhand des übergebenen SearchElements zurück
     *
     * @param searchElement
     * @returns
     */
    getSearchDateType(searchElement: SearchElementInterface): 'single' | 'between' {
        return searchElement.dateSingle && searchElement.dateSingle === true
            ? 'single'
            : 'between';
    }

    ngOnDestroy(): void {
        this.pageActionSub.unsubscribe();
    }

    initFilters() {
        this.displayedSearchElements = {};
        if (this.filterConfig) {
            for (const element of this.filterConfig.searchElements) {
                this.displayedSearchElements[element.name] = false;
            }

            for (const element of this.filterConfig.sortElements) {
                this.displayedSortElements[element.name] = false;
            }

            if (this.filterConfig.searchElements.length > 0) {
                this.showSearch.set(true);
            }

            if (this.filterConfig.sortElements.length > 0) {
                this.showSort.set(true);
            }
        }
    }

    /**
     * Einblenden/Ausblenden des Elements mit dem übergebenen Namen
     *
     * @param name
     */
    toggleElement(type: 'search' | 'sort', name: string) {
        if (type === "search") {
            this.displayedSearchElements[name] = !this.displayedSearchElements[name];
        } else if (type === "sort") {
            this.displayedSortElements[name] = !this.displayedSortElements[name];
        }
    }

    updateSearchModel($event: SearchEventInterface<unknown>) {
        if ($event.value === null && this.searchQuery.search[$event.name]) {
            delete this.searchQuery.search[$event.name];
        } else {
            this.searchQuery.search[$event.name] = $event.value as string;
        }
    }

    onSortChanged($event: SortEventInterface) {
        this.searchQuery.sort = {};

        if ($event.order === 'asc') {
            this.searchQuery.sort.orderAsc = $event.name;
        } else if ($event.order === 'desc') {
            this.searchQuery.sort.orderDesc = $event.name;
        }
    }

    /**
     * Gibt zurück, ob ein Filter gesetzt ist
     * 
     * @returns 
     */
    private filtersAreEmpty(): boolean {
        return [this.searchIsEmpty(), this.sortIsEmpty()].reduce((prev, current) => {
            return (prev && current);
        });
    }

    /**
     * Gibt zurück, ob die Suche derzeit leer ist
     * 
     * @returns 
     */
    private searchIsEmpty(): boolean {
        const keys = Object.keys(this.searchQuery.search);

        if (keys.length < 1) {
            return true;
        }

        const isEmpty = keys.map((key) => {
            const value = this.searchQuery.search[key];
            if (typeof value !== 'string' || value.trim() !== '') {
                return false
            }
            
            return true;
        }).reduce((prev, current) => {
            return (prev && current);
        });

        if (isEmpty) {
            this.searchQuery.search = {};
        }

        return isEmpty;
    }

    /**
     * Gibt zurück ob die Sortierung derzeit leer ist
     * 
     * @returns 
     */
    private sortIsEmpty(): boolean {
        const asc = this.searchQuery.sort.orderAsc;
        const desc = this.searchQuery.sort.orderDesc 
        return (asc === undefined && desc === undefined);
    }
       
}
