import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const ADD_AGENCY_USER_SCHEMA = new InjectionToken<FormPage>('"Neuer Agentur-Benutzer Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'add-agency-user',
            navigation_label: 'agency-user',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'add-agency-account-header',
                    type: 'sub-header',
                    label: 'Accountinformationen',
                    columnSize: 12
                },
                {
                    id: 'userName',
                    type: 'email',
                    model: 'username',
                    label: 'Benutzername',
                    placeholder: 'E-Mail eingeben',
                    required: true,
                    columnSize: 6
                },
                {
                    id: 'agency-role',
                    type: 'nb-dropdown',
                    model: 'roleId',
                    label: 'Rolle',
                    required: true,
                    columnSize: 6,
                    options: [
                        {
                            key: 3,
                            value: 'Administrator'
                        },
                        {
                            key: 4,
                            value: 'FDL'
                        }
                    ]
                },
                {
                    id: 'add-agency-person-header',
                    type: 'sub-header',
                    label: 'Daten zur Person',
                    columnSize: 12
                },
                {
                    id: 'sex',
                    type: 'nb-dropdown',
                    model: 'sex',
                    label: 'Anrede',
                    options: [
                        {
                            key: 'f',
                            value: 'Frau'
                        },
                        {
                            key: 'm',
                            value: 'Herr'
                        },
                        {
                            key: 'd',
                            value: 'Divers'
                        }
                    ],
                    columnSize: 6
                },
                {
                    id: 'academic',
                    type: 'textarea',
                    model: 'academic',
                    label: 'Titel',
                    placeholder: 'Akademischer Grad',
                    columnSize: 6
                },
                {
                    id: 'firstName',
                    type: 'textarea',
                    model: 'firstName',
                    label: 'Vorname',
                    rows: 1,
                    placeholder: '',
                    columnSize: 6
                },
                {
                    id: 'lastName',
                    type: 'textarea',
                    model: 'lastName',
                    label: 'Nachname',
                    rows: 1,
                    required: true,
                    placeholder: '',
                    columnSize: 6
                },
                {
                    id: 'phone',
                    type: 'phone',
                    model: 'phone',
                    label: 'Telefonnummer',
                    placeholder: 'im internationalem Format',
                    required: true,
                    columnSize: 6
                },
                {
                    id: 'sendMail',
                    type: 'nb-dropdown',
                    model: 'sendMail',
                    label: 'Willkommensmail absenden',
                    required: true,
                    options: [
                        {
                            key: '0',
                            value: 'Nein'
                        },
                        {
                            key: '1',
                            value: 'Ja'
                        }
                    ],
                    columnSize: 6
                }
            ]
        };
    }
});
