<ion-app>

    <shared-search-menu #searchMenu (closeEmitter)="closeSearchMenu()"></shared-search-menu>

    <ion-split-pane when="lg" contentId="main" (ionSplitPaneVisible)="watchSplitPaneVisibility($event)">

        <!-- sidebar with navigation and page actions -->

        @if (showNav) {
        <ion-menu menuId="navMenu" side="start" contentId="main">
            <ion-header>
                <ion-toolbar>
                    <div class="page-toolbar-content page-toolbar-content--sidebar">
                        <img alt="Workforce Logo" src="/android-icon-36x36.png">
                        <span class="page-title">Workforce</span>
                        @if(!splitPaneVisible) {
                        <ion-button class="nav-menu-close-button" (click)="closeNavigationMenu()" shape="round" fill="clear" color="medium">
                            <mat-icon [icon]="Icon.CLOSE" [theme]="IconTheme.INHERIT"></mat-icon>
                        </ion-button>
                        }
                    </div>
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <shared-navigation [menu]="menu"></shared-navigation>
                @if (pageActions().length > 0) {
                    <hr class="nav-ruler">
                    <shared-page-actions [pageActions]="pageActions()"></shared-page-actions>
                }
            </ion-content>
        </ion-menu>
        }

        <!-- page header with title, back and search buttons -->

        <main class="ion-page" id="main">
            <ion-header>
                <ion-toolbar>
                    <div [class]="showNav
                            ? 'page-toolbar-content'
                            : 'page-toolbar-content page-toolbar-content--no-nav'">
                        <img class="ion-hide-lg-up" alt="Workforce Logo" src="/android-icon-36x36.png">

                        @if (backAction() !== null) {
                            <ion-button [title]="backAction()?.label" (click)="backAction()?.clickFn?.()"
                                shape="round" fill="clear" color="medium">
                                <mat-icon [icon]="Icon.BACK"></mat-icon>
                            </ion-button>
                        }

                        <h1 class="page-title">{{ pageTitle() }}</h1>
                        @if (filterAction() !== null && !isFiltered) {
                            <ion-button title="Ergebnisse filtern" (click)="openSearchMenu()"
                                shape="round" fill="clear" color="medium">
                                <mat-icon [icon]="Icon.SEARCH"></mat-icon>
                            </ion-button>
                        }

                        @if (filterAction() && isFiltered) {
                            <ion-button title="Filter anpassen" (click)="openSearchMenu()"
                                shape="round" fill="clear" color="medium">
                                <mat-icon [icon]="Icon.SEARCH_MANAGE"></mat-icon>
                            </ion-button>
                        }

                        @if (showNav) {
                            <ion-menu-button menu="navMenu" class="ion-hide-lg-up"></ion-menu-button>
                        }

                    </div>
                </ion-toolbar>
            </ion-header>
            <ion-content class="ion-padding">
                <router-outlet></router-outlet>
            </ion-content>
        </main>
    </ion-split-pane>
</ion-app>
