@if(profile && form) {
    <ion-header>
        <ion-col>
            <ion-title>
                Ihre Daten zur Abrechnung
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <form [formGroup]="form">
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>Steuer-ID</mat-label>
                            <input type="text" matInput formControlName="taxId" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline">
                            <mat-label>Krankenversicherungsnummer</mat-label>
                            <input type="text" matInput formControlName="kkNr" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline" >
                            <mat-label>Rentenversicherungsnummer</mat-label>
                            <input type="text" matInput formControlName="rvNr" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <mat-form-field appearance="outline" >
                            <mat-label>Iban</mat-label>
                            <input type="text" matInput formControlName="iban" autocomplete="off">
                        </mat-form-field>
                    </ion-col>
                </ion-row>
            </form>
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>

            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickSave()" color="success" *ngIf="form.valid">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}


