import { SearchMenuInterface } from "../interfaces/search-menu.interface";
import { SearchQueryInterface } from "../interfaces/search-query.interface";
import { AccRouteDefinitionType } from "../types/acc-route-definition.type";


export class PageFilterAction {

    constructor(
        /**
         * Defines what can be filtered and how.
         */
        public filterConfig: SearchMenuInterface,
        /**
         * Invoked when a new filter has been set by the user.
         */
        public onFilter: (searchQuery: SearchQueryInterface) => Promise<void>,
        /**
         * Invoked when the user has reset the filter.
         */
        public onReset: () => Promise<void>,
        /**
         * Optionally protect the search by an ACC route ID.
         */
        public protectedBy?: AccRouteDefinitionType | undefined,
    ) { }
}
