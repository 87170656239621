import { Route } from "@angular/router";
import { DriverMyProfileComponent } from "./component/my-profile/driver-my-profile.component";

export const driverRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full'
    },
    {
        path: 'profile',
        pathMatch: 'full',
        component: DriverMyProfileComponent,
        title: 'Mein Profil',
    }
];
