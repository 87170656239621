import { Component, Input } from '@angular/core';
import { NavigationItemComponent } from '../navigation-item/navigation-item.component';
import { NavigationNodeInterface } from '../../interfaces/navigation-node.interface';


/**
 * This component is needed so we can have a wrapper item around the menu, which
 * may be called recursively and we do not want the wrapper element to be
 * included in the recursion.
 */
@Component({
    standalone: true,
    imports: [NavigationItemComponent],
    selector: 'shared-navigation',
    templateUrl: './navigation.component.html',
})
export class NavigationComponent {
    @Input() menu?: NavigationNodeInterface[];
}
