<ion-item lines ="none">
    <ion-chip [class]= "isSelected(null) ? 'selected' : ''" [outline]="true" (click)="changeSelection(null)">
       <ion-label>
        Alle
       </ion-label>
    </ion-chip>

    @for (option of options; track option.label) {
        <ion-chip [class]= "isSelected(option) ? 'selected' : ''" [outline]="true" (click)="changeSelection(option)">
            <ion-label>
                {{ option.label }}
            </ion-label>
        </ion-chip>
    }
</ion-item>