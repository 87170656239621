import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

/**
 * @TODO Dependencies fixen
 */
import {
    BooleanToStringPipe,
    DriverReferencesInterface,
    DriverStatusToStringPipe,
    FullnamePipe,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    DriverLanguageInterface,
    DriverFahrerlaubnisInterface,
    DriverSkillInterface,
    AgencyMemberInterface,
    DriverInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { DriverBusinessRelationshipToStringPipe } from '../../../pipes/driver-business-relationship-to-string.pipe';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-driver-knowledge-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        FullnamePipe,
        BooleanToStringPipe,
        DriverBusinessRelationshipToStringPipe,
        DriverStatusToStringPipe,
        IconDirective,
        MatIcon,
        PrintNbDateTimePipe
    ],
    templateUrl: './driver-knowledge-card.component.html',
    styleUrl: './driver-knowledge-card.component.scss',
    providers: [
        PrintNbDatePipe,
        FullnamePipe,
    ]
})
export class DriverKnowledgeCardComponent implements OnInit {
    protected readonly IconEnum = IconEnum;

    @Input()
    account!: AgencyMemberInterface;
    @Input()
    driver!: DriverInterface;

    skills: DriverSkillInterface[] | null = null;
    fahrerlaubnis: DriverFahrerlaubnisInterface[] | [] = [];
    driverLanguages: DriverLanguageInterface[] | null = null;
    motherLanguages: DriverLanguageInterface[] | [] = [];
    otherLanguages: DriverLanguageInterface[] | [] = [];
    references: DriverReferencesInterface[] | null = null;

    Icon = TitleIconEnum;
    IconTheme = IconThemeEnum;
    showPlace: WritableSignal<boolean> = signal(false);

    ngOnInit(): void {
        if (this.account !== null && this.driver !== null) {
            this.skills = this.driver.skills;
            this.fahrerlaubnis = this.driver.fahrerlaubnis;
            this.driverLanguages = this.driver.driverLanguages;
            this.motherLanguages = this.driverLanguages.filter(lang => lang.isMotherLanguage);
            this.otherLanguages = this.driverLanguages.filter(lang => !lang.isMotherLanguage);
            this.references = this.driver.driverReferences;
            this.showPlace.set(true);
        }
    }

    getSkillsStrings(): string {
        const name: string[] = [];
        if (this.skills && this.skills.length > 0) {
            this.skills.forEach((skill) => {
                name.push(skill.name);
            })
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }

    getFahrerlaubnisStrings(): string {
        const name: string[] = [];
        if (this.fahrerlaubnis && this.fahrerlaubnis.length > 0) {
            this.fahrerlaubnis.forEach((skill) => {
                name.push(skill.name);
            })
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return 'Keine Angaben';
    }

    getLanguagesAsString(): string {
        const name: string[] = [];
        if(this.driverLanguages && this.driverLanguages.length > 0) {
            if (this.motherLanguages.length > 0) {
                this.motherLanguages.forEach((lang) => {
                    const label = `${lang.name} (Muttersprache)`;
                    name.push(label);
                })
            }
            if (this.otherLanguages.length > 0) {
                this.otherLanguages.forEach((lang) => {
                    const label = `${lang.name} (${lang.level})`;
                    name.push(label);
                })
            }
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }



}
