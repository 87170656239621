import { addIcons } from "ionicons";
import { pencil } from "ionicons/icons";
import { cloudDownloadSharp } from "ionicons/icons";
import { list } from "ionicons/icons";
import { refreshOutline } from "ionicons/icons";
import { closeOutline } from "ionicons/icons";
import { trashOutline } from "ionicons/icons";

export function registerIonIcons() {
    addIcons({
        pencil,
        cloudDownloadSharp,
        list,
        refreshOutline,
        closeOutline,
        trashOutline
    });
}