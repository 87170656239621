import { FahrerlaubnisEavInterface } from '@nf-workforce/shared';

export function fahrerlaubnisToOptions(options: FahrerlaubnisEavInterface[]): { key: string, value: string }[] {
    return options.map((val) => {
        return {
            key: val.id.toString(),
            value: val.label
        };
    })
}
