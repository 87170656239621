import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { TitleStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor, AuthService, CacheInterceptor, HttpService, LOGIN_SERVICE, LoadingService, ToastService, ValidationErrorInterceptor } from '@nf-workforce/shared';
import { AccountService } from '@nf-workforce/account';
import { WorkforceTitleStrategy } from './class/workforce-title-strategy.class';
import { ModalController } from '@ionic/angular';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes, withComponentInputBinding()),
        { provide: TitleStrategy, useClass: WorkforceTitleStrategy },
        provideAnimationsAsync('noop'),
        provideIonicAngular({}),
        ModalController,
        provideHttpClient(
            withInterceptors(
                [
                    AuthInterceptor,
                    CacheInterceptor,
                    ValidationErrorInterceptor
                ]
            )
        ),
        LoadingService,
        ToastService,
        HttpService,
        AuthService,
        AccountService,
        { provide: LOGIN_SERVICE, useClass: AccountService },
    ],
};
