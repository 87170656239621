import { Directive, HostBinding} from '@angular/core';

@Directive({
    selector: '[uiTableHead]',
    standalone: true
})
export class TableHeadDirective {

  @HostBinding('class')
  elementClass = 'table-row table-head';

}