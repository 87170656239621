<ion-row>
    <ion-col>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button fill="outline" [disabled]="isActive(1)" (click)="changeRoute(1)">
                    Liste
                </ion-button>

                <ion-button fill="outline" [disabled]="isActive(2)" (click)="changeRoute(2)">
                    Neue Nachricht
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-col>
</ion-row>