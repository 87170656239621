@if (showPlace()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Dokumente</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.DOCUMENT"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            @if (missingEavDocuments.length > 0) {
                <ion-row>
                    <ion-col>
                        <ion-card>
                            <ion-card-header>
                                <ion-card-subtitle color="danger">Nicht alle Pflichtdokumente wurden hinterlegt!</ion-card-subtitle>
                            </ion-card-header>
                            <ion-card-content>
                                <ol>
                                    @for (eavDoc of missingEavDocuments; track eavDoc.id) {
                                        <li>{{ eavDoc.name }}</li>
                                    }
                                </ol>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            }
            <ion-row>
                <div class="driver-account-document-list-grid">
                    @for (eavDoc of eavDocuments; track eavDoc.id) {
                        @if (hasDocument(eavDoc)) {
                            @if (isSingleEavDocument(eavDoc.id)) {
                                <ion-col>
                                    <agency-driver-one-document
                                        [account]="account"
                                        [eavDocument]="eavDoc"
                                        [driverDocument]="findOneDocumentByEav(eavDoc)"
                                        (accept)="clickAcceptDocument($event)"
                                        (reject)="clickRejectDocument($event)"
                                    ></agency-driver-one-document>
                                </ion-col>
                            } @else {
                                @for (driverDocument of findAllDocumentsByEav(eavDoc); track driverDocument.id) {
                                    <ion-col>
                                        <agency-driver-one-document
                                            [account]="account"
                                            [eavDocument]="eavDoc"
                                            [driverDocument]="findOneDocumentByEav(eavDoc)"
                                            (accept)="clickAcceptDocument($event)"
                                            (reject)="clickRejectDocument($event)"
                                        ></agency-driver-one-document>
                                    </ion-col>
                                }
                            }
                        }
                    }
                </div>
            </ion-row>
        </ion-card-content>
    </ion-card>
}
