import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const SINGLE_MESSAGE_SCHEMA = new InjectionToken<FormPage>('"Schema Einzelnachricht"', {
    factory: () => {
        return {
            ber_reiter_id: 'message',
            header: 'Nachricht erstellen',
            navigation_label: 'single-message',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'betreff',
                    type: 'textarea',
                    model: 'betreff',
                    label: 'Betreff',
                    required: true,
                    placeholder: 'Betreff eintragen',
                    columnSize: 12,
                },
                {
                    id: 'nachricht',
                    type: 'textarea',
                    model: 'nachricht',
                    label: 'Nachricht',
                    required: true,
                    placeholder: 'Nachricht eintragen',
                    rows: 5,
                    columnSize: 12,
                },
                {
                    id: 'addGroup',
                    type: 'nb-dropdown',
                    model: 'addGroup',
                    label: 'Gruppe hinzufügen',
                    options: [],
                    columnSize: 6,
                    onChange: {
                        func: 'addGroupToMessage',
                        parameters: ['addGroup']
                    }
                },
                {
                    id: 'addUser',
                    type: 'nb-dropdown',
                    model: 'addUser',
                    label: 'Benutzer hinzufügen',
                    options: [],
                    columnSize: 6,
                    onChange: {
                        func: 'addUserToMessage',
                        parameters: ['addUser']
                    }
                },
            ]
        };
    }
});