
import { ACCOUNT_RECOVERY_SCHEMA } from '../../schema/account-recovery.schema';
import { AuthService, createEmptyForm, ErrorService, isString, LoadingService, ToastService } from '@nf-workforce/shared';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { Form, FormPage, NbFormComponent } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { MyAccountService } from '../../services/my-account.service';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-validate-account',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
    ],
    providers: [
        MyAccountService,
    ],
    templateUrl: './validate-account.component.html',
    styleUrl: './validate-account.component.scss',
})
export class ValidateAccountComponent implements OnInit {

    @ViewChild(NbFormComponent) formInstance!: NbFormComponent;
    disableSave = signal(true);
    errors = signal<string[]>([]);
    validateAccountForm: Form;

    constructor(
        @Inject(ACCOUNT_RECOVERY_SCHEMA) private formSchema: FormPage,
        private authService: AuthService,
        private errorService: ErrorService,
        private loadingService: LoadingService,
        private myAccountService: MyAccountService,
        private router: Router,
        private toastService: ToastService,
    ) {
        this.formSchema.header = undefined; // page title is enough
        this.validateAccountForm = createEmptyForm(1, this.formSchema);
    }

    async ngOnInit(): Promise<void> {
        if (this.authService.isValidated) {
            await this.redirectToMyAccount();
        }
    }

    /**
     * Clickhandler für das Freischalten des Accounts
     */
    async clickValidateAccount() {
        await this.loadingService.start();
        const result = await this.myAccountService.validateAccount({
            password: this.validateAccountForm.data['newPassword'] as string
        }).catch(async (e) => {
            console.warn('Fehler bei der Anfrage: ', e);
            await this.toastService.presentToast('Fehler beim Speichern. Bitte versuchen Sie es später erneut.');
            return null;
        });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Ihr Account wurde freigeschaltet.');
            await this.redirectToMyAccount();
        } else {
            this.errorService.highlightFormErrors(this.formInstance, this.formSchema);
        }
    }

    /**
     * Handler für Änderungen im Formular, wird verwendet um zusätzliche Fehlernachrichten anzuzeigen
     *
     * @returns
     */
    onFormChange() {
        if (!this.formInstance) {
            return;
        }

        const validator = this.formInstance.getValidator();
        if (validator.validate() === false) {
            if (validator.errorFields.length === 1) {
                const errorControl = validator.errorFields[0].control;
                const controlErrors = errorControl.errors as { [key: string]: string };
                const errorKeys = Object.keys(controlErrors);

                // NbFormBadRequest rausfiltern + die zutreffenden Fehlermeldungen zurückgeben
                const errorMessages = errorKeys.filter((key) => {
                  return (key !== 'nbFormBadRequest' && isString(controlErrors[key]));
                }).map((key) => {
                  return controlErrors[key];
                })

                this.errors.set(errorMessages);
                validator.highlightErrors = true;
                validator.setValidationErrors({
                  password: 'Bitte überprüfen Sie Ihre Eingabe.'
                });
                this.disableSave.set(true);
            }
        } else {
            this.errors.set([]);
            this.disableSave.set(false);
            validator.highlightErrors = false;
        }
    }

    /**
     * Weiterleitung zur Seite "Mein Account"
     */
    private async redirectToMyAccount() {
        await this.router.navigate(['/account/my-account']);
    }
}
