@if (job && form) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{title}}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <form [formGroup]="form">
                @if (formType === 'laufzeit' || formType === 'bewerbung') {
                    <ion-row>
                        <ion-col>
                            <ion-item lines="none">Laufzeit Job</ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-label>Start<sup>*</sup>:</ion-label>
                                <ion-datetime-button datetime="datetime-startDate"></ion-datetime-button>
                                <ion-modal [keepContentsMounted]="true">
                                    <ng-template>
                                        <ion-datetime
                                            id="datetime-startDate"
                                            formControlName="startDate"
                                            [min]="minDate"
                                            [max]="maxDate"
                                        >
                                            <span slot="title">Job Start</span>
                                        </ion-datetime>
                                    </ng-template>
                                </ion-modal>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item>
                                <ion-label>Ende<sup>*</sup>:</ion-label>
                                <ion-datetime-button datetime="datetime-endDate"></ion-datetime-button>
                                <ion-modal [keepContentsMounted]="true">
                                    <ng-template>
                                        <ion-datetime
                                            id="datetime-endDate"
                                            formControlName="endDate"
                                            [min]="minDate"
                                            [max]="maxDate"
                                        >
                                            <span slot="title">Job Ende</span>
                                        </ion-datetime>
                                    </ng-template>
                                </ion-modal>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="none">Laufzeit Bewerbung</ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-label>Start<sup>*</sup>:</ion-label>
                                <ion-datetime-button datetime="datetime-showDate"></ion-datetime-button>
                                <ion-modal [keepContentsMounted]="true">
                                    <ng-template>
                                        <ion-datetime
                                            id="datetime-showDate"
                                            formControlName="showDate"
                                            presentation="date"
                                            [min]="minDate"
                                            [max]="maxDate"
                                        >
                                            <span slot="title">Start Bewerbung</span>
                                        </ion-datetime>
                                    </ng-template>
                                </ion-modal>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item>
                                <ion-label>Ende<sup>*</sup>:</ion-label>
                                <ion-datetime-button datetime="datetime-closeDate"></ion-datetime-button>
                                <ion-modal [keepContentsMounted]="true">
                                    <ng-template>
                                        <ion-datetime
                                            id="datetime-closeDate"
                                            formControlName="closeDate"
                                            [min]="minDate"
                                            [max]="maxDate"
                                            presentation="date"
                                        >
                                            <span slot="title">Ende Bewerbung</span>
                                        </ion-datetime>
                                    </ng-template>
                                </ion-modal>
                            </ion-item>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col>
                            <mat-form-field>
                                <mat-label>Zeitzone</mat-label>
                                <mat-select formControlName="timezone">
                                    @for (timezone of timezoneOptions; track timezone) {
                                        <mat-option [value]="timezone.value">{{ timezone.key }}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item lines="none">Austragen durch Fahrer</ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-toggle
                                    [enableOnOffLabels]="true"
                                    [checked]="(job.deadline !== null)"
                                    (ionChange)="onToggleDeadline($event)"
                                    labelPlacement="start"
                                >Fahrer dürfen sich selbst Austragen</ion-toggle>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <div [style]="(!showDeadLine)?'display:none':''">
                        <ion-row>
                            <ion-col>
                                <ion-item>
                                    <ion-label>Austragen bis<sup>*</sup>:</ion-label>
                                    <ion-datetime-button datetime="datetime-deadline"></ion-datetime-button>
                                    <ion-modal [keepContentsMounted]="true">
                                        <ng-template>
                                            <ion-datetime
                                                id="datetime-deadline"
                                                formControlName="deadline"
                                                [min]="minDate"
                                                [max]="maxDate"
                                                presentation="date"
                                            >
                                                <span slot="title">Austragen bis</span>
                                            </ion-datetime>
                                        </ng-template>
                                    </ion-modal>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </div>
                } @else if (formType === 'places') {
                    <ion-row>
                        <ion-col>
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    Plätze
                                    <ui-form-error [showError]="showError(form, 'freePlaces')">
                                        Anzahl der Plätze muss größer 0 sein.
                                    </ui-form-error>
                                </mat-label>
                                <input type="number" matInput formControlName="freePlaces" autocomplete="off">
                            </mat-form-field>
                        </ion-col>
                    </ion-row>
                } @else {
                    <ion-row>
                        <ion-col>
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    Name
                                    <ui-form-error [showError]="showError(form, 'name')">
                                        Name derf nicht leer sein.
                                    </ui-form-error>
                                </mat-label>
                                <input type="text" matInput formControlName="name" autocomplete="off">     
                            </mat-form-field>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <mat-form-field appearance="outline">
                                <mat-label>
                                    Beschreibung
                                    <ui-form-error [showError]="showError(form, 'description')">
                                        Beschreibung derf nicht leer sein.
                                    </ui-form-error>
                                </mat-label>
                                <textarea matInput formControlName="description" autocomplete="off" rows="5"></textarea>
                            </mat-form-field>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col>
                            <mat-slide-toggle color="primary" formControlName="moderated">
                                <ion-label>moderierte Bewerbungen</ion-label>
                            </mat-slide-toggle>
                        </ion-col>
                    </ion-row>
                }

            </form>
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>

            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickSave()" color="success" *ngIf="form.valid">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}
