import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { JobKnowledgeHelperService } from '../../../services/job-knowledge-helper.service';
import { IconDirective, IconEnum, IconThemeEnum, JobListInterface, TitleIconEnum } from '@nf-workforce/shared';

@Component({
    selector: 'joblist-knowledge-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon
    ],
    templateUrl: './knowledge-card.component.html',
    styleUrl: './knowledge-card.component.scss',
    providers: [
        JobKnowledgeHelperService
    ]
})
export class KnowledgeCardComponent implements OnInit {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    @Input()
    job!: JobListInterface | null;

    @Output()
    editKnowledge: EventEmitter<string> = new EventEmitter();

    constructor(
        private knowledgeHelper: JobKnowledgeHelperService,
    ) {
    }

    ngOnInit() {
        if (this.job) {
            this.knowledgeHelper.setJob(this.job);
        }
    }

    async clickEditJobKnowledge(formType: string) {
        this.editKnowledge.emit(formType);
    }

    getSkillsStrings(): string {
        return this.knowledgeHelper.getSkillsStrings();
    }

    getFahrerlaubnisStrings(): string {
        return this.knowledgeHelper.getFahrerlaubnisStrings();
    }

    getLanguageStrings(): string {
        return this.knowledgeHelper.getLanguageStrings();
    }
}

