import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const AGENCY_SCHEMA = new InjectionToken<FormPage>('"Agentur Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'agency',
            header: 'Name der Agentur',
            navigation_label: 'agency',
            elements: [
                {
                    id: 'agency-name',
                    type: 'textarea',
                    model: 'name',
                    label: 'Name der Agentur',
                    required: true
                },
                {
                    id: 'agency-full-name',
                    type: 'textarea',
                    model: 'fullName',
                    label: 'Voller Name der Agentur',
                    required: true
                },
                {
                    id: 'agency-description',
                    type: 'textarea',
                    model: 'description',
                    label: 'Beschreibung der Agentur',
                    required: false
                },
                {
                    id: 'agency-phone',
                    type: 'phone',
                    model: 'phone',
                    label: 'Telefonnummer',
                    required: false
                },
                {
                    id: 'agency-web',
                    type: 'textarea',
                    model: 'web',
                    label: 'Internetpräsenz der Agentur',
                    required: false
                }
            ]
        };
    }
});
