import { Route } from "@angular/router";
import { AccGuard, AuthGuard } from "@nf-workforce/shared";
import { AgencyListComponent } from "./component/agency-list/agency-list.component";
import { AgencyOneComponent } from "./component/agency-one/agency-one.component";
import { AgencyNewComponent } from "./component/agency-new/agency-new.component";

export const agencyRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: AgencyListComponent,
        canActivate: [AuthGuard, AccGuard],
        pathMatch: 'full',
        title: 'Agenturen',
        data: { protectedBy: 'agency_list' },
    },
    {
        path: 'new',
        component: AgencyNewComponent,
        canActivate: [AuthGuard, AccGuard],
        pathMatch: 'full',
        title: 'Neue Agentur',
        data: { protectedBy: 'agency_new' },
    },
    {
        path: ':id',
        component: AgencyOneComponent,
        canActivate: [AuthGuard, AccGuard],
        pathMatch: 'full',
        title: 'Agentur', // @todo overwrite in component @see https://stackoverflow.com/a/72834889
        data: { protectedBy: 'agency_list' },
    }
];
