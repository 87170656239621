
import { AddJobListComponent } from './component/add-job-list/add-job-list.component';
import { JobListComponent } from './component/joblist/job-list.component';
import { OneJobComponent } from './component/one-job/one-job.component';
import { Routes } from '@angular/router';
import { WorkforceComponent } from './component/workforce/workforce.component';

export const jobListRoutes: Routes = [
    {
        path: '',
        component: JobListComponent,
        pathMatch: 'full',
        title: 'Jobs',
    },
    {
        path: 'new',
        component: AddJobListComponent,
        pathMatch: 'full',
        title: 'Neuer Job',
    },
    {
        path: ':id',
        component: OneJobComponent,
        pathMatch: 'full',
        title: 'Job #', // @todo show job ID in title
    },
    {
        path: ':id/workforce',
        component: WorkforceComponent,
        pathMatch: 'full',
    }
];
