import { CountryInterface } from '../interfaces/country.interface';

export function countriesToOptions(options: CountryInterface[]): { key: string, value: string }[] {
    return options.map((val) => {
        return {
            key: val.id.toString(),
            value: val.country
        };
    })
}
