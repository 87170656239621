import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { SearchEventInterface } from '../../interfaces/search-event.interface';
import { isString } from '../../type-guards/is-string';

@Component({
    selector: 'shared-search-text',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule
    ],
    templateUrl: './search-text.component.html',
    styleUrl: './search-text.component.scss',
    providers: []
})
export class SearchTextComponent implements OnInit {

    control = new FormControl();

    @Input()
    label = "Suche";

    @Input()
    placeholder = "Suche";

    @Input()
    name!: string;

    @Input()
    isNumber: boolean | undefined = false;

    @Input()
    value: string | unknown = '';

    @Output()
    search: EventEmitter<SearchEventInterface<string>> = new EventEmitter();

    ngOnInit(): void {
        if (isString(this.value) || !isNaN(this.value as number)) {
            console.debug("Sollte den Value setzen");
            this.control.setValue(this.value);
        }
    }

    changeInput() {
        if (!isNaN(this.control.value) || (isString(this.control.value) && this.control.value.length > 0)) {
            this.search.emit({
                name: this.name,
                value: this.control.value
            });
        } else {
            this.search.emit({
                name: this.name,
                value: null
            });
        }
    }

}
