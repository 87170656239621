import { Pipe, PipeTransform } from '@angular/core';
import { DriverAgencyState } from '@nf-workforce/shared';

@Pipe({
    name: 'agencyState',
    standalone: true
})
export class AgencyStatePipe implements PipeTransform {

    transform(value: number): string {

        switch (value) {
            case DriverAgencyState.NEW: {
                return "Profil verborgen";
            }
            case DriverAgencyState.VALIDATE: {
                return "Validierung angefordert";
            }
            case DriverAgencyState.VALIDATED: {
                return "Validiert";
            }
            case DriverAgencyState.REJECTED: {
                return "Abgelehnt";
            }
            default: {
                return "Unbekannter Status";
            }
        }

    }


}
