@if(this.driver) {

    <ion-row>
        <ion-col>
            <ion-list>
                <ion-list-header>Welches Dokument wollen Sie hochladen?</ion-list-header>
                @for (eavDoc of eavDocuments; track eavDoc.id) {
                    @if (isSingleEavDocument(eavDoc.id) && eavDoc.isRequired && !hasDocument(eavDoc)) {
                        <ion-item (click)="onClickEavDoc(eavDoc.id)">
                            <ion-button
                                title="Upload: {{ eavDoc.name }}"
                                fill="clear"
                                size="small"
                            > <mat-icon [icon]="IconEnum.UPLOAD"
                                        [theme]="IconTheme.INHERIT" ></mat-icon>
                                &nbsp; {{ eavDoc.name }}</ion-button>
                        </ion-item>
                    }
                }
                @for (eavDoc of eavDocuments; track eavDoc.id) {
                    @if (!isSingleEavDocument(eavDoc.id) ) {
                        <ion-item (click)="onClickEavDoc(eavDoc.id)">
                            <ion-button
                                title="Upload: {{ eavDoc.name }}"
                                fill="clear"
                                size="small"
                            ><mat-icon [icon]="IconEnum.UPLOAD"
                                       [theme]="IconTheme.INHERIT" ></mat-icon>
                                &nbsp; {{ eavDoc.name }}</ion-button>
                        </ion-item>
                    }
                }
            </ion-list>
        </ion-col>
    </ion-row>
}
