import { Routes } from '@angular/router';
import { MessageComponent } from './components/message/message.component';  
import { MessageListComponent } from './components/message-list/message-list.component';
import { AddSingleMessageComponent } from './components/add-single-message/add-single-message.component';

export const messageRoutes: Routes = [
    {
        path: ':jobId',
        component: MessageComponent,
        children: [
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full',
            },
            {
                path: 'list',
                component: MessageListComponent,
                pathMatch: 'full',
                data: { protectedBy: 'joblist_message_list' }
            }, 
            {
                path: 'add-single',
                component: AddSingleMessageComponent,
                pathMatch: 'full',
                data: { protectedBy: 'joblist_message_list' }
            }
        ]
    }
];
