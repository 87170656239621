import { Component, EventEmitter,Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    BooleanToStringPipe,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    TitleIconEnum
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'joblist-stammdaten-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        PrintNbDatePipe,
        PrintNbDateTimePipe,
        BooleanToStringPipe
    ],
    templateUrl: './stammdaten-card.component.html',
    styleUrl: './stammdaten-card.component.scss',
})
export class StammdatenCardComponent {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;

    @Input()
    job!: JobListInterface | null;

    @Output()
    editStammdaten: EventEmitter<string> = new EventEmitter();

    async clickEditJobStammdaten(formType: string) {
        this.editStammdaten.emit(formType);
    }
}

