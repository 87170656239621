import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'ui-form-error',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule
    ],
    templateUrl: './form-error.component.html',
    styleUrl: './form-error.component.scss',
})
export class FormErrorComponent {

    @Input()
    showError = false;

}
