<ion-header>
    <ion-col>
        <ion-title>
          {{ title }}
        </ion-title>
    </ion-col>
</ion-header>

<ion-content>
    <ion-col>

        @if(form) {
            <nb-form
            [form]="form"
            appearance="outline"
            [delay]="0"
            [debug]="false"
        ></nb-form>
        }

    </ion-col>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="clickCancel()" color="secondary">
                <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                &nbsp; Abbrechen
            </ion-button>

        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="clickSave()" color="success">
                <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                &nbsp; Speichern
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
