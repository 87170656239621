<ion-content>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button
                size="small"
                fill="clear"
                title="Einstellungen"
                aria-label="Einstellungen"
                (click)="defaultList()">
                <mat-icon [icon]="IconEnum.SEARCH" [theme]="IconTheme.INHERIT"></mat-icon>
                Aktuelle Jobs
            </ion-button>
            <ion-button
                size="small"
                fill="clear"
                title="Einstellungen"
                aria-label="Einstellungen"
                (click)="defaultPastList()">
                <mat-icon [icon]="IconEnum.SEARCH" [theme]="IconTheme.INHERIT"></mat-icon>
                Abgelaufene Jobs
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-row>
            <ion-col>
                @if (joblists().length > 0) {
                    <div uiTable class="expand-xl fixed-header w-100" [changeEmitter]="changeEmitter">
                        <div uiTableHead>
                            <div uiTableCell>#</div>
                            <div uiTableCell>Name</div>
                            <div uiTableCell>Start Bewerbung</div>
                            <div uiTableCell>Ende Bewerbung</div>
                            <div uiTableCell>Start Job</div>
                            <div uiTableCell>Ende Job</div>
                            <div uiTableCell>Fahrer darf sich selbst austragen bis</div>
                            <div uiTableCell>Plätze</div>
                            <div uiTableCell>
                                belegte Plätze<br>
                                Warteliste | Aktiv | Abgelehnt
                            </div>
                            <div uiTableCell>Online</div>
                            <div uiTableCell></div>
                        </div>

                    @for (job of joblists(); track job) {
                        <div uiTableRow [attr.data-job-id]="job.id">
                            <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                                <div uiTableKicker># {{ job.id }}</div>
                                <div class="headline" (click)="(job.isActive) ? clickGoToWorkforce(job): clickEditJob(job)">
                                    <h3>{{ job.name }}</h3>
                                </div>
                            </div>

                            <div uiTableCell [hideOnCollapse]="true">
                                {{ job.id }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>Name: </span>
                                {{ job.name }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Start der Anzeige:
                                </span>
                                {{ job.showDate | printNbDate }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Ende der Anzeige:
                                </span>
                                {{ job.closeDate | printNbDate }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Start des Jobs:
                                </span>
                                {{ job.startDate | printNbDateTime }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Ende des Jobs:
                                </span>
                                {{ job.endDate | printNbDateTime }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Fahrer darf sich selbst wieder austragen bis:
                                </span>
                                {{ job.deadline | printNbDate }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Freie Plätze:
                                </span>
                                {{ job.freePlaces }}
                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    Belegte Plätze<br>
                                </span>
                                Warteliste: {{ job.countWaiting }}<br>
                                Aktiv: {{ job.countAccepted }}<br>
                                Abgelehnt: {{ job.countRejected }}

                            </div>

                            <div uiTableCell>
                                <span uiTableCellLabel>
                                    ist derzeit Aktiv<br>
                                </span>
                                {{ job.isActive | booleanToString }}
                            </div>

                            <div uiTableCell>
                                <ion-buttons slot="end">
                                    <ion-button
                                        size="small"
                                        fill="clear"
                                        title="Einstellungen"
                                        aria-label="Einstellungen"
                                        (click)="clickEditJob(job)">
                                        <mat-icon [icon]="Icon.SETTINGS" [theme]="IconTheme.INHERIT"></mat-icon>
                                    </ion-button>

                                    <ion-button
                                        size="small"
                                        fill="clear"
                                        title="Personalliste"
                                        aria-label="Personalliste"
                                        (click)="clickGoToWorkforce(job)">
                                        <mat-icon [icon]="Icon.MANAGE_ACCOUNTS" [theme]="IconTheme.INHERIT"></mat-icon>
                                    </ion-button>
                                    <ion-button
                                        size="small"
                                        fill="clear"
                                        title="Nachrichten"
                                        aria-label="Nachrichten"
                                        (click)="clickGoToMessages(job)">
                                        <mat-icon [icon]="Icon.MESSAGES" [theme]="IconTheme.INHERIT"></mat-icon>
                                    </ion-button>
                                </ion-buttons>
                            </div>
                        </div>
                    }

                    @if(enableMoreResults()) {
                        <div uiTableFooter>
                            <ion-button
                                size="small"
                                fill="outline"
                                [color]="IconTheme.INHERIT"
                                (click)="clickLoadNextPage()"
                                title="Mehr Ergebnisse laden"
                                aria-label="Mehr Ergebnisse laden"
                            ><mat-icon [icon]="IconEnum.DOWNLOAD_NEXT_PAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                                &nbsp;mehr Ergebnisse laden
                            </ion-button>
                        </div>
                    }
                </div>
                } @else {
                    <ion-row>
                        <ion-col class ="ion-text-center">
                            <ion-text color="danger">
                                Es wurden keine Ergebnisse gefunden.
                            </ion-text>
                        </ion-col>
                    </ion-row>

                }
            </ion-col>
        </ion-row>
</ion-content>
