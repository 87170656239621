import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService } from 'nb-form';
import { IonicModule, ModalController } from '@ionic/angular';
import { MyAccountService } from '../../../services/my-account.service';
import {
    ToastService,
    LoadingService,
    LoginResponseInterface,
    AuthService,
    IconDirective,
    IconEnum,
    IconThemeEnum
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'lib-my-account-remove-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ReactiveFormsModule
    ],
    templateUrl: './my-account-remove-modal.component.html',
    styleUrl: './my-account-remove-modal.component.scss',
    providers: [
        MyAccountService
    ]
})
export class MyAccountRemoveModalComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    title = "Account löschen";
    user: LoginResponseInterface;
    form: FormGroup;
    username: FormControl;

    constructor(
        private alertService: AlertsService,
        private authService: AuthService,
        private loading: LoadingService,
        private myAccountService: MyAccountService,
        private modalController: ModalController,
        private router: Router,
        private toastService: ToastService,
    ) {
        this.user = this.authService.getSession() as LoginResponseInterface;
        const opt = {
            username: new FormControl('', [Validators.required]),
        }
        this.form = new FormGroup(opt);
        this.username = opt.username;
    }

    get isFormValid() {
        if (this.form.invalid) {
            return false;
        }
        const value = this.username.value;
        return (value === this.user.username);
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    async clickDeleteAccount() {
        if (!this.isFormValid) {
            await this.toastService.presentToast('Bitte prüfen Sie Ihre Eingabe!');
            return;
        }
        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie Ihren Account jetzt endgültig löschen?",
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (confirmation) {
            await this.deleteAccount();
        }
    }



    private async deleteAccount() {
        await this.loading.start('Bitte warten ... Account wird gelöscht.');
        const result = await this.myAccountService.deleteAccount().catch(async (e) => {
            await this.loading.stop();
            console.debug("Fehler beim Löschen des Accounts: ", e);
            await this.toastService.presentToast("Fehler beim Löschen des Accounts: Bitte versuchen Sie es später erneut.");
            return null;
        });
        if (result) {
            await this.loading.stop();
            await this.loading.start('Bitte warten ... Account wurde gelöscht: System wird gesäubert');
            window.setTimeout(() => this.logout(), 800);
        }
    }

    async logout() {
        this.authService.clearSession();
        await this.router.navigate(['/login']);
    }

}
