import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import {
    DriverAgencyState,
    DriverDataInterface,
    DriverProfileInterface,
    DriverAgencyInterface,
    LoadingService,
    LoginResponseInterface, PrintNbDatePipe,
    ProfileImageService, ToastService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AuthService, FullnamePipe } from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { AgencyStatePipe } from '../../../pipe/agency-state.pipe';
import { AgencyStateColorPipe } from '../../../pipe/agency-state-color.pipe';
import { DriverProfileService } from '../../../service/driver-profile.service';
import { AlertsService } from 'nb-form';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-image-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FullnamePipe,
        AgencyStatePipe,
        AgencyStateColorPipe,
        IconDirective,
        MatIcon,
        PrintNbDatePipe
    ],
    templateUrl: './profile-image-card.component.html',
    styleUrl: './profile-image-card.component.scss',
    providers: [
        ProfileImageService,
        FullnamePipe,
    ]
})
export class ProfileImageCardComponent implements OnInit {

    showPlace: WritableSignal<boolean> = signal(false);
    @Input()
    driver: DriverDataInterface | null = null;
    @Input()
    profile: DriverProfileInterface | null = null;
    @Input()
    driverAgencies: DriverAgencyInterface[] = [];
    @Output()
    edit: EventEmitter<string> = new EventEmitter();
    @Output()
    reload: EventEmitter<boolean> = new EventEmitter();

    user: LoginResponseInterface;
    profileImage: unknown = null;

    constructor(
        private alertService: AlertsService,
        private authService: AuthService,
        private loadingService: LoadingService,
        private profileImageService: ProfileImageService,
        private profileService: DriverProfileService,
        private toastService: ToastService,
    ) {
        this.user = this.authService.getSession() as LoginResponseInterface;
    }
    async ngOnInit() {
        await this.setProfileImage();
        this.showPlace.set(true);
    }

    clickEdit(profileType: string) {
        this.edit.emit(profileType);
    }

    async setProfileImage() {
        const result = await this.profileImageService.getProfileImage(this.user.id)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler beim Laden", e);
                return null;
            });
        if (result) {
            this.profileImage = result;
        }
    }

    get agencyHeadline(): string {
        if (this.driverAgencies && this.driverAgencies.length > 1) {
            return 'Ihre Agenturen';
        }
        return 'Ihre Agentur';
    }

    /**
     * Gibt zurück, ob der Fahrer bei der Agentur eine Validierung beauftragen kann
     */
    canValidate(driverAgency: DriverAgencyInterface): boolean {
        return (driverAgency.status === DriverAgencyState.NEW || driverAgency.status === DriverAgencyState.REJECTED);
    }



    async clickRequestValidation(driverAgency: DriverAgencyInterface) {
        const aName = driverAgency.agencyName;
        if (!this.canValidate(driverAgency)) {
            const errorMsg = `Keine Validierung bei der Agentur ${aName} möglich - derzeitiger Status: ${driverAgency.status}`;
            console.warn(errorMsg);
            await this.toastService.presentToast(`Keine Validierung bei der Agentur ${aName} möglich`);
            return;
        }
        const question = `Validierung des Profils bei "${aName}" anfordern?`;
        const confirmation = await this.alertService.confirm({
            text: question,
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
        if (confirmation) {
            await this.loadingService.start();
            const result = await this.profileService.requestValidation(driverAgency)
                .catch(async (e: HttpErrorResponse) => {
                    console.warn("Fehler bei der Anfrage: ", e);
                    await this.toastService.presentToast(
                        "Fehler bei der Anfrage, bitte versuchen Sie es später erneut."
                    );
                    return null;
                });

            await this.loadingService.stop();
            if (result) {
                const msg = `Die Validierung bei der Agentur "${aName}" wurde angefragt.`;
                await this.toastService.presentToast(msg);
                this.reload.emit(true);
            }
        }
        return;
    }

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
}
