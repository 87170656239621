import { Route } from "@angular/router";
import { NewJobsComponent } from "./component/new-jobs/new-jobs.component";
import { DriverJobComponent } from "./component/new-jobs/driver-job/driver-job.component";
import { MyJobsComponent } from "./component/my-jobs/my-jobs.component";
import { OneJobListComponent } from "./component/my-jobs/one-job-list/one-job-list.component";

export const driverJoblistRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'open',
        pathMatch: 'full',
    },
    {
        path: 'open',
        pathMatch: 'full',
        component: NewJobsComponent,
        title: 'Offene Jobs',
    },
    {
        path: 'open/:id',
        pathMatch: 'full',
        component: DriverJobComponent,
    },
    {
        path: 'overview',
        pathMatch: 'full',
        redirectTo: 'overview/200',
        title: 'Meine Jobs',
    },
    {
        path: 'overview/:type',
        pathMatch: 'full',
        component: MyJobsComponent,
        title: 'Meine Jobs',
    },
    {
        path: 'one/:id',
        pathMatch: 'full',
        component: OneJobListComponent,
    }
];
