
export function calculateAge(birthDate: Date): number {
  const today = new Date();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  let ageInYears = today.getFullYear() - birthDate.getFullYear();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      ageInYears--;
  }
  return ageInYears;
}
