
import { AuthService } from './auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { isAllowedByAcc } from '../function/is-allowed-by-acc.function';
import { PageActionInterface } from '../interfaces/page-action.interface';
import { PageFilterAction } from '../classes/page-filter-action.class';

@Injectable({ providedIn: 'root' })
export class PageActionService {

    private actionEmitter: EventEmitter<void> = new EventEmitter<void>();
    private backAction: PageActionInterface | null = null;
    private filterAction: PageFilterAction | null = null;
    private filterState: 'filtered' | 'unfiltered' = 'unfiltered';
    private pageActions: PageActionInterface[] = [];

    constructor(private authService: AuthService) { }

    clear() {
        this.pageActions = [];
        this.backAction = null;
        this.filterAction = null;
        this.actionEmitter.emit();
    }

    updates() {
        return this.actionEmitter;
    }

    // -- general page actions --

    addAction(action: PageActionInterface) {
        this._addAction(action);
        this.actionEmitter.emit();
    }

    addActions(actions: PageActionInterface[]) {
        actions.forEach(action => {
            this._addAction(action);
        });
        this.actionEmitter.emit();
    }

    getPageActions(): PageActionInterface[] {
        return this.pageActions;
    }

    private _addAction(action: PageActionInterface) {
        if (this._hasAction(action.id)) {
            throw new Error(
                'Error cae47169-1212-4260-a03c-e342a0782424: action already registered.'
            );
        }
        this.pageActions.push(action);
    }

    private _hasAction(id: string): boolean {
        return this.pageActions.filter(
            registeredAction => registeredAction.id === id
        ).length > 0;
    }

    // -- back button --

    addBackAction(action: PageActionInterface) {
        if (this.backAction) {
            throw new Error(
                'Error 5b385792-c488-420b-add0-6cd94aeb5006: back action already registered.'
            );
        }
        this.backAction = action;
        this.actionEmitter.emit();
    }

    getBackAction(): PageActionInterface | null {
        return this.backAction;
    }

    // -- search filter --

    addFilterAction(action: PageFilterAction) {
        if (this.filterAction) {
            throw new Error(
                'Error bd669429-ffee-4eeb-be74-343a16097520: filter action already registered.'
            );
        }
        /**
         * Filter action needs to be checked against ACC. This is different from
         * regular page actions, which are checked through the
         * `NavigationItemComponent` which renders them.
         */
        if (
            action.protectedBy
            && !isAllowedByAcc(action.protectedBy, this.authService.acl)
        ) {
            return;
        }
        this.filterAction = action;
        this.actionEmitter.emit();
    }

    getFilterAction(): PageFilterAction | null {
        return this.filterAction;
    }

    isFiltered(): boolean {
        if (!this.filterAction) {
            throw new Error('Error XXX: no filter action');

        }
        return this.filterState === 'filtered'
    }

    setFilterState(newState: 'filtered' | 'unfiltered') {
        if (this.filterState === newState) {
            return;
        }
        this.filterState = newState;
    }
}
