import { Injectable } from '@angular/core';
import { ENV, HttpService, JobListInterface, PaginatorResponse, SearchQueryInterface } from '@nf-workforce/shared';
import { AddJobListInterface } from '../interface/joblist/add-job-list.interface';
import { EavRequestInterface } from '../interface/joblist/eav-request.interface';


@Injectable()
export class JobListService {
    constructor(
        private httpService: HttpService
    ) {}

    get jobListRoute(): string {
        return `${ENV.apiUrl}/joblist`;
    }

    /**
     * Holen der Liste der JobLists
     *
     * @param page
     * @param limit
     * @returns
     */
    async getList(page: number, limit: number, searchQuery: SearchQueryInterface) {
        const query = {
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        };

        return this.httpService
            .getBypassCache<PaginatorResponse<JobListInterface>>(this.jobListRoute, query);
    }

    /**
     * Suche einer Jobliste anhand der übergebenen ID
     *
     * @param id
     * @returns
     */
    async getOne(id: number) {
        return this.httpService
            .getBypassCache<PaginatorResponse<JobListInterface>>(this.jobListRoute, {
                page: 1,
                limit: 1,
                id: id
            });
    }

    /**
     * Hinzufügen einer neuen Joblist
     *
     * @param payload
     * @returns
     */
    async addNewJob(payload: AddJobListInterface) {
        return this.httpService
            .post(this.jobListRoute, payload);
    }

    /**
     * Aktualisierung einer JobList
     *
     * @param id
     * @param payload
     * @returns
     */
    async updateJob(id: number, payload: AddJobListInterface) {
        const url = `${this.jobListRoute}/${id}`;
        return this.httpService
            .patch(url, payload);
    }

    /**
     *
     * @param id
     * @param payload
     * @returns
     */
    async patchFahrerlaubnis(id: number, payload: EavRequestInterface) {
        const route = `${this.jobListRoute}/${id}/fahrerlaubnis`;
        return this.httpService.patch(route, payload)
    }

    /**
     *
     * @param id
     * @param payload
     * @returns
     */
    async patchSkills(id: number, payload: EavRequestInterface) {
        const route = `${this.jobListRoute}/${id}/skill`;
        return this.httpService.patch(route, payload)
    }

    /**
     *
     * @param id
     * @param payload
     * @returns
     */
    async patchLanguages(id: number, payload: EavRequestInterface) {
        const route = `${this.jobListRoute}/${id}/language`;
        return this.httpService.patch(route, payload)
    }

    /**
     * Sendet die Schnittstellenanfrage zum aktivieren/deaktivieren der Joblist mit der übergebenen ID
     *
     * @param id
     * @returns
     */
    async toggleJoblist(id: number) {
        const route = `${this.jobListRoute}/${id}/active`;
        return this.httpService.patch(route, {});
    }

    /**
     * Gibt zurück, ob eine Bulk-Nachricht für den übergebenen Job möglich ist
     * 
     * @param jobId 
     * @returns 
     */
     getIsBulkMessagePossible(jobId: number) {
        const url = `${ENV.apiUrl}/joblist/${jobId}/bulk-communication`;
        return this.httpService.get(url);
    }

}
