
<ion-row>
    <ion-col>
        @if (form) {
            <nb-form
            #formInstance
            [form]="form"
            appearance="outline"
            [delay]="0"
            [debug]="false"
            [helpers]="helpers"
            (changeData)="onChangeForm()"
            ></nb-form>
        }
        
    </ion-col>
</ion-row>

<ion-row>
    @if (selectedGroups().length > 0) {
        <ion-col>
            <ion-row>
                <ion-col>
                    <ion-list>
                        <ion-item>
                            <ion-label>
                                Ausgewählte Gruppen:
                            </ion-label>
                        </ion-item>
                        @for (groupId of selectedGroups(); track groupId) {
                            <ion-item>
                                {{ displayGroup(groupId) }}
                            </ion-item>
                        }
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-col>
    }

    @if (selectedUsers().length > 0) {
        <ion-col>
            <ion-row>
                <ion-col>
                    <ion-list>
                        <ion-item>
                            <ion-label>
                                Ausgewählte Fahrer:
                            </ion-label>
                        </ion-item>
                        @for (userId of selectedUsers(); track userId) {
                            <ion-item>
                                {{ displayDriver(userId) }}
                            </ion-item>
                        }
                    </ion-list>
                </ion-col>
            </ion-row>
            
        </ion-col>
    }
    
</ion-row>

@if (form) {
    <ion-row>
        <ion-button (click)="clickSave()" [disabled]="disableSave()">
            Speichern
        </ion-button>
    </ion-row>
}

