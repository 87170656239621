@if(showPlace()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Stammdaten</h2>
                <mat-icon class="driver-account-header__icon"
                    [icon]="Icon.ID_CARD"
                    [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Person</span>
                </label>
                <span class="driver-skill__skill">
                   {{ account.firstName }} {{ account.lastName }}
                    <br>
                    {{account.username}}
                    @if (account.phone) {
                        <br>{{ account.phone }}
                    }
                </span>
            </div>
            <br/>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Adresse</span>
                </label>
                <span class="driver-skill__skill">
                    @if (profile.street) {
                        {{ profile.street }}<br>
                    }
                    @if (profile.zipCode || profile.city) {
                        {{ profile.zipCode }} {{ profile.city }} <br>
                    }
                    @if (profile.area) {
                        {{ profile.area }}&nbsp;,
                    }
                    @if(profile.country) {
                        {{ profile.country }}
                    }
                </span>
            </div>
            <br/>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Geboren</span>
                </label>
                <span class="driver-skill__skill">
                    Am: {{ profile.birthday | printNbDate }}<br>
                    In: {{ profile.birthplace}}
                </span>
                <br>
                <span class="driver-skill__skill">
                    Staatsangehörigkeit: {{ profile.nationality }}
                </span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Abrechnung</span>
                </label>
                <span class="driver-skill__skill">
                    Steuer-ID: {{ profile.taxId }}<br>
                    KV-Nummer: {{ profile.kkNr }} <br>
                    RV-Nummer:  {{ profile.rvNr }} <br>
                    IBAN: {{ profile.iban }}
                </span>
            </div>
        </ion-card-content>
    </ion-card>
}
