import { Component, OnInit, signal,  ViewContainerRef, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { PhotoCropperComponent } from '@nf-workforce/ui';
import {
    ENV,
    LoadingService,
    ToastService,
    SelectedFileInterface,
    UploadImageRequest,
    UploadImageModalComponent,
    AgencyInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum
} from '@nf-workforce/shared';
import { AlertsService } from 'nb-form';
import { MyAgencyService } from '../../../services/my-agency.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-agency-logo',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon
    ],
    templateUrl: './agency-logo.component.html',
    styleUrl: './agency-logo.component.scss',
})
export class AgencyLogoComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    agency!: AgencyInterface;
    logoImage!: string;
    selected_file: SelectedFileInterface | null = null;

    showPlace: WritableSignal<boolean> = signal(false);
    hasProfileBild: WritableSignal<boolean> = signal(false);
    hasFileSelected: WritableSignal<boolean> = signal(false);

    constructor(
        private alertService: AlertsService,
        private loadingService: LoadingService,
        private myAgencyService: MyAgencyService,
        private modalController: ModalController,
        private router: Router,
        private toastService: ToastService,
        private viewContainer: ViewContainerRef,
    ) {
    }

    async ngOnInit(): Promise<void> {
        await this.loadAgency(true);
    }

    async loadAgency(cached: boolean) {
        this.showPlace.set(false);
        this.hasFileSelected.set(false);
        await this.loadingService.start();
        const agencyResult = await this.getAgency(cached);
        await this.loadingService.stop();
        if (agencyResult) {
            this.agency = agencyResult;
            this.hasProfileBild.set((this.agency.logo !== null));
            this.showPlace.set(true);
        }
    }

    async getAgency(cached: boolean) {
        if (cached) {
            return await this.myAgencyService.getCachedAgency()
                .catch((e) => {
                    console.warn("Fehler beim LAden der Agentur", e);
                    return null;
                });
        }
        return await this.myAgencyService.getAgency()
            .catch((e) => {
                console.warn("Fehler beim LAden der Agentur", e);
                return null;
            });
    }

    get currentImageSource() {
        if (this.hasFileSelected() && this.selected_file) {
            return this.selected_file.data.replace(/\s/g, '');
        }
        return this.agency
            ? `${ENV.apiUrl}/public/images/274e2d31-a2e7-461f-bda1-36c4d766c519/agency/${this.agency.id}/logo`
            : '';
    }

    async clickSelectNewImage() {
        const modal = await this.modalController.create({
            component: UploadImageModalComponent,
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                if (result.data['saved'] && result.data['saved'] === true) {
                    if (result.data['selectedFile'] && result.data['selectedFile'] !== null) {
                        this.selected_file = result.data['selectedFile'];
                        this.hasFileSelected.set(true);
                    }
                }
            }
        }
    }

    async showPhotoCropper() {
        const photoCropper = this.viewContainer.createComponent(PhotoCropperComponent);
        photoCropper.setInput('url', this.currentImageSource);
        photoCropper.setInput('onCancel', () => {
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
            this.viewContainer.clear();
        });
        photoCropper.setInput('onSave', (data: string) => {
            if (this.selected_file) { // nach upload
                this.selected_file.data = data;
            } else {
                this.selected_file = {
                    name: "profileImage",
                    data: data,
                }
                this.hasFileSelected.set(true);
            }
            this.viewContainer.remove(
                this.viewContainer.indexOf(photoCropper.hostView)
            );
        });
    }

    async clickUploadImage() {
        if (this.selected_file) {
            let fileName = this.selected_file.name;
            if (fileName === "profileImage") {
                const type = this.selected_file.data.split(';')[0].split('/')[1];
                fileName = `${this.agency.id}-agencyLogo.${type}`;
            }
            const FileItem = {
                name: fileName,
                data: this.selected_file.data,
            }
            const payload = { file: [FileItem] } as UploadImageRequest;
            const confirmation = await this.alertService.confirm({
                text: "Ihr Agentur-Logo jetzt hochladen?",
                textOk: "Ja",
                textCancel: "Nein"
            });
            if (confirmation) {
                await this.uploadImage(payload);
            }
        } else {
            await this.toastService.presentToast('Keine Datei zum Hochladen vorhanden!');
        }
    }

    async uploadImage(payload: UploadImageRequest) {
        await this.loadingService.start();
        const result = await this.myAgencyService.uploadLogo(payload)
            .catch((e: HttpErrorResponse) => {
                console.debug(e);
                this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut.');
                return null;
            });
        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast('Ihr Profilbild wurde erfolgreich hochgeladen.');
            await this.router.navigate(['/my-agency']);
        }
    }

    async clickDeleteImage() {
        if (this.hasProfileBild()) {
            const confirmation = await this.alertService.confirm({
                text: "Ihr Agentur-Logo jetzt löschen?",
                textOk: "Ja",
                textCancel: "Nein"
            });
            if (confirmation) {
                await this.loadingService.start();
                const result = await this.myAgencyService.deleteLogo()
                    .catch((e: HttpErrorResponse) => {
                        console.debug(e);
                        this.toastService.presentToast('Fehler bei der Anfrage, bitte versuchen Sie es später erneut.');
                        return null;
                    });
                await this.loadingService.stop();
                if (result) {
                    await this.toastService.presentToast('Ihr Agentur-Logo wurde erfolgreich gelöscht.');
                    await this.loadAgency(false);
                }
            }
        }
    }
}
