import { Component, Inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import {
    BooleanToStringPipe,
    SearchQueryInterface,
    SearchMenuInterface,
    PageActionService,
    ToastService,
    LoadingService,
    PageFilterAction,
    PageAction,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { AgencyGroupInterface } from '../../interfaces/agency-group.interface';
import { DriverGroupService } from '../../service/driver-group.service';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableHeadlineDirective,
    TableKickerDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { agencyGroupSearch } from '../../search/agency-group.config';
import { AlertsService } from 'nb-form';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { DriverAgencyProfileStatePipe } from '@nf-workforce/agency';

@Component({
    selector: 'driver-group-list',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableHeadlineDirective,
        TableKickerDirective,
        TableRowDirective,
        BooleanToStringPipe,
        DriverAgencyProfileStatePipe,
        IconDirective,
        MatIcon
    ],
    providers: [
        DriverGroupService
    ],
    templateUrl: './driver-group-list.component.html',
    styleUrl: './driver-group-list.component.scss',
})
export class DriverGroupListComponent implements OnInit {

    agencyGroups: WritableSignal<AgencyGroupInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);
    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };

    private nextPage = 1;
    private limit = 25;

    constructor(
        @Inject(agencyGroupSearch) public searchConfig: SearchMenuInterface,
        private driverGroupService: DriverGroupService,
        private pageActionService: PageActionService,
        private router: Router,
        private alertsService: AlertsService,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {}

    async ngOnInit(): Promise<void> {
        this.agencyGroups.set(await this.loadDriverGroups());
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) }
        ));
        this.pageActionService.addAction(PageAction.route(
            'Neue Gruppe',
            '/driver-groups/new',
            'agency_one_driver_group_create',
        ));
    }

    /**
     * Laden der Fahrergruppen anhand der derzeitigen Suche
     *
     * @returns
     */
    private async loadDriverGroups(): Promise<AgencyGroupInterface[]> {
        const response = await this.driverGroupService.getList(this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.log("Fehler bei der Anfrage", e);
                return null;
            });

        if (response) {
            this.nextPage = response.page + 1;

            if (this.nextPage <= response.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return response.data;
        }

        return [];
    }

    /**
     * Nachladen weiterer Ergebnisse
     */
    async loadNextPage() {
        const currentGroups = this.agencyGroups();
        const newGroups = await this.loadDriverGroups();
        this.agencyGroups.set(currentGroups.concat(newGroups));
    }

    /**
     * Routing zur Einzelansicht der Gruppen
     *
     * @param group
     */
    goToGroup(group: AgencyGroupInterface) {
        this.router.navigate([ `/driver-groups/one/${group.id}`]);
    }

    /**
     * handler beim Ändern der Suche
     *
     * @param $event
     */
    async searchChanged($event: SearchQueryInterface) {
        this.nextPage = 1;
        this.searchQuery = $event;
        this.agencyGroups.set(await this.loadDriverGroups());
    }

    /**
     * Löschen einer Fahrergruppe
     *
     * @param group
     */
    async deleteGroup(group: AgencyGroupInterface) {
        const confirmation = await this.alertsService.confirm({
            text: `Möchten Sie die Gruppe ${group.name} wirklich löschen?`
        });

        if (confirmation) {
            await this.loadingService.start();
            const result = await this.driverGroupService.deleteGroup(group.id)
                .catch((e: HttpErrorResponse) => {
                    console.warn("Fehler bei der Anfrage", e);
                    this.toastService.presentToast("Fehler beim Löschen der Gruppe. Bitte versuchen Sie es später erneut.");
                    return null;
                });
            await this.loadingService.stop();

            if (result) {
                this.toastService.presentToast(`Die Gruppe ${group.name} wurde erfolgreich gelöscht.`);
                this.nextPage = 1;
                this.agencyGroups.set( await this.loadDriverGroups());
            }
        }

    }

  protected readonly IconTheme = IconThemeEnum;
  protected readonly IconEnum = IconEnum;
    protected readonly Icon = TitleIconEnum;
}
