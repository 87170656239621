<ion-content>
    <ion-row>
        <ion-col>
            <ion-toolbar>
                <ion-buttons>
                    @if(!agency()?.isActive) {
                    <ion-button fill="outline" color="success" title="Agentur aktivieren" (click)="clickEnableAgency()">
                        Aktivieren
                    </ion-button>
                    } @else {
                    <ion-button fill="outline" color="warning" title="Agentur deaktivieren" (click)="clickDisableAgency()">
                        Deaktivieren
                    </ion-button>
                    }
                    <ion-button fill="outline" color="danger" title="Agentur löschen" (click)="clickDeleteAgency()">
                        Löschen
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-col>
    </ion-row>

    @if (agency()) {
    <ion-row>
        <ion-col>
            <ion-card>
                <ion-card-header class="text-center">
                    <ion-card-title>Stammdaten</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    @if (agencyForm) {
                    <ion-row>
                        <ion-col>
                            <nb-form #agencyFormInstance [form]="agencyForm" appearance="outline" [delay]="0"
                                [debug]="false"></nb-form>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-col>
                            <ion-button fill="outline" color="primary" (click)="clickSave()">
                                Speichern
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    }

                </ion-card-content>
            </ion-card>
        </ion-col>
        <ion-col>
            <ion-card>
                <ion-card-header class="text-center">
                    <ion-card-title>
                        Aktuelles Logo
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <div class="text-center current-logo">
                        <ui-avatar [url]="logoUrl" [sizeRem]="16"></ui-avatar>
                        @if (hasUploadedLogo()) {
                        <ion-button fill="outline" color="primary" (click)="showPhotoCropper()">zuschneiden</ion-button>
                        }
                    </div>
                    @if (agencyLogoForm) {
                    <ion-row>
                        <ion-col>
                            <nb-form #agencyLogoInstance [form]="agencyLogoForm" appearance="outline" [delay]="0"
                                [debug]="false"></nb-form>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            @if (hasSelectedLogoFile()) {
                            <ion-button fill="outline" color="primary" (click)="clickUploadLogo()">hochladen</ion-button>
                            <ion-button fill="outline" color="primary" (click)="clickCropLogo()">zuschneiden &amp; hochladen</ion-button>
                        }
                        </ion-col>
                    </ion-row>
                    }
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>
    }
</ion-content>
