import { InjectionToken } from '@angular/core';
import { FormPage } from 'nb-form';

export const ACCEPT_DRIVER_VALIDATION_SCHEMA = new InjectionToken<FormPage>('"Fahrerprofil Validieren Schema"', {
    factory: () => {
        return {
            ber_reiter_id: 'acceptDriverValidation',
            header: '',
            navigation_label: 'acceptDriverValidation',
            useGrid: true,
            gridSize: 12,
            elements: [
                {
                    id: 'validUntil',
                    type: 'date',
                    model: 'validUntil',
                    label: 'Gültig bis',
                    columnSize: 12,
                    required: true,
                    range: {
                        year: {
                            min: '+0',
                            max: '+20'
                        }
                    },
                }
            ]
        };
    }
});
