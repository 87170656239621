import { Injectable } from "@angular/core";
import { from, lastValueFrom, map, of, switchMap } from "rxjs";
import { LoadingController } from '@ionic/angular';
import { findOne } from "../function/dom-find.function";

@Injectable()
export class LoadingService {

    showCloseButton = false;

    private loading = 0;

    constructor(private loadingController: LoadingController) {}

    start(message?: string): Promise<unknown> {
        this.loading = this.loading + 1;
        if (this.loading > 1) {
            return Promise.resolve();
        }

        return lastValueFrom(from(this.loadingController.getTop()).pipe(
            switchMap((dialog) => {
                if (dialog) {
                    return of(dialog);
                }

                return from(this.loadingController.create({
                    message: message ? message : 'Bitte warten...'
                })).pipe(
                    map(dom => this.showCloseButton ? this.renderCloseButton(dom) : dom),
                    switchMap(loading => loading.present())
                );
            })
        ));
    }

    stop() {
        this.loading = this.loading - 1;
        if (this.loading > 0) {
            return;
        }

        if (this.loading < 0) {
            this.loading = 0;
        }

        return this.loadingController.getTop().then((dialog) => {
            if (dialog && this.loading === 0) {
                return dialog.dismiss();
            }

            return false;
        });
    }

    isLoading() {
        return this.loading > 0;
    }

    /**
     * Funktion zum hinzufügen des Schließen-Button zum Loading-Popover
     * 
     * 
     * @param loading 
     * @returns 
     */
    private renderCloseButton(loading: HTMLIonLoadingElement) {
        const container = findOne('.loading-wrapper', loading);

        if (container) {
            const closeContainer = document.createElement('div');
            closeContainer.classList.add('loading-clear');
            const button = document.createElement('button');
            button.innerHTML = '<i class="fas fa-times"></i>';
            button.addEventListener('click', () => loading.dismiss());
            closeContainer.append(button);
            container.append(closeContainer);
        }
      
        return loading;
    }

}