import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DocumentEavInterface,
    DriverDataInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
} from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'driver-document-upload-wizard-select-two-files',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        MatIcon,
        ReactiveFormsModule,
        IconDirective,
    ],
    templateUrl: './select-document-has-two-files.component.html',
    styleUrl: './select-document-has-two-files.component.scss',
})
export class SelectDocumentHasTwoFilesComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;

    @Input()
    driver: DriverDataInterface | null = null;
    @Input()
    currentEav: DocumentEavInterface | null = null;
    @Output()
    hasJustOneSite: EventEmitter<boolean> = new EventEmitter();

    title = 'Dokument hochladen';

    async ngOnInit() {
        if (this.driver && this.currentEav) {
            this.title = this.currentEav?.name + ' hochladen';
        }
    }

    setHasBackSideRadio(event: CustomEvent) {
        const justOneSite = event.detail.value as boolean;
        this.hasJustOneSite.emit(justOneSite);
    }

}
