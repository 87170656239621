import { Pipe, PipeTransform } from '@angular/core';
import { DriverAgencyState } from '@nf-workforce/shared';

@Pipe({
    name: 'driverAgencyProfileState',
    standalone: true
})
export class DriverAgencyProfileStatePipe implements PipeTransform {

    transform(value: number | null | undefined): string {

        switch (value) {
            case DriverAgencyState.NEW: {
                return 'unvollständig';
            }
            case DriverAgencyState.VALIDATE: {
                return 'wartet auf Freischaltung';
            }
            case DriverAgencyState.VALIDATED: {
                return "Freigeschaltet";
            }
            case DriverAgencyState.REJECTED: {
                return "Abgelehnt";
            }
            default: {
                return "Unbekannter Status";
            }
        }
    }
}
