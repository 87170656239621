
<ion-row class="form">
    <ion-col>
        @if (form) {
        <nb-form #nbFormInstance [form]="form" appearance="outline" [delay]="0"></nb-form>
        <ion-row class="buttons">
            <ion-button expand="full" (click)="clickResetPassword()" [disabled]="disableButton">
                Passwort zurücksetzen
            </ion-button>
            <ion-button expand="full" (click)="clickLoginLink()">
                zum Login
            </ion-button>
        </ion-row>
        }
    </ion-col>
</ion-row>
