import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DriverJoblistService } from '../../services/driver-joblist.service';
import { HttpErrorResponse } from '@angular/common/http';
import {
    TableDirective,
    TableHeadDirective,
    TableRowDirective,
    TableCellDirective,
    TableCellLabelDirective,
    TableFooterDirective,
    TableKickerDirective
} from '@nf-workforce/ui';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import {
    PrintNbDatePipe,
    BooleanToStringPipe,
    PrintNbDateTimePipe,
    JobListInterface,
    LoadingService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';

@Component({
    selector: 'driver-joblist-new-jobs',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableDirective,
        TableHeadDirective,
        TableRowDirective,
        TableCellDirective,
        TableCellLabelDirective,
        TableFooterDirective,
        PrintNbDatePipe,
        BooleanToStringPipe,
        TableKickerDirective,
        PrintNbDateTimePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './new-jobs.component.html',
    styleUrl: './new-jobs.component.scss',
    providers: [
        DriverJoblistService
    ]
})
export class NewJobsComponent implements OnInit {

    driverJoblists: WritableSignal<JobListInterface[]> = signal([]);
    showView: WritableSignal<boolean> = signal(false);

    hasMoreResults: WritableSignal<boolean> = signal(false);

    private nextPage = 1;
    private limit = 20;

    constructor(
        private loadingService: LoadingService,
        private joblistService: DriverJoblistService,
        private router: Router
    ) {}

    async ngOnInit(): Promise<void> {
        const jobs = await this.loadDriverJobs();
        this.driverJoblists.set(jobs);
        this.showView.set(true);
    }

    /**
     * Laden der Jobliste für den derzeit eingeloggten Fahrer
     *
     * @returns
     */
    async loadDriverJobs() {
        await this.loadingService.start();

        const result = await this.joblistService.getOpenJobLists(this.nextPage, this.limit)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage: ", e);
                return null;
            });

        await this.loadingService.stop();
        if (!result) {
            return [];
        }

        this.nextPage = result.page + 1;
        if (this.nextPage <= result.countResultPages) {
            this.hasMoreResults.set(true);
        } else {
            this.hasMoreResults.set(false);
        }

        return result.data;
    }

    /**
     * Nachladen der Daten
     */
    async clickLoadNextPage() {
        const currentJobs = this.driverJoblists();
        const newJobs = await this.loadDriverJobs();
        this.driverJoblists.set(currentJobs.concat(newJobs));
    }

    /**
     * NAvigation zur Einzelansicht des übergebenen Jobs
     *
     * @param job
     */
    async goToJob(job: JobListInterface) {
        const route = `/my-jobs/open/${job.id}`;
        await this.router.navigate([route]);
    }

    jobHasFreePlaces(job: JobListInterface): boolean {
        return (job.freePlaces - job.countAccepted > 0);
    }


    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
}
