<ion-menu menuId="searchMenu" autoHide="true" side="end" contentId="main">
    <ion-header>
        <ion-toolbar>
            <div class="page-toolbar-content">
                <span class="page-title">Ergebnisse filtern</span>
            </div>
        </ion-toolbar>
    </ion-header>
    <ion-content>
        <ion-col>
            @if (showSearch()) {
                <ion-row>
                    <ion-col>
                        <ion-title>
                            Suche
                        </ion-title>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            @for (entry of filterConfig?.searchElements; track entry.name) {
                                <ion-item (click)="toggleElement('search', entry.name)" [button]="true">
                                    <ion-label>{{ entry.label }}</ion-label>
                                </ion-item>
        
                                @if (displayedSearchElements[entry.name]) {
                                    <ion-item lines="none">
                                        <ion-col class="ion-padding">
                                            @switch (entry.type) {
                                                @case ("text") {
                                                <shared-search-text [isNumber]="entry.isNumber" [placeholder]="entry.placeholder"
                                                    [name]="entry.name" [value]="searchQuery.search[entry.name]"
                                                    (search)="updateSearchModel($event)"></shared-search-text>
                                                }
                                                @case ("selection") {
                                                <shared-search-selection [options]="entry.selection" [name]="entry.name"
                                                    (search)="updateSearchModel($event)"></shared-search-selection>
                                                }
                                                @case ("date") {
                                                <shared-search-date [name]="entry.name" [type]="getSearchDateType(entry)"
                                                    [presentation]="entry.datePresentation"
                                                    (search)="updateSearchModel($event)"></shared-search-date>
                                                }
                                            }
                                        </ion-col>
                                    </ion-item>
                                }
                            }
                        </ion-list>
                    </ion-col>
                </ion-row>
            }
    
            @if (showSort()) {
                <ion-row>
                    <ion-col>
                        <ion-title>
                            Sortierung
                        </ion-title>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            @for (entry of filterConfig?.sortElements; track entry.name) {
                                <ion-item (click)="toggleElement('sort', entry.name)" [button]="true">
                                    <ion-label>{{ entry.label }}</ion-label>
                                </ion-item>
        
                                @if (displayedSortElements[entry.name]) {
                                    <ion-item lines="none">
                                        <ion-col class="ion-padding">
                                            <shared-sort [sortElement]= "entry" (changeSort)="onSortChanged($event)"></shared-sort>
                                        </ion-col>
                                    </ion-item>
                                }
                            }
                        </ion-list>
                    </ion-col>
                </ion-row>
            }



            <ion-row>
                <ion-col>
                    <ion-toolbar>
                        <ion-buttons slot="secondary">
                            <ion-button (click)="clickReset()">Zurücksetzen</ion-button>
                        </ion-buttons>
                        <ion-buttons slot="primary">
                            <ion-button (click)="clickApply()">Anwenden</ion-button>
                        </ion-buttons>
                    </ion-toolbar>
                </ion-col>
            </ion-row>
        </ion-col>
        
    </ion-content>
</ion-menu>
