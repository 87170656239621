
import { Injectable } from '@angular/core';
import { AclModel, LoginResponseInterface } from '../interfaces/login-response.interface';
import { AuthServiceInterface } from '../interfaces/auth-service.interface';
import { isAllowedByAcc } from '../function/is-allowed-by-acc.function';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements AuthServiceInterface {

    private session: LoginResponseInterface | undefined = undefined;

    public forceRefreshToken = true;

    public SESSION_STORAGE_GUID = 'd028db8e-cf52-492f-b80c-a27bdd904ef9';


    isAuthenticated() {
        return !!this.token;
    }

    setSession(session: LoginResponseInterface) {
        localStorage.setItem(this.SESSION_STORAGE_GUID, JSON.stringify(session));
        this.session = session;
        if (session) {
            this.forceRefreshToken = false;
        }
    }

    setValueInSession(field: string, value: string|number|boolean|null) {
        const session = this.getSession();
        if (session) {
            const sessionKeys = Object.keys(session);

            if (!sessionKeys.includes(field)) {
                throw new Error(`Ungültiger Schreibvorgang: Feld ${field} existiert nicht in der Session.`);
            }

            Object.assign(session, { [field]: value });
            this.setSession(session);
        }
    }

    updatePersonInSession(
        sex: string,
        academic: string|null,
        firstName: string|null,
        lastName: string,
        phone: string
    ): void {
        this.setValueInSession('sex', sex);
        this.setValueInSession('academic', academic);
        this.setValueInSession('firstName', firstName);
        this.setValueInSession('lastName', lastName);
        this.setValueInSession('phone', phone);
    }

    getSession(): LoginResponseInterface | undefined {
        if (this.session) {
            return this.session;
        }

        const session = localStorage.getItem(this.SESSION_STORAGE_GUID);
        if (typeof session === 'string') {
            this.session = JSON.parse(session);
            return this.session;
        }

        this.forceRefreshToken = true;

        return undefined;
    }

    clearSession() {
        localStorage.removeItem(this.SESSION_STORAGE_GUID);
        this.forceRefreshToken = true;
        delete this.session;
    }

    get userId(): number | undefined {
        const session = this.getSession();

        return session ? session.id : undefined;
    }

    get isValidated(): boolean {
        const session = this.getSession();
        if (session && session.isValidated) {
            return true;
        }

        return false;
    }

    get token(): string | undefined {
        const session = this.getSession();

        if (session) {
            return session.token;
        }

        return undefined;
    }

    get acl(): AclModel[] {
        const session = this.getSession();
        return !!session && Array.isArray(session.acl) ? session.acl : [];
    }

    get roles(): number[] {
        const session = this.getSession();
        return !!session && Array.isArray(session.roles) ? session.roles : [];
    }

    public hasPermission(route: string): boolean {
        return isAllowedByAcc(route, this.acl);
    }
}
