import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV } from '@nf-workforce/shared';

export const DRIVER_LICENCE_ID = 1;
export const DRIVER_PSHEIN_ID = 2;

@Injectable()
export class EavService {
    constructor(
        private http: HttpClient
    ) {}

    private get skillRoute(): string {
        return `${ENV.apiUrl}/eav_skill_list`;
    }

    private get documentRoute(): string {
        return `${ENV.apiUrl}/eav_document_list`;
    }

    private get countryRoute(): string {
        return `${ENV.apiUrl}/eav_country_list`;
    }

    private get languageRoute(): string {
        return `${ENV.apiUrl}/eav_language_list`;
    }

    private get fahrerlaubnisRoute(): string {
        return `${ENV.apiUrl}/eav_fahrerlaubnis_list`;
    }

    getSkills() {
        return this.http.get(this.skillRoute);
    }

    getDocuments() {
        return this.http.get(this.documentRoute);
    }

    getCountries() {
        return this.http.get(this.countryRoute);
    }

    getLanguages() {
        return this.http.get(this.languageRoute);
    }

    getFahrerlaubnis() {
        return this.http.get(this.fahrerlaubnisRoute);
    }

}