@if (job) {
    <ion-content>
        <ion-row>
            <ion-col>
                <ion-card class="show-on-hover-trigger">
                    <ion-card-header>
                        <div class="driver-account-header">
                            <h2 class="driver-account-header__text">
                                {{ job.name }}
                            </h2>
                        </div>
                    </ion-card-header>
                    <ion-card-content>
                        <div class="driver-skill">
                            <label class="driver-skill__label">
                                <span class="driver-skill__label-text">
                                     @if (job.listType === DriverJobListType.TYPE_ACTIVE) {
                                         Sie sind für den Job gebucht!
                                     } @else if (job.listType === DriverJobListType.TYPE_WAITING) {
                                         Sie sind auf der Warteliste!
                                     } @else if (job.listType === DriverJobListType.TYPE_REJECTED) {
                                         Sie werden für den Job nicht benötigt oder haben sich selbst ausgetragen!
                                     }
                                </span>
                            </label>
                        </div>
                        <br>
                        <ion-row>
                            <ion-col>
                                <driver-joblist-one-job-stammdaten-card
                                    [job]="job"
                                    [canRemove]="canRemove"
                                    [isDriverJob]="true"
                                ></driver-joblist-one-job-stammdaten-card>
                            </ion-col>
                            <ion-col>
                                <driver-joblist-one-job-knowledge-card
                                    [job]="job"
                                ></driver-joblist-one-job-knowledge-card>
                                <ion-toolbar>
                                    <ion-buttons slot="end">
                                        @if (canRemove) {
                                            <ion-button
                                                size="small"
                                                fill="outline"
                                                [color]="IconTheme.DANGER"
                                                (click)="clickRemoveFromJob()"
                                                title="Bewerben"
                                                aria-label="Bewerben"
                                            ><mat-icon
                                                [icon]="IconEnum.DELETE"
                                                [theme]="IconTheme.DANGER">
                                            </mat-icon>
                                                &nbsp;Austragen
                                            </ion-button>
                                        }
                                        <ion-button
                                            size="small"
                                            fill="outline"
                                            [color]="IconTheme.INHERIT"
                                            (click)="goBackToList()"
                                            title="Zurück zur Liste"
                                            aria-label="Zurück zur Liste"
                                        ><mat-icon
                                            [icon]="IconEnum.BACK"
                                            [theme]="IconTheme.INHERIT">
                                        </mat-icon>
                                            &nbsp;Liste
                                        </ion-button>
                                    </ion-buttons>
                                </ion-toolbar>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-content>
} @else {
    @if (!jobFound) {
        <ion-content>
            <ion-col>
                <ion-row>
                    <ion-col>
                        <ion-text>Es konnte kein Job gefunden werden!</ion-text>
                    </ion-col>
                </ion-row>
                <ion-row>
                        <ion-col>
                            <ion-toolbar>
                                <ion-buttons slot="start">
                                    <ion-button
                                        size="small"
                                        fill="outline"
                                        [color]="IconTheme.INHERIT"
                                        (click)="goBackToList()"
                                        title="Zurück zur Liste"
                                        aria-label="Zurück zur Liste"
                                    ><mat-icon
                                        [icon]="IconEnum.BACK"
                                        [theme]="IconTheme.INHERIT">
                                    </mat-icon>
                                        &nbsp;Liste
                                    </ion-button>
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-col>
                    </ion-row>
            </ion-col>
        </ion-content>
    }
}
