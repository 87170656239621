import { Injectable } from '@angular/core';
import {
    DriverJobListType,
    ENV,
    HttpService,
    JobDriverInterface,
    PaginatorResponse,
    QueryParamsInterface,
    SearchQueryInterface
} from '@nf-workforce/shared';
import { MessageInterface } from '../interfaces/message.interface';
import { SingleMessageRequestInterface } from '../interfaces/single-message-request.interface';
import { BulkMessageRequestInterface } from '../interfaces/bulk-message-request.interface';
import { HttpResponse } from '@angular/common/http';


@Injectable()
export class MessageService {
    constructor(
        private httpService: HttpService
    ) {}

    private listRoute = `${ENV.apiUrl}/joblist/communication`;

    private jobId = 0;

    /**
     * Gibt die Liste der Nachrichten für die Jobliste mit der übergebenen Id zurück
     * 
     * @param jobId 
     * @param page 
     * @param limit 
     * @param searchQuery 
     * @returns 
     */
    getMessages(
        jobId: number,
        page: number,
        limit: number,
        searchQuery: SearchQueryInterface
    ) {
        const query = {
            idJob: jobId,
            ...searchQuery.search,
            ...searchQuery.sort,
            page: page,
            limit: limit
        } as QueryParamsInterface;

        return this.httpService
            .getBypassCache<PaginatorResponse<MessageInterface>>(this.listRoute, query);
    }

    /**
     * Setzt die JobId im Servixe
     * 
     * @param id 
     */
    setJobId(id: number) {
        this.jobId = id;
    }

    /**
     * Gibt die derzeitige JobId zurück
     * 
     * @returns 
     */
    getJobId(): number {
        return this.jobId;
    }

    /**
     * Gibt die Liste der Fahrer für eine Benachrichtigung zurück
     * 
     * @param jobId 
     * @returns 
     */
    getDriverList(jobId: number) {
        const queryParams = {
            listType: DriverJobListType.TYPE_ACTIVE,
            page: 1,
            limit: 500
        } as QueryParamsInterface;

        const route = `${ENV.apiUrl}/joblist/${jobId}/driver`;
        return this.httpService.getBypassCache<PaginatorResponse<JobDriverInterface>>(route, queryParams);
    }

    /**
     * Erstellt eine Nachricht in der übergebenen Jobliste
     * 
     * @param jobId 
     * @param payload 
     * @returns 
     */
    createMessage(jobId: number, payload: SingleMessageRequestInterface) {
        const url = `${ENV.apiUrl}/joblist/${jobId}/communication`;
        return this.httpService.post(url, payload, true);
    }

    /**
     * Gibt zurück, ob eine Bulk-Nachricht für den übergebenen Job möglich ist
     * 
     * @param jobId 
     * @returns 
     */
    getIsBulkMessagePossible(jobId: number) {
        const url = `${ENV.apiUrl}/joblist/${jobId}/bulk-communication`;
        return this.httpService.get(url);
    }

    /**
     * Erstellt eine BUlk-Nachricht für die Jobliste
     * 
     * @param jobId 
     * @param payload 
     * @returns 
     */
    createBulkMessage(jobId: number, payload: BulkMessageRequestInterface) {
        const url = `${ENV.apiUrl}/joblist/${jobId}/bulk-communication`;
        return this.httpService.post<HttpResponse<unknown>>(url, payload);
    }

}
