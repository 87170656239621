import { Component, WritableSignal, signal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsService } from 'nb-form';
import { IonicModule, ModalController, ModalOptions } from '@ionic/angular';
import {
    AuthService,
    LoginResponseInterface,
    ProfileImageService,
    FullnamePipe, RoleEnum,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { MyAccountService } from '../../services/my-account.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { ProfilPasswordModalComponent } from './password-modal/profil-password-modal.component';
import { ProfilPersonModalComponent } from './person-modal/profil-person-modal.component';
import { ProfilUsernameModalComponent } from './username-modal/profil-username-modal.component';
import { MyAccountRemoveModalComponent } from './remove-account/my-account-remove-modal.component';
import { Router } from '@angular/router';
import { DriverJoblistService } from '@nf-workforce/driver-joblist';

@Component({
    selector: 'lib-my-account',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FullnamePipe,
        IconDirective,
        MatIcon
    ],
    templateUrl: './my-account.component.html',
    styleUrl: './my-account.component.scss',
    providers: [
        MyAccountService,
        DriverJoblistService,
        ProfileImageService,
    ]
})
export class MyAccountComponent implements OnInit{

    protected readonly Icon = TitleIconEnum;
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    profileImage: any = null;
    user!: LoginResponseInterface;
    canDriverDeleteAccount = true;

    showPlace: WritableSignal<boolean> = signal(false);


    constructor(
        private alertService: AlertsService,
        public authService: AuthService,
        private modalController: ModalController,
        private joblistService: DriverJoblistService,
        private profileImageService: ProfileImageService,
        private router: Router,
    ) {
        this.user = this.authService.getSession() as LoginResponseInterface;
    }

    get isDriver() {
        const isDriver = this.user.roles?.find((role) => {
            return role === RoleEnum['DRIVER'];
        });
        return !!isDriver;
    }

    private getModalOptions(profileType: string) {
        if (profileType === 'profil-person') {
            return  {
                component: ProfilPersonModalComponent,
            }
        }
        if (profileType === 'profil-username') {
            return  {
                component: ProfilUsernameModalComponent,
            }
        }
        if (profileType === 'profil-password') {
            return  {
                component: ProfilPasswordModalComponent,
            }
        }
        return null;
    }

    async showModal(opt: ModalOptions) {
        const modal = await this.modalController.create(opt);
        await modal.present();
        const result = await modal.onDidDismiss();
        if (result) {
            if (typeof result.data !== 'undefined') {
                // Nachladen, wenn Datensatz erfolgreich bearbeitet
                if (result.data['saved'] && result.data['saved'] === true) {
                    await this.reload();
                }
            }
        }
    }

    async ngOnInit() {
        await this.reload();
        if (this.isDriver) {
            const result = await this.joblistService.getJobCounts()
                .catch((e: HttpErrorResponse) => {
                    console.warn("Fehler beim holen der Badge-Counter", e);
                    return null;
                });

            if (result) {
                if (result.waiting > 0 || result.accepted > 0) {
                    this.canDriverDeleteAccount = false;
                }
            }
        }
    }

    async reload() {
        this.showPlace.set(false);
        this.user = this.authService.getSession() as LoginResponseInterface;
        await this.setProfileImage();
        this.showPlace.set(true);
    }

    async setProfileImage() {
        const result = await this.profileImageService.getProfileImage(this.user.id)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler beim Laden", e);
                return null;
            });
        if (result) {
            this.profileImage = result;
        }
    }

    async clickEdit(profileType: string) {
        const opt = this.getModalOptions(profileType);
        if (opt === null) {
            return;
        }
        await this.showModal(opt);
    }

    async clickGoToProfileImage() {
        await this.router.navigate(['/account/my-profile-image']);
    }

    async clickDeleteAccount() {
        const confirmation = await this.alertService.confirm({
            text: "Möchten Sie Ihren Account endgültig löschen?",
            textOk: "Ja",
            textCancel: "Nein"
        });
        if (confirmation) {
            const modal = await this.modalController.create({
                component: MyAccountRemoveModalComponent,
            });
            await modal.present();
            await modal.onDidDismiss();
        }
    }

}
