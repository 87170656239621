<ion-header>
    <ion-col>
        <ion-title>
            {{ title() }}
        </ion-title>
    </ion-col>
</ion-header>

<ion-content class ="ion-padding">
   <ion-col>
    @if (form) {
        <nb-form
            [form]="form"
            appearance="outline"
            [delay]="0"
            [debug]="false"
        ></nb-form>
    }
       
   </ion-col>
</ion-content>

<ion-footer class ="ion-padding">
    <ion-toolbar>
        <ion-buttons slot="primary">
            <ion-button fill="outline" (click)="confirm()">
                Speichern
            </ion-button>
            <ion-button fill="outline" (click)="cancel()">
                Abbrechen
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
