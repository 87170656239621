@if(user) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{ title }}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-row>
            <ion-col>
                <ion-card>
                    <ion-card-header>
                        <ion-card-title [color]="IconTheme.DANGER">
                            Sie wollen Ihren Account vollständig löschen!
                        </ion-card-title>
                        <ion-card-subtitle>
                            Diese Aktion ist unwiderruflich!
                        </ion-card-subtitle>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                <p>
                                    Bitte geben Sie Ihren Benutzernamen ein.
                                    Diese Aktion verhindert, dass Sie versehentlich Ihren Account löschen!
                                </p>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <form [formGroup]="form">
                                    <ion-item>
                                        <ion-input
                                            label="Ihr Benutzername"
                                            formControlName="username">
                                        </ion-input>
                                    </ion-item>
                                </form>
                            </ion-col>
                        </ion-row>
                        <ion-toolbar *ngIf="isFormValid">
                            <ion-buttons slot="end">
                                <ion-button
                                    fill="outline"
                                    (click)="clickDeleteAccount()"
                                    [color]="IconTheme.DANGER"
                                >
                                    <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.INHERIT" ></mat-icon>
                                    &nbsp; Account jetzt löschen!
                                </ion-button>
                            </ion-buttons>
                        </ion-toolbar>
                    </ion-card-content>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-content>

    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>

}
