import { Routes } from "@angular/router";
import { DriverGroupListComponent } from "./component/driver-group-list/driver-group-list.component";
import { DriverGroupNewComponent } from "./component/driver-group-new/driver-group-new.component";
import { DriverGroupOneComponent } from "./component/driver-group-one/driver-group-one.component";

export const driverGroupRoutes: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        pathMatch: 'full',
        component: DriverGroupListComponent,
        title: 'Gruppen',
    },
    {
        path: 'new',
        pathMatch: 'full',
        component: DriverGroupNewComponent,
        title: 'Neue Gruppe',
    },
    {
        path: 'one/:id',
        pathMatch: 'full',
        component: DriverGroupOneComponent,
    }
];
