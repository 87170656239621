/* eslint-disable @typescript-eslint/no-explicit-any */

import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { InjectionToken, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginServiceInterface } from '../interfaces/login-service.interface';

export const LOGIN_SERVICE = new InjectionToken<LoginServiceInterface>(
  'LOGIN_SERVICE',
);

export const AuthInterceptor: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
    const authService = inject(AuthService);
    const loginService = inject(LOGIN_SERVICE) as LoginServiceInterface;
    const router = inject(Router);
    
    if (authService.isAuthenticated()) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${authService.token}`,
          }
        });
    }
    
    return next(req).pipe(
        catchError(err => {
          if (err.status === 401 && req.url !== loginService.apiLoginUrl) {
            authService.clearSession();
            router.navigate([loginService.frontendLoginUrl]).then(() => window.location.reload());
          }
          return throwError(err);
        })
      );  
};