import { Pipe, PipeTransform } from '@angular/core';
import { UserListDtoInterface } from '@nf-workforce/shared';

@Pipe({
    name: 'recipients',
    standalone: true
})
export class RecipientsPipe implements PipeTransform {
    transform(recipients: UserListDtoInterface[]): string {
        let recipientsStr = "";

        for (const user of recipients) {
            recipientsStr = recipientsStr + ` ${user.firstName} ${user.lastName},`;
        }
     
        return recipientsStr.substring(0, recipientsStr.length - 1).trimStart()
  }

}