
import { AclModel } from '../interfaces/login-response.interface';
import { parseAccRoutes } from './parse-acc-routes.function';

/**
 * @param {*} unparsedRouteDef unparsed route definition, e.g. `'route_id'`, `['route_id1', 'route_id2']`, etc.
 * @param {AclModel[]} acl allowed routes for user received in login response
 * @returns {boolean} true if the given route/routes is/are allowed for the user
 */
export function isAllowedByAcc(
    unparsedRouteDef: unknown,
    acl: AclModel[]
): boolean {

    let aclsToCheck: string[] = [];

    if (Array.isArray(unparsedRouteDef)) {
        unparsedRouteDef.filter(item => typeof item === 'string');
        aclsToCheck = unparsedRouteDef.filter(rId => typeof rId === 'string');
    } else if (typeof unparsedRouteDef === 'string') {
        aclsToCheck = parseAccRoutes(unparsedRouteDef);
    } else {
        console.warn('isAllowedByAcc: UNPARSABLE INPUT', unparsedRouteDef);
        return false;
    }

    // iterate over allowed routes
    for (const protectedRoute of aclsToCheck) {
        if (acl.find(allowedRoute => {
            return allowedRoute.route === protectedRoute
                ? allowedRoute
                : undefined;
        })) {
            return true;
        }
    }

    return false;
}
