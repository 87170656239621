
const ALLOWED_DELIMITERS: string[] = [' ', '|', ',', ';'];

/**
 * @param routes single or multiple ACC routes separated by allowed delimiters
 * @returns array of ACC route IDs
 */
export function parseAccRoutes(routes: string): string[] {
    // check for multi-route string
    let delim = '';
    ALLOWED_DELIMITERS.forEach(allowedDelim => {
      if (routes.trim().includes(allowedDelim)) {
        delim = allowedDelim;
      }
    });

    // build array of routes to check
    if (delim.length === 0) {
      return [routes.trim()];
    }
    return routes.split(delim).map(route => route.trim());
}
