import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import {
    DriverLanguageInterface,
    DriverDataInterface,
    DriverReferencesInterface,
    LoadingService,
    ToastService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { AlertsService } from 'nb-form';
import { DriverProfileService } from '../../../service/driver-profile.service';
import { DriverReferenceRequestInterface } from '../../../interface/requests/driver-reference-request.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { DriverLanguageHelperService } from '../../../service/driver-language-helper.service';

@Component({
    selector: 'driver-my-profile-knowledge-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
    ],
    templateUrl: './profile-knowledge-card.component.html',
    styleUrl: './profile-knowledge-card.component.scss',
    providers: [
        DriverLanguageHelperService
    ]
})
export class ProfileKnowledgeCardComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
    @Input()
    driver: DriverDataInterface | null = null;
    @Output()
    edit: EventEmitter<string> = new EventEmitter();
    @Output()
    newReference: EventEmitter<unknown> = new EventEmitter();
    @Output()
    editReference: EventEmitter<DriverReferencesInterface> = new EventEmitter();
    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    references: DriverReferencesInterface[] | null = null;

    constructor(
        private alertsService: AlertsService,
        private driverProfileService: DriverProfileService,
        private languageHelper: DriverLanguageHelperService,
        private loadingService: LoadingService,
        private toastService: ToastService,

    ) { }

    ngOnInit() {
        if (this.driver) {
            this.languageHelper.setDriver(this.driver);
        }
        this.setReferences();
    }


    setReferences() {
        if (this.driver) {
            this.references = this.driver.driverUserDto.driverReferences;
        }
    }

    get driverLanguages(): DriverLanguageInterface[] | null {
        return this.languageHelper.driverLanguages;
    }

    get motherLanguages(): DriverLanguageInterface[] {
        return this.languageHelper.motherLanguages;
    }

    get otherLanguages(): DriverLanguageInterface[] {
        return this.languageHelper.otherLanguages;
    }

    getLanguageHeader(isMotherLanguage: boolean): string {
        return this.languageHelper.getLanguageHeader(isMotherLanguage);
    }

    getLanguagesAsString(): string {
        const name: string[] = [];
        if(this.driverLanguages && this.driverLanguages.length > 0) {
            if (this.motherLanguages.length > 0) {
                this.motherLanguages.forEach((lang) => {
                    const label = `${lang.name} (Muttersprache)`;
                    name.push(label);
                })
            }
            if (this.otherLanguages.length > 0) {
                this.otherLanguages.forEach((lang) => {
                    const label = `${lang.name} (${lang.level})`;
                    name.push(label);
                })
            }
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }

    getSkillsStrings(): string {
        const name: string[] = [];
        if (this.driver) {
            const driverSkills = this.driver.driverUserDto.skills;
            driverSkills.forEach((skill) => {
                name.push(skill.name);
            })
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }

    clickEdit(profileType: string) {
        this.edit.emit(profileType);
    }

    clickNewReference() {
        this.newReference.emit();
    }

    clickEditReference(reference: DriverReferencesInterface) {
        this.editReference.emit(reference);
    }

    async clickDeleteLanguage(language: DriverLanguageInterface | null) {
        if (language === null) {
            return;
        }
        const confirm = await this.alertsService.confirm({
            text: `Möchten Sie die Sprache "${language.name}" wirklich entfernen?`
        });
        if (confirm) {
            await this.loadingService.start();
            const result = await this.driverProfileService.deleteDriverLanguage({ id: language.id })
                .catch((e) => {
                    console.debug("Fehler bei der Anfrage.", e);
                    this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut.");
                    return null;
                });
            await this.loadingService.stop();
            if (result) {
                await this.toastService.presentToast(`${language.name} wurde erfolgreich entfernt.`);
                this.reload.emit();
            }
        }
    }



    async clickDeleteReference(reference: DriverReferencesInterface, index: number) {
        const confirm = await this.alertsService.confirm({
            text: `Möchten Sie die Referenz ${index + 1} löschen?`
        });
        if (confirm) {
            await this.loadingService.start();
            const payload = {
                id: reference.id,
                data: reference.data
            } as DriverReferenceRequestInterface
            const result = await this.driverProfileService.deleteDriverReference(payload)
                .catch((e: HttpErrorResponse) => {
                    console.debug("Fehler bei der Abfrage", e);
                    this.toastService.presentToast("Fehler beim Speichern. Bitte versuchen Sie es später erneut.");
                    return null;
                });

            await this.loadingService.stop();
            if (result) {
                await this.toastService.presentToast("Referenz wurde erfolgreich entfernt.");
                this.reload.emit();
            }
        }
    }

    protected readonly IconEnum = IconEnum;
}
