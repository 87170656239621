import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { DriverDataInterface, IconDirective, IconEnum, IconThemeEnum } from '@nf-workforce/shared';
import { MatIcon } from '@angular/material/icon';
import { ProfileFahrerlaubnisComponent } from '../fahrerlaubnis/profile-fahrerlaubnis.component';

@Component({
    selector: 'driver-my-profile-fahrerlaubnis-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ProfileFahrerlaubnisComponent
    ],
    templateUrl: './profile-fahrerlaubnis-modal.component.html',
    styleUrl: './profile-fahrerlaubnis-modal.component.scss',
})
export class ProfileFahrerlaubnisModalComponent {
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    title = 'Fahrerlaubnis-Klassen';

    @Input()
    driver: DriverDataInterface | null = null;

    constructor(private modalController: ModalController) {
    }


    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

}
