import { Component, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventType, Router, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MessageViewType } from '../../enum/message-list-type.enum';
import { JobListInterface, LoadingService, TitleService } from '@nf-workforce/shared';
import { MessageActionBarComponent } from '../message-action-bar/message-action-bar.component';
import { Subscription } from 'rxjs';
import { MessageService } from '../../service/message.service';
import { MessageJobService } from '../../service/message-job.service';

@Component({
    selector: 'message-overview',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        MessageActionBarComponent
    ],
    providers: [
        MessageService,
        MessageJobService
    ],
    templateUrl: './message.component.html',
    styleUrl: './message.component.scss',
})
export class MessageComponent implements OnInit, OnDestroy {

    private router = inject(Router);

    private jobListService = inject(MessageJobService);

    private titleService = inject(TitleService);

    private loadingService = inject(LoadingService);

    private messageService = inject(MessageService);

    private routeSub: Subscription;

    @Input()
    jobId!: number;

    currentViewType = signal<MessageViewType>(MessageViewType.LIST);

    currentJob = signal<JobListInterface | null>(null);

    constructor() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event.type === EventType.NavigationEnd) {
                if (event.url.endsWith('list')) {
                    this.currentViewType.set(MessageViewType.LIST);
                } else if (event.url.endsWith('add-single')) {
                    this.currentViewType.set(MessageViewType.SINGLE);
                }
            }
        });
    }

    async ngOnInit() {
        if (this.jobId) {
            this.messageService.setJobId(this.jobId);
            await this.loadingService.start();
            this.titleService.titleEmitter.emit(`Job #${this.jobId} - Nachrichten`);
            this.currentJob.set(await this.loadJob());
            await this.loadingService.stop();
        }
    }

    ngOnDestroy(): void {
        this.routeSub.unsubscribe();
    }

    /**
     * Lädt einen einzelnen Job anhand der derzeit im Komponent gespeicherten ID
     * 
     * @returns 
     */
    private async loadJob() {
        return await this.jobListService.getOne(this.jobId)
            .then((response) => {
                return response.data[0];
            });
    }

}
