@if(showPlace() && driverDocument && eavDocument) {
    <ion-card>
        <ion-card-header>
            <ion-card-title>{{eavDocument.name}}</ion-card-title>
            <ion-card-subtitle>
                @if(driverDocument.isValid) {
                    <span class="driver-skill__skill">
                        <ion-text>Dokument geprüft von {{ driverDocument.validatedBy }}</ion-text>
                    </span>
                } @else {
                    @if (driverDocument.validatedBy !== null) {
                        <span class="driver-skill__skill">
                            <ion-text [color]="IconTheme.DANGER">Dokument abgelehnt von {{ driverDocument.validatedBy }}</ion-text>
                        </span>
                    } @else {
                        <span class="driver-skill__skill">
                        <ion-text [color]="IconTheme.DANGER">Dokument nicht geprüft</ion-text>
                    </span>
                    }

                }
            </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
               
                @if (!driverDocument.hasBackside) {
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Dokument</span>
                        <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(false)">
                            <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT" ></mat-icon>
                        </span>
                    </label>
                } @else {
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Dokument</span>
                    </label>

                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Vorderseite</span>
                        <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(false)">
                            <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT" ></mat-icon>
                        </span>
                    </label>

                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Rückseite</span>
                        <span class="driver-skill__edit-button show-on-hover" role="button" (click)="clickDownload(true)">
                            <mat-icon [icon]="IconEnum.DOWNLOAD" [theme]="IconTheme.INHERIT" ></mat-icon>
                        </span>
                    </label>
                }
            </div>
            <br>
            @if (hasEavDates()) {
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Gültigkeit</span>
                    </label>
                    @if (hasEavCreationDate()) {
                        <span class="driver-skill__skill">
                        Ausgestellt am: {{driverDocument.createdDate | printNbDate }}
                    </span>
                    }
                    @if (hasEavCreationDate() && hasEavExpiredDate()) {
                        <br/>
                    }
                    @if (hasEavExpiredDate()) {
                        <span class="driver-skill__skill">
                        Gültig bis: {{driverDocument.expireDate | printNbDate }}
                    </span>
                    }
                </div>
                <br/>
            }

            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">{{getLabelDescription()}}</span>
                </label>
                <span class="driver-skill__skill">
                    @if(driverDocument.notice !== null && driverDocument.notice !== '') {
                    <ion-text class="whitespace-pre-wrap">
                        {{driverDocument.notice}}
                    </ion-text>
                    } @else {
                    -
                    }
                </span>
            </div>

            @if (isDriverLicence()) {
                <div class="driver-skill">
                    <label class="driver-skill__label">
                        <span class="driver-skill__label-text">Klassen</span>
                    </label>
                    <span class="driver-skill__skill">{{getDriverFahrerlaubnisAsStrings()}}</span>
                </div>
            }
            <ion-toolbar>
                @if(driverDocument.isValid || driverDocument.validatedBy === null) {
                <ion-buttons slot="start">
                    <ion-button
                        size="small"
                        fill="outline"
                        [color]="IconTheme.DANGER"
                        (click)="clickReject()">Ablehnen</ion-button>
                </ion-buttons>
                }
                @if (!driverDocument.isValid) {
                    <ion-buttons slot="end">
                    <ion-button
                        size="small"
                        fill="outline"
                        [color]="IconTheme.PRIMARY"
                        (click)="clickAccept()">Akzeptieren</ion-button>
                    </ion-buttons>
                }
            </ion-toolbar>
        </ion-card-content>
    </ion-card>
}
