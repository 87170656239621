import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { AddDriverToJobInterface } from '../../../../interface/workforce/add-driver-to-job.interface';
import { DriverListService } from '../../../../services/driver-list.service';
import {
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    LoadingService,
    ToastService,
    UserListDtoInterface
} from '@nf-workforce/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from 'nb-form';
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'joblist-list-driver-button-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatFormField,
        MatIcon,
        MatInput,
        MatLabel,
        MatOption,
        ReactiveFormsModule
    ],
    templateUrl: './list-driver-button-modal.component.html',
    styleUrl: './list-driver-button-modal.component.scss',
    providers: [
        DriverListService
    ],
})
export class ListDriverButtonModalComponent implements OnInit{
    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    driver!: UserListDtoInterface;
    @Input()
    job!: JobListInterface;
    @Input()
    newListType!: number;

    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    jobId = 0;
    driverId = 0;
    sendMail = false;


    constructor(
        private alertsService: AlertsService,
        private driverListService: DriverListService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private toastService: ToastService
    ) {
    }

    ngOnInit() {
        if (this.job && this.driver) {
            this.jobId = this.job.id;
            this.driverId = this.driver.id;
        }
    }

    get title(): string {
        let html = this.driverName;
        html += (this.newListType === 500) ? ' ablehnen' : ' verschieben';
        return  html;
    }

    get driverName(): string {
        let html = '';
        if (this.driver) {
            if (this.driver.firstName !== null && this.driver.firstName !== '') {
                html += `${this.driver.firstName} `;
            }
            html += `${this.driver.lastName}`;
        }
        return  html;
    }

    onToggleSendMail(event: CustomEvent) {
        this.sendMail = event.detail.checked;
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    private createPayload(): AddDriverToJobInterface {
        return {
            listType: this.newListType,
            sendMail: this.sendMail,
            userId: this.driverId
        }
    }

    private async isValid(payload: AddDriverToJobInterface) {
        if (payload.listType !== 100 && payload.listType !== 200 && payload.listType !== 500) {
            await this.toastService.presentToast('Keine Liste ausgewählt');
            return false;
        }
        if (typeof payload.userId === 'undefined' || payload.userId < 1) {
            await this.toastService.presentToast('Kein Fahrer ausgewählt');
            return false;
        }
        return true;
    }

    private async askSave() {
        let question = `Wollen Sie ${this.driverName} jetzt `;
        if (this.newListType === 500) {
            question += 'ablehnen';
        } else if (this.newListType === 200) {
            question += 'dem Job zuweisen';
        } else {
            question += 'auf die Warteliste verschieben';
        }
        question += (this.sendMail) ? ' UND informieren?' : ' UND NICHT informieren?';
        if (this.newListType === 500) {
            question += `\n\nAbgelehnte Fahrer:innen können sich nicht mehr auf diesen Job bewerben.`;
        }
        return await this.alertsService.confirm({
            text: question,
            textCancel: 'Nein',
            textOk: 'Ja'
        });
    }

    async clickSave() {
        const payload = this.createPayload();
        const isValid = await this.isValid(payload);
        if (!isValid) {
            return;
        }
        const confirmation = await this.askSave();
        if (!confirmation) {
            return;
        }
        await this.loadingService.start('Bitte warten ... ');
        const result = await this.driverListService.addDriver(this.jobId, payload)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fahrer:in konnte nicht hinzugefügt werden.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Fahrer:in wurde erfolgreich hinzugefügt");
            await this.modalController.dismiss({
                saved: true
            });
        }
    }
}
