import { AbstractControl, ValidationErrors } from "@angular/forms";
import { isString } from "@nf-workforce/shared";

export function passwordLengthValidator(control: AbstractControl): ValidationErrors | null {
    if (!isString(control.value)) {
        return {
            nbPasswordLength: "Das Passwort ist zu kurz. Es muss aus mindestens 8 Zeichen bestehen."
        };
    }

    const password = control.value as string;
    if (password.length < 8) {
        return {
            nbPasswordLength: "Das Passwort ist zu kurz. Es muss aus mindestens 8 Zeichen bestehen."
        };
    }

    return null;
}