import { Injectable } from '@angular/core';
import { ENV, HttpService } from '@nf-workforce/shared';
import { UpdatePersonRequest } from '../interface/person-request.interface';
import { UpdateUsernameRequest } from '../interface/username-request.interface';


@Injectable({
    providedIn: 'root'
})
export class AgencyMemberService {

    constructor(
        private httpService: HttpService
    ) {}

    /**
     * Sendet das Request zur Aktualisierung des Benutzernamens
     *
     * @param id
     * @param payload
     * @returns
     */
    updateUserName(id: number, payload: UpdateUsernameRequest) {
        const urlUserName = `${ENV.apiUrl}/agency-user/${id}/username`;
        return this.httpService.patch(urlUserName, payload);
    }

    /**
     * Sendet das Request zur Aktualisierung der Stammdaten
     *
     * @param id
     * @param payload
     * @returns
     */
    updatePerson(id: number, payload: UpdatePersonRequest) {
        const urlPerson = `${ENV.apiUrl}/agency-user/${id}/person`;
        return this.httpService.patch(urlPerson, payload);
    }

    /**
     * Sendet das Request zum zurücksetzen des Passwortes für ein Agentur-Mitglied
     *
     * @param id
     * @returns
     */
    sendNewPassword(id: number) {
        const urlPassword = `${ENV.apiUrl}/agency-user/${id}/password`;
        return this.httpService.get(urlPassword);
    }

    /**
     * Sendet das Request zum erneuten verschicken der Willkommensmail an das Agentur-Mitglied
     *
     * @param id
     * @returns
     */
    sendWelcomeMail(id: number) {
        const urlWelcomeMail = `${ENV.apiUrl}/agency-user/${id}/welcome`;
        return this.httpService.get(urlWelcomeMail);
    }

    /**
     * Sendet das Request zum löschen des Agentur-Mitglieds
     *
     * @param id
     * @returns
     */
    deleteMember(id: number) {
        const urlDeleteMember = `${ENV.apiUrl}/agency-user/${id}`;
        return this.httpService.delete(urlDeleteMember);
    }

    /**
     * Login für das Agentur-Mitglied erlauben
     *
     * @param id
     * @returns
     */
    enableLogin(id: number) {
        const urlEnableLogin = `${ENV.apiUrl}/agency-user/${id}/login`;
        return this.httpService.post(urlEnableLogin, {});
    }

    /**
     * Login für das Agentur-Mitglied verbieten
     *
     * @param id
     * @returns
     */
    disableLogin(id: number) {
        const urlDisableLogin = `${ENV.apiUrl}/agency-user/${id}/login`;
        return this.httpService.delete(urlDisableLogin);
    }

}
