import {
    Component,
    ElementRef,
    Input,
    OnInit,
    signal,
    ViewChild,
    WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import {
    AutocompleteComponent,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface,
    LoadingService,
    ToastService
} from '@nf-workforce/shared';
import { DriverAutocompleteService } from '../../../../services/driver-autocomplete.service';
import { DriverListService } from '../../../../services/driver-list.service';
import {
    MatAutocomplete,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    MatOption
} from '@angular/material/autocomplete';
import {  MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AlertsService, FormElementOption } from 'nb-form';
import { debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs';
import { AddDriverToJobInterface } from '../../../../interface/workforce/add-driver-to-job.interface';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'joblist-search-driver-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        AutocompleteComponent,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatFormFieldModule,
        MatInput,
        MatLabel,
        MatOption,
        ReactiveFormsModule,
        MatButtonModule,
        IconDirective,
        MatIcon
    ],
    providers: [
        DriverAutocompleteService,
        DriverListService
    ],
    templateUrl: './search-driver-modal.component.html',
    styleUrl: './search-driver-modal.component.scss',
})
export class SearchDriverModalComponent implements OnInit{

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    jobId = 0;
    sendMail = true;
    selectedList = 0;
    searchDriver = new FormControl(null);
    filteredOptions!: Observable<{key: string, value: string}[]>;

    @ViewChild("autocomplete", {static: false}) autocomplete!: ElementRef;

    @Input()
    job!: JobListInterface | null;

    selectedDriver: WritableSignal<FormElementOption | null> = signal(null);
    driverOptions: WritableSignal<FormElementOption[] | []> = signal([]);

    constructor(
        private alertsService: AlertsService,
        private autocompleteService: DriverAutocompleteService,
        private driverListService: DriverListService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private toastService: ToastService
    ) {
    }

    async ngOnInit() {
        if (this.job) {
            this.jobId = this.job.id;
            this.filteredOptions = this.searchDriver.valueChanges.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                switchMap((val) => {
                    return this.filter(val || '');
                })
            );
        }
    }

    get title(): string {
        if (this.selectedDriver()) {
            return `${this.selectedDriver()?.value} hinzufügen`;
        }
        return  'Verfügbare Fahrer:innen suchen';
    }

    async filter(val: string) {
        if (val.length < 4) {
            return [];
        }
        if (val.length % 2 === 0) {
            const result = await this.autocompleteService.searchDrivers(this.jobId, { query: val });
            this.autocomplete.nativeElement.focus();
            if (result) {
                this.driverOptions.set(result.data.map((driver) => {
                    return {
                        key: driver.id,
                        value: `${driver.firstName} ${driver.lastName}`
                    };
                }));
                return this.driverOptions();
            }
        }
        this.driverOptions.set([]);
        return [];
    }

    displayFn = (userId: number | null | undefined): string => {
        if (typeof userId === 'number' && userId > 0 && this.driverOptions().length > 0) {
            const result = this.driverOptions().find(d => {
                return d.key === userId;
            });
            if (result) {
                return result.value;
            }
        }
        return '';
    }

    onDriverSelected(event: MatAutocompleteSelectedEvent) {
        this.selectedDriver.set(null);
        const result = this.driverOptions().find(d => {
            return d.key === parseInt(event.option.value, 10);
        });
        if (result) {
            this.autocomplete.nativeElement.value = '';
            this.selectedDriver.set(result);
        }
    }

    clearSelection() {
        this.selectedDriver.set(null);
        this.driverOptions.set([]);
        this.sendMail = true;
        this.selectedList = 0;
    }

    onToggleSendMail(event: CustomEvent) {
        this.sendMail = event.detail.checked;
    }

    onSelectList(event: CustomEvent) {
        this.selectedList = parseInt(event.detail.value, 10);
    }

    private createPayload(): AddDriverToJobInterface {
        return {
            listType: this.selectedList,
            sendMail: this.sendMail,
            userId: this.selectedDriver()?.key
        }
    }

    private async isValid(payload: AddDriverToJobInterface) {
        if (payload.listType !== 100 && payload.listType !== 200) {
            await this.toastService.presentToast('Keine Liste ausgewählt');
            return false;
        }
        if (typeof payload.userId === 'undefined' || payload.userId < 1) {
            await this.toastService.presentToast('Kein Fahrer ausgewählt');
            return false;
        }
        return true;
    }

    private async askSave() {
        let question = `${this.selectedDriver()?.value} `;
        question += (this.selectedList === 200) ? 'dem Job zuweisen' : ' auf Warteliste setzen';
        question += (this.sendMail) ? ' UND informieren?' : ' UND NICHT informieren?';
        return await this.alertsService.confirm({
            text: question,
            textCancel: 'Nein',
            textOk: 'Ja'
        });
    }

    async onClickAdd() {
        const payload = this.createPayload();
        const isValid = await this.isValid(payload);
        if (!isValid) {
            return;
        }
        const confirmation = await this.askSave();
        if (!confirmation) {
            return;
        }
        await this.loadingService.start('Bitte warten ... ');
        const result = await this.driverListService.addDriver(this.jobId, payload)
            .catch(async (e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage", e);
                await this.toastService.presentToast("Fahrer:in konnte nicht hinzugefügt werden.");
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Fahrer:in wurde erfolgreich hinzugefügt");
            await this.modalController.dismiss({
                saved: true
            });
        }
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }
}
