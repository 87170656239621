
import { NavigationNodeInterface, PageAction } from '@nf-workforce/shared';

export const APP_MENU: NavigationNodeInterface[] = [
    PageAction.route('Agenturen', '/admin/agency/', 'agency_list'),
    PageAction.route('Meine Agentur', '/my-agency', 'agency_index').children([
        PageAction.route('Accounts', '/my-agency/accounts', 'agency_user_list'),
        PageAction.route('Fahrer:innen', '/my-agency/workforce', 'agency_user_list'),
        PageAction.route('Gruppen', '/driver-groups/list', 'agency_one_driver_group_list'),
        PageAction.route('Jobs', '/jobs', 'joblist_list'),
    ]),
    PageAction.route('Mein Account', '/account/my-account', 'my_account'),
    PageAction.route('Mein Profil', '/driver', 'driver_profile_patch'),
    PageAction.route('Offene Jobs', '/my-jobs/open', 'driver_joblist_open'),
    PageAction.route('Meine Nachrichten', '/my-messages', 'joblist_driver_message_list'),
    PageAction.route('Meine Jobs', '/my-jobs/overview', 'driver_joblist'),
];
