import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { DriverDataInterface, EavService, IconDirective, IconEnum, IconThemeEnum, LoadingService, SkillEavInterface, ToastService } from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { DriverEavRequestInterface } from '../../../../interface/requests/driver-eav-request.interface';
import { DeleteDriverEavRequestInterface } from '../../../../interface/requests/delete-driver-eav-request.interface';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-skill-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon
    ],
    templateUrl: './profile-skill-modal.component.html',
    styleUrl: './profile-skill-modal.component.scss',
})
export class ProfileSkillModalComponent implements OnInit {

    eavSkills: SkillEavInterface[] = [];
    activeSkillsIds: number[] = [];

    @Input()
    driver: DriverDataInterface | null = null;

    title = "Skills bearbeiten";

    constructor(
        private eavService: EavService,
        private loadingService: LoadingService,
        private modalController: ModalController,
        private driverProfileService: DriverProfileService,
        private toastService: ToastService
    ) {}

    async ngOnInit() {
        this.setEavSkills();
        this.setActiveSkillsIds();
    }

    setEavSkills() {
        const eavSkills = this.eavService.getSkills();
        if (eavSkills) {
            this.eavSkills = eavSkills;
        } else {
            throw new Error('EavSkills nicht gesetzt');
        }
    }

    setActiveSkillsIds() {
        this.activeSkillsIds = [];
        if (this.eavSkills && this.eavSkills.length > 0 && this.driver) {
            const driverSkills = this.driver.driverUserDto.skills;
            driverSkills.forEach((skill) => {
                if (skill.isActive) {
                    this.activeSkillsIds.push(skill.eavId);
                }
            });
        }
    }

    isSkillActive(eavSkill: SkillEavInterface) {
        const result = this.activeSkillsIds.find(activeSkill => {
            return activeSkill === eavSkill.id;
        });
        return (typeof result !== 'undefined' && result > 0);

    }

    async onSkillChange(event: CustomEvent) {
        if (event.detail.checked) {
            await this.addSkill(parseInt(event.detail.value, 10));
        } else {
            await this.deleteSkill(parseInt(event.detail.value, 10));
        }
    }

    /**
     * Hinzufügen eines neuen Skills zum Fahrerprofil
     */
    private async addSkill(idEav: number) {
        const payload = {
            eavId: idEav,
        } as DriverEavRequestInterface;

        await this.loadingService.start();
        const result = await this.driverProfileService.addSkill(payload)
            .catch((e) => {
                console.debug("Fehler beim Speichern des Requests", e);
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert.");
        }
    }

    /**
     * Löschen eines bereits vorhandenen Skills
     *
     * @param idEav
     */
    private async deleteSkill(idEav: number) {
        const payload = {
            id: idEav,
        } as DeleteDriverEavRequestInterface;

        await this.loadingService.start();
        const result = await this.driverProfileService.deleteSkill(payload)
            .catch((e) => {
                console.debug("Fehler beim Speichern des Requests", e);
            });

        if (result) {
            await this.toastService.presentToast("Erfolgreich gespeichert.");
        }
        await this.loadingService.stop();
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
}
