
import { DriverAgencyState } from '../enum/driver-agency-status.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'driverStatusToString',
    standalone: true,
})
export class DriverStatusToStringPipe implements PipeTransform {
    transform(status: number | null): string {
        if (status === DriverAgencyState.NEW) {
            return 'Fahrer:in muss Profil vervollständigen';
        }
        if (status === DriverAgencyState.VALIDATE) {
            return 'Fahrer:in muss validiert werden';
        }
        if (status === DriverAgencyState.VALIDATED) {
            return 'Fahrer:in freigeschaltet';
        }

        if (status === DriverAgencyState.REJECTED) {
            return 'Fahrer:in abgelehnt';
        }

        return '-';
    }
}
