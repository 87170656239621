import { ErrorParserInterface } from "../interfaces/error/error-parser.interface";
import { ErrorResponse } from "../interfaces/error/error-response.interface";
import { InternalErrorInterface } from "../interfaces/error/internal-error.interface";

export class ErrorParser implements ErrorParserInterface {

    constructor(private error: ErrorResponse) {}
    
    /**
     * Gibt die Fehlermeldung zurück
     * 
     * @returns 
     */
    getErrorMessage(): string {
        return this.error.message;
    }

    /**
     * Gibt die Fehler als Array aus Strings zurück
     * 
     * @returns 
     */
    getErrors(): InternalErrorInterface[] {
        return this.error.errors.map((error) => {
            return {
                field: error.field,
                message: error.message
            };
        });
    }

}