import { Component, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { ErrorParserInterface } from '../../interfaces/error/error-parser.interface';
import { MatIconModule } from '@angular/material/icon';
import { InternalErrorInterface } from '../../interfaces/error/internal-error.interface';
import { ErrorPipe } from '../../pipes/error.pipe';

@Component({
    selector: 'shared-error-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        MatIconModule,
        ErrorPipe
    ],
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.scss',
})
export class ErrorModalComponent implements OnInit {

    title = "Fehler";

    @Input()
    errorParser!: ErrorParserInterface;

    errorMessage = signal<string | null>(null);

    errorRows = signal<InternalErrorInterface[]>([]);

    constructor(
        private modalController: ModalController,
        private loadingService: LoadingService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.loadingService.start();

        this.errorMessage.set(this.errorParser.getErrorMessage());
        this.errorRows.set(this.errorParser.getErrors());
        await this.loadingService.stop();
    }

    async closeModal() {
        await this.modalController.dismiss();
    }

}
