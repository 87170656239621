import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { JobKnowledgeHelperService } from '@nf-workforce/joblist';
import {
    DriverJobListInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    JobListInterface
} from '@nf-workforce/shared';


@Component({
    selector: 'driver-joblist-one-job-knowledge-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
    ],
    templateUrl: './one-job-knowledge-card.component.html',
    styleUrl: './one-job-knowledge-card.component.scss',
    providers: [
        JobKnowledgeHelperService,
    ]
})
export class OneJobKnowledgeCardComponent {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;


    constructor(
        private knowledgeHelper: JobKnowledgeHelperService,
    ) {}

    @Input()
    job!: DriverJobListInterface | JobListInterface;

    getSkillsStrings(): string {
        return this.knowledgeHelper.getSkillsStrings();
    }

    getFahrerlaubnisStrings(): string {
        return this.knowledgeHelper.getFahrerlaubnisStrings();
    }

    getLanguageStrings(): string {
        return this.knowledgeHelper.getLanguageStrings();
    }
}
