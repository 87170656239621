
import { FormPage } from 'nb-form';
import { InjectionToken } from '@angular/core';
import { passwordFormatValidator } from '../validators/password-format.validator';
import { passwordLengthValidator } from '../validators/password-length.validator';

export const ACCOUNT_RECOVERY_SCHEMA = new InjectionToken<FormPage>('"Account-Recovery"', {
    factory: () => {
        return {
            ber_reiter_id: 'account-recovery',
            header: 'Passwort zurücksetzen',
            navigation_label: 'account-recovery',
            elements: [
                {
                    id: 'password',
                    type: 'password',
                    model: 'newPassword',
                    label: 'Neues Passwort',
                    validators: [
                        passwordLengthValidator,
                        passwordFormatValidator
                    ],
                    messages: {
                        'nbPasswordFormat': "Kurze Passwörter müssen mindestens ein Sonderzeichen, einen Großbuchstaben, einen Kleinbuchstaben sowie eine Zahl enthalten.",
                        'nbPasswordLength': "Das Passwort muss mindestens 8 Zeichen lang sein."
                    },
                    required: true,
                    confirmPassword: true
                }
            ]
        };
    }
});
