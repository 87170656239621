import { agencyListSearch } from './../../search/agency-list.config';
import { AgencyService } from '../../services/agency.service';
import { AgencyInterface, BooleanToStringPipe, PageAction, PageActionService, PageFilterAction, SearchMenuInterface, SearchQueryInterface, } from '@nf-workforce/shared';
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, WritableSignal, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { TableCellDirective, TableCellLabelDirective, TableDirective, TableFooterDirective, TableHeadDirective, TableHeadlineDirective, TableKickerDirective, TableRowDirective, } from '@nf-workforce/ui';

@Component({
    selector: 'agency-list',
    standalone: true,
    imports: [
        BooleanToStringPipe,
        CommonModule,
        IonicModule,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableHeadlineDirective,
        TableKickerDirective,
        TableRowDirective,
    ],
    templateUrl: './agency-list.component.html',
    styleUrl: './agency-list.component.scss',
    providers: []
})
export class AgencyListComponent implements OnInit {

    agencies: WritableSignal<AgencyInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);
    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };
    
    private nextPage = 1;
    private limit = 25;

    constructor(
        @Inject(agencyListSearch) public searchConfig: SearchMenuInterface,
        private agencyService: AgencyService,
        private pageActionService: PageActionService,
        private router: Router,
    ) {}

    async ngOnInit(): Promise<void> {
        this.agencies.set(await this.loadAgencies());
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) },
            // @todo 'protected_by'
        ));
        this.pageActionService.addAction(PageAction.route(
            'Neue Agentur',
            '/admin/agency/new',
            'agency_new',
        ));
    }

    private async loadAgencies(): Promise<AgencyInterface[]> {
        const agencyResponse = await this.agencyService.getList(this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.log("Fehler bei der Anfrage", e);
            });

        if (agencyResponse) {
            this.nextPage = agencyResponse.page + 1;

            if (this.nextPage <= agencyResponse.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return agencyResponse.data;
        }

        return [];
    }

    async clickLoadNextPage() {
        const currentAgencies = this.agencies();
        const agencies = await this.loadAgencies();
        this.agencies.set(currentAgencies.concat(agencies));
    }

    goToAgency(agency: AgencyInterface) {
        const route = `/admin/agency/${agency.id}`;
        this.router.navigate([route]);
    }

    async searchChanged($event: SearchQueryInterface) {
        this.nextPage = 1;
        this.searchQuery = $event;
        console.debug("SearchChanged", this.searchQuery);
        this.agencies.set(await this.loadAgencies());
    }
}
