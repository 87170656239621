import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    BooleanToStringPipe,
    DriverReferencesInterface,
    DriverStatusToStringPipe,
    FullnamePipe,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    DriverLanguageInterface,
    DriverFahrerlaubnisInterface,
    DriverSkillInterface,
    DriverProfileInterface,
    AgencyMemberInterface,
    DriverInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import { DriverBusinessRelationshipToStringPipe } from '../../../pipes/driver-business-relationship-to-string.pipe';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-driver-stammdaten-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        FullnamePipe,
        BooleanToStringPipe,
        DriverBusinessRelationshipToStringPipe,
        DriverStatusToStringPipe,
        IconDirective,
        MatIcon,
        PrintNbDateTimePipe
    ],
    templateUrl: './driver-stammdaten-card.component.html',
    styleUrl: './driver-stammdaten-card.component.scss',
    providers: [
        PrintNbDatePipe,
        FullnamePipe,
    ]
})
export class DriverStammdatenCardComponent implements OnInit {
    @Input()
    account!: AgencyMemberInterface;
    @Input()
    driver!: DriverInterface;

    profile!: DriverProfileInterface;

    skills: DriverSkillInterface[] | null = null;
    fahrerlaubnis: DriverFahrerlaubnisInterface[] | null = null;
    driverLanguages: DriverLanguageInterface[] | null = null;
    motherLanguages: DriverLanguageInterface[] | null = null;
    otherLanguages: DriverLanguageInterface[] | null = null;
    references: DriverReferencesInterface[] | null = null;

    Icon = TitleIconEnum;
    IconTheme = IconThemeEnum;
    showPlace: WritableSignal<boolean> = signal(false);

    ngOnInit(): void {
        if (this.account !== null && this.driver !== null) {
            this.profile = this.driver.profile;
            this.skills = this.driver.skills;
            this.fahrerlaubnis = this.driver.fahrerlaubnis;
            this.driverLanguages = this.driver.driverLanguages;
            this.motherLanguages = this.driverLanguages.filter(lang => lang.isMotherLanguage);
            this.otherLanguages = this.driverLanguages.filter(lang => !lang.isMotherLanguage);
            this.references = this.driver.driverReferences;
            this.showPlace.set(true);
        }
    }

    getLanguageStrings(isMotherLanguage: boolean): string {
        const name: string[] = [];
        if (isMotherLanguage) {
            if (this.motherLanguages && this.motherLanguages.length > 0) {
                this.motherLanguages.forEach((lang) => {
                    name.push(lang.name);
                })
            }
        } else {
            if (this.otherLanguages && this.otherLanguages.length > 0) {
                this.otherLanguages.forEach((lang) => {
                    const str = lang.name + ' (' + lang.level + ')';
                    name.push(str);
                })
            }
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }

    getLanguageHeader(isMotherLanguage: boolean): string {
        if (isMotherLanguage) {
            if (this.motherLanguages) {
                if (this.motherLanguages?.length > 1) {
                    return 'Muttersprachen:';
                } else if (this.motherLanguages.length === 1) {
                    return 'Muttersprache:';
                } else {
                    return '';
                }
            }
        } else {
            if (this.otherLanguages) {
                if (this.otherLanguages.length > 1) {
                    return 'Weitere Sprachen:';
                } else if (this.otherLanguages.length === 1) {
                    return 'Weitere Sprache:';
                } else {
                    return '';
                }
            }
        }
        return '';
    }

    getSkillsStrings(): string {
        const name: string[] = [];
        if (this.skills && this.skills.length > 0) {
            this.skills.forEach((skill) => {
                name.push(skill.name);
            })
        }
        if (name.length > 0) {
            return name.join(', ');
        }
        return '';
    }

    getLanguageColor(language: DriverLanguageInterface): string {
        if (language.level !== null) {
            switch (language.level.substring(0,1)) {
                case 'A': return 'secondary';
                case 'B': return 'primary';
                case 'C': return 'success';
            }
        }
        return 'danger';
    }

    protected readonly IconEnum = IconEnum;
}
