import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService, PageActionService, PageFilterAction, SearchQueryInterface } from '@nf-workforce/shared';
import { IonicModule } from '@ionic/angular';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { MessageInterface } from '../../interfaces/message.interface';
import { MessageService } from '../../service/message.service';
import { RecipientsPipe } from '../../pipes/recipients.pipe';
import { messageListSearch } from '../../search/message-search.config';
import { Observable } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
    selector: 'message-list',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        TableDirective,
        TableHeadDirective,
        TableRowDirective,
        TableCellDirective,
        TableCellLabelDirective,
        TableFooterDirective,
        RecipientsPipe
    ],
    templateUrl: './message-list.component.html',
    styleUrl: './message-list.component.scss',
})
export class MessageListComponent implements OnInit {

    private loadingService = inject(LoadingService);

    private messageService = inject(MessageService);

    private pageActionService = inject(PageActionService);

    private searchConfig = inject(messageListSearch)

    changeEmitter: Observable<MessageInterface[]>;

    jobId!: number;

    enableMoreResults: WritableSignal<boolean> = signal(false);
    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };
    
    private nextPage = 1;
    private limit = 25;

    messages = signal<MessageInterface[]>([]);

    constructor() {
        this.changeEmitter = toObservable(this.messages);
    }

    async ngOnInit(): Promise<void> {
        await this.loadingService.start();
        this.jobId = this.messageService.getJobId();
        this.pageActionService.clear();
        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) }
        ));
        this.messages.set(await this.loadMessageList());
        await this.loadingService.stop();
    }

    /**
     * Handler bei geänderter Suche
     * 
     * @param $event 
     */
    async searchChanged($event: SearchQueryInterface) {
        this.nextPage = 1;
        this.searchQuery = $event;
        this.messages.set(await this.loadMessageList());
    }

    private async loadMessageList(): Promise<MessageInterface[]> {
        const response = await this.messageService.getMessages(this.jobId, this.nextPage, this.limit, this.searchQuery)
            .catch((e) => {
                console.log("Fehler bei der Anfrage", e);
            });

        if (response) {
            this.nextPage = response.page + 1;

            if (this.nextPage <= response.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return response.data;
        }

        return [];
    }

    /**
     * Handler zum Nachladen der nächsten Seite
     */
    async clickLoadNextPage() {
        const currentMessages = this.messages();
        const newMessages = await this.loadMessageList();
        this.messages.set(currentMessages.concat(newMessages));
    }

}
