
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TitleService {
    /**
     * Use this emitter in your component to emit a custom page title.
     */
    public titleEmitter: EventEmitter<string> = new EventEmitter<string>();
}
