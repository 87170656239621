import { Injectable } from '@angular/core';
import { DriverProfileInterface } from '@nf-workforce/shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Injectable()
export class DriverFormStammdatenService {

    profile: DriverProfileInterface | null = null;

    setDriverProfile(profile: DriverProfileInterface) {
        this.profile = profile;
    }

    get configWohnort() {
        const country = (this.profile?.country !== null) ? {country: this.profile?.country} : '';
        return {
            street: new FormControl(this.profile?.street, [Validators.required]),
            zipCode: new FormControl(this.profile?.zipCode, [Validators.required]),
            city: new FormControl(this.profile?.city, [Validators.required]),
            area: new FormControl(this.profile?.area, [Validators.required]),
            country: new FormControl(country, [Validators.required]),
        };
    }

    get configAbrechnung() {
        return {
            taxId: new FormControl(this.profile?.taxId),
            kkNr: new FormControl(this.profile?.kkNr),
            rvNr: new FormControl(this.profile?.rvNr),
            iban: new FormControl(this.profile?.iban),
        };
    }

    get configBirth() {
        const country = (this.profile?.nationality !== null) ? {country: this.profile?.nationality} : {country: 'Deutschland'};
        return {
            birthday: new FormControl(this.profile?.birthday, [Validators.required]),
            birthplace: new FormControl(this.profile?.birthplace, [Validators.required]),
            nationality: new FormControl(country, [Validators.required]),
        };
    }

    get defaultPayload(): DriverProfileInterface {
        return {
            birthday: (this.profile?.birthday !== undefined) ? this.profile.birthday : null,
            birthplace: (this.profile?.birthplace !== undefined) ? this.profile.birthplace : null,
            nationality: (this.profile?.nationality !== undefined) ? this.profile.nationality : null,
            taxId: (this.profile?.taxId !== undefined) ? this.profile.taxId : null,
            kkNr: (this.profile?.kkNr !== undefined) ? this.profile.kkNr : null,
            rvNr: (this.profile?.rvNr !== undefined) ? this.profile.rvNr : null,
            iban: (this.profile?.iban !== undefined) ? this.profile.iban : null,
            street: (this.profile?.street !== undefined) ? this.profile.street : null,
            zipCode: (this.profile?.zipCode !== undefined) ? this.profile.zipCode : null,
            city: (this.profile?.city !== undefined) ? this.profile.city : null,
            area: (this.profile?.area !== undefined) ? this.profile.area : null,
            country: (this.profile?.country !== undefined) ? this.profile.country : null,
        } as DriverProfileInterface
    }

    getPayloadForWohnort(form: FormGroup): DriverProfileInterface {
        const data = this.defaultPayload;
        data.street = form.get('street')?.value;
        data.zipCode = form.get('zipCode')?.value;
        data.city = form.get('city')?.value;
        data.area = form.get('area')?.value;
        data.country = form.get('country')?.value.country;
        return data;
    }

    getPayloadForAbrechnung(form: FormGroup): DriverProfileInterface {
        const data = this.defaultPayload;
        data.taxId = form.get('taxId')?.value;
        data.kkNr = form.get('kkNr')?.value;
        data.rvNr = form.get('rvNr')?.value;
        data.iban = form.get('iban')?.value;
        return data;
    }

    getPayloadForBirthday(form: FormGroup): DriverProfileInterface {
        const data = this.defaultPayload;
        data.birthday = form.get('birthday')?.value.substring(0,10);
        data.birthplace = form.get('birthplace')?.value;
        data.nationality = form.get('nationality')?.value.country;
        return data;
    }
}
