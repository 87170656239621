@if(driver && currentEav && hasJustOneFile !== null) {
    <ion-row class="ion-hide-md-up">
        <ion-col>
            <ion-card>
                <ion-card-header>
                    <ion-card-title>{{title}}</ion-card-title>
                </ion-card-header>
            </ion-card>
        </ion-col>
    </ion-row>

    @if (frontSide === null) {
        <shared-select-file-for-upload
            [config]="getConfigSideSelectFileComponent(false)"
            (selectedFile)="setFrontSideFile($event)"
        ></shared-select-file-for-upload>
    } @else {
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-label>
                        @if (currentEav.hasBackSide && !hasJustOneFile) {
                            Vorderseite
                        }
                        {{currentEav.name}}: {{frontSide.name}}
                    </ion-label>
                    <ion-button
                        title="Dokument verwerfen"
                        size="small"
                        fill="outline"
                        [color]="IconTheme.DANGER"
                        (click)="cancelFrontSideFile()"
                    ><mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.DANGER" ></mat-icon>
                    </ion-button>
                </ion-item>
            </ion-col>
        </ion-row>
    }
    @if (!hasJustOneFile) {
        @if (backSide === null ) {
            <shared-select-file-for-upload
                [config]="getConfigSideSelectFileComponent(true)"
                (selectedFile)="setBackSideFile($event)"
            ></shared-select-file-for-upload>
        } @else {
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-label>
                            Rückseite {{currentEav.name}}: {{backSide.name}}
                        </ion-label>
                        <ion-button
                            title="Dokument verwerfen"
                            size="small"
                            fill="outline"
                            [color]="IconTheme.DANGER"
                            (click)="cancelBackSideFile()"
                        ><mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.DANGER" ></mat-icon>
                        </ion-button>
                    </ion-item>
                </ion-col>
            </ion-row>
        }
    }
    @if (showForm()) {
        <form [formGroup]="form">
            @if (currentEav.hasCreatedDate) {
                <input type="hidden" formControlName="createdDate">
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>Ausgestellt am<sup>*</sup>:</ion-label>
                            <ion-datetime-button datetime="datetime-created"></ion-datetime-button>
                            <ion-modal [keepContentsMounted]="true">
                                <ng-template>
                                    <ion-datetime
                                        id="datetime-created"
                                        presentation="date"
                                        [max]="heute"
                                        (ionChange)="onDateChange($event, 'createdDate')"
                                    >
                                        <span slot="title">Ausgestellt am</span>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </ion-item>
                    </ion-col>
                </ion-row>
            }
            @if (currentEav.hasExpiredDate) {
                <input type="hidden" formControlName="expireDate">
                <ion-row>
                    <ion-col>
                        <ion-item>
                            <ion-label>Gültig bis<sup>*</sup>:</ion-label>
                            <ion-datetime-button datetime="datetime-expired"></ion-datetime-button>
                            <ion-modal [keepContentsMounted]="true">
                                <ng-template>
                                    <ion-datetime
                                        id="datetime-expired"
                                        presentation="date"
                                        [min]="morgen"
                                        [value]="morgen"
                                        [max]="2099-12-31"
                                        (ionChange)="onDateChange($event, 'expireDate')"
                                    >
                                        <span slot="title">Gültig bis</span>
                                    </ion-datetime>
                                </ng-template>
                            </ion-modal>
                        </ion-item>
                    </ion-col>
                </ion-row>
            }
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-input
                            formControlName="description"
                            [label]="getLabelDescription()"
                        ></ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
        </form>
    }

}
