@if (job) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Wissen</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.KNOWLEDGE"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Fahrerlaubnis</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobKnowledge('fahrererlaubnis')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">{{ getFahrerlaubnisStrings() }}</span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Skills</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobKnowledge('skills')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">{{ getSkillsStrings() }}</span>
            </div>
            <br>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    <span class="driver-skill__label-text">Sprachen</span>
                    <span class="driver-skill__edit-button show-on-hover"
                          role="button"
                          (click)="clickEditJobKnowledge('languages')">
                        <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                    </span>
                </label>
                <span class="driver-skill__skill">
                    {{getLanguageStrings()}}
                </span>
            </div>
        </ion-card-content>
    </ion-card>
}
