@if(member()) {
    <ion-row>
        <ion-col>
            <ion-buttons>
                <ion-button color="primary" fill="outline" (click)="clickNewPassword()" size="small">
                    Passwort zusenden
                </ion-button>

                <ion-button color="secondary" fill="outline" (click)="clickSendWelcome()" size="small">
                    Willkommensmail zusenden
                </ion-button>

                <ion-button color="danger" fill="outline" (click)="clickDelete()" size="small">
                    Löschen
                </ion-button>
            </ion-buttons>

        </ion-col>
    </ion-row>

    <hr>
    
    @if (usernameForm) {
        <ion-row>
            <ion-col>
                <nb-form
                    #usernameInstance
                    [form]="usernameForm"
                    appearance="outline"
                    [delay]="0"
                    [debug]="false"
                ></nb-form>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button fill="outline" size="small" (click)="clickUpdateUsername()">
                    Speichern
                </ion-button>
            </ion-col>
        </ion-row>
    }

    @if (personForm) {
        <ion-row>
            <ion-col>
                <nb-form
                    #personInstance
                    [form]="personForm"
                    appearance="outline"
                    [delay]="0"
                    [debug]="false"
                ></nb-form>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button fill="outline" size="small" (click)="clickUpdatePerson()">
                    Speichern
                </ion-button>
            </ion-col>
        </ion-row>
    }
   
}
