import { Injectable } from '@angular/core';
import {
    HttpService,
    ENV,
    UploadImageRequest,
    ProfileImageInterface
} from '@nf-workforce/shared';
import { PasswordRequestInterface } from '../interfaces/password-request.interface';
import { PersonRequestInterface } from '../interfaces/person-request.interface';
import { UserNameRequestInterface } from '../interfaces/username-request.interface';

@Injectable()
export class MyAccountService {

    constructor(private httpService: HttpService) { }

    /**
     * Benutzername ändern
     */
    updateUserName(payload: UserNameRequestInterface): Promise<unknown> {
        return this.httpService.post(`${ENV.apiUrl}/my-account/change-username`, payload);
    }

    /**
     * Stammdaten des Benutzers aktualisieren
     */
    updatePerson(payload: PersonRequestInterface): Promise<unknown> {
        return this.httpService.patch(`${ENV.apiUrl}/my-account/person`, payload);
    }

    /**
     * Passwort des Benutzers aktualisieren
     */
    updatePassword(payload: PasswordRequestInterface): Promise<unknown> {
        return this.httpService.patch(`${ENV.apiUrl}/my-account/password`, payload);
    }

    /**
     * Account validieren
     */
    validateAccount(payload: PasswordRequestInterface): Promise<unknown> {
        return this.httpService.patch(`${ENV.apiUrl}/my-account/validate`, payload);
    }

    deleteAccount(): Promise<unknown> {
        return this.httpService.delete(`${ENV.apiUrl}/my-account`);
    }

    uploadProfileImage(
        payload: UploadImageRequest
    ): Promise<{ data: ProfileImageInterface } | void> {
        return this.httpService.post(`${ENV.apiUrl}/my-account/image`, payload);
    }

    deleteProfileImage() {
        return this.httpService.delete(`${ENV.apiUrl}/my-account/image`);
    }
}
