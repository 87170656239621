import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SelectionOptionInterface } from '../../interfaces/dropdown-option.interface';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchEventInterface } from '../../interfaces/search-event.interface';

@Component({
    selector: 'shared-search-selection',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule
    ],
    templateUrl: './search-selection.component.html',
    styleUrl: './search-selection.component.scss',
    providers: []
})
export class SearchSelectionComponent implements OnInit {
    @Input()
    name!: string;

    @Input()
    placeHolder = "Keine Auswahl"

    @Input()
    options: SelectionOptionInterface[] | undefined = [];

    selectedOption: SelectionOptionInterface | null = null

    @Output()
    search: EventEmitter<SearchEventInterface<SelectionOptionInterface>> = new EventEmitter();

    okText = "Ok"
    cancelText = "Abbrechen";

    ngOnInit(): void {
        if (!Array.isArray(this.options)) {
            this.options = [];
            console.warn(`Die Auswahloptionen der Suche für das Element ${this.name} fehlt`);
        }
    }

    changeSelection(selection: SelectionOptionInterface | null) {
        this.selectedOption = selection;
        this.search.emit({
            name: this.name,
            value: this.selectedOption
        });
    }

    isSelected(option: SelectionOptionInterface | null): boolean {
        return (this.selectedOption === option);
    }

}
