import { Injectable } from '@angular/core';
import { DriverJobListInterface, DriverJobListType, ENV, HttpService, JobListInterface, PaginatorResponse } from '@nf-workforce/shared';
import { DriverJobCountInterface } from '../interface/driver-job-count.interface';

@Injectable()
export class DriverJoblistService {
    constructor(
        private httpService: HttpService
    ) { }

    get driverOpenJobsRoute(): string {
        return `${ENV.apiUrl}/driver/joblist-open`;
    }

    get joblistRoute(): string {
        return `${ENV.apiUrl}/driver/joblist/`;
    }

    get driverJobsRoute(): string {
        return `${ENV.apiUrl}/driver/joblist-driver`;
    }

    get driverJobCountRoute(): string {
        return `${ENV.apiUrl}/driver/joblist/count`;
    }
    
    /**
     * Gibt die derzeit offenen Jobs für den eingeloggten Fahrer zurück
     * 
     * 
     * @param page 
     * @param limit 
     * @returns 
     */
    async getOpenJobLists(page: number, limit: number) {
        return this.httpService
            .getBypassCache<PaginatorResponse<JobListInterface>>(this.driverOpenJobsRoute, {
                page: page,
                limit: limit
            });
    }

    /**
     * Sucht nach einem Job anhand der übergebenen Id
     * 
     * @param id 
     * @returns 
     */
    async getOneOpenJobList(id: number) {
        return this.httpService
            .getBypassCache<PaginatorResponse<JobListInterface>>(this.driverOpenJobsRoute, {
                page: 1,
                limit: 1,
                id: id
            });
    }

    /**
     * Bei dem übergebenen Job bewerben
     * 
     * @param job 
     * @returns 
     */
    async applyForJob(job: JobListInterface) {
        const route = this.joblistRoute + job.id;
        return this.httpService.post(route, {});
    }

    /**
     * Entfernt den derzeit eingeloggten Fahrer von der Jobliste
     * 
     * @param job 
     */
    async removeFromJob(job: DriverJobListInterface) {
        const route = `${this.joblistRoute}` + job.joblistId;
        return this.httpService.delete(route);
    }

    /**
     * Gibt die Jobs zurück, wo sich der Fahrer derzeit beworben hat
     * 
     * @returns 
     */
    async getMyJobs(page: number, limit: number, type: DriverJobListType) {
        const route = this.driverJobsRoute;
        return this.httpService
            .getBypassCache<PaginatorResponse<DriverJobListInterface>>(route, {
            page: page,
            limit: limit,
            listType: type
        });
    }

    /**
     * Abfrage für einen einzelnen Job
     * 
     * @param id 
     * @returns 
     */
    async getOneJob(id: number) {
        return this.httpService
            .getBypassCache<PaginatorResponse<DriverJobListInterface>>(this.driverJobsRoute, {
                page: 1,
                limit: 1,
                id: id
            });
    }

    /**
     * Holt die Zähler für die Darstellung der Jobs aus dem Backend
     */
    async getJobCounts() {
        return this.httpService
            .getBypassCache<DriverJobCountInterface>(this.driverJobCountRoute);
    }

}
