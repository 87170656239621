import {
    BooleanToStringPipe,
    DriverStatusToStringPipe,
    FullnamePipe,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    DriverAgencyState,
    AgencyMemberInterface,
    DriverInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum
} from '@nf-workforce/shared';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { DriverBusinessRelationshipToStringPipe } from '../../../pipes/driver-business-relationship-to-string.pipe';
import { DriverStatusModalComponent } from '../driver-status-modal/driver-status-modal.component';
import { IonicModule, ModalController } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'agency-driver-status-card',
    standalone: true,
    imports: [
        BooleanToStringPipe,
        CommonModule,
        DriverBusinessRelationshipToStringPipe,
        DriverStatusToStringPipe,
        FullnamePipe,
        IconDirective,
        IonicModule,
        MatIcon,
        PrintNbDatePipe,
        PrintNbDateTimePipe,
    ],
    templateUrl: './driver-status-card.component.html',
    styleUrl: './driver-status-card.component.scss',
    providers: [
        FullnamePipe,
        PrintNbDatePipe,
    ]
})
export class DriverStatusCardComponent implements OnInit {

    @Input()
    account!: AgencyMemberInterface;

    @Input()
    driver!: DriverInterface;

    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    DriverAgencyState = DriverAgencyState;
    Icon = IconEnum;
    IconTheme = IconThemeEnum;
    canValidate: WritableSignal<boolean> = signal(false);
    showStatus: WritableSignal<boolean> = signal(false);

    constructor(private modalController: ModalController) { }

    ngOnInit(): void {
        if (this.account && this.driver) {
            this.showStatus.set(true);
            if (this.driver.status === DriverAgencyState.VALIDATE) {
                this.canValidate.set(true);
            } else {
                this.canValidate.set(false)
            }
        }
    }

    async clickAcceptProfile() {
        await this.openModal(true);
    }

    async clickRejectProfile() {
        await this.openModal(false);
    }

    /**
     * Öffnet das Modal zum Akzeptieren oder zum Ablehnen der Fahrervalidierung
     *
     * @param isAccept
     */
    private async openModal(isAccept: boolean) {
        const modal = await this.modalController.create({
            component: DriverStatusModalComponent,
            componentProps: {
                account: this.account,
                driver: this.driver,
                isAccept: isAccept
            }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        // Nachladen, wenn ein Datensatz erfolgreich gespeichert wurde
        if (result?.role === 'saved') {
            this.reload.emit();
        }
    }
}
