import { Component, Inject, OnInit, WritableSignal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import {
    LoadingService,
    PrintNbDatePipe,
    SearchQueryInterface,
    BooleanToStringPipe,
    SearchQueryBuilder,
    PrintNbDateTimePipe,
    SearchMenuInterface,
    PageActionService,
    PageFilterAction,
    PageAction,
    JobListInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    TitleIconEnum
} from '@nf-workforce/shared';
import {
    TableCellDirective,
    TableCellLabelDirective,
    TableDirective,
    TableFooterDirective,
    TableHeadDirective,
    TableKickerDirective,
    TableRowDirective
} from '@nf-workforce/ui';
import { jobListSearchConfig } from '../../search/joblist.config';
import { JobListService } from '../../services/job-list.service';


@Component({
    selector: 'joblist-job-list',
    standalone: true,
    imports: [
        BooleanToStringPipe,
        CommonModule,
        IconDirective,
        IonicModule,
        MatIcon,
        PrintNbDateTimePipe,
        TableCellDirective,
        TableCellLabelDirective,
        TableDirective,
        TableFooterDirective,
        TableHeadDirective,
        TableKickerDirective,
        TableRowDirective,
        PrintNbDatePipe,
    ],
    templateUrl: './job-list.component.html',
    styleUrl: './job-list.component.scss',
    providers: [
        JobListService
    ]
})
export class JobListComponent implements OnInit {

    changeEmitter: Observable<JobListInterface[]>;
    joblists: WritableSignal<JobListInterface[]> = signal([]);
    enableMoreResults: WritableSignal<boolean> = signal(false);

    private nextPage = 1;
    private limit = 5;

    searchQuery: SearchQueryInterface = {
        search: {},
        sort: {}
    };

    constructor(
        private loadingService: LoadingService,
        private router: Router,
        private jobListService: JobListService,
        @Inject(jobListSearchConfig) public searchConfig: SearchMenuInterface,
        private pageActionService: PageActionService
    ) {
        this.changeEmitter = toObservable(this.joblists);
    }

    async ngOnInit(): Promise<void> {
        this.joblists.set([]);
        const results = await this.loadJobLists();
        if (results.length > 0) {
            this.joblists.set(results);
        }

        this.pageActionService.addFilterAction(new PageFilterAction(
            this.searchConfig,
            async (searchQuery) => {
                await this.searchChanged(searchQuery);
            },
            async () => {
                this.searchChanged({
                    search: {},
                    sort: {}
            }) }
        ));
        this.pageActionService.addAction(PageAction.route(
            'Neuer Job',
            '/jobs/new',
            'agency_new',
        ));
    }

    async defaultPastList() {
        this.nextPage = 1;
        this.searchQuery = {
            search: {
                'past': '1'
            },
            sort: {}
         } as SearchQueryInterface;
        this.joblists.set(await this.loadJobLists());
    }

    async defaultList() {
        this.nextPage = 1;
        this.searchQuery = {
            search: {
                'today': '1'
            },
            sort: {}
        } as SearchQueryInterface;
        this.joblists.set(await this.loadJobLists());
    }

    async loadJobLists(): Promise<JobListInterface[]> {
        await this.loadingService.start();
        const jobResponse = await this.jobListService.getList(this.nextPage, this.limit, this.searchQuery)
        .catch((e) => {
            console.log("Fehler bei der Anfrage", e);
        });

        await this.loadingService.stop();
        if (jobResponse) {
            this.nextPage = jobResponse.page + 1;
            if (this.nextPage <= jobResponse.countResultPages) {
                this.enableMoreResults.set(true);
            } else {
                this.enableMoreResults.set(false);
            }

            return jobResponse.data;
        }

        return [];
    }

    clickEditJob(job: JobListInterface) {
        this.router.navigate([`/jobs/${job.id}`]);
    }

    async clickLoadNextPage() {
        const results = await this.loadJobLists();
        if (results.length > 0) {
            const currentResults = this.joblists();
            this.joblists.set(currentResults.concat(results));
        }
    }

    async searchChanged($event: SearchQueryInterface) {
        console.log($event);
        this.nextPage = 1;
        const queryClone = { ...$event };

        const searchQueryBuilder = new SearchQueryBuilder(this.searchConfig.searchElements, queryClone);
        this.searchQuery = searchQueryBuilder.getSearchQuery();
        this.joblists.set(await this.loadJobLists());
    }

    /**
     * Navigation
     *
     * @param job
     * @returns
     */
    async clickGoToWorkforce(job: JobListInterface) {
        const route = `/jobs/${job.id}/workforce`;
        return await this.router.navigate([route]);
    }

    /**
     * Navigation zu den Nachrichten zum übergebenen Job
     * 
     * @param job 
     * @returns 
     */
    async clickGoToMessages(job: JobListInterface) {
        return await this.router.navigate([`/message/${job.id}/list`]);
    }

    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
}
