<ion-content>
    <ion-col>
        <ion-row>
            <ion-col>
                @if (validateAccountForm) {
                    <nb-form
                    #formInstance
                    [form]="validateAccountForm"
                    appearance="outline"
                    [delay]="0"
                    [debug]="false"
                    (changeData)="onFormChange()"
                ></nb-form>
                }
               
            </ion-col>
        </ion-row>

        @for (error of errors(); track error) {
            <ion-row>
                <ion-col>
                    <ion-text color="danger">
                        {{ error }}
                    </ion-text>
                </ion-col>
            </ion-row>
        }

        <ion-row>
            <ion-col>
                <ion-button fill="outline" (click)="clickValidateAccount()" [disabled]="disableSave()">
                    Account freischalten
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-col>
   
</ion-content>