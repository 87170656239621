<ion-header>
    <ion-row>
        <ion-col>
            <ion-title>
                Job Pushen
            </ion-title>
        </ion-col>
    </ion-row>
</ion-header>
<ion-content class ="ion-padding">
    <ion-row>
        <ion-col>
            @if (bulkIsPossible() && form) {
                <nb-form
                #formInstance
                [form]="form"
                appearance="outline"
                [delay]="0"
                [debug]="false"
                [helpers]="helpers"
                (changeData)="onChangeForm()"
                ></nb-form>
            } @else {
                <ion-text>
                    Für diesen Job gibt es noch unbearbeitete Aufträge.<br>
                    Bitte warten Sie, bis diese abgearbeitet sind!
                </ion-text>
            }
            
        </ion-col>
    </ion-row>
    
    <ion-row>
        @if (selectedGroups().length > 0) {
            <ion-col>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-label>
                                    Ausgewählte Gruppen:
                                </ion-label>
                            </ion-item>
                            @for (groupId of selectedGroups(); track groupId) {
                                <ion-item>
                                    {{ displayGroup(groupId) }}
                                </ion-item>
                            }
                        </ion-list>
                    </ion-col>
                </ion-row>
            </ion-col>
        }
    
        @if (selectedUsers().length > 0) {
            <ion-col>
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-label>
                                    Ausgewählte Fahrer:
                                </ion-label>
                            </ion-item>
                            @for (userId of selectedUsers(); track userId) {
                                <ion-item>
                                    {{ displayDriver(userId) }}
                                </ion-item>
                            }
                        </ion-list>
                    </ion-col>
                </ion-row>
                
            </ion-col>
        }
        
    </ion-row>
</ion-content>

<ion-footer class ="ion-padding">
    <ion-row>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button fill="outline" (click)="clickClose()">
                    Abbrechen
                </ion-button>
            </ion-buttons>
          
            <ion-buttons slot="end">
                <ion-button fill="outline" (click)="clickSave()" [disabled]="disableSave()">
                    Speichern
                </ion-button>
            </ion-buttons>
          
        </ion-toolbar>
     

       
    </ion-row>
</ion-footer>