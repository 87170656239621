import { Injectable } from '@angular/core';
import { ENV, HttpService, QueryParamsInterface } from '@nf-workforce/shared';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class AgencyDriverDocumentService {

    constructor(
        private httpService: HttpService,
        private httpClient: HttpClient
    ) {}

    /**
     * Download eines Fahrer-Dokumentes
     * 
     * @param id 
     * @returns 
     */
    downloadDriverDocument(id: number, isBackside: boolean) {
        const urlDownload = `${ENV.apiUrl}/agency/driver/document/${id}`;
        if (isBackside) {
            const queryParams = {
                backSide: isBackside
            } as QueryParamsInterface;
    
           
            return this.httpClient.get(urlDownload, {
                observe: 'response',
                responseType: 'blob',
                params: queryParams
              });
        }

        return this.httpClient.get(urlDownload, {
            observe: 'response',
            responseType: 'blob'
          });
    }

    /**
     * Akzeptieren des FahrerDocuments mit der übergebenen Id
     * 
     * @param id 
     * @returns 
     */
    acceptDriverDocument(id: number) {
        const urlAcceptDocument = `${ENV.apiUrl}/agency/driver/document/${id}/validation`;
        return this.httpService.post(urlAcceptDocument, {});
    }

    rejectDriverDocument(id: number) {
        const urlRejectDocument = `${ENV.apiUrl}/agency/driver/document/${id}/validation`;
        return this.httpService.delete(urlRejectDocument);
    }

}
