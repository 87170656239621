import { AccountService } from '../../services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewChild,
    WritableSignal,
    inject,
    signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Form, FormPage, NbFormComponent } from 'nb-form';
import { IonicModule } from '@ionic/angular';
import { AuthService, ErrorService, ToastService, hasProperty, isString } from '@nf-workforce/shared';
import { CONFIRM_PASSWORD_SCHEMA } from '../../schema/confirm-username.schema';

@Component({
    selector: 'lib-change-username',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
    ],
    templateUrl: './change-username.component.html',
    providers: [],
})
export class ChangeUsernameComponent implements OnInit, AfterViewChecked{

    private activatedRoute = inject(ActivatedRoute)

    private router = inject(Router);
   
    private accountService = inject(AccountService);

    private toastService = inject(ToastService);

    private cdr = inject(ChangeDetectorRef);

    private authService = inject(AuthService);

    private errorService = inject(ErrorService);

    private changeCodeKey = 'nonce';

    private changeCode = '';
   
    errors: WritableSignal<string[]> = signal([]);

    formIsValid = signal(false);

    isLoggedIn = signal(false);
   
    form: Form;

    @ViewChild(NbFormComponent)    
    nbFormInstance!: NbFormComponent;

    /**
     * Gibt die Erfolgsnachricht zurück
     */
    get successMessage(): string {
        if (this.isLoggedIn()) {
            return 'Die Änderung des Benutzernamens war erfolgreich.';
        } else {
            return 'Die Änderung des Benutzernamens war erfolgreich. Sie können sich nun mit ihrer neuen E-Mail Adresse einloggen';
        }
    }

    constructor(
        @Inject(CONFIRM_PASSWORD_SCHEMA) private recoverySchema: FormPage,
    ) {
        this.form = new Form();
        this.form.schema = [this.recoverySchema];
        this.form.data = {
            password: null
        };
        this.cdr.detach();
    }

    ngOnInit(): void {
        const queryParams = this.activatedRoute.snapshot.queryParams;
        if (hasProperty(queryParams, this.changeCodeKey) && isString(queryParams[this.changeCodeKey])) {
            this.changeCode = queryParams[this.changeCodeKey];
        }

        this.isLoggedIn.set(this.authService.isAuthenticated());
    }

    ngAfterViewChecked(): void {
        this.cdr.reattach();
        this.cdr.detectChanges();
    }

    /**
     * Sendet das Request zum finalen Ändern des Benutzernamens an den Server
     * 
     * @returns 
     */
    async clickChangeUserName() {
        if (!this.nbFormInstance.getValidator().validate()) {
            await this.toastService.presentToast("Bitte überprüfen Sie Ihre Eingaben.");
            return;
        }

        const result = await this.accountService.changeUserName({
            changeId: this.changeCode,
            password: this.form.data['password'] as string
        }).catch(async (e) => {
            return null;
        });

        if (result) {
            await this.toastService.presentToast(this.successMessage);
            if (this.isLoggedIn()) {
                console.debug("Result", result);
                this.authService.setValueInSession('username', result.newUsername)
                this.router.navigate(['/account/my-account']);
            } else {
                this.router.navigate(['/login']);
            }
        } else {
            this.errorService.highlightFormErrors(this.nbFormInstance, this.recoverySchema);
        }
    }

    formChanged() {
        this.validateForm();
        this.cdr.markForCheck();
    }

    /**
     * Funktion zum Validieren des Formulares und setzen der Extra-Nachrichten
     */
    private validateForm() {
        if (this.nbFormInstance) {
            const validator = this.nbFormInstance.getValidator();
            const result = validator.validate();

            this.formIsValid.set(result);
            if (!result && validator.errorFields.length === 1) {
                const errorControl = validator.errorFields[0].control;
                const controlErrors = errorControl.errors as { [key: string]: string };
                const errorKeys = Object.keys(controlErrors);

                // NbFormBadRequest rausfiltern + die zutreffenden Fehlermeldungen zurückgeben
                const errorMessages = errorKeys.filter((key) => {
                    return (key !== 'nbFormBadRequest' && isString(controlErrors[key]));
                }).map((key) => {
                    return controlErrors[key];
                })

                this.errors.set(errorMessages);
                validator.highlightErrors = true;
                validator.setValidationErrors({
                    password: 'Bitte überprüfen Sie Ihre Eingabe.'
                });
            } else {
                this.errors.set([]);
                validator.highlightErrors = false;
            }
        }
    }
   
}
