<ion-col>
    <ion-row>
        <ion-col>
            @if (hasDrivers()) {
            <div uiTable class="expand-xl fixed-header w-100" [changeEmitter]="changeEmitter">
                <div uiTableHead>
                    <div uiTableCell>#</div>
                    <div uiTableCell>Nachname</div>
                    <div uiTableCell>Vorname</div>
                    <div uiTableCell>Benutzername</div>
                    <div uiTableCell>Telefon</div>
                    <div uiTableCell>Status</div>
                    <div uiTableCell></div>
                </div>

                @for (driver of drivers(); track driver) {
                <div uiTableRow [attr.data-driver-id]="driver.id">
                    <div uiTableCell [ariaHidden]="true" [hideOnExpand]="true">
                        <div uiTableKicker># {{ driver.id }}</div>
                        <div class="headline" (click)="clickGoToDetails(driver)">
                            <h3>{{ driver | fullname }}</h3>
                        </div>
                    </div>

                    <div uiTableCell [hideOnCollapse]="true">
                        {{ driver.id }}
                    </div>

                    <div uiTableCell [hideOnCollapse]="true">
                        {{ driver.lastName }}
                    </div>

                    <div uiTableCell [hideOnCollapse]="true">
                        {{ driver.firstName }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>Benutzername:<br></span>
                        {{ driver.username }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>Telefon:<br></span>
                        {{ driver.phone }}
                    </div>

                    <div uiTableCell>
                        <span uiTableCellLabel>Status:<br></span>
                        <table class="w-100">
                            <tbody>
                            <tr>
                                <td>Login erlaubt:</td>
                                <td>
                                    @if (driver.driver) {
                                        {{ driver.driver.isActive | booleanToString }}
                                    }
                                    </td>
                            </tr>
                            <tr>
                                <td>Profil:</td>
                                <td>{{driver.driver?.status | driverAgencyProfileState }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div uiTableCell [hideOnCollapse]="true">
                        <ion-buttons slot="end">
                            <ion-button
                                size="small"
                                fill="clear"
                                title="Fahrerprofil"
                                aria-label="Fahrerprofil"
                                (click)="clickGoToDetails(driver)">
                                <mat-icon [icon]="IconEnum.EDIT" [theme]="IconTheme.INHERIT"></mat-icon>
                            </ion-button>
                        </ion-buttons>
                    </div>
                </div>
                }

                @if (enableMoreResults()) {
                <div uiTableFooter>
                    <ion-button
                        size="small"
                        fill="outline"
                        [color]="IconTheme.INHERIT"
                        (click)="clickLoadNextPage()"
                        title="Mehr Ergebnisse laden"
                        aria-label="Mehr Ergebnisse laden"
                    ><mat-icon [icon]="IconEnum.DOWNLOAD_NEXT_PAGE" [theme]="IconTheme.INHERIT"></mat-icon>
                        &nbsp;mehr Ergebnisse laden
                    </ion-button>
                </div>
                }
            </div>
            } @else {
                <ion-card>
                    <ion-card-header>
                        <ion-title>Keine Fahrer:innen vorhanden!</ion-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-row>
                            <ion-col>
                                <ion-button
                                    title="Neue Fahrer:in"
                                    aria-label="Neue Fahrer:in"
                                    (click)="clickAddDriver()">
                                    <mat-icon [icon]="IconEnum.NEW" [theme]="IconTheme.INHERIT"></mat-icon>
                                    Fahrer:in anlegen
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </ion-card-content>
                </ion-card>
            }
        </ion-col>
    </ion-row>
</ion-col>
