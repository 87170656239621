import { Component, EventEmitter, Input, OnInit, Output, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { DriverProfileService } from '../../../service/driver-profile.service';
import {
    BooleanToStringPipe,
    DocumentEavInterface,
    DriverAgencyInterface,
    DriverDataInterface,
    DriverDocumentInterface,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    PrintNbDatePipe,
    TitleIconEnum,
    ToastService
} from '@nf-workforce/shared';
import { DriverMyProfileOneDocumentComponent } from './one-document/driver-my-profile-one-document.component';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertsService } from 'nb-form';
import { DriverDocumentHelperService } from '../../../service/driver-document-helper.service';

@Component({
    selector: 'driver-my-profile-documents-card',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        PrintNbDatePipe,
        DriverMyProfileOneDocumentComponent,
        BooleanToStringPipe
    ],
    templateUrl: './driver-my-profile-documents-card.component.html',
    styleUrl: './driver-my-profile-documents-card.component.scss',
    providers: [
        DriverDocumentHelperService
    ]
})
export class DriverMyProfileDocumentsCardComponent implements OnInit {

    protected readonly IconTheme = IconThemeEnum;
    protected readonly Icon = TitleIconEnum;
    protected readonly IconEnum = IconEnum;

    @Input()
    driver: DriverDataInterface | null = null;
    @Input()
    driverAgencies: DriverAgencyInterface[] = [];

    @Output()
    editAgencyAccess: EventEmitter<DriverDocumentInterface> = new EventEmitter();
    @Output()
    editFahrerlaubnis: EventEmitter<unknown> = new EventEmitter();
    @Output()
    upload: EventEmitter<number> = new EventEmitter();
    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();


    showPlace: WritableSignal<boolean> = signal(false);

    constructor(
        private alertService: AlertsService,
        private documentHelper: DriverDocumentHelperService,
        private driverProfileService: DriverProfileService,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {
    }

    get missingEavDocuments() {
        return this.documentHelper.missingEavDocuments;
    }

    get eavDocuments() {
        return this.documentHelper.eavDocuments;
    }


    ngOnInit(): void {
        if (this.driver && this.driverAgencies) {
            this.documentHelper.setDriver(this.driver);
            this.documentHelper.setMissingDocuments();
            this.showPlace.set(true);
        }
    }

    isSingleEavDocument(eavId: number) {
        return this.documentHelper.isSingleEavDocument(eavId);
    }

    hasDocument(eav: DocumentEavInterface): boolean {
        return this.documentHelper.hasDocument(eav);
    }

    findOneDocumentByEav(eav: DocumentEavInterface): DriverDocumentInterface | undefined {
        return this.documentHelper.findOneDocumentByEav(eav);
    }

    findAllDocumentsByEav(eav: DocumentEavInterface): DriverDocumentInterface[] | undefined {
        return this.documentHelper.findAllDocumentsByEav(eav);
    }

    clickEditAgencyAccess(driverDocument: DriverDocumentInterface) {
        this.editAgencyAccess.emit(driverDocument);
    }

    async deleteDocument(driverDocument: DriverDocumentInterface) {
        if (driverDocument === undefined) {
            console.warn("Delete nicht möglich - Document undefined");
            return;
        }

        await this.loadingService.start();
        const result = await this.driverProfileService.deleteDocument(driverDocument.id).catch(
            async (e: HttpErrorResponse) => {
                console.warn("Fehler bei der Anfrage: ", e);
                await this.toastService.presentToast(
                    "Fehler bei der Anfrage, bitte versuchen Sie es später erneut."
                );
                return null;
            });

        await this.loadingService.stop();
        if (result) {
            const msg = `Dokument "${driverDocument.name}" gelöscht.`;
            await this.toastService.presentToast(msg);
            this.reload.emit(true);
        }
    }

    clickEditFahrerlaubnis() {
        this.editFahrerlaubnis.emit();
    }

    clickNewDocument() {
        this.upload.emit(0);
    }

}

