<ion-header>
    <ion-col>
        <ion-title>
            {{ title }}
        </ion-title>
    </ion-col>
</ion-header>
<ion-content>
    @if(this.driver) {
        @switch (wizardStep) {
            @case (1) {
                <ion-row>
                    <ion-col>
                        <driver-document-upload-wizard-select-eav-document
                            [driver]="driver"
                            (currentEavId)="onClickEavDoc($event)">
                        </driver-document-upload-wizard-select-eav-document>
                    </ion-col>
                </ion-row>
            }
            @case (2) {
                <ion-row>
                    <ion-col>
                        <driver-document-upload-wizard-select-two-files
                            [driver]="driver"
                            [currentEav]="currentEav"
                            (hasJustOneSite)="setHasJustOneFile($event)"
                        >
                        </driver-document-upload-wizard-select-two-files>
                    </ion-col>
                </ion-row>
            }
            @case (3) {
                <ion-row>
                    <ion-col>
                        <driver-document-upload-wizard-upload-files
                            [driver]="driver"
                            [currentEav]="currentEav"
                            [hasJustOneFile]="hasJustOneFile"
                            (document)="setDocument($event)"
                        >
                        </driver-document-upload-wizard-upload-files>
                    </ion-col>
                </ion-row>
            }
            @case (4) {
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-list-header>Welches Führerschein-Klassen besitzen Sie?</ion-list-header>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <driver-my-profile-fahrerlaubnis
                    [driver]="driver">
                </driver-my-profile-fahrerlaubnis>
            }
            @case (5) {
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-list-header>Für welche Agenturen wollen Sie das Dokument freigeben?</ion-list-header>
                        </ion-list>
                    </ion-col>
                </ion-row>
                <driver-my-profile-document-agency
                    [document]="document"
                    [agencies]="driverAgencies"
                ></driver-my-profile-document-agency>
            }
            @case (500) {
                <ion-row>
                    <ion-col>
                        <ion-list>
                            <ion-item>
                                <ion-text [color]="IconTheme.DANGER">
                                    Leider ist ein Fehler aufgetreten!
                                </ion-text>
                            </ion-item>
                            <ion-item lines="none">
                                1. Bitte laden Sie die Seite neu.
                            </ion-item>
                            <ion-item lines="none">
                                2. Prüfen Sie, ob das Dokument schon hochgeladen wurde.
                                Falls ja: Löschen Sie das Dokument.
                            </ion-item>
                            <ion-item lines="none">
                                3. Versuchen Sie es erneut.
                            </ion-item>
                        </ion-list>
                    </ion-col>
                </ion-row>
            }
        }
    }

</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="start">
            @if (wizardStep < 4) {
                <ion-button (click)="clickCancel()" color="secondary">
                    <mat-icon [icon]="IconEnum.CLOSE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Abbrechen
                </ion-button>
            }
            @if (wizardStep === 5) {
                <ion-button (click)="wizardBack()" color="secondary">
                    <mat-icon [icon]="IconEnum.BACK" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Zurück
                </ion-button>
            }
        </ion-buttons>
        <ion-buttons slot="end">
            @if (wizardStep > 1 && wizardStep < 4) {
                <ion-button (click)="wizardBack()" color="secondary">
                    <mat-icon [icon]="IconEnum.BACK" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Zurück
                </ion-button>
            }
            @if (wizardStep === 3) {
                <ion-button (click)="clickUpload()" color="success">
                    <mat-icon [icon]="IconEnum.UPLOAD" [theme]="IconTheme.SUCCESS" ></mat-icon>
                    &nbsp; Upload
                </ion-button>
            }
            @if (wizardStep === 4) {
                <ion-button (click)="wizardStep=5" color="success">
                    <mat-icon [icon]="IconEnum.SHARE" [theme]="IconTheme.SUCCESS" ></mat-icon>
                    &nbsp; Freigeben
                </ion-button>
            }
            @if (wizardStep === 5) {
                <ion-button (click)="clickSuccess()" color="success">
                    <mat-icon [icon]="IconEnum.CLOSE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Fertig
                </ion-button>
            }
        </ion-buttons>
    </ion-toolbar>
</ion-footer>

