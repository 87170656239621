import { JobDriverInterface } from "@nf-workforce/shared";
import { FormElementOption } from "nb-form";

export function driversToOptions(driverList: JobDriverInterface[]): FormElementOption[] {
    return driverList.map((driver) => {
        return {
            key: driver.userListDto.id,
            value: `${driver.userListDto.firstName} ${driver.userListDto.lastName}`
        } as FormElementOption;
    }) as FormElementOption[];
}