@if (agencyGroup !== null) {
    <ion-row>
        <ion-col>
            <div uiTable class="expand-xl w-100">
                <div uiTableHead>
                    <div uiTableCell align="right">#</div>
                    <div uiTableCell>Name</div>
                    <div uiTableCell>Benutzername</div>
                    <div uiTableCell>Login</div>
                    <div uiTableCell>Profil</div>
                    <div uiTableCell></div>
                </div>

                @for (member of agencyMembers(); track member) {
                    <div uiTableRow>
                        <div uiTableCell align="right" [hideOnCollapse]="true">
                            {{ member.id }}
                        </div>

                        <div uiTableCell>
                            <span uiTableCellLabel>Name: </span>
                            {{ member.fullName }}
                        </div>

                        <div uiTableCell>
                            <span uiTableCellLabel>Benutzername: </span>
                            {{ member.userName }}
                        </div>

                        <div uiTableCell>
                            <span uiTableCellLabel>Login: </span>
                            {{ member.isActive | booleanToString }}
                        </div>

                        <div uiTableCell>
                            <span uiTableCellLabel>Login: </span>
                            {{ member.driverProfileStatus | driverAgencyProfileState }}
                        </div>

                        <div uiTableCell>
                            <ion-buttons slot="end">
                                <ion-button
                                    size="small"
                                    fill="clear"
                                    title="Mitglied von Gruppe entfernen"
                                    aria-label="Einstellungen"
                                    (click)="removeMember(member)">
                                    <mat-icon [icon]="IconEnum.DELETE" [theme]="IconTheme.DANGER"></mat-icon>
                                </ion-button>
                            </ion-buttons>
                        </div>
                    </div>
                }
            </div>
        </ion-col>
    </ion-row>

}
