<ion-content>
    <ion-row>
        <ion-col>
            <ion-toolbar>
                <ion-title slot="secondary"> Seite nicht gefunden </ion-title>
            </ion-toolbar>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col> 
            <ion-text color="secondary">
                <p>
                    Die von Ihnen gesuchte Seite existiert leider nicht.
                </p>
              </ion-text>
        </ion-col>
    </ion-row>
</ion-content>
