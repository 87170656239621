/**
 * Funktion zum formatieren der Telefonnummer - wandelt die von den Formularen erlaubten Formate in ein gültige Telefonnummer um
 * 
 * @param phone 
 */
export function formatPhoneNumber(phone: string): string {
    let tmpNumber = "";

    if (phone.startsWith("00")) {
        // Behandlung, im Fall, dass die ersten beiden Zweichen zwei mal das Zeichen "0" sind - durch plus ersetzen
        tmpNumber = "+" + phone.substring(2, phone.length);
    } else if (phone.startsWith("0")) {
        // Wenn die Telefonnummer mit einer einzelnen 0 beginnt, wird die 0 durch +49 ersetzt, da wir von einer deutschen NUmmer ausgehen
        tmpNumber = "+49" + phone.substring(1, phone.length);
    } else {
        tmpNumber = phone;
    }

    return tmpNumber
}