import { Injectable } from "@angular/core";
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {


    constructor(
        public toastController: ToastController
    ) { }

    async presentToast(message: string, duration = 3000) {
        const toast = await this.toastController.create({
            message: message,
            duration: duration,
            position: 'top',
        });

        await toast.present();
    }


}