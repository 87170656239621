import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MessageViewType } from '../../enum/message-list-type.enum';
import { IonicModule } from '@ionic/angular';

@Component({
    selector: 'message-action-bar',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule
    ],
    templateUrl: './message-action-bar.component.html',
    styleUrl: './message-action-bar.component.scss',
})
export class MessageActionBarComponent {

    @Input()
    viewType!: MessageViewType;

    @Input()
    jobId!: number;

    private router = inject(Router);
    
    /**
     * Überprüft ob die übergebene Route derzeit aktiv ist
     * 
     * @param type 
     * @returns 
    */
    isActive(type: MessageViewType) {
        return (this.viewType === type);
    }

    /**
     * Handler zum Ändern der derzeit angezeigten Liste
     * 
     * @param type 
    */
    changeRoute(type: MessageViewType) {
        if (type === MessageViewType.LIST) {
            this.router.navigate([`/message/${this.jobId}/list`]);
        } else if (type === MessageViewType.SINGLE) {
            this.router.navigate([`message/${this.jobId}/add-single`]);
        }
    }

}
