import { Directive, HostBinding} from '@angular/core';

@Directive({
    selector: '[uiTableFooter]',
    standalone: true
})
export class TableFooterDirective {

  @HostBinding('class')
  elementClass = 'table-footer';

}