import { Route } from "@angular/router";
import { ResetPasswordComponent } from "./component/reset-password/reset-password.component";
import { MyAccountComponent } from "./component/my-account/my-account.component";
import { AccGuard, AuthGuard } from "@nf-workforce/shared";
import { ValidateAccountComponent } from "./component/validate-account/validate-account.component";
import { MyAccountLogoComponent } from './component/my-account/profile-logo/my-account-logo.component';

export const accountRoutes: Route[] = [
    {
        path: '',
        redirectTo: 'my-account',
        pathMatch: 'full'
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        pathMatch: 'full',
        title: 'Passwort zurücksetzen',
        data: { hideNav: true },
    },
    {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [AuthGuard, AccGuard],
        pathMatch: 'full',
        title: 'Mein Account',
        data: { protectedBy: 'my_account' },
    },
    {
        path: 'my-profile-image',
        component: MyAccountLogoComponent,
        pathMatch: 'full',
        title: 'Mein Profilbild',
        data: { protectedBy: 'my_account' },
        canActivate: [AuthGuard, AccGuard],
    },
    {
        path: 'validate-account',
        component: ValidateAccountComponent,
        canActivate: [AuthGuard, AccGuard],
        pathMatch: 'full',
        title: 'Account freischalten',
        data: { protectedBy: 'my_account_validate' },
    }
];
