import { EventEmitter, Injectable } from '@angular/core';
import {
    DriverProfileInterface,
    DriverDocumentInterface,
    DriverDataInterface,
    DriverLanguageInterface,
    DriverAgencyInterface,
    ENV,
    HttpService, QueryParamsInterface
} from '@nf-workforce/shared';
import { DriverEavRequestInterface } from '../interface/requests/driver-eav-request.interface';
import { DeleteDriverEavRequestInterface } from '../interface/requests/delete-driver-eav-request.interface';
import { DriverReferenceRequestInterface } from '../interface/requests/driver-reference-request.interface';
import { DriverLanguageRequestInterface } from '../interface/requests/driver-language-request.interface';
import { UploadDocumentRequestInterface } from '../interface/requests/upload-document-request.interface';
import { CompanyProfileInterface } from '../interface/requests/company-profile.interface';
import { ToggleDriverDocumentInterface } from '../interface/requests/toggle-drivert-document.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DriverProfileService {

    doUploadDocument: EventEmitter<boolean> = new EventEmitter();
    uploadCompleted: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private httpService: HttpService,
        private httpClient: HttpClient
    ) { }

    get driverRoute(): string {
        return `${ENV.apiUrl}/driver`;
    }

    /**
     * Holen der Fahrerdaten
     *
     * @returns
     */
    getDriverData(): Promise<DriverDataInterface> {
        return this.httpService.getBypassCache(this.driverRoute);
    }

    /**
     * Hinzufügen einer neuen Fahrerreferenz
     *
     * @param payload
     * @returns
     */
    addDriverReference(payload: DriverReferenceRequestInterface) {
        const route = `${this.driverRoute}/reference`;
        return this.httpService.post(route, payload);
    }

    /**
     * Aktualisieren einer Fahrerreferenz
     *
     * @param payload
     * @returns
     */
    updateDriverReference(payload: DriverReferenceRequestInterface) {
        const route = `${this.driverRoute}/reference`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Löschen einer Fahrerreferenz
     *
     * @param payload
     * @returns
     */
    deleteDriverReference(payload: DriverReferenceRequestInterface) {
        const route = `${this.driverRoute}/reference`;
        return this.httpService.deleteWithBody(route, payload as unknown as { [key: string]: unknown });
    }

    /**
     * Hinzufügen eines neuen Skills
     *
     * @param payload
     * @returns
     */
    addSkill(payload: DriverEavRequestInterface) {
        const route = `${this.driverRoute}/skill`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Löschen eines Fahrer-Skills
     *
     * @param payload
     * @returns
     */
    deleteSkill(payload: DeleteDriverEavRequestInterface) {
        const route = `${this.driverRoute}/skill`;
        return this.httpService.deleteWithBody(route, payload as unknown as { [key: string]: unknown });
    }

    /**
     *
     * Speichern des Fahrerprofils
     *
     * @param payload
     * @returns
     */
    patchDriverProfile(payload: DriverProfileInterface) {
        const route = `${this.driverRoute}/profile`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Aktualisieren des Firmenprofils
     *
     * @param payload
     * @returns
     */
    patchCompanyProfile(payload: CompanyProfileInterface) {
        const route = `${this.driverRoute}/company-profile`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Speichern/Aktualisieren der Fahrersprache
     *
     * @param payload
     * @returns
     */
    saveDriverLanguage(payload: DriverLanguageRequestInterface): Promise<{data: DriverLanguageInterface} | void>  {
        const route = `${this.driverRoute}/language`;
        return this.httpService.patch(route, payload);
    }

    /**
     *
     * Löschen der Fahrersprache
     *
     * @param payload
     * @returns
     */
    deleteDriverLanguage(payload: DeleteDriverEavRequestInterface) {
        const route = `${this.driverRoute}/language`;
        return this.httpService.deleteWithBody(route, payload as unknown as { [key: string]: unknown });
    }

    /**
     * Speichert eine neue Fahrerlaubnis für den derzeit eingeloggten Fahrer ab
     *
     * @param payload
     * @returns
     */
    patchDriverFahrerlaubnis(payload: DriverEavRequestInterface) {
        const route = `${this.driverRoute}/fahrerlaubnis`;
        return this.httpService.patch(route, payload);
    }

    /**
     * Sendet das Request zum löschen der fahrerlaubnis mit der übergebenen Id ab
     *
     * @param payload
     * @returns
     */
    deleteDriverFahrerlaubnis(payload: DeleteDriverEavRequestInterface) {
        const route = `${this.driverRoute}/fahrerlaubnis`;
        return this.httpService.deleteWithBody(route, payload as unknown as { [key: string]: unknown });
    }

    /**
     * Request zum hochladen eines Dokumentes
     *
     * @param payload
     * @returns
     */
    uploadDocument(payload: UploadDocumentRequestInterface): Promise<{data: DriverDocumentInterface} | void> {
        const route = `${this.driverRoute}/document`;
        return this.httpService.post(route, payload);
    }

    deleteDocument(idDriverDocument: number) {
        const route = `${this.driverRoute}/document/${idDriverDocument}`;
        return this.httpService.delete(route);
    }

    /**
     * Freischalten/Entfernen des Dokumentes für die Agency
     *
     * @param payload
     * @returns
     */
    toggleAgency(payload: ToggleDriverDocumentInterface) {
        const route = `${this.driverRoute}/document/toggle-agency`;
        return this.httpService.post(route, payload);
    }

    /**
     * Herunterladen eines Fahrer-Dokumentes
     *
     * @param driverDocument
     * @param isBackSide Vorderseite oder Rückseite
     * @returns
     */
    downloadDocument(driverDocument: DriverDocumentInterface, isBackSide: boolean) {
        const route = `${this.driverRoute}/document/${driverDocument.id}`;
        if (isBackSide) {
            const queryParams = {
                backSide: isBackSide
            } as QueryParamsInterface;

            return this.httpClient.get(route, {
                params: queryParams,
                observe: 'response',
                responseType: 'blob'
            });
        } else {
            return this.httpClient.get(route, {
                observe: 'response',
                responseType: 'blob'
            });
        }
    }

    /**
     * Validierung der Agentur anfragen
     *
     * @param driverAgency
     * @returns
     */
    requestValidation(driverAgency: DriverAgencyInterface) {
        const route = `${this.driverRoute}/validation/agency/${driverAgency.idAgency}`;
        return this.httpService.get(route);
    }

}
