/**
 * Funktion zum Formatieren eines Javascript-Dates in das Format für die Speicherung im Backend
 * 
 * @param value 
 * @param ignoreTime 
 * @returns 
 */
export function formatFormDate(value: Date, ignoreTime = false): string {
    const fullYear = value.getFullYear();
    const month = (value.getMonth() + 1).toFixed().padStart(2, "0");
    const date = value.getDate().toFixed().padStart(2, "0");

    let dateString = `${fullYear}-${month}-${date}`;

    
    if (ignoreTime) {
        dateString += `T00:00:00`;
    } else {
        const hours = value.getHours().toFixed().padStart(2, "0");
        const mins = value.getMinutes().toFixed().padStart(2, "0");
        const secs = value.getSeconds().toFixed().padStart(2, "0");
        dateString += `T${hours}:${mins}:${secs}`;
    }

    const tzOffsetInMinutes = value.getTimezoneOffset() * -1;
    const tzHours = (tzOffsetInMinutes / 60).toFixed().padStart(2, "0");
    const tzMinutes = (tzOffsetInMinutes % 60).toFixed().padStart(2, "0");

    if (tzOffsetInMinutes > 0) {
        dateString += `+${tzHours}:${tzMinutes}`;
    } else {
        dateString += `-${tzHours}:${tzMinutes}`;
    }

    return dateString;
    
}