<span class="nav-link">
    @if (level > 1) {
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M400-280v-400l200 200-200 200Z"/></svg>
    }
    @if(navItem.clickFn || navItem.routerLink) {
    @if(navItem.clickFn) {
    <a mat-button href="#" (click)="navItemClick($event, navItem)" [title]="title(navItem)">{{ label(navItem) }}</a>
    }
    @else {
    <a mat-button (click)="dispatchCloseNavigationMenuEvent()" [routerLink]="navItem.routerLink" [title]="title(navItem)">{{ label(navItem) }}</a>
    }
    }
    @else {
    <span mat-button [title]="title(navItem)">{{ label(navItem) }}</span>
    }
</span>
