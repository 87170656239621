
import { Component, Input } from '@angular/core';

@Component({
    imports: [],
    selector: 'ui-avatar',
    standalone: true,
    styleUrl: './avatar.component.scss',
    templateUrl: './avatar.component.html',
})
export class AvatarComponent {

    @Input()
    sizeRem: number | undefined;

    @Input()
    url!: string;

    get style(): string {
        if (this.sizeRem) {
            return `height: ${this.sizeRem}rem`;
        }
        return '';
    }
}
