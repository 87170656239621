export function find(query: string, el: Element): NodeListOf<Element> | null {
    if (!el) {
        return null;
    }
    return el.querySelectorAll(query);
}

export function findOne(query: string, el: Element) {
    if (!el) {
        return null;
    }
    return el.querySelector(query);
}

export function parent(query: string, el: Element): Element | null {
    if (!el) {
        return null;
    }
    let target = el;
    while (!target.matches(query) && !!target.parentElement) {
        target = target.parentElement;
    }
    if (!target.matches(query)) {
        return null;
    }
    return target;
}

export function sibling(parentQuery: string, siblingQuery: string, el: Element): NodeListOf<Element> | null {
    const parentElement = parent(parentQuery, el);
    if (parentElement) {
        return find(siblingQuery, parentElement);
    }

    return null;
}

export function siblingOne(parentQuery: string, siblingQuery: string, el: Element): Element | null {
    const parentElement = parent(parentQuery, el);
    if (parentElement) {
        return findOne(siblingQuery, parentElement);
    }

    return null;
}
