import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DriverJoblistService } from '../../../services/driver-joblist.service';
import {
    DriverJobListInterface,
    DriverJobListType,
    hasProperty,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    isString,
    LoadingService,
    PrintNbDatePipe,
    PrintNbDateTimePipe,
    ToastService
} from '@nf-workforce/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { AlertsService } from 'nb-form';
import { OneJobStammdatenCardComponent } from '../../driver-job-card/jobdaten-card/one-job-stammdaten-card.component';
import { OneJobKnowledgeCardComponent } from '../../driver-job-card/knowledge-card/one-job-knowledge-card.component';

@Component({
    selector: 'driver-joblist-driver-job',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        IconDirective,
        MatIcon,
        PrintNbDateTimePipe,
        OneJobStammdatenCardComponent,
        OneJobKnowledgeCardComponent
    ],
    templateUrl: './one-job-list.component.html',
    styleUrl: './one-job-list.component.scss',
    providers: [
        DriverJoblistService,
    ]
})
export class OneJobListComponent implements OnInit {

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly DriverJobListType = DriverJobListType;

    private jobId = 0;
    job!: DriverJobListInterface;
    jobFound = true;

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: AlertsService,
        private loadingService: LoadingService,
        private joblistService: DriverJoblistService,
        private toastService: ToastService,
        private router: Router
    ) {
    }

    /**
     * Gibt zurück, ob der Fahrer sich selbst aus der Liste austragen kann
    */
    get canRemove(): boolean {
        if (!this.job
            || this.job.listType === DriverJobListType.TYPE_REJECTED
            || this.job.listType === DriverJobListType.TYPE_PAST
        ) {
            return false;
        }
        const deadline = this.job.deadline;
        if (isString(deadline)) {
            const deadlineDate = new Date(deadline);
            deadlineDate.setDate(deadlineDate.getDate() + 1);
            const currentDate = new Date();
            return (currentDate.getTime() < deadlineDate.getTime());
        }
        return false;
    }


    async ngOnInit(): Promise<void> {
        const params = this.activatedRoute.snapshot.params;
        if (hasProperty(params, 'id')) {
            this.jobId = params['id'] as number;
            const job = await this.loadOne();
            if (job) {
                this.job = job;
            }
        }
    }

    /**
     * Laden eines einzelnen Jobs
     *
     * @returns
     */
    async loadOne() {
        await this.loadingService.start();
        const result = await this.joblistService.getOneJob(this.jobId)
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler bei der Anfrage", e);
                return null;
            });

        await this.loadingService.stop();
        if (result && result.data.length === 1) {
            this.jobFound = true;
            return result.data[0];
        }
        this.jobFound = false;
        throw new Error(`Es konnte kein Job mit der Id: ${this.jobId} gefunden werden.`);
    }
    async askRemove() {
        let question = '';
        if (this.job.listType === DriverJobListType.TYPE_ACTIVE) {
            question = 'Wollen Sie Ihre Bewerbung zurückziehen?';
        } else if (this.job.listType === DriverJobListType.TYPE_WAITING) {
            question = 'Wollen Sie sich aus der Warteliste austragen?';
        } else {
           return false;
        }
        return await this.alertService.confirm({
            text: question,
            textOk: "Ja",
            textCancel: "Abbrechen"
        });
    }

    /**
     * Handler zum entfernen des Fahrers vom Job
     *
     * @returns
     */
    async clickRemoveFromJob() {
        if (!this.job) {
            console.warn("Keine Jobliste!");
            return;
        }
        if (!this.canRemove) {
            await this.toastService.presentToast('Zum Austragen wenden Sie sich bitte an die Agentur!');
            return;
        }
        const confirmation = await this.askRemove();
        if (!confirmation) {
            return;
        }
        await this.loadingService.start();
        const result = await this.joblistService.removeFromJob(this.job)
            .catch(async (e: HttpErrorResponse) => {
                console.warn("Fehler bei der Anfrage", e);
                return null;
            });
        await this.loadingService.stop();

        if (result) {
            const msg = `Sie haben sich erfolgreich aus dem Job ${this.job.name} ausgetragen.`;
            await this.toastService.presentToast(msg);
            const route = `/my-jobs/overview/${DriverJobListType.TYPE_ACTIVE}`;
            await this.router.navigate([route]);
        }
    }

    async goBackToList() {
        const listType = (this.job && this.job.listType) ? this.job.listType : 200;
        const route = `/my-jobs/overview/${listType}`;
        await this.router.navigate([route], { replaceUrl: true });
    }
}
