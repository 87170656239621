import {
    Component,
    ElementRef, EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    ViewChild,
    WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MatIcon } from '@angular/material/icon';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SelectedFileInterface } from '../../interfaces/frontend/selected_file.interface';
import { IconDirective } from '../../directives/icon/icon.directive';
import { IconThemeEnum } from '../../enum/icon-theme.enum';
import { IconEnum } from '../../enum/icon.enum';
import { TitleIconEnum } from '../../enum/title-icon.enum';

@Component({
    selector: 'shared-select-file-for-upload',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        IconDirective,
        MatIcon,
        ReactiveFormsModule,
    ],
    templateUrl: './select-file-for-upload.component.html',
    styleUrl: './select-file-for-upload.component.scss',
})
export class SelectFileForUploadComponent implements OnInit, OnDestroy {
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
    protected readonly TitleIcon = TitleIconEnum;

    // Subscriptions
    private file_selection_sub!: Subscription;
    showForm: WritableSignal<boolean> = signal(false);


    @ViewChild("fileSelector", {static: false}) file_selector!: ElementRef;
    form!: FormGroup;
    selected_file: SelectedFileInterface | null = null;

    @Input() config!: {
        inputLabel: string | null,
        MIME_types_accepted?: string| null,
    }

    @Output()
    selectedFile: EventEmitter<SelectedFileInterface> = new EventEmitter();

    get acceptedMimeTypes() {
        if (this.config?.MIME_types_accepted) {
            return this.config.MIME_types_accepted
        } else {
            return '';
        }
    }

    get inputLabel() {
        if (this.config?.inputLabel) {
            return this.config.inputLabel
        } else {
            return 'Datei auswählen';
        }
    }
    private convertBase64(file: unknown) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file as Blob);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    trackFileSelection(){
        this.file_selection_sub = this.form.get('file_selection')?.valueChanges.subscribe(
            async () => {
                const file_selection = this.file_selector.nativeElement;
                const data = await this.convertBase64(file_selection.files[0]);
                if (file_selection.files.length === 1) {
                    this.selected_file = {
                        name: file_selection.files[0].name as string,
                        data: data as string,
                        thumbnail: '',
                        description: '',
                    };
                    this.selectedFile.emit(this.selected_file);
                }
                this.file_selector.nativeElement.value = '';
            }
        ) as Subscription;
    }

    openFileSelector(){
        const file_selection = this.file_selector.nativeElement;
        file_selection.click();
    }

    ngOnInit() {
        if (this.config) {
            this.form = new FormGroup({
                file_selection: new FormControl()
            });
            this.trackFileSelection();
            this.showForm.set(true);
        }
    }

    ngOnDestroy(): void {
        this.selected_file = null;
        if (this.file_selection_sub) {
            this.file_selection_sub.unsubscribe();
        }
    }
}
