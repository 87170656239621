@if(showPlace()) {
    <ion-row>
        @for (eavSkill of eavKlassen; track eavSkill.id) {
            @if (eavSkill.id !== DriverFahrerlaubnisEnum.P_SCHEIN) {
                <ion-col>
                    <ion-item>
                        <ion-toggle
                            [enableOnOffLabels]="true"
                            [checked]="isSkillActive(eavSkill)"
                            (ionChange)="onSkillChange($event)"
                            labelPlacement="start"
                            [value]="isSkillActive(eavSkill) ? getDriverKlassenId(eavSkill) : eavSkill.id"
                        >{{ eavSkill.label }}</ion-toggle>
                    </ion-item>
                </ion-col>
            }
        }
    </ion-row>
}


