import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ICON_SVG_MAP, IconEnum, TitleIconEnum } from '@nf-workforce/shared';

export function initIcons(
    domSanitizer: DomSanitizer,
    matIconRegistry: MatIconRegistry,
) {
    [
        ...Object.values(IconEnum),
        ...Object.values(TitleIconEnum),

    ].forEach(iconName => {
        matIconRegistry.addSvgIconLiteral(
            iconName,
            domSanitizer.bypassSecurityTrustHtml(
                ICON_SVG_MAP.get(iconName) as string
            )
        );
    });
}
