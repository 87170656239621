import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, ModalController } from '@ionic/angular';
import { DRIVER_REFERENCE_SCHEMA } from '../../../../schema/driver-reference.schema';
import { Form, FormPage, NbFormComponent, cleanInjectedValue } from 'nb-form';
import { DriverReferencesInterface, IconDirective, IconEnum, IconThemeEnum, LoadingService, ToastService, createEmptyForm } from '@nf-workforce/shared';
import { DriverProfileService } from '../../../../service/driver-profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'driver-my-profile-reference-modal',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        NbFormComponent,
        IconDirective,
        MatIcon
    ],
    templateUrl: './profile-reference-modal.component.html',
    styleUrl: './profile-reference-modal.component.scss',
})
export class ProfileReferenceModalComponent implements OnInit {

    form: Form | null = null;

    @ViewChild(NbFormComponent)
    formInstance!: NbFormComponent

    reference: DriverReferencesInterface | null = null;

    title = "Neue Referenz";

    constructor(
        private modalController: ModalController,
        @Inject(DRIVER_REFERENCE_SCHEMA) private referenceSchema: FormPage,
        private toastService: ToastService,
        private driverProfileService: DriverProfileService,
        private loadingService: LoadingService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        if (this.reference) {
            this.title = `Referenz ${this.reference.id} bearbeiten`;
            this.form = createEmptyForm(1, cleanInjectedValue(this.referenceSchema));
            this.form.data['reference'] = this.reference.data;
            this.cd.detectChanges();
        } else {
            this.form = createEmptyForm(1, cleanInjectedValue(this.referenceSchema));
        }

        this.loadingService.stop();
    }

    async clickSave() {
        if (!this.form) {
            return;
        }

        if (!this.formInstance.getValidator().validate()) {
            await this.toastService.presentToast('Bitte überprüfen Sie Ihre Eingaben.');
            return;
        }

        let result;
        // Aktualisieren
        if (!this.reference) {
            result = await this.save();
        } else {
            result = await this.update();
        }

        if (!result) {
            return await this.modalController.dismiss({
                saved: false
            });
        }

        return await this.modalController.dismiss({
            saved: true
        });
    }

    /**
     * Speichern neuer Datensatz
     *
     * @returns
     */
    private async save() {
        if (!this.form) {
            return;
        }

        await this.loadingService.start();
        const result = await this.driverProfileService
            .addDriverReference({ data: this.form.data['reference'] as string })
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler: ", e);
                return null;
            });

        await this.loadingService.stop();
        return result;
    }

    /**
     * Aktualisierung der Fahrerreferenz
     *
     * @returns
     */
    private async update() {
        if (!this.form) {
            return;
        }

        await this.loadingService.start();
        const result = await this.driverProfileService
            .updateDriverReference({ id: this.reference?.id, data: this.form.data['reference'] as string})
            .catch((e: HttpErrorResponse) => {
                console.debug("Fehler: ", e);
                return null;
            });

        await this.loadingService.stop();
        return result;
    }

    async clickCancel() {
        await this.modalController.dismiss({
            saved: false
        });
    }

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;
}
