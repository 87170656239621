@if(driver && currentEav) {
    <ion-row>
        <ion-col>
            <ion-card>
                <ion-card-header class="ion-hide-md-up">
                    <ion-card-title>{{title}}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    @if (currentEav.hasBackSide) {
                        <ion-row>
                            <ion-col>
                                <ion-list>
                                    <ion-item lines="none">
                                        <ion-text [color]="IconTheme.DANGER">
                                            Es werden Vorder- und Rückseite des Dokumentes benötigt.
                                        </ion-text>
                                    </ion-item>
                                    <ion-item lines="none">
                                        Haben Sie beide Seiten in einer Datei? Dann müssen Sie nur ein Dokument
                                        hochladen!
                                        <br/>
                                        Haben Sie Vorder- und Rückseite in einer Datei?
                                    </ion-item>
                                    <ion-item>
                                        <ion-select
                                            label="Vorder- und Rückseite in einer Datei?"
                                            placeholder="Bitte wählen"
                                            label-placement="floating"
                                            (ionChange)="setHasBackSideRadio($event)"
                                        >
                                            <ion-select-option [value]="false">Nein</ion-select-option>
                                            <ion-select-option [value]="true">Ja</ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                </ion-list>
                            </ion-col>
                        </ion-row>
                    }
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>
}
