import {
    Component,
    ElementRef, EventEmitter,
    Input,
    OnInit, Output,
    signal,
    ViewChild,
    WritableSignal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { AutocompleteComponent, IconDirective, IconEnum, IconThemeEnum, LoadingService, ToastService } from '@nf-workforce/shared';
import {
    MatAutocomplete,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
    MatOption
} from '@angular/material/autocomplete';
import {  MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AlertsService, FormElementOption } from 'nb-form';
import { debounceTime, distinctUntilChanged, Observable, startWith, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AgencyGroupInterface } from '../../../interfaces/agency-group.interface';
import { DriverAutocompleteService } from '../../../service/driver-autocomplete.service';
import { GroupMemberRequestInterface } from '../../../interfaces/add-group-member-request.interface';
import { DriverGroupService } from '../../../service/driver-group.service';

@Component({
    selector: 'driver-group-add-driver',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        AutocompleteComponent,
        MatAutocomplete,
        MatAutocompleteTrigger,
        MatFormFieldModule,
        MatInput,
        MatLabel,
        MatOption,
        ReactiveFormsModule,
        MatButtonModule,
        IconDirective,
        MatIcon
    ],
    providers: [
        DriverAutocompleteService,
        DriverGroupService
    ],
    templateUrl: './add-driver.component.html',
    styleUrl: './add-driver.component.scss',
})
export class AddDriverComponent implements OnInit{

    protected readonly IconEnum = IconEnum;
    protected readonly IconTheme = IconThemeEnum;

    @Input()
    agencyGroup: AgencyGroupInterface | null = null;
    @Output()
    reload = new EventEmitter();

    idGroup!: number;

    searchDriver = new FormControl(null);
    filteredOptions!: Observable<{key: string, value: string}[]>;

    @ViewChild("autocomplete", {static: false}) autocomplete!: ElementRef;

    driverOptions: WritableSignal<FormElementOption[] | []> = signal([]);

    constructor(
        private alertsService: AlertsService,
        private autocompleteService: DriverAutocompleteService,
        private groupService: DriverGroupService,
        private loadingService: LoadingService,
        private toastService: ToastService
    ) {
    }

    async ngOnInit() {
        if (this.agencyGroup) {
            this.idGroup = this.agencyGroup.id;
            this.filteredOptions = this.searchDriver.valueChanges.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                switchMap((val) => {
                    return this.filter(val || '');
                })
            );
        }
    }


    async filter(val: string) {
        if (val.length < 4) {
            return [];
        }
        if (val.length % 2 === 0) {
            const result = await this.autocompleteService.searchDrivers({ query: val });
            this.autocomplete.nativeElement.focus();
            if (result) {
                this.driverOptions.set(result.data.map((driver) => {
                    return {
                        key: driver.id,
                        value: `${driver.firstName} ${driver.lastName}`
                    };
                }));
                return this.driverOptions();
            }
        }
        this.driverOptions.set([]);
        return [];
    }

    displayFn = (userId: number | null | undefined): string => {
        if (typeof userId === 'number' && userId > 0 && this.driverOptions().length > 0) {
            const result = this.driverOptions().find(d => {
                return d.key === userId;
            });
            if (result) {
                return result.value;
            }
        }
        return '';
    }

    async onDriverSelected(event: MatAutocompleteSelectedEvent) {
        const formElementOption = this.driverOptions().find(d => {
            return d.key === parseInt(event.option.value, 10);
        });
        if (formElementOption) {
            const question = `Möchten Sie ${formElementOption.value} zur Gruppe hinzufügen?`;
            const confirmation = await this.alertsService.confirm({
                text: question
            });
            if (confirmation) {
                const payload = {
                    userId: formElementOption.key
                } as GroupMemberRequestInterface
                await this.loadingService.start();
                const result = await this.groupService.addGroupMember(this.idGroup, payload)
                    .catch(async (e: HttpErrorResponse) => {
                        await this.toastService.presentToast("Fehler bei der Anfrage");
                        console.warn("Fehler bei der Anfrage: ", e);
                        return null;
                    });
                await this.loadingService.stop();
                if (result) {
                    await this.toastService.presentToast("Mitglied erfolgreich zur Gruppe hinzugefügt.");
                    this.reload.emit(true);
                }
            }
        }
        this.clearSelection();
    }

    showClearButton(): boolean {
        return (this.autocomplete.nativeElement.value !== '');
    }

    clearSelection() {
        this.driverOptions.set([]);
        this.autocomplete.nativeElement.value = '';
    }



}
