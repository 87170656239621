
<ion-row class="form">
    <form [formGroup]="loginForm">
        <ion-col>
            <ion-row>
                <ion-col>
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Benutzername
                            <ui-form-error [showError]="showError(loginForm, 'username')">
                                Benutzername darf nicht leer sein.
                            </ui-form-error>
                        </mat-label>
                        <input matInput type="email" formControlName="username" autocomplete="username" 
                        (cdkAutofill)="onAutofill($event)">
                    </mat-form-field>
                </ion-col>
            </ion-row>
    
            <ion-row>
                <ion-col>
                    <mat-form-field appearance="outline">
                        <mat-label>
                            Passwort
                        </mat-label>

                        @if (hidePassword()) {
                            <input matInput type="password" autocomplete="current-password"
                            formControlName="password" (cdkAutofill)="onAutofill($event)">
                        } @else {
                            <input matInput type="text" formControlName="password" (cdkAutofill)="onAutofill($event)">
                        }
                        
                        <!-- Visibility Toggle-->
                        <mat-icon matSuffix (click)="toggleVisibility()">{{!hidePassword() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </ion-col>
            </ion-row>
    
            <ion-row class="buttons">
                <ion-button expand="full" (click)="clickLogin()" [disabled]="disableLogin()">
                    Anmelden
                </ion-button>
                <ion-button expand="full" (click)="clickResetPassword()">
                    Passwort vergessen?
                </ion-button>
            </ion-row>
        </ion-col>
    </form>
    
</ion-row>
