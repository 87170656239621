@if(jobId > 0 && driverId > 0 && this.newListType) {
    <ion-header>
        <ion-col>
            <ion-title>
                {{title}}
            </ion-title>
        </ion-col>
    </ion-header>
    <ion-content>
        <ion-col>
            <ion-row>
                <ion-col>
                    @if (this.newListType === 500) {
                        <ion-item lines="none">
                            <ion-label>
                                <ion-text [color]="IconTheme.DANGER"> Fahrer:in {{driverName}} ablehnen.</ion-text>
                                <br/>
                                Abgelehnte Fahrer:innen können sich nicht mehr auf diesen Job bewerben.
                            </ion-label>
                        </ion-item>
                    } @else if (this.newListType === 200) {
                        <ion-item lines="none">
                            <ion-label>
                                <ion-text [color]="IconTheme.DANGER"> Fahrer:in {{driverName}} zuweisen.</ion-text>
                                <br/>
                                Fahrer:in wird diesem Job zugewiesen.
                            </ion-label>
                        </ion-item>
                    } @else if (this.newListType === 100) {
                        <ion-item lines="none">
                            <ion-label>
                                <ion-text [color]="IconTheme.DANGER"> Fahrer:in {{driverName}} auf Warteliste setzen.</ion-text>
                                <br/>
                                Fahrer:in wird auf die Warteliste verschoben.
                            </ion-label>
                        </ion-item>
                    }
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-toggle
                            [enableOnOffLabels]="true"
                            [checked]="sendMail"
                            (ionChange)="onToggleSendMail($event)"
                            labelPlacement="start"
                        >Fahrer:in per E-Mail informieren</ion-toggle>
                    </ion-item>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button (click)="clickCancel()" [color]="IconTheme.SECONDARY">
                    <mat-icon [icon]="IconEnum.CANCEL" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Schließen
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="end">
                <ion-button (click)="clickSave()" [color]="IconTheme.SUCCESS">
                    <mat-icon [icon]="IconEnum.SAVE" [theme]="IconTheme.INHERIT" ></mat-icon>
                    &nbsp; Speichern
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
}


