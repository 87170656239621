import { Component, EventEmitter, Input, OnChanges, OnInit, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
    AgencyDriverDocumentInterface,
    AgencyMemberInterface,
    BooleanToStringPipe,
    DocumentEavInterface,
    EavDocumentId,
    EavService,
    IconDirective,
    IconEnum,
    IconThemeEnum,
    LoadingService,
    PrintNbDatePipe,
    TitleIconEnum,
    ToastService
} from '@nf-workforce/shared';
import { AgencyDriverDocumentService } from '../../../services/agency-driver-document.service';
import { MatIcon } from '@angular/material/icon';
import { DriverOneDocumentComponent } from './driver-one-document/driver-one-document.component';

@Component({
    selector: 'agency-driver-document',
    standalone: true,
    imports: [
        CommonModule,
        IonicModule,
        PrintNbDatePipe,
        BooleanToStringPipe,
        IconDirective,
        MatIcon,
        DriverOneDocumentComponent
    ],
    templateUrl: './driver-document.component.html',
    styleUrl: './driver-document.component.scss',
    providers: []
})
export class DriverDocumentComponent implements OnInit, OnChanges {
    @Input()
    account!: AgencyMemberInterface | null;

    eavDocuments: DocumentEavInterface[] = [];
    driverDocuments: AgencyDriverDocumentInterface[] = [];
    missingEavDocuments: DocumentEavInterface[] = [];
    showPlace: WritableSignal<boolean> = signal(false);

    @Output()
    reload: EventEmitter<unknown> = new EventEmitter();

    constructor(
        private eavService: EavService,
        private loadingService: LoadingService,
        private driverService: AgencyDriverDocumentService,
        private toastService: ToastService
    ) {
        const eavDocuments = this.eavService.getDocuments();
        if (eavDocuments) {
            this.eavDocuments = eavDocuments;
        } else {
            throw new Error('EavDokumente nicht gesetzt');
        }
    }

    ngOnInit(): void {
        if (this.account && this.account.driver) {
            this.driverDocuments = this.account.driver.documents;
            this.setMissingDocuments();
            this.showPlace.set(true);
        }
    }

    setMissingDocuments() {
        this.missingEavDocuments = [];
        const eavRequired = this.eavDocuments.filter(function(eav) {
            return (eav.isRequired && eav.onlyDriver);
        });
        eavRequired.forEach((eav) => {
            const document = this.driverDocuments?.find((doc) => {
                return doc.eav === eav.id;
            });
            if (typeof document === 'undefined') {
                this.missingEavDocuments.push(eav);
            }
        });
    }

    isSingleEavDocument(eavId: number) {
        return (eavId !== EavDocumentId.DRIVER_OTHERS_ID && eavId !== EavDocumentId.DRIVER_CERTS_ID);
    }

    hasDocument(eav: DocumentEavInterface): boolean {
        const document = this.findOneDocumentByEav(eav);
        return (document !== undefined);
    }

    findOneDocumentByEav(eav: DocumentEavInterface): AgencyDriverDocumentInterface | undefined {
        return this.driverDocuments?.find((doc) => {
            return (doc.eav === eav.id);
        });
    }

    findAllDocumentsByEav(eav: DocumentEavInterface): AgencyDriverDocumentInterface[] | undefined {
        return this.driverDocuments?.filter((doc) => {
            return (doc.eav === eav.id);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['account'] && this.account && this.account.driver !== null) {
            this.driverDocuments = this.account.driver.documents;
        }
    }


    /**
     * Akzeptieren des Fahrerdokumentes
     *
     * @param document
     * @returns
     */
    async clickAcceptDocument(document: AgencyDriverDocumentInterface) {
        if (document === undefined) {
            console.warn("Annehmen nicht möglich - Document undefined");
            return;
        }

        await this.loadingService.start();
        const result = await this.driverService.acceptDriverDocument(document.id)
            .catch((e) => {
                console.debug("Fehler beim Download: ", e);
                this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();

        if (result) {
            await this.toastService.presentToast("Das Dokument wurde erfolgreich akzeptiert.");
            this.reload.emit(true);
        }

    }

    /**
     * Ablehnen des Fahrer-Dokumentes
     *
     * @param document
     * @returns
     */
    async clickRejectDocument(document: AgencyDriverDocumentInterface) {
        if (document === undefined) {
            console.warn("Ablehnen nicht möglich - Document undefined");
            return;
        }

        await this.loadingService.start();
        const result = await this.driverService.rejectDriverDocument(document.id)
            .catch((e) => {
                console.debug("Fehler beim Download: ", e);
                this.toastService.presentToast("Fehler bei der Anfrage, bitte versuchen Sie es später erneut.");
                return null;
            });

        await this.loadingService.stop();

        if (result) {
            await this.toastService.presentToast("Das Dokument wurde erfolgreich akzeptiert.");
            this.reload.emit(true);
        }
    }

    protected readonly Icon = TitleIconEnum;
    protected readonly IconTheme = IconThemeEnum;
    protected readonly IconEnum = IconEnum;
}
