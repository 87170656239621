@if(showPlace()) {
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header driver-account-header--masterdata">
                <h2 class="driver-account-header__text">
                    {{ account.firstName }}<br>
                    {{ account.lastName }}<br>
                    <div class="driver-account-header__links">
                        <a href="mailto:{{ account.username }}">{{ account.username }}</a>
                        @if (account.phone) {
                            <br><a href="tel:{{ account.phone }}">{{ account.phone }}</a>
                        }
                    </div>
                </h2>
                <img class="driver-account-header__image"
                     [src]="profileImage"
                     alt="{{account | fullname }}"
                >
            </div>
        </ion-card-header>
    </ion-card>
    <br/>
    <agency-driver-status-card
        [account]="account"
        [driver]="driver"
        (reload)="reloadDriver()"
    ></agency-driver-status-card>
    <br/>
    <ion-card class="show-on-hover-trigger">
        <ion-card-header>
            <div class="driver-account-header">
                <h2 class="driver-account-header__text">Account</h2>
                <mat-icon class="driver-account-header__icon"
                          [icon]="Icon.ACCOUNT"
                          [theme]="IconTheme.INHERIT"
                ></mat-icon>
            </div>
        </ion-card-header>
        <ion-card-content>
            <div class="driver-skill">
                <label class="driver-skill__label">
                    Login ist @if (driver.isActive) { erlaubt } @else { verboten }
                </label>
                <span class="driver-skill__skill">
                    Benutzername: {{ account.username }}
                </span>
                <br/>
                @if (driver.isActive) {
                    @if (account.isValidated && account.lastMail) {
                        <span class="driver-skill__skill">
                            Fahrer:in hat Account bestätigt
                            &nbsp;|&nbsp;
                            Passwort-Mail:
                            @if (account.lastMail) {
                                {{ account.lastMail | printNbDateTime }}
                            }
                        </span>
                    } @else {
                        @if (account.lastMail) {
                            <span class="driver-skill__skill">
                                <ion-text [color]="(!account.lastMail)?IconTheme.DANGER:IconTheme.INHERIT">
                                        Passwort-Mail: {{ account.lastMail | printNbDateTime }}
                                </ion-text>
                            </span>
                            <br/>
                        }
                        <span class="driver-skill__skill">
                            <ion-text [color]="(!account.isValidated)?IconTheme.DANGER:IconTheme.INHERIT">
                                @if (!account.isValidated) {
                                    Fahrer:in hat noch kein eigenes Passwort gesetzt!
                                } @else {
                                    Fahrer:in hat eigenes Passwort gesetzt!
                                }
                            </ion-text>
                        </span>
                    }
                }
            </div>
        </ion-card-content>
    </ion-card>
}
