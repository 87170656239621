@if (agency()) {
    <ion-content>
        <ion-col>
            <ion-row>
                <ion-col>
                    <ion-card class="show-on-hover-trigger">
                        <ion-card-header>
                            <div class="driver-account-header driver-account-header--masterdata">
                                <h2 class="driver-account-header__text">
                                    {{ agency()?.fullName }}<br/>
                                    {{ agency()?.name}}
                                </h2>
                                <img class="driver-account-header__image"
                                     [src]="logoUrl"
                                     alt="{{ agency()?.name}}"
                                     (click)="clickGoToAgencyLogo()"
                                >
                            </div>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-row>
                                <ion-col>
                                    <ion-card class="show-on-hover-trigger" (click)="clickEditAgency()">
                                        <ion-card-content>
                                            <div class="driver-skill">
                                                <label class="driver-skill__label">
                                                    <span class="driver-skill__label-text">Ihre Agentur</span>
                                                    <span class="driver-skill__edit-button show-on-hover" role="button">
                                                        <mat-icon
                                                            [icon]="IconEnum.EDIT"
                                                            [theme]="IconTheme.INHERIT">
                                                        </mat-icon>
                                                    </span>
                                                </label>
                                                <span class="driver-skill__skill">
                                                    {{ agency()?.fullName }}<br/>
                                                    {{ agency()?.name}}<br/>
                                                    Tel: {{phone}} <br>
                                                    Web: {{web}} <br>
                                                    Kurzbeschreibung: {{description}}
                                                </span>
                                            </div>
                                        </ion-card-content>
                                    </ion-card>
                                </ion-col>
                            </ion-row>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-content>
}
