import { AbstractControl, ValidationErrors } from "@angular/forms";

// Diese Regexe haben nicht geklappt - Geht so wohl nicht in Javascript

// const hasNumbers = /[\p{Nd}]/;
// const hasLowercaseLetters = /[\p{Ll}]/;
// const hasUppercaseLetters = /[\p{Lu}]/;
// const hasSymbols = /[^[\p{Nd}\p{Lu}\p{Ll}]]/;

const hasNumbers = /(?=.*\d)/;
const hasUppercaseLetters = /(?=.*[A-Z])/;
const hasLowercaseLetters = /(?=.*[a-z])/;
const hasSymbols = /(?=.*\W)/;

export function passwordFormatValidator(control: AbstractControl): ValidationErrors | null {
    const passwordValue = control.value;

    // Wenn die Passwortlänge mindestens 20 Zeichen beträgt, so wird hier die restliche validierung übersprungen
    if (passwordValue !== null && passwordValue.length > 20) {
        return null;
    }

    if (
        !hasNumbers.test(passwordValue)
        || !hasUppercaseLetters.test(passwordValue)
        || !hasLowercaseLetters.test(passwordValue)
        || !hasSymbols.test(passwordValue)
    ) {
        return {
            nbPasswordFormat: "Das Passwort muss mindesten ein Sonderzeichen, Großbuchstaben, Kleinbuchstaben sowie Nummer enthalten."
        }
    }

    return null;
}
